import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ArrowDropDown, ArrowDropUp, OpenInNew, AccountCircle, Visibility, CheckCircle, SubdirectoryArrowRight, FilterList, FilterListOff, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { Sankey, Layer, Rectangle, Tooltip as Charttip } from 'recharts'
import NumberFormat from 'react-number-format'
import { reduce, uniqBy, sortBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc } from '../../functions'
import Popover from '../Popover'
import PartName from '../Parts/PartName'
import { findLineItems } from '../Invoices/functions'
import InvoiceProp from '../Invoices/InvoiceProp'
import CreateInvoice from '../Invoices/Create'
import AllocateJobs from '../Jobs/Allocate'
import ContactProp from '../Contacts/ContactProp'
import Ship from '../Shipments/Ship'
import { findPartOrders } from './functions'
import PartOrderAction from './PartOrderAction'
import OrderNumber from './OrderNumber'
import OrderContactProp from './OrderContactProp'

export const PartOrderRow = ({ companyId, viewOptions, ...partOrder }) => {
  const { id: partOrderId, orderId, lineNumber, isPurchase, partId, unitPrice, quantity, quantityBalance, status, dueAt = new Date(), createdAt, isPriceTbd, isDueAsap } = partOrder
  const [isInvoicing, setIsInvoicing] = useState(false)
  const [invoiceLineItems, setInvoiceLineItems] = useState()

  const navigate = useNavigate()

  useAsyncFunc(async args => {
    if (args.partOrderId) return findLineItems(args)
    return () => null
  }, { partOrderId, orderBy: ['createdAt','desc'] }, setInvoiceLineItems, [partOrderId])

  const isInvoiced = invoiceLineItems && invoiceLineItems.length > 0
  const invoices = uniqBy(invoiceLineItems, ({ invoiceId }) => invoiceId)
  const totalPaid = reduce((invoiceLineItems || []), (sum, { status, quantity, unitPrice }) => sum + (status === 'COMPLETED' ? quantity * unitPrice : 0), 0)
  const isPaidInFull = totalPaid === quantity * unitPrice

  return (
    <TableRow>
      {!viewOptions.nested && (
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <IconButton size="small" edge="start" style={{ marginLeft: '-5px' }} onClick={() => navigate(`/orders/${orderId}`)}>
            <Visibility fontSize="small" color="disabled" />
          </IconButton>
          <OrderNumber orderId={orderId} />
        </TableCell>
      )}
      <TableCell>
        <IconButton size="small" edge="start" style={{ marginLeft: '2px' }}>
          <SubdirectoryArrowRight fontSize="small" color="disabled" />
        </IconButton>
        {`${viewOptions.nested ? `Line ` : ``}${lineNumber}`}
      </TableCell>
      <TableCell colSpan={viewOptions.nested ? 2 : 1}>
        <Tooltip title={<PartName partId={partId} />}>
          <Typography variant="body2" style={{ width: '170px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {viewOptions.nested ? <PartName partId={partId} /> : <OrderContactProp partOrderId={partOrderId} contactIdField="shipToContactId" prop="name" />}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>{(viewOptions.showDate === 'createdAt' ? createdAt.toLocaleDateString() : isDueAsap ? 'ASAP' : dueAt.toLocaleDateString())}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {`${quantity - quantityBalance} / ${quantity}`}
        <PartOrderAction companyId={companyId} partOrderId={partOrder.id} shippingDisabled={isPurchase} allocatingDisabled={isPurchase} />
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {isPriceTbd ? 'TBD' : (
          <NumberFormat
            value={unitPrice*quantity}
            prefix="$"
            thousandSeparator
            fixedDecimalScale
            decimalScale={2}
            displayType="text"
          />
        )}
        <Popover
          button={(
            <Button
              className="tiny"
              size="small"
              variant="outlined"
              {...!isInvoiced ? {
                color: 'warning',
                onClick: () => !isPurchase && setIsInvoicing(true),
              } : !isPaidInFull ? {
                color: 'primary',
              } : {
                color: 'success',
                startIcon: <CheckCircle />,
              }}
              style={{ minWidth: 'auto', marginLeft: '10px', padding: `0 5px 0${isPaidInFull ? ` 12px` : ''}`, fontSize: '12px' }}
              disableElevation
            >
              {!isInvoiced ? 'No Invoice' :
                totalPaid === 0 ? `Invoiced (${invoices.length})` :
                  !isPaidInFull ? 'Partially Paid' :
                    'Paid In Full'}
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          {...invoices.length > 0 && { content: (
            <Box style={{ padding: '15px 20px 5px' }}>
              {invoices.map(({ invoiceId }) => (
                <Box key={invoiceId} style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
                  {`Invoice number `}
                  <InvoiceProp invoiceId={invoiceId} prop="number" />
                  <Tooltip title="Open In New Window">
                    <Link to={`/invoices/${invoiceId}`} target="_blank" rel="noopener noreferrer">
                      <IconButton size="small">
                        <OpenInNew fontSize="small" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Box>
              ))}
            </Box>
          )}}
          trigger="hover"
          clickableInside
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
        <CreateInvoice
          companyId={companyId}
          orderId={orderId}
          forceOpen={isInvoicing}
          setForceOpen={setIsInvoicing}
          data={{ companyId, status: 'OPEN', isNumberAuto: true, date: new Date() }}
        />
      </TableCell>
    </TableRow>
  )
}

function OrderRow({ id, companyId, number, numberRef, shipFromContactId, shipToContactId, isPurchase, viewOptions }) {
  const [partOrders, setPartOrders] = useState()
  const [invoiceLineItems, setInvoiceLineItems] = useState()
  const [isShowingPartOrders, setIsShowingPartOrders] = useState(false)

  const navigate = useNavigate()
  const earliestDueUnshippedPartOrder = sortBy(partOrders, ({ isDueAsap, dueAt }) => isDueAsap ? 1 : dueAt).find(({ status }) => status !== 'SHIPPED')

  useAsyncFunc(async args => {
    if (id) return findPartOrders(args)
    return () => null
  }, { orderId: id, statusNotIn: ['ARCHIVED'], orderBy: ['lineNumber','asc'] }, setPartOrders, [id])

  useEffect(() => {
    if (partOrders && partOrders.length > 0) findLineItems({ partOrderIdIn: partOrders.map(({ id }) => id), orderBy: ['createdAt','desc'] }).then(setInvoiceLineItems)
  }, [partOrders])

  return (
    <>
      <TableRow>
        <TableCell style={{ whiteSpace: 'nowrap' }}>
          <IconButton size="small" edge="start" style={{ marginLeft: '-5px' }} onClick={() => navigate(`/orders/${id}`)}>
            <Visibility fontSize="small" color="disabled" />
          </IconButton>
          {number}
          <IconButton size="small" onClick={() => setIsShowingPartOrders(!isShowingPartOrders)}>
            {isShowingPartOrders ? <ArrowDropUp fontSize="small" color="disabled" /> : <ArrowDropDown fontSize="small" color="disabled" />}
          </IconButton>
        </TableCell>
        <TableCell>{numberRef}</TableCell>
        <TableCell>
          <IconButton size="small" edge="start" style={{ marginLeft: '-5px' }} onClick={() => navigate(`/orders/${id}`)}>
            <AccountCircle fontSize="small" color="disabled" />
          </IconButton>
          <ContactProp contactId={isPurchase ? shipFromContactId : shipToContactId} prop="name" />
        </TableCell>
        <TableCell>
          <Typography variant="body2" {...(earliestDueUnshippedPartOrder && (earliestDueUnshippedPartOrder.dueAt < new Date() || earliestDueUnshippedPartOrder.isDueAsap) && { style: { fontWeight: 'bold', color: 'red' } })}>
            {earliestDueUnshippedPartOrder ? earliestDueUnshippedPartOrder.isDueAsap ? 'ASAP' : earliestDueUnshippedPartOrder.dueAt?.toLocaleDateString() : ''}
          </Typography>
        </TableCell>
        <TableCell>
          {reduce((partOrders || []), (sum, { status, quantity, quantityBalance }) => sum + (status === 'SHIPPED' ? quantity - quantityBalance : 0), 0)}
          {` / `}
          {reduce((partOrders || []), (sum, { quantity }) => sum + quantity, 0)}
        </TableCell>
        <TableCell style={{ whiteSpace: 'nowrap' }}>
          {earliestDueUnshippedPartOrder?.isPriceTbd ? 'TBD' : (
            <NumberFormat
              value={reduce((partOrders || []), (sum, { unitPrice, quantity }) => sum + unitPrice*quantity, 0)}
              prefix="$"
              thousandSeparator
              fixedDecimalScale
              decimalScale={2}
              displayType="text"
            />
          )}
          {` / `}
          <NumberFormat
            value={reduce((invoiceLineItems || []), (sum, { unitPrice, quantity }) => sum + unitPrice*quantity, 0)}
            prefix="$"
            thousandSeparator
            fixedDecimalScale
            decimalScale={2}
            displayType="text"
          />
          {` (${uniqBy((invoiceLineItems || []), ({ invoiceId }) => invoiceId).length})`}
          {` / `}
          <NumberFormat
            value={reduce((invoiceLineItems || []), (sum, { unitPrice, quantity, status }) => sum + (status === 'COMPLETED' ? unitPrice * quantity : 0), 0)}
            prefix="$"
            thousandSeparator
            fixedDecimalScale
            decimalScale={2}
            displayType="text"
          />
          {` (${uniqBy((invoiceLineItems || []).filter(({ status }) => status === 'COMPLETED'), ({ invoiceId }) => invoiceId).length})`}
        </TableCell>
      </TableRow>
      {isShowingPartOrders && sortBy(partOrders || [], ['lineNumber']).map(partOrder => <PartOrderRow key={partOrder.id} companyId={companyId} {...partOrder} viewOptions={{ ...viewOptions, nested: true }} />)}
    </>
  )
}

export default OrderRow