import { Box, Button } from '@mui/material'
import { Visibility, PrintOutlined, ContentCopy, DeleteOutline, Launch } from '@mui/icons-material'
import { get } from 'lodash'
import Confirm from '../Confirm'
import ShareLink from '../ShareLink'
import { updatePart } from './functions'
import CreatePart from './Create'

function Actions({ item }) {
  return item ? (
    <>
      <Button style={{ flexGrow: 1 }}>
        {`Part Number ${get(item, 'number')}`}
      </Button>
      <Box>
        <ShareLink
          button={(
            <Button
              startIcon={<Launch />}
              style={{ marginLeft: '5px' }}
            >
              Share
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          url={`edimfg.com/parts/${item.id}`}
        />
        <Button
          startIcon={<PrintOutlined />}
          style={{ marginLeft: '5px' }}
        >
          Print
        </Button>
        <CreatePart
          companyId={item.companyId}
          button={(
            <Button
              startIcon={<ContentCopy />}
              style={{ marginLeft: '5px' }}
            >
              Copy
            </Button>
          )}
          buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
          data={{ number: item.number, revision: item.revision, type: item.type, name: item.name, description: item.description }}
          onCreate={() => window.location.reload()}
        />
        <Confirm
          button={(
            <Button
              color="error"
              startIcon={<DeleteOutline />}
              style={{ marginLeft: '5px' }}
            >
              Delete
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          title="Confirm delete?"
          content="Are you sure you want to delete this part?"
          onConfirm={() => updatePart(item.id, { status: 'INACTIVE' }).then(() => window.location.reload())}
        />
      </Box>
    </>
  ) : (
    <Button style={{ flexGrow: 1 }}>
      No item selected
    </Button>
  )
}

export default Actions