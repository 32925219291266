import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, Badge, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Add, Clear, CheckBoxOutlineBlank, CheckBox, DeleteOutline, Architecture } from '@mui/icons-material'
import { differenceBy } from 'lodash'
import { useAsyncFunc, getDateDiffStr, positiveInt, getLocal, setLocal, jobRanges as ranges, jobStatuses as statuses } from '../../functions'
import { findJobs } from './functions'
import CreateJob from './Create'
import JobFilters from './Filters'
import JobWithConnections from './JobWithConnections'

function ConnectJobs({ companyId, partId, connected, connectionsQuery, connectionNode, nodePadding, button, buttonStyle, forceOpen, setForceOpen, onConnect, createJobData }) {
  const [isConnecting, setIsConnecting] = useState(false)
  const [jobs, setJobs] = useState([])
  const [connections, setConnections] = useState([])

  const [todayFrom, todayTo] = ranges.find(({ label }) => label === 'Today').value

  const [filterBy, setFilterBy] = useState({
    label: 'Part Number',
    value: 'partId',
  })
  const [filterByValue, setFilterByValue] = useState({
    shipAtFrom: todayFrom,
    shipAtTo: todayTo,
    dueAtFrom: todayFrom,
    dueAtTo: todayTo,
    partId,
  })
  const [statusIn, setStatusIn] = useState(statuses.map(({ value }) => value))

  const getFilterArgs = (field, value) => {
    const { shipAtFrom, shipAtTo, dueAtFrom, dueAtTo, numberBeginsWith, partId } = value
    return {
      ...field === 'shipAt' && { shipAtFrom, shipAtTo },
      ...field === 'dueAt' && { dueAtFrom, dueAtTo },
      ...field === 'poNumber' && { numberBeginsWith, orderBy: ['updatedAt','desc'] },
      ...field === 'partId' && { partId, orderBy: ['updatedAt','desc'] },
    }
  }

  const handleClose = () => {
    setIsConnecting(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isConnecting !== forceOpen) setIsConnecting(forceOpen)
  }, [forceOpen, isConnecting])

  useAsyncFunc(async args => {
    setLocal('jobsFilterBy', filterBy)
    setLocal('jobsFilterByValue', filterByValue)
    if (companyId) return findJobs(args)
    return () => []
  }, {
    companyId,
    ...getFilterArgs(filterBy.value, filterByValue),
    statusIn,
  }, setJobs, [companyId, filterBy, filterByValue, statusIn])

  useEffect(() => {
    setConnections(connected)
  }, [connected])

  const fetch = () => findJobs({
    companyId,
    ...getFilterArgs(filterBy.value, filterByValue),
    statusIn,
  }).then(setJobs)

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsConnecting(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isConnecting}
        onClose={handleClose}
      >
        <DialogTitle>Connect jobs</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <JobFilters
              companyId={companyId}
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              filterByValue={filterByValue}
              setFilterByValue={setFilterByValue}
              statusIn={statusIn}
              setStatusIn={setStatusIn}
            />
            <List style={{ marginTop: '15px', marginBottom: '15px' }}>
              {jobs.map(job => (
                <JobWithConnections
                  key={job.id}
                  {...job}
                  connectionsQuery={connectionsQuery}
                  connectionNode={connectionNode}
                  nodePadding={nodePadding}
                  onConnect={onConnect}
                />
              ))}
            </List>
            <CreateJob
              companyId={companyId}
              button={(
                <Button
                  startIcon={<Add />}
                  style={{ marginLeft: '5px' }}
                >
                  New Job
                </Button>
              )}
              buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
              data={createJobData}
              onCreate={fetch}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ConnectJobs