import { Box, Button, TextField, IconButton } from '@mui/material'
import { FilterList, Search } from '@mui/icons-material'
import { get } from 'lodash'
import { jobRanges as ranges } from '../../functions'
import Checkbox from '../Checkbox'
import DateRange from '../DateRange'
import Dropdown from '../Dropdown'
import SelectPart from '../Parts/SelectPart'
import SelectContact from '../Contacts/SelectContact'

function OrderFilters({ companyId, beforeFilters, afterFilters, filterBy, setFilterBy, filterByValue, setFilterByValue }) {
  return (
    <Box style={{ display: 'flex', gap: '10px' }}>
      {beforeFilters}
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<FilterList />}
          >
            {filterBy.label}
          </Button>
        )}
        items={[
          { label: 'Order Date', value: 'orderAt' },
          { label: 'Due Date', value: 'dueAt' },
          { label: 'PO Number', value: 'poNumber' },
          { label: 'Part Number', value: 'partId' },
          { label: 'Ship From', value: 'shipFromContactId' },
          { label: 'Ship To', value: 'shipToContactId' },
        ]}
        onChange={setFilterBy}
      />
      {filterBy.value === 'orderAt' && (
        <DateRange
          value={[filterByValue.orderAtFrom, filterByValue.orderAtTo]}
          ranges={ranges}
          inputStyle={{ padding: '6px 12px', fontSize: '14px' }}
          onChange={([orderAtFrom, orderAtTo]) => setFilterByValue({ ...filterByValue, orderAtFrom, orderAtTo })}
        />
      )}
      {filterBy.value === 'dueAt' && (
        <DateRange
          value={[filterByValue.dueAtFrom, filterByValue.dueAtTo]}
          ranges={ranges}
          inputStyle={{ padding: '6px 12px', fontSize: '14px' }}
          onChange={([dueAtFrom, dueAtTo]) => setFilterByValue({ ...filterByValue, dueAtFrom, dueAtTo })}
        />
      )}
      {filterBy.value === 'poNumber' && (
        <>
          <TextField
            size="small"
            variant="outlined"
            placeholder="PO Number"
            value={filterByValue.numberRefBeginsWith}
            onChange={({ target: { value } }) => setFilterByValue({ ...filterByValue, numberRefBeginsWith: value })}
            InputProps={{
              startAdornment: <IconButton edge="start"><Search /></IconButton>,
            }}
          />
        </>
      )}
      {filterBy.value === 'partId' && (
        <Box style={{ flexGrow: 1 }}>
          <SelectPart
            companyId={companyId}
            partId={filterByValue.partId}
            onChange={part => setFilterByValue({ ...filterByValue, partId: get(part, 'id') })}
          />
        </Box>
      )}
      {filterBy.value === 'shipFromContactId' && (
        <Box style={{ flexGrow: 1 }}>
          <SelectContact
            companyId={companyId}
            contactId={filterByValue.shipFromContactId}
            onChange={contact => setFilterByValue({ ...filterByValue, shipFromContactId: get(contact, 'id') })}
          />
        </Box>
      )}
      {filterBy.value === 'shipToContactId' && (
        <Box style={{ flexGrow: 1 }}>
          <SelectContact
            companyId={companyId}
            contactId={filterByValue.shipToContactId}
            onChange={contact => setFilterByValue({ ...filterByValue, shipToContactId: get(contact, 'id') })}
          />
        </Box>
      )}
      {afterFilters}
    </Box>
  )
}

export default OrderFilters