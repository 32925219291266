import { useState, useEffect } from 'react'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'

function Confirm({ button, buttonStyle, forceOpen, setForceOpen, title, content, onConfirm, onCancel, confirmButton, cancelButton }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  const confirm = () => {
    if (onConfirm) onConfirm()
    handleClose()
  }

  const cancel = () => {
    if (onCancel) onCancel()
    handleClose()
  }

  return (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        style={{ zIndex: 9999 }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {confirmButton ? confirmButton(confirm) : (
            <Button
              variant="contained"
              startIcon={<Check />}
              disableElevation
              onClick={confirm}
            >
              Confirm
            </Button>
          )}
          {cancelButton ? cancelButton(cancel) : (
            <Button
              variant="outlined"
              startIcon={<Clear />}
              disableElevation
              onClick={cancel}
            >
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Confirm