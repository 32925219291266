import { useState, useEffect, useMemo } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer, Skeleton } from '@mui/material'
import { UploadFile, PrintOutlined, AddLink, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { useAsyncFunc, findFileRefs, findFiles, addFileRef, getDateDiffStr } from '../functions'
import UpdateFileRef from './UpdateFileRef'
import Checkbox from './Checkbox'
import Dropdown from './Dropdown'
import Paginate from './Paginate'
import Popover from './Popover'
import ReferenceHelper from './ReferenceHelper'
import SearchFiles from './SearchFiles'
import Upload from './Upload'
import { JobUsage, PartUsage, FileUsage } from './FileUsage'

const fileTypes = [
  { label: 'FAIs', value: 'FAI' },
  { label: 'Technical Drawings', value: 'Technical Drawing' },
  { label: 'Certificates', value: 'Certificate' },
  { label: 'Purchase Orders', value: 'Purchase Order' },
  { label: 'Invoices', value: 'Invoice' },
]

function FileRefs({ companyId, refFromId, refFroms, refTos, table, inline, inlineItemRender, selected, setSelected, ignored, setIgnored, onDragStart, onDragEnd }) {
  const [fileRefs, setFileRefs] = useState()
  const [filterTypes, setFilterTypes] = useState(['All'])
  const [startAfter, setStartAfter] = useState()
  const [endBefore, setEndBefore] = useState()
  const [limit, setLimit] = useState(5)
  const [isUploading, setIsUploading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const refFromIds = useMemo(() => refFroms.map(({ id }) => id), [refFroms])

  useAsyncFunc(findFileRefs, {
    refFromIds,
    ...!filterTypes.includes('All') && { categoriesIn: filterTypes },
    orderBy: ['createdAt','desc'],
    ...startAfter && { startAfter },
    ...endBefore && { endBefore },
    limit,
  }, setFileRefs, [refFromIds, filterTypes, startAfter, endBefore, limit])

  const fetch = () => findFileRefs({
    refFromIds,
    ...!filterTypes.includes('All') && { categoriesIn: filterTypes },
    orderBy: ['createdAt','desc'],
    ...startAfter && { startAfter },
    ...endBefore && { endBefore },
    limit,
  }).then(setFileRefs)

  return inline ? (
    <>
      <SearchFiles
        companyId={companyId}
        refFroms={refFroms}
        refFromIds={refFromIds}
        onUpdated={fetch}
        button={(
          <Tooltip title="Attach file">
            <IconButton
              edge="start"
              size="small"
              variant="outlined"
            >
              <AddLink fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        buttonStyle={{ display: 'inline-block', marginBottom: '5px', verticalAlign: 'top' }}
      />
      {(fileRefs || []).map(fileRef => {
        const { id, name, updatedAt } = fileRef
        return inlineItemRender ? inlineItemRender(fileRef) : (
          <UpdateFileRef
            key={id}
            button={(
              <Tooltip title={`Attached ${updatedAt.toLocaleDateString()}`}>
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginLeft: '5px', marginBottom: '5px' }}
                >
                  {name}
                </Button>
              </Tooltip>
            )}
            buttonStyle={{ display: 'inline-block' }}
            fileRef={fileRef}
            onUpdated={fetch}
          />
        )
      })}
    </>
  ) : (
    <Box style={{ border: '1px solid #bfbfbf', borderRadius: '5px', overflowX: 'hidden' }}>
      <Stack>
        <Alert
          severity="info"
          action={<ReferenceHelper refFroms={refFroms} />}
        >
          {`Send files to add@file.edimfg.com to append to list`}
        </Alert>
      </Stack>
      <Upload
        companyId={companyId}
        forceOpen={isUploading}
        setForceOpen={setIsUploading}
        onUploaded={async files => Promise.all(
          files.map(({ id: fileId, name }) => (refTos || refFroms).map(({ id, type }) => addFileRef({ companyId, name, fileId, refFromId: id, refFromType: type, status: 'ACTIVE', ...!filterTypes.includes('All') && { categories: filterTypes } })))
        ).then(fileRefs => {
          fetch()
          setIsUploading(false)
          enqueueSnackbar('Files uploaded successfully.', {
            variant: 'success',
          })
        })}
      />
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '10px', padding: '8px 8px 0 8px' }}>
        <Dropdown
          button={(
            <Box>
              <Button
                size="small"
                variant="outlined"
                startIcon={<FilterList />}
              >
                Filter
              </Button>
            </Box>
          )}
          items={[
            {
              label: (
                <Checkbox
                  checked={isEqual(filterTypes, fileTypes.map(({ value }) => value))}
                  onChange={() => setFilterTypes(isEqual(filterTypes, fileTypes.map(({ value }) => value)) ? [] : fileTypes.map(({ value }) => value))}
                  label="All"
                />
              )
            },
            ...fileTypes.map(({ label, value }) => ({
              label: (
                <Checkbox
                  checked={filterTypes.includes(value)}
                  onChange={() => {}}
                  label={label}
                />
              ),
              value,
            })),
          ]}
          disableCloseOnSelect
        />
        <Button
          size="small"
          variant="outlined"
          startIcon={<UploadFile />}
          onClick={() => setIsUploading(true)}
        >
          Upload
        </Button>
      </Box>
      {table ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Filename</TableCell>
                <TableCell>Usage</TableCell>
                <TableCell>Part(s)</TableCell>
                <TableCell>Job(s)</TableCell>
                <TableCell>Added</TableCell>
                {(setSelected || setIgnored) && (
                  <TableCell>
                    <Tooltip title="Include in FAI">
                      <PrintOutlined style={{ marginLeft: '-2px', verticalAlign: 'middle' }} />
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
            {fileRefs?.length === 0 && <TableRow><TableCell colSpan={6}>No files found.</TableCell></TableRow>}
            {(fileRefs || []).map((fileRef, index) => {
              const { id, fileId, name, categories, createdAt } = fileRef
              const ext = name.split('.')[name.split('.').length - 1]
              return (
                <TableRow
                  key={id}
                  {...onDragStart && onDragEnd && {
                    draggable: true,
                    onDragStart: e => onDragStart(e, fileRef),
                    onDragEnd: e => onDragEnd(e, fileRef),
                  }}
                >
                  <TableCell>
                    <UpdateFileRef
                      button={(
                        <Box style={{ width: '32px' }}>
                          <FileIcon extension={ext} {...defaultStyles[ext]} />
                        </Box>
                      )}
                      buttonStyle={{ cursor: 'zoom-in' }}
                      fileRef={fileRef}
                      fileRefs={fileRefs}
                      startingIndex={index}
                      onUpdated={fetch}
                      onRemoved={() => {
                        setFileRefs()
                        return fetch()
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {name}
                    <Stack direction="row" spacing={.5} style={{ marginTop: '2px', marginLeft: '-6px' }}>
                      {(categories || []).map(category => <Chip key={category} size="small" label={category} />)}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={.5}>
                      <Chip size="small" label={<FileUsage fileId={fileId} />} />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={.5}>
                      <Chip size="small" label={<PartUsage fileId={fileId} />} />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={.5}>
                      <Chip size="small" label={<JobUsage fileId={fileId} />} />
                    </Stack>
                  </TableCell>
                  <TableCell>{createdAt.toLocaleDateString()}</TableCell>
                  {(setSelected || setIgnored) && (
                    <TableCell>
                      <Checkbox
                        checked={(selected && selected.find(s => s.id === id)) || (ignored && !ignored.find(s => s.id === id))}
                        onChange={({ target: { checked } }) => {
                          if (selected && setSelected) {
                            if (checked && !selected.find(s => id === s.id)) return setSelected([...selected, fileRef])
                            return setSelected(selected.filter(s => s.id !== id))
                          }
                          if (ignored && setIgnored) {
                            if (!checked && !ignored.find(s => id === s.id)) return setIgnored([...ignored, fileRef])
                            return setIgnored(ignored.filter(s => s.id !== id))
                          }
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <List style={{ minHeight: '30vh' }}>
          {fileRefs && fileRefs.length === 0 && (
            <Box style={{ padding: '5px 10px' }}>
              <Typography color="GrayText">No references found</Typography>
              <Typography color="#1976d2" sx={{ cursor: 'pointer' }} onClick={() => setIsUploading(true)}>Add new</Typography>
            </Box>
          )}
          {(fileRefs || []).map((fileRef, index) => (
            <UpdateFileRef
              key={fileRef.id}
              button={(
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <Badge
                        color="error"
                        variant="dot"
                      >
                        <Article />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText
                      primary={fileRef.name}
                      secondary={`Updated ${getDateDiffStr(null, fileRef.updatedAt)} | ${(fileRef.categories || []).join(' · ')}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              fileRef={fileRef}
              fileRefs={fileRefs}
              startingIndex={index}
              onUpdated={fetch}
            />
          ))}
        </List>
      )}
      {(fileRefs || []).length > 0 && refFromIds && (
        <Box style={{ display: 'flex', gap: '5px', padding: '5px' }}>
          <Paginate
            label="Prev"
            size="small"
            variant="outlined"
            startIcon={<ArrowBack />}
            disableElevation
            onClick={() => {
              setStartAfter()
              setEndBefore(fileRefs[0]._snap)
            }}
            query={() => findFileRefs({
              refFromIds,
              ...!filterTypes.includes('All') && { categoriesIn: filterTypes },
              orderBy: ['createdAt','desc'],
              endBefore: fileRefs[0]._snap,
              limit,
            })}
          />
          <Paginate
            label="Next"
            size="small"
            variant="outlined"
            endIcon={<ArrowForward />}
            disableElevation
            onClick={() => {
              setStartAfter(fileRefs[fileRefs.length - 1]._snap)
              setEndBefore()
            }}
            query={() => findFileRefs({
              refFromIds,
              ...!filterTypes.includes('All') && { categoriesIn: filterTypes },
              orderBy: ['createdAt','desc'],
              startAfter: fileRefs[fileRefs.length - 1]._snap,
              limit,
            })}
          />
        </Box>
      )}
    </Box>
  )
}

export default FileRefs