import { useState, useEffect } from 'react'
import { Box, TextField, Button, IconButton } from '@mui/material'
import { Undo, ListAlt, ArrowDropDown } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps } from '../../functions'
import ChangeLogs from '../ChangeLogs'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import PhoneField from '../PhoneField'
import SelectContact from '../Contacts/SelectContact'
import ContactFields from '../Contacts/ContactFields'
import { updateOrder } from './functions'

function EditOrderDetails({ order, onUpdate }) {
  const [updatedOrder, setUpdatedOrder] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    if (!get(updatedOrder, 'orderAt')) errors.orderAt = 'Required'
    if (get(updatedOrder, 'isPurchase') && !get(updatedOrder, 'shipFromName')) errors.shipFromName = 'Required'
    if (get(updatedOrder, 'isPurchase') && !get(updatedOrder, 'shipFromAddress')) errors.shipFromAddress = 'Required'
    if (get(updatedOrder, 'isPurchase') && !get(updatedOrder, 'shipFromPhone')) errors.shipFromPhone = 'Required'
    if (!get(updatedOrder, 'isPurchase') && !get(updatedOrder, 'shipToName')) errors.shipToName = 'Required'
    if (!get(updatedOrder, 'isPurchase') && !get(updatedOrder, 'shipToAddress')) errors.shipToAddress = 'Required'
    if (!get(updatedOrder, 'isPurchase') && !get(updatedOrder, 'shipToPhone')) errors.shipToPhone = 'Required'
    if (!get(updatedOrder, 'billToName')) errors.billToName = 'Required'
    if (!get(updatedOrder, 'billToAddress')) errors.billToAddress = 'Required'
    if (!get(updatedOrder, 'billToPhone')) errors.billToPhone = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateOrder(order.id, {
      number: updatedOrder.number,
      numberRef: updatedOrder.numberRef || updatedOrder.number,
      ...updatedOrder.orderAt && { orderAt: updatedOrder.orderAt },
      ...updatedOrder.shipVia && { shipVia: updatedOrder.shipVia },
      ...updatedOrder.terms && { terms: updatedOrder.terms },
      ...updatedOrder.shipFromContactId && { shipFromContactId: updatedOrder.shipFromContactId },
      ...updatedOrder.shipFromName && { shipFromName: updatedOrder.shipFromName },
      ...updatedOrder.shipFromAddress && { shipFromAddress: updatedOrder.shipFromAddress },
      ...updatedOrder.shipFromEmail && { shipFromEmail: updatedOrder.shipFromEmail },
      ...updatedOrder.shipFromPhone && { shipFromPhone: updatedOrder.shipFromPhone },
      ...updatedOrder.shipFromFax && { shipFromFax: updatedOrder.shipFromFax },
      ...updatedOrder.shipToContactId && { shipToContactId: updatedOrder.shipToContactId },
      ...updatedOrder.shipToName && { shipToName: updatedOrder.shipToName },
      ...updatedOrder.shipToAddress && { shipToAddress: updatedOrder.shipToAddress },
      ...updatedOrder.shipToEmail && { shipToEmail: updatedOrder.shipToEmail },
      ...updatedOrder.shipToPhone && { shipToPhone: updatedOrder.shipToPhone },
      ...updatedOrder.shipToFax && { shipToFax: updatedOrder.shipToFax },
      ...updatedOrder.billToContactId && { billToContactId: updatedOrder.billToContactId },
      ...updatedOrder.billToName && { billToName: updatedOrder.billToName },
      ...updatedOrder.billToAddress && { billToAddress: updatedOrder.billToAddress },
      ...updatedOrder.billToEmail && { billToEmail: updatedOrder.billToEmail },
      ...updatedOrder.billToPhone && { billToPhone: updatedOrder.billToPhone },
      ...updatedOrder.billToFax && { billToFax: updatedOrder.billToFax },
    }).then(onUpdate)
      .then(() => enqueueSnackbar('Order updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedOrder(order)
  }, [order])

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Order reference"
            fullWidth
            value={get(updatedOrder, 'numberRef', '')}
            onChange={({ target: { value } }) => setUpdatedOrder({ ...updatedOrder, numberRef: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'numberRef')}
          />
          <DateField
            label="Order date"
            value={get(updatedOrder, 'orderAt')}
            onChange={orderAt => setUpdatedOrder({ ...updatedOrder, orderAt })}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                fullWidth
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'orderAt')}
              />
            )}
            withDropdown
          />
          {get(updatedOrder, 'isPurchase') ? (
            <ContactFields
              companyId={order.companyId}
              data={updatedOrder}
              setData={setUpdatedOrder}
              errors={errors}
              contactLabel="Ship from name"
              addressLabel="Ship from address"
              idField="shipFromContactId"
              nameField="shipFromName"
              addressField="shipFromAddress"
              emailField="shipFromEmail"
              phoneField="shipFromPhone"
              faxField="shipFromFax"
            />
          ) : (
            <ContactFields
              companyId={order.companyId}
              data={updatedOrder}
              setData={setUpdatedOrder}
              errors={errors}
              contactLabel="Ship to name"
              addressLabel="Ship to address"
              idField="shipToContactId"
              nameField="shipToName"
              addressField="shipToAddress"
              emailField="shipToEmail"
              phoneField="shipToPhone"
              faxField="shipToFax"
            />
          )}
        </Box>
        <Box style={{ width: '50%' }}>
          <Dropdown
            button={(
              <TextField
                size="small"
                variant="outlined"
                name="shipVia"
                label="Ship Via"
                fullWidth
                value={get(updatedOrder, 'shipVia', '')}
                onChange={({ target: { value } }) => setUpdatedOrder({ ...updatedOrder, shipVia: value })}
                InputProps={{
                  endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                }}
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'shipVia')}
              />
            )}
            items={['UPS','FedEx','Our Truck'].map(value => ({ label: value, value }))}
            onChange={({ value }) => setUpdatedOrder({ ...updatedOrder, shipVia: value })}
          />
          <TextField
            size="small"
            variant="outlined"
            name="terms"
            label="Payment terms"
            fullWidth
            value={get(updatedOrder, 'terms', '')}
            onChange={({ target: { value } }) => setUpdatedOrder({ ...updatedOrder, terms: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'terms')}
          />
          <ContactFields
            companyId={order.companyId}
            data={updatedOrder}
            setData={setUpdatedOrder}
            errors={errors}
            contactLabel="Bill to name"
            addressLabel="Bill to address"
            idField="billToContactId"
            nameField="billToName"
            addressField="billToAddress"
            emailField="billToEmail"
            phoneField="billToPhone"
            faxField="billToFax"
          />
        </Box>
      </Box>
      {!isEqual(order, updatedOrder) && (
        <Button
          variant="outlined"
          startIcon={<Undo />}
          onClick={() => setUpdatedOrder(order)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        style={{ marginRight: '5px' }}
        disabled={isEqual(order, updatedOrder)}
        disableElevation
      >
        Save Details
      </Button>
      <ChangeLogs
        col="orderLogs"
        id={order.id}
        button={(
          <Button
            variant="outlined"
            disableElevation
            startIcon={<ListAlt />}
          >
            Changelog
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
      />
    </>
  )
}

export default EditOrderDetails