import { useState, useEffect } from 'react'
import { Button, Typography, TableRow, TableCell, Tooltip } from '@mui/material'
import { CheckCircle, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc } from '../../functions'
import Confirm from '../Confirm'
import PartNumber from '../Parts/PartNumber'
import PartName from '../Parts/PartName'
import { updateJob } from '../Jobs/functions'
import { findOrders, findPartOrders, updatePartOrder } from './functions'

function LookupRow({ isAddingJob, isShowingOrderData, ...job }) {
  const [partOrder, setPartOrder] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const { id, lineNumber, quantity, partId, dueAt, shipAt, updatedAt } = job

  useAsyncFunc(async ({ companyId, poNumber, lineNumber, quantity, dueAt }) => {
    if (companyId && poNumber && lineNumber && quantity && dueAt) {
      const [order] = await findOrders({ companyId, numberRef: poNumber, orderBy: ['createdAt','desc'] })
      if (!order) return null
      const [partOrder] = await findPartOrders({ orderId: order.id, lineNumber, quantity, orderBy: ['createdAt','desc'] })
      return (partOrder && dueAt.toLocaleDateString() === partOrder.dueAt.toLocaleDateString()) ? partOrder : null
    }
    return () => null
  }, job, setPartOrder, [id, updatedAt])

  return (
    <TableRow key={id}>
      <TableCell>
        {lineNumber}
        {partOrder && (
          <Tooltip title="Job matches order line item">
            <CheckCircle fontSize="small" color="success" style={{ marginLeft: '5px', verticalAlign: 'top' }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell colSpan={isAddingJob ? 2 : 1}>
        <Tooltip title={<PartName partId={partId} />}>
          <Typography variant="body2" style={{ width: '190px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <PartNumber partId={partId} />
            {` · `}
            <PartName partId={partId} />
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        {quantity}
        <Typography variant="subtitle2" color="GrayText" style={{ display: 'inline-block', marginLeft: '5px' }}>
          {`pc${quantity > 1 ? 's' : ''}`}
        </Typography>
      </TableCell>
      {isShowingOrderData && (
        <>
          <TableCell align="right">
            <NumberFormat
              value={get(partOrder, 'unitPrice', '-')}
              prefix="$"
              thousandSeparator
              fixedDecimalScale
              decimalScale={2}
              displayType="text"
            />
          </TableCell>
          <TableCell align="right">
            <NumberFormat
              value={get(partOrder, 'unitPrice', 0) * get(partOrder, 'quantity', 0)}
              prefix="$"
              thousandSeparator
              fixedDecimalScale
              decimalScale={2}
              displayType="text"
            />
          </TableCell>
        </>
      )}
      <TableCell align="right">{dueAt.toLocaleDateString()}</TableCell>
      <TableCell align="right">{shipAt.toLocaleDateString()}</TableCell>
      {isShowingOrderData && partOrder && (
        <>
          <TableCell>
            {get(partOrder, 'shippedAt') ? (
              <Tooltip title={`Confirmed: ${partOrder.shippedAt.toLocaleDateString()}`}>
                <Button
                  className="tiny"
                  size="small"
                  variant="outlined"
                  startIcon={<CheckBox />}
                  style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                  disableElevation
                >
                  Shipped
                </Button>
              </Tooltip>
            ) : (
              <Confirm
                button={(
                  <Button
                    className="tiny"
                    size="small"
                    variant="outlined"
                    startIcon={<CheckBoxOutlineBlank />}
                    style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                    disableElevation
                  >
                    Ship
                  </Button>
                )}
                buttonStyle={{ display: 'inline-block' }}
                title="Confirm shipment"
                content={`Date of shipment: ${(new Date()).toLocaleDateString()}`}
                onConfirm={() => updatePartOrder(partOrder.id, { shippedAt: new Date() })
                  .then(updatedPartOrder => {
                    setPartOrder(updatedPartOrder)
                    updateJob(job.id, { status: 'COMPLETED' })
                    enqueueSnackbar('Order updated successfully.', { variant: 'success' })
                  })
                }
              />
            )}
          </TableCell>
          <TableCell>
            {get(partOrder, 'invoicedAt') ? (
              <Tooltip title={`Confirmed: ${partOrder.invoicedAt.toLocaleDateString()}`}>
                <Button
                  className="tiny"
                  size="small"
                  variant="outlined"
                  startIcon={<CheckBox />}
                  style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                  disableElevation
                >
                  Invoiced
                </Button>
              </Tooltip>
            ) : (
              <Confirm
                button={(
                  <Button
                    className="tiny"
                    size="small"
                    variant="outlined"
                    startIcon={<CheckBoxOutlineBlank />}
                    style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                    disableElevation
                  >
                    Invoice
                  </Button>
                )}
                buttonStyle={{ display: 'inline-block' }}
                title="Confirm invoice"
                content={`Date of invoice: ${(new Date()).toLocaleDateString()}`}
                onConfirm={() => updatePartOrder(partOrder.id, { invoicedAt: new Date() })
                  .then(updatedPartOrder => {
                    setPartOrder(updatedPartOrder)
                    enqueueSnackbar('Order updated successfully.', { variant: 'success' })
                  })
                }
              />
            )}
          </TableCell>
        </>
      )}
      {isAddingJob && <TableCell align="right">{updatedAt.toLocaleDateString()}</TableCell>}
    </TableRow>
  )
}

export default LookupRow