import { TableRow, TableCell } from '@mui/material'
import NumberFormat from 'react-number-format'
import { get, sumBy, minBy, maxBy } from 'lodash'
import moment from 'moment'

function LaborRow({ partQuote, partQuoteLines }) {
  const labor = partQuoteLines.filter(({ unit }) => ['HOUR','MINUTE'].includes(unit))

  if (labor.length === 0) return

  const sumDuration = moment.duration({
    hours: sumBy(labor, ({ quantity, unit, quantityMade }) => unit === 'HOUR' ? (quantity / (quantityMade || 1)) : 0),
    minutes: sumBy(labor, ({ quantity, unit, quantityMade }) => unit === 'MINUTE' ? (quantity / (quantityMade || 1)) : 0),
  })
  const sumPrice = sumBy(labor, ({ quantity, quantityMade, unitPrice, markupRate }) => quantity*unitPrice*markupRate / (quantityMade || 1))
  const minUnitPrice = minBy(labor, ({ unitPrice, quantityMade }) => unitPrice / (quantityMade || 1)).unitPrice
  const maxUnitPrice = maxBy(labor, ({ unitPrice, quantityMade }) => unitPrice / (quantityMade || 1)).unitPrice
  const minMarkupRate = minBy(labor, ({ markupRate }) => markupRate).markupRate
  const maxMarkupRate = maxBy(labor, ({ markupRate }) => markupRate).markupRate

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell colSpan={3}>{`Labor - for ${get(partQuote, 'quantityBase')} part(s) made`}</TableCell>
      {/* <TableCell align="right">{sumDuration.asMinutes().toFixed(2)}</TableCell>
      <TableCell>MINUTE(S)</TableCell> */}
      <TableCell align="right">{`$${minUnitPrice.toFixed(2)}${maxUnitPrice !== minUnitPrice ? ` — $${maxUnitPrice.toFixed(2)}` : ''}`}</TableCell>
      <TableCell align="right">{`${minMarkupRate}x${maxMarkupRate !== minMarkupRate ? ` — ${maxMarkupRate}x` : ''}`}</TableCell>
      <TableCell align="right">
        <NumberFormat
          value={sumPrice}
          prefix="$"
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          displayType="text"
        />
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

export default LaborRow