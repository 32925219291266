import { useState, useEffect } from 'react'
import { Box, TextField, Button, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { DeleteOutline, ArrowBack, ArrowForward } from '@mui/icons-material'
import FileViewer from 'react-file-viewer'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { range } from 'lodash'
import { getFile } from '../functions'

function File({ fileId, onRendered }) {
  const [file, setFile] = useState({})
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    getFile(fileId).then(setFile)
  }, [fileId])

  useEffect(() => {
    if (file.type !== 'application/pdf' && onRendered) setTimeout(() => onRendered(), 1000)
  }, [file])

  const { name, type, url } = file

  return (
    <Box style={{ overflow: 'hidden' }}>
      {url ? type === 'application/pdf' ? (
        <Document file={url} onLoadSuccess={({ numPages }) => setMaxPage(numPages)}>
          {range(maxPage + 1).map(i => i > 0 && <Page pageNumber={i} onRenderSuccess={() => i === maxPage && onRendered && onRendered()} />)}
        </Document>
      ) : type.startsWith('image/') ? (
        <img width="100%" alt={name} src={url} />
      ) : (
        <FileViewer
          fileType={type.split('/')[1]}
          filePath={url}
        />
      ) : <Skeleton variant="rectangular" animation="wave" />}
    </Box>
  )
}

export default File