import { useState, useEffect, useRef } from 'react'
import { Box, TextField, Button, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Print, Download, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import FileViewer from 'react-file-viewer'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import NumberFormat from 'react-number-format'
import { useReactToPrint } from 'react-to-print'
import QRCode from 'react-qr-code'
import { get, sumBy } from 'lodash'
import { useAsyncFunc, getCompany } from '../../functions'
import Checkbox from '../Checkbox'
import ContactProp from '../Contacts/ContactProp'
import { getInvoice, findLineItems } from './functions'
import LineItemRow from './LineItemRow'

function Preview({ button, buttonStyle, invoiceId, forcePrint }) {
  const [isOpen, setIsOpen] = useState(false)
  const [invoice, setInvoice] = useState()
  const [lineItems, setLineItems] = useState()
  const [company, setCompany] = useState()
  const [isQRIncluded, setIsQRIncluded] = useState(false)
  const [isRevIncluded, setIsRevIncluded] = useState(false)
  const [isLineIncluded, setIsLineIncluded] = useState(true)

  useAsyncFunc(getInvoice, invoiceId, setInvoice, [invoiceId, isOpen])
  useAsyncFunc(findLineItems, { invoiceId, orderBy: ['createdAt','asc'] }, setLineItems, [invoiceId, isOpen])

  useEffect(() => {
    if (invoice) getCompany(invoice.companyId).then(setCompany)
  }, [invoice])

  useEffect(() => {
    if (isOpen && invoice && lineItems && forcePrint) setTimeout(() => print(), 500)
  }, [isOpen, invoice, lineItems, forcePrint])

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  const content = (
    <Box className="page" ref={contentRef} style={{ display: 'flex', flexDirection: 'column', gap: '10px', transform: 'scale(.9)' }}>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '145px' }}>
          <img alt="logo" src="https://i.imgur.com/CltCBmd.png" width="100%" />
        </Box>
        <Box style={{ flexGrow: 1, padding: '0 10px 5px' }}>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {get(company, 'address')}
          </Typography>
          <Typography variant="body2">
            {`Phone: `}
            <NumberFormat
              format="(###) ###-####"
              value={get(company, 'phone')}
              displayType="text"
            />
          </Typography>
          <Typography variant="body2">
            {`Email: ${get(company, 'email')}`}
          </Typography>
          <Typography variant="body2">
            {`Website: ${get(company, 'website')}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" style={{ fontWeight: 'bold', marginRight: '10px', textAlign: 'right' }}>
            Invoice
          </Typography>
        </Box>
        <Box>
          <Box style={{ padding: '5px', border: '1px solid #455a64', borderRadius: '5px', textAlign: 'center' }}>
            <Typography variant="h4">
              {get(invoice, 'numberRef') || get(invoice, 'number')}
            </Typography>
            {isRevIncluded && (
              <Typography variant="overline" display="block" style={{ padding: '0 20px', background: '#f2f2f2', borderRadius: '0 0 5px 5px' }}>
                Rev.0
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '33.33%', height: '120px', padding: '0 10px 5px' }}>
          <Typography variant="overline">
            {`Ship To: `}
            <ContactProp contactId={get(invoice, 'shipToContactId')} prop="contactName" />
          </Typography>
          <Typography>
            {get(invoice, 'shipToName')}
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {get(invoice, 'shipToAddress')}
          </Typography>
          <Typography variant="body2">
            <NumberFormat
              value={get(invoice, 'shipToPhone')}
              format="(###) ###-####"
              displayType="text"
            />
          </Typography>
        </Box>
        <Box style={{ width: '33.33%', height: '120px', padding: '0 10px 5px' }}>
          <Typography variant="overline">
            {`Bill To: `}
            <ContactProp contactId={get(invoice, 'billToContactId')} prop="contactName" />
          </Typography>
          <Typography>
            {get(invoice, 'billToName')}
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {get(invoice, 'billToAddress')}
          </Typography>
          <Typography variant="body2">
            <NumberFormat
              value={get(invoice, 'billToPhone')}
              format="(###) ###-####"
              displayType="text"
            />
          </Typography>
        </Box>
        <Box style={{ display: 'flex', width: '33.33%', height: '70px', padding: '0 10px 5px' }}>
          <Box style={{ width: '33.33%' }}>
            <Typography variant="overline">
              Invoice Date
            </Typography>
            <Typography variant="body2">
              {get(invoice, 'createdAt') ? invoice.createdAt.toLocaleDateString() : ''}
            </Typography>
          </Box>
          <Box style={{ width: '33.33%' }}>
            <Typography variant="overline">
              Ship Via
            </Typography>
            <Typography variant="body2">
              {get(invoice, 'shipVia')}
            </Typography>
          </Box>
          <Box style={{ width: '33.33%' }}>
            <Typography variant="overline">
              Terms
            </Typography>
            <Typography variant="body2">
              {get(invoice, 'terms')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box style={{ padding: '5px', border: '1px solid #ccc' }}>
        <TableContainer>
          <Table className="align-top" size="small">
            <TableHead>
              <TableRow>
                {isLineIncluded && (
                  <>
                    <TableCell>PO</TableCell>
                    <TableCell>Line</TableCell>
                  </>
                )}
                {/* <TableCell>Unit</TableCell> */}
                <TableCell>Part Description</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Subtotal</TableCell>
                <TableCell align="right">Ship Date</TableCell>
                <TableCell align="right">Due Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(lineItems || []).map(lineItem => (
                <LineItemRow
                  key={lineItem.id}
                  {...lineItem}
                  invoice={invoice}
                  readOnly
                  isLineIncluded={isLineIncluded}
                  view="PRINT"
                />
              ))}
              <TableRow>
                <TableCell align="right" colSpan={isLineIncluded ? 5 : 3} sx={{ fontWeight: 'bold' }}>Total Amount Due</TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={sumBy(lineItems, ({ quantity, unitPrice }) => quantity * unitPrice)}
                    prefix="$"
                    thousandSeparator
                    fixedDecimalScale
                    decimalScale={2}
                    displayType="text"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className="page-footer" style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
        <Box style={{ flexGrow: 1, height: '120px', padding: '0 10px 5px' }}>
          <Typography variant="overline">
            Footnote
          </Typography>
          <Typography variant="body2">
            {get(invoice, 'footnote')}
          </Typography>
          <Box style={{ display: 'flex', gap: '10px', width: '50%', marginTop: '5px' }}>
            <Box style={{ width: '50%', paddingBottom: '5px', borderBottom: '1px solid #000' }}>
              X.
            </Box>
            <Box style={{ width: '50%', paddingBottom: '5px', borderBottom: '1px solid #000' }}>
              Date.
            </Box>
          </Box>
        </Box>
        <Box>
          {isQRIncluded && <QRCode size={64} value={`edimfg.com/invoices/${invoiceId}`} />}
        </Box>
      </Box>
    </Box>
  )

  return button ? (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{ zIndex: 1400 }}
      >
        <DialogTitle style={{ borderBottom: '1px solid #e6e6e6' }}>Preview</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px', boxShadow: '0 0 25px rgba(0, 0, 0, .125)', color: '#000' }}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Checkbox
            checked={isLineIncluded}
            onChange={({ target: { checked } }) => setIsLineIncluded(checked)}
            label="Include PO Data"
          />
          <Checkbox
            checked={isRevIncluded}
            onChange={({ target: { checked } }) => setIsRevIncluded(checked)}
            label="Include Rev."
          />
          <Checkbox
            checked={isQRIncluded}
            onChange={({ target: { checked } }) => setIsQRIncluded(checked)}
            label="Include QR code"
          />
          {/* <Button
            size="small"
            variant="outlined"
            startIcon={<Download />}
            disableElevation
            onClick={() => {}}
          >
            Download
          </Button> */}
          <Button
            size="small"
            variant="contained"
            startIcon={<Print />}
            disableElevation
            onClick={print}
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : content
}

export default Preview