import { Timeline as MuiTimeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab'
import { timelineItemClasses } from '@mui/lab/TimelineItem'

function Timeline({ items }) {
  return (
    <MuiTimeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {items.map(({ id, render, label, isEnd }) => render || (
        <TimelineItem key={id}>
          <TimelineSeparator>
            <TimelineDot />
            {!isEnd && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>{label}</TimelineContent>
        </TimelineItem>
      ))}
    </MuiTimeline>
  )
}

export default Timeline