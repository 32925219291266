import { useState, useEffect, forwardRef } from 'react'
import { Box, TextField, Button, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@mui/material'
import { Undo, ListAlt, ArrowDropDown, ManageSearch, ArrowDownward, Search, Check, Clear } from '@mui/icons-material'
import { PieChart, Pie, Legend, Tooltip as ChartTip, ResponsiveContainer } from 'recharts'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt, getCurrentUserId } from '../../functions'
import Accordion from '../Accordion'
import ApprovedByOn from '../ApprovedByOn'
import Checkbox from '../Checkbox'
import ChangeLogs from '../ChangeLogs'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import FileRefs from '../FileRefs'
import SearchFiles from '../SearchFiles'
import PartCount from '../Parts/PartCount'
import SelectPart from '../Parts/SelectPart'
import SelectContact from '../Contacts/SelectContact'
import SelectPartOrder from '../Orders/SelectPartOrder'
import { findPartOrders } from '../Orders/functions'
import OrderNumber from '../Orders/OrderNumber'
import PartOrderProp from '../Orders/PartOrderProp'
import { dispositionTypes, updateJournalEntry } from './functions'

function EditJournalEntryDetails({ journalEntry, onUpdate, onUpdated, afterDetails }) {
  const [updatedJournalEntry, setUpdatedJournalEntry] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    if (!get(updatedJournalEntry, 'number')) errors.number = 'Required'
    if (!get(updatedJournalEntry, 'eventAt')) errors.eventAt = 'Required'
    if (!get(updatedJournalEntry, 'partId')) errors.partId = 'Required'
    if (!get(updatedJournalEntry, 'shipToContactId')) errors.shipToContactId = 'Required'
    if (!get(updatedJournalEntry, 'shipFromContactId')) errors.shipFromContactId = 'Required'
    if (get(updatedJournalEntry, 'quantityReceived') === undefined) errors.quantityReceived = 'Required'
    if (get(updatedJournalEntry, 'quantityInspected') === undefined) errors.quantityInspected = 'Required'
    if (get(updatedJournalEntry, 'quantityAccepted') === undefined) errors.quantityAccepted = 'Required'
    if (get(updatedJournalEntry, 'quantityRejected') === undefined) errors.quantityRejected = 'Required'
    if (get(updatedJournalEntry, 'source') === 'CUSTOMER_COMPLAINT' && !get(updatedJournalEntry, 'customerDispositionType')) errors.customerDispositionType = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateJournalEntry(journalEntry.id, {
      eventAt: updatedJournalEntry.eventAt,
      ...updatedJournalEntry.dueAt && { dueAt: updatedJournalEntry.dueAt },
      numberRef: updatedJournalEntry.numberRef,
      partId: updatedJournalEntry.partId,
      shipToContactId: updatedJournalEntry.shipToContactId,
      shipFromContactId: updatedJournalEntry.shipFromContactId,
      quantityReceived: updatedJournalEntry.quantityReceived,
      quantityInspected: updatedJournalEntry.quantityInspected,
      quantityAccepted: updatedJournalEntry.quantityAccepted,
      quantityRejected: updatedJournalEntry.quantityRejected,
      ...updatedJournalEntry.customerDispositionType && { customerDispositionType: updatedJournalEntry.customerDispositionType },
      ...updatedJournalEntry.dispositionType && { dispositionType: updatedJournalEntry.dispositionType },
      ...updatedJournalEntry.disposition && { disposition: updatedJournalEntry.disposition },
      nonconformanceCauseRequired: !!updatedJournalEntry.nonconformanceCauseRequired,
      ...updatedJournalEntry.nonconformanceCauseDueAt && { nonconformanceCauseDueAt: updatedJournalEntry.nonconformanceCauseDueAt },
      nonconformanceCauseIsolated: !!updatedJournalEntry.nonconformanceCauseIsolated,
      ...updatedJournalEntry.nonconformanceCause && { nonconformanceCause: updatedJournalEntry.nonconformanceCause },
      correctiveActionRequired: !!updatedJournalEntry.correctiveActionRequired,
      ...updatedJournalEntry.correctiveActionDueAt && { correctiveActionDueAt: updatedJournalEntry.correctiveActionDueAt },
      correctiveActionIsolated: !!updatedJournalEntry.correctiveActionIsolated,
      ...updatedJournalEntry.correctiveActionNotes && { correctiveActionNotes: updatedJournalEntry.correctiveActionNotes },
    }).then(onUpdated)
      .then(() => enqueueSnackbar('Journal entry updated successfully.', { variant: 'success' }))
  }

  const signAndDate = () => updateJournalEntry(journalEntry.id, {
    approvedByUserId: getCurrentUserId(),
    approvedAt: new Date(),
  }).then(onUpdated)
    .then(() => enqueueSnackbar('Journal entry updated successfully.', { variant: 'success' }))

  useEffect(() => {
    setUpdatedJournalEntry(journalEntry)
  }, [journalEntry])

  useEffect(() => {
    if (onUpdate) onUpdate(!isEqual(journalEntry, updatedJournalEntry) && actions)
  }, [updatedJournalEntry])

  const actions = (
    <>
      {!isEqual(journalEntry, updatedJournalEntry) && (
          <Button
            variant="outlined"
            startIcon={<Undo />}
            onClick={() => setUpdatedJournalEntry(journalEntry)}
            style={{ marginRight: '5px' }}
            disableElevation
          >
            Revert
          </Button>
        )}
        <Button
          variant="contained"
          onClick={submit}
          style={{ marginRight: '5px' }}
          disabled={isEqual(journalEntry, updatedJournalEntry)}
          disableElevation
        >
          Save Details
        </Button>
    </>
  )

  const localPartCount = [
    { name: 'Accepted', value: get(updatedJournalEntry, 'quantityAccepted', 0) },
    { name: 'Rejected', value: get(updatedJournalEntry, 'quantityRejected', 0) },
  ]

  const companyPartCount = [
    { name: 'Conforming', value: get(updatedJournalEntry, 'quantityAccepted', 0) },
    { name: 'Non-Conforming', value: get(updatedJournalEntry, 'quantityRejected', 0) },
  ]

  return (
    <>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Entry number"
            fullWidth
            value={get(updatedJournalEntry, 'number', 0)}
            style={{ marginBottom: '10px' }}
            disabled
          />
          <TextField
            size="small"
            variant="outlined"
            label="Reference number"
            fullWidth
            value={get(updatedJournalEntry, 'numberRef', '')}
            onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, numberRef: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'numberRef')}
          />
          <Dropdown
            button={(
              <TextField
                size="small"
                variant="outlined"
                name="customerDispositionType"
                label="Customer Disposition"
                fullWidth
                value={get(updatedJournalEntry, 'customerDispositionType', '')}
                onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, customerDispositionType: value })}
                InputProps={{
                  endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                }}
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'customerDispositionType')}
              />
            )}
            items={dispositionTypes.map(value => ({ label: value, value }))}
            onChange={({ value }) => setUpdatedJournalEntry({ ...updatedJournalEntry, customerDispositionType: value })}
          />
        </Box>
        <Box style={{ width: '50%' }}>
          <DateField
            label="Incident date"
            value={get(updatedJournalEntry, 'eventAt')}
            onChange={eventAt => setUpdatedJournalEntry({ ...updatedJournalEntry, eventAt })}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                fullWidth
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'eventAt')}
              />
            )}
          />
          <DateField
            label="File date"
            value={get(updatedJournalEntry, 'createdAt')}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                fullWidth
                style={{ marginBottom: '10px' }}
                disabled
              />
            )}
          />
          <DateField
            label="Due date"
            value={get(updatedJournalEntry, 'dueAt')}
            onChange={dueAt => setUpdatedJournalEntry({ ...updatedJournalEntry, dueAt })}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                fullWidth
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'dueAt')}
              />
            )}
          />
        </Box>
      </Box>

      <SelectPart
        companyId={get(updatedJournalEntry, 'companyId')}
        partId={get(updatedJournalEntry, 'partId')}
        onChange={part => setUpdatedJournalEntry({ ...updatedJournalEntry, partId: get(part, 'id') })}
        style={{ marginBottom: '30px', marginTop: '30px' }}
        inputProps={{
          ...addErrorProps(errors, 'partId')
        }}
      />

      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '25%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Qty received"
            fullWidth
            value={get(updatedJournalEntry, 'quantityReceived', '')}
            onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, quantityReceived: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            InputProps={{
              startAdornment: <ArrowDownward fontSize="small" sx={{ marginRight: '10px', color: 'blue' }} />,
            }}
            {...addErrorProps(errors, 'quantityReceived')}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Qty inspected"
            fullWidth
            value={get(updatedJournalEntry, 'quantityInspected', '')}
            onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, quantityInspected: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            InputProps={{
              startAdornment: <Search fontSize="small" sx={{ marginRight: '10px', color: 'gray' }} />,
            }}
            {...addErrorProps(errors, 'quantityInspected')}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Qty accepted"
            fullWidth
            value={get(updatedJournalEntry, 'quantityAccepted', '')}
            onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, quantityAccepted: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            InputProps={{
              startAdornment: <Check fontSize="small" sx={{ marginRight: '10px', color: 'green' }} />,
            }}
            {...addErrorProps(errors, 'quantityAccepted')}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Qty rejected"
            fullWidth
            value={get(updatedJournalEntry, 'quantityRejected', '')}
            onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, quantityRejected: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            InputProps={{
              startAdornment: <Clear fontSize="small" sx={{ marginRight: '10px', color: 'red' }} />,
            }}
            {...addErrorProps(errors, 'quantityRejected')}
          />
        </Box>
        <Box style={{ width: '75%' }}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <Box style={{ width: '50%' }}>
              <SelectContact
                companyId={journalEntry.companyId}
                contactId={get(updatedJournalEntry, 'shipToContactId', '')}
                label="Customer"
                onChange={contact => setUpdatedJournalEntry({
                  ...updatedJournalEntry,
                  shipToContactId: get(contact, 'id', ''),
                  shipToAttn: get(contact, 'contactName', ''),
                  shipToName: get(contact, 'name', ''),
                  shipToAddress: get(contact, 'address', ''),
                  shipToEmail: get(contact, 'email', ''),
                  shipToPhone: get(contact, 'phone', ''),
                  shipToFax: get(contact, 'fax', ''),
                })}
                textFieldProps={{
                  ...addErrorProps(errors, 'shipToContactId')
                }}
                findType="CUST"
              />
            </Box>
            <Box style={{  width: '50%', display: 'flex', gap: '10px' }}>
              <SelectPartOrder
                companyId={journalEntry.companyId}
                button={(
                  <Button
                    variant="outlined"
                    disableElevation
                    startIcon={<ManageSearch />}
                  >
                    {get(updatedJournalEntry, 'salesPartOrderId') ? (
                      <>
                        {`O/N `}
                        <OrderNumber partOrderId={updatedJournalEntry.salesPartOrderId} />
                        {` Line # `}
                        <PartOrderProp partOrderId={updatedJournalEntry.salesPartOrderId} prop="lineNumber" />
                      </>
                    ) : `Sales Order Lookup`}
                  </Button>
                )}
                partId={get(updatedJournalEntry, 'partId')}
                onChange={(partOrderId, { lineNumber, quantity, notes }, { numberRef, number }) => setUpdatedJournalEntry({ ...updatedJournalEntry, salesPartOrderId: partOrderId })}
              />
            </Box>
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <SelectContact
                companyId={journalEntry.companyId}
                contactId={get(updatedJournalEntry, 'shipFromContactId', '')}
                label="Vendor"
                onChange={contact => setUpdatedJournalEntry({
                  ...updatedJournalEntry,
                  shipFromContactId: get(contact, 'id', ''),
                  shipFromAttn: get(contact, 'contactName', ''),
                  shipFromName: get(contact, 'name', ''),
                  shipFromAddress: get(contact, 'address', ''),
                  shipFromEmail: get(contact, 'email', ''),
                  shipFromPhone: get(contact, 'phone', ''),
                  shipFromFax: get(contact, 'fax', ''),
                })}
                textFieldProps={{
                  ...addErrorProps(errors, 'shipFromContactId')
                }}
                findType="VEND"
              />
            </Box>
            <Box style={{  width: '50%', display: 'flex', gap: '10px' }}>
              <SelectPartOrder
                companyId={journalEntry.companyId}
                button={(
                  <Button
                    variant="outlined"
                    disableElevation
                    startIcon={<ManageSearch />}
                  >
                    {get(updatedJournalEntry, 'purchasePartOrderId') ? (
                      <>
                        {`O/N `}
                        <OrderNumber partOrderId={updatedJournalEntry.purchasePartOrderId} />
                        {` Line # `}
                        <PartOrderProp partOrderId={updatedJournalEntry.purchasePartOrderId} prop="lineNumber" />
                      </>
                    ) : `Purchase Order Lookup`}
                  </Button>
                )}
                partId={get(updatedJournalEntry, 'partId')}
                isPurchase
                onChange={(partOrderId, { lineNumber, quantity, notes }, { numberRef, number }) => setUpdatedJournalEntry({ ...updatedJournalEntry, purchasePartOrderId: partOrderId })}
              />
            </Box>
          </Box>
          {/* {get(updatedJournalEntry, 'partId') && (
            <TableContainer style={{ marginBottom: '5px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
              <Table>
                <TableHead style={{ background: '#fafafa' }}>
                  <TableRow>
                    <TableCell>
                      Total Ordered
                    </TableCell>
                    <TableCell>
                      Non-Conforming
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell>
                    <PartCount
                      partId={updatedJournalEntry.partId}
                      addFunc={args => findPartOrders({ partId: updatedJournalEntry.partId, orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                      suffix={() => ` pcs`}
                    />
                  </TableCell>
                  <TableCell>
                    <PartCount
                      partId={updatedJournalEntry.partId}
                      addFunc={args => findJournalEntries({ companyId: journalEntry.companyId, partId: updatedJournalEntry.partId, orderBy: ['updatedAt','desc'], limit: 9999, ...args })}
                      countOn="quantityRejected"
                      suffix={() => ` pcs`}
                    />
                  </TableCell>
                </TableBody>
              </Table>
            </TableContainer>
          )} */}
        </Box>
      </Box>

      <ApprovedByOn
        label="Initiated by"
        approvedByUserId={get(updatedJournalEntry, 'createdByUserId')}
        approvedAt={get(updatedJournalEntry, 'createdAt')}
        style={{ position: 'sticky', top: '50px', margin: '20px -25px', padding: '15px 25px', background: '#fdeded', zIndex: 2 }}
        onApprove={signAndDate}
      />

      {afterDetails}

      {/* <SearchFiles
        companyId={journalEntry.companyId}
        refFroms={[
          { id: journalEntry.id, type: 'journalEntry' },
        ]}
        refFromIds={[journalEntry.id]}
        button={(
          <Tooltip title="Attach file">
            <IconButton
              edge="start"
              size="small"
              variant="outlined"
            >
              <AddLink fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        buttonStyle={{ display: 'inline-block', marginBottom: '5px', verticalAlign: 'top' }}
      /> */}

      <FileRefs
        companyId={journalEntry.companyId}
        refFroms={[
          { id: journalEntry.id, type: 'journalEntry' },
        ]}
      />

      <Accordion
        title="2. Cause of Nonconformance"
        afterTitle={(
          <Box style={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={get(updatedJournalEntry, 'nonconformanceCauseRequired', false)}
              onChange={({ target: { checked } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, nonconformanceCauseRequired: checked })}
              label="Required"
              disabled={get(updatedJournalEntry, 'source') === 'CUSTOMER_COMPLAINT'}
            />
            {get(updatedJournalEntry, 'nonconformanceCauseRequired', false) && (
              <DateField
                label="Due date"
                value={get(updatedJournalEntry, 'nonconformanceCauseDueAt')}
                onChange={date => setUpdatedJournalEntry({ ...updatedJournalEntry, nonconformanceCauseDueAt: date })}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ marginRight: '30px' }}
                    {...addErrorProps(errors, 'nonconformanceCauseDueAt')}
                  />
                )}
              />
            )}
            <Checkbox
              checked={get(updatedJournalEntry, 'nonconformanceCauseIsolated', false)}
              onChange={({ target: { checked } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, nonconformanceCauseIsolated: checked })}
              label="Isolated case"
            />
          </Box>
        )}
        content={(
          <>
            {get(updatedJournalEntry, 'nonconformanceCauseRequired', false) && (
              <>
                <TextField
                  size="small"
                  variant="outlined"
                  rows={4}
                  multiline
                  fullWidth
                  value={get(updatedJournalEntry, 'nonconformanceCause', '')}
                  onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, nonconformanceCause: value })}
                  style={{ marginBottom: '10px' }}
                  {...addErrorProps(errors, 'nonconformanceCause')}
                />
                <ApprovedByOn
                  label="By"
                  approvedByUserId={get(updatedJournalEntry, 'nonconformanceCauseApprovedByUserId', '')}
                  approvedAt={get(updatedJournalEntry, 'nonconformanceCauseApprovedAt')}
                  onApprove={signAndDate}
                />
              </>
            )}
          </>
        )}
        expanded={get(updatedJournalEntry, 'nonconformanceCauseRequired', false)}
        onToggle={enabled => setUpdatedJournalEntry({ ...updatedJournalEntry, nonconformanceCauseRequired: enabled })}
      />

      <Accordion
        title="3. Corrective Action Proposed"
        // afterTitle={(
        //   <Box style={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }} onClick={e => e.stopPropagation()}>
        //     <Checkbox
        //       checked={get(updatedJournalEntry, 'dispositionByCustomer', false)}
        //       onChange={({ target: { checked } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, dispositionByCustomer: checked })}
        //       label="Disposition by customer"
        //     />
        //   </Box>
        // )}
        content={(
          <>
            <Dropdown
              button={(
                <TextField
                  size="small"
                  variant="outlined"
                  name="dispositionType"
                  label="Decision"
                  fullWidth
                  value={get(updatedJournalEntry, 'dispositionType', '')}
                  onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, dispositionType: value })}
                  InputProps={{
                    endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                  }}
                  style={{ marginBottom: '10px' }}
                  {...addErrorProps(errors, 'dispositionType')}
                />
              )}
              items={dispositionTypes.map(value => ({ label: value, value }))}
              onChange={({ value }) => setUpdatedJournalEntry({ ...updatedJournalEntry, dispositionType: value })}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Disposition"
              rows={4}
              multiline
              fullWidth
              value={get(updatedJournalEntry, 'disposition', '')}
              onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, disposition: value })}
              style={{ marginBottom: '10px' }}
              {...addErrorProps(errors, 'disposition')}
            />
            <ApprovedByOn
              approvedByUserId={get(updatedJournalEntry, 'dispositionApprovedByUserId', '')}
              approvedAt={get(updatedJournalEntry, 'dispositionApprovedAt')}
              onApprove={signAndDate}
            />
          </>
        )}
        defaultExpanded
      />

      <Accordion
        title="4. Corrective Action Completed"
        afterTitle={(
          <Box style={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={get(updatedJournalEntry, 'correctiveActionRequired', false)}
              onChange={({ target: { checked } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, correctiveActionRequired: checked })}
              label="Required"
              disabled={get(updatedJournalEntry, 'source') === 'CUSTOMER_COMPLAINT'}
            />
            {get(updatedJournalEntry, 'correctiveActionRequired', false) && (
              <DateField
                label="Due date"
                value={get(updatedJournalEntry, 'correctiveActionDueAt')}
                onChange={date => setUpdatedJournalEntry({ ...updatedJournalEntry, correctiveActionDueAt: date })}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ marginRight: '30px' }}
                    {...addErrorProps(errors, 'correctiveActionDueAt')}
                  />
                )}
              />
            )}
            <Checkbox
              checked={get(updatedJournalEntry, 'correctiveActionIsolated', false)}
              onChange={({ target: { checked } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, correctiveActionIsolated: checked })}
              label="Isolated case"
            />
          </Box>
        )}
        content={(
          <>
            {get(updatedJournalEntry, 'correctiveActionRequired', false) && (
              <>
                <TextField
                  size="small"
                  variant="outlined"
                  rows={4}
                  multiline
                  fullWidth
                  value={get(updatedJournalEntry, 'correctiveActionNotes', '')}
                  onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, correctiveActionNotes: value })}
                  style={{ marginBottom: '10px' }}
                  {...addErrorProps(errors, 'correctiveActionNotes')}
                />
                <ApprovedByOn
                  label="By"
                  approvedByUserId={get(updatedJournalEntry, 'correctiveActionNotesApprovedByUserId', '')}
                  approvedAt={get(updatedJournalEntry, 'correctiveActionNotesApprovedAt')}
                  onApprove={signAndDate}
                />
              </>
            )}
          </>
        )}
        expanded={get(updatedJournalEntry, 'correctiveActionRequired', false)}
        onToggle={enabled => setUpdatedJournalEntry({ ...updatedJournalEntry, correctiveActionRequired: enabled })}
      />

      <Accordion
        title="5. Re-Audit / Review Findings"
        content={(
          <>
            {get(updatedJournalEntry, 'correctiveActionRequired', false) && (
              <>
                <TextField
                  size="small"
                  variant="outlined"
                  rows={4}
                  multiline
                  fullWidth
                  value={get(updatedJournalEntry, 'reauditNotes', '')}
                  onChange={({ target: { value } }) => setUpdatedJournalEntry({ ...updatedJournalEntry, reauditNotes: value })}
                  style={{ marginBottom: '10px' }}
                  {...addErrorProps(errors, 'reauditNotes')}
                />
                <ApprovedByOn
                  label="By"
                  approvedByUserId={get(updatedJournalEntry, 'reauditNotesApprovedByUserId', '')}
                  approvedAt={get(updatedJournalEntry, 'reauditNotesApprovedAt')}
                  onApprove={signAndDate}
                />
              </>
            )}
          </>
        )}
        expanded={get(updatedJournalEntry, 'correctiveActionRequired', false)}
        onToggle={enabled => setUpdatedJournalEntry({ ...updatedJournalEntry, correctiveActionRequired: enabled })}
      />
      
      {actions}
      <ChangeLogs
        col="journalEntryLogs"
        id={journalEntry.id}
        button={(
          <Button
            variant="outlined"
            disableElevation
            startIcon={<ListAlt />}
          >
            Changelog
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
      />
    </>
  )
}

export default EditJournalEntryDetails