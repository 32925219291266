import { useState } from 'react'
import { Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Skeleton } from '@mui/material'
import { groupBy, reduce } from 'lodash'
import { useAsyncFunc, findFileRefs } from '../functions'
import { getJob, getJobLine } from './Jobs/functions'

const RefCount = ({ fileRefs }) => {
  const [count, setCount] = useState()

  useAsyncFunc(async () => {
    return reduce(await Promise.all(fileRefs.map(({ refFromId, refFromType }) => {
      if (refFromType === 'job') return getJob(refFromId).then(({ quantity }) => quantity)
      if (refFromType === 'jobLine') return getJobLine(refFromId).then(({ quantity }) => quantity)
      return 0
    })), (sum, quantity) => sum + quantity, 0)
  }, null, setCount, [fileRefs])

  return count !== undefined ? count : <Skeleton variant="rectangular" animation="wave" />
}

function RefInventory({ fileId }) {
  const [fileRefs, setFileRefs] = useState()

  useAsyncFunc(async () => {
    if (fileId) return findFileRefs({ fileId, orderBy: ['createdAt','desc'] })
  }, null, setFileRefs, [fileId])

  const groupedFileRefs = groupBy(fileRefs || [], ({ refFromType }) => refFromType)
  const refFromTypes = Object.keys(groupedFileRefs)

  return (
    <TableContainer style={{ border: '1px solid #d9d9d9', borderRadius: '5px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              References
            </TableCell>
            <TableCell>
              Quantity
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refFromTypes.map(refFromType => (
            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 500 }}>
                  {refFromType.toUpperCase()}
                </Typography>
              </TableCell>
              <TableCell>
                {groupedFileRefs[refFromType].length}
              </TableCell>
              <TableCell>
                <RefCount fileRefs={refFromType === 'part' ? fileRefs : groupedFileRefs[refFromType]} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RefInventory