import { useState, useEffect } from 'react'
import { Box, TextField, TableRow, TableCell, Button, IconButton, Tooltip } from '@mui/material'
import { AddLink, ArrowDropDown, ArrowDropUp, ChatOutlined, ChatBubbleOutline, MarkUnreadChatAltOutlined, OpenInNew, ManageSearch, DeleteOutline, Replay } from '@mui/icons-material'
import { deleteField } from 'firebase/firestore'
import { get } from 'lodash'
import { useAsyncFunc, findFileRefs, positiveInt, positiveFloat } from '../../functions'
import Confirm from '../Confirm'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'
import Popover from '../Popover'
import SearchFiles from '../SearchFiles'
import UpdateFileRef from '../UpdateFileRef'
import { getPartOrder } from '../Orders/functions'
import SelectPartOrder from '../Orders/SelectPartOrder'
import Assignee from './Assignee'
import SelectAssignee from './SelectAssignee'

function JobLine({ companyId, partId, readOnly, onUpdate, onMove, onNext, onRemove, onRestore, isDetailed, isDroppable, isDragover, rowProps, ...line }) {
  const [updates, setUpdates] = useState()
  const [fileRefs, setFileRefs] = useState([])

  const { id, number, description, quantity, unit, completedAt, note, assigneeId, partOrderId, status } = line

  const fetch = () => findFileRefs({ refFromIds: [id], orderBy: ['updatedAt','desc'] }).then(setFileRefs)

  useAsyncFunc(findFileRefs, { refFromIds: [id], orderBy: ['updatedAt','desc'] }, setFileRefs, [id])

  useEffect(() => {
    if (updates) onUpdate(updates)
  }, [updates])

  useEffect(() => {
    fetch()
  }, [isDragover])

  return (
    <>
      <TableRow {...rowProps && { ...rowProps, ...rowProps.onDragEnter && { onDragEnter: e => rowProps.onDragEnter(e, line) } }}>
        <TableCell>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {get(updates, 'number', number)}
            {!readOnly && (
              <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                <Tooltip title="Move up" placement="right">
                  <IconButton
                    size="small"
                    onClick={() => onMove(id, -1)}
                  >
                    <ArrowDropUp />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Move down" placement="right">
                  <IconButton
                    size="small"
                    onClick={() => onMove(id, 1)}
                  >
                    <ArrowDropDown />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {readOnly ? get(updates, 'description', description) : (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={get(updates, 'description', description)}
              onChange={({ target: { value } }) => setUpdates({ ...updates, description: value })}
              style={{ marginBottom: '5px', transform: 'translateX(-5px)', ...status === 'INACTIVE' && { textDecoration: 'line-through' } }}
              InputProps={{
                endAdornment: (
                  <>
                    {get(updates, 'partOrderId', partOrderId) && (
                      <Tooltip title="Open In New Window">
                        <Button
                          className="tiny"
                          size="small"
                          color="success"
                          variant="contained"
                          startIcon={<OpenInNew />}
                          onClick={() => getPartOrder(partOrderId).then(({ orderId }) => window.open(`/orders/${orderId}`,'_blank'))}
                          style={{ minWidth: 'auto', marginRight: '5px', padding: '0 8px 0 12px', fontSize: '12px' }}
                          disableElevation
                        >
                          External
                        </Button>
                      </Tooltip>
                    )}
                    <SelectPartOrder
                      companyId={companyId}
                      button={(
                        <Tooltip title="Reference a Purchase Order">
                          <IconButton size="small" edge="end">
                            <ManageSearch fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      partId={partId}
                      isPurchase
                      onChange={(partOrderId, { lineNumber, quantity, notes }, { numberRef, number }) => setUpdates({
                        ...updates,
                        partOrderId,
                        quantity,
                        ...!get(updates, 'description', description) && { description: `P/O ${numberRef || number} L/N ${lineNumber}` },
                        ...!get(updates, 'note', note) && notes && { notes },
                      })}
                    />
                  </>
                ),
              }}
              {...(isDroppable || isDragover) && { className: `${isDroppable ? 'droppable' : ''} ${isDragover ? 'dragover' : ''}` }}
              rows={3}
              multiline
              autoFocus
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  return onNext(line)
                }
                return e
              }}
            />
          )}
        </TableCell>
        {isDetailed && (
          <>
            <TableCell align="right">
              {readOnly ? get(updates, 'quantity', quantity) : (
                <TextField
                  size="small"
                  variant="outlined"
                  value={get(updates, 'quantity', quantity)}
                  onChange={({ target: { value } }) => setUpdates({ ...updates, quantity: get(updates, 'unit', unit) === 'piece' ? positiveInt(value) : positiveFloat(value) })}
                  style={{ maxWidth: '120px', transform: 'translateX(5px)' }}
                />
              )}
            </TableCell>
            <TableCell>
              {readOnly ? get(updates, 'unit', unit) : (
                <Dropdown
                  button={(
                    <TextField
                      size="small"
                      variant="outlined"
                      value={get(updates, 'unit', unit)}
                      InputProps={{
                        endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                      }}
                      style={{ maxWidth: '100px', transform: 'translateX(-5px)' }}
                    />
                  )}
                  items={[
                    { label: 'piece', value: 'piece' },
                    { label: 'hour', value: 'hour' },
                  ]}
                  onChange={({ value }) => setUpdates({ ...updates, unit: value })}
                />
              )}
            </TableCell>
            <TableCell>
              <SelectAssignee
                companyId={companyId}
                button={<Assignee id={assigneeId} />}
                onChange={({ value }) => setUpdates({ ...updates, assigneeId: value })}
              />
            </TableCell>
          </>
        )}
        <TableCell>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <Checkbox
              checked={get(updates, 'completedAt', completedAt) instanceof Date}
              onChange={({ target: { checked } }) => setUpdates({ ...updates, completedAt: checked ? new Date() : deleteField() })}
            />
            <Popover
              button={(
                <Tooltip title="Add note">
                  <IconButton>
                    {get(updates, 'note') ? (
                      <MarkUnreadChatAltOutlined style={{ color: '#1a73e8' }} />
                    ) : note ? (
                      <ChatOutlined />
                    ) : (
                      <ChatBubbleOutline style={{ color: '#ccc' }} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              content={(
                <Box style={{ padding: '15px' }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Note"
                    fullWidth
                    value={get(updates, 'note', note)}
                    onChange={({ target: { value } }) => setUpdates({ ...updates, note: value })}
                    multiline
                    rows={3}
                  />
                </Box>
              )}
              forceClosingOnClick
            />
            {status === 'ACTIVE' ? (
              <IconButton edge="end" onClick={() => onRemove(line)}>
                <DeleteOutline />
              </IconButton>
            ) : (
              <IconButton edge="end" onClick={() => onRestore(line)}>
                <Replay />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell colSpan={5}>
          {!readOnly && (
            <SearchFiles
              companyId={companyId}
              refFroms={[
                { id, type: 'jobLine' },
                { id: partId, type: 'part' },
              ]}
              refFromIds={[id, partId]}
              onUpdated={fetch}
              button={(
                <Tooltip title="Attach file">
                  <IconButton
                    edge="start"
                    size="small"
                    variant="outlined"
                  >
                    <AddLink fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              buttonStyle={{ display: 'inline-block', marginBottom: '5px', verticalAlign: 'top' }}
            />
          )}
          {fileRefs.map(fileRef => {
            const { id, name, updatedAt } = fileRef
            return (
              <UpdateFileRef
                key={id}
                button={(
                  <Tooltip title={`Attached ${updatedAt.toLocaleDateString()}`}>
                    <Button
                      size="small"
                      variant="outlined"
                      style={{ marginLeft: '5px', marginBottom: '5px' }}
                    >
                      {name}
                    </Button>
                  </Tooltip>
                )}
                buttonStyle={{ display: 'inline-block' }}
                fileRef={fileRef}
                onUpdated={fetch}
              />
            )
          })}
        </TableCell>
      </TableRow>
    </>
  )
}

export default JobLine