import { useState, useEffect } from 'react'
import { Box, TextField, Typography, Button, IconButton } from '@mui/material'
import { Undo, ListAlt, ArrowDropDown } from '@mui/icons-material'
import { get, isEqual, isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt, getLocal, setLocal } from '../../functions'
import ChangeLogs from '../ChangeLogs'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import PhoneField from '../PhoneField'
import SelectContact from '../Contacts/SelectContact'
import { updateQuote } from './functions'

function EditQuoteDetails({ quote, onUpdate }) {
  const [updatedQuote, setUpdatedQuote] = useState()
  const [prefs, setPrefs] = useState(getLocal('quotesPrefs', {}))
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    if (!get(updatedQuote, 'numberRef')) errors.numberRef = 'Required'
    if (!get(updatedQuote, 'date')) errors.date = 'Required'
    if (!get(updatedQuote, 'dateExp')) errors.date = 'Required'
    if (!get(updatedQuote, 'shipToName')) errors.shipToName = 'Required'
    if (!get(updatedQuote, 'shipToAddress')) errors.shipToAddress = 'Required'
    if (!get(updatedQuote, 'shipToPhone')) errors.shipToPhone = 'Required'
    if (!get(updatedQuote, 'billToName')) errors.billToName = 'Required'
    if (!get(updatedQuote, 'billToAddress')) errors.billToAddress = 'Required'
    if (!get(updatedQuote, 'billToPhone')) errors.billToPhone = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const mapFields = (origin, keys, test) => {
    const fields = {}
    keys.map(key => {
      if (test && test(origin[key])) return
      fields[key] = origin[key]
    })
    return fields
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateQuote(quote.id, {
      numberRef: updatedQuote.numberRef,
      ...mapFields(updatedQuote, [
        'date','dateExp','shipVia','terms',
        'shipToContactId','shipToAttn','shipToName','shipToAddress','shipToEmail','shipToPhone','shipToFax',
        'billToContactId','billToAttn','billToName','billToAddress','billToEmail','billToPhone','billToFax',
      ], !isNil),
    }).then(onUpdate)
      .then(() => enqueueSnackbar('Quote updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedQuote({ ...quote, ...prefs })
  }, [quote])

  useEffect(() => {
    setLocal('quotesPrefs', prefs)
  }, [prefs])

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Quote number"
            fullWidth
            value={get(updatedQuote, 'numberRef', '')}
            onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, numberRef: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'numberRef')}
          />
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <DateField
                label="Date"
                value={get(updatedQuote, 'date')}
                onChange={date => setUpdatedQuote({ ...updatedQuote, date })}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    style={{ marginBottom: '10px' }}
                    {...addErrorProps(errors, 'date')}
                  />
                )}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <DateField
                label="Expiration"
                value={get(updatedQuote, 'dateExp')}
                onChange={dateExp => setUpdatedQuote({ ...updatedQuote, dateExp })}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    style={{ marginBottom: '10px' }}
                    {...addErrorProps(errors, 'dateExp')}
                  />
                )}
              />
            </Box>
          </Box>
          <SelectContact
            companyId={quote.companyId}
            contactId={get(updatedQuote, 'shipToContactId', '')}
            label="Ship to name"
            onChange={contact => setUpdatedQuote({
              ...updatedQuote,
              shipToContactId: get(contact, 'id', ''),
              shipToAttn: get(contact, 'contactName', ''),
              shipToName: get(contact, 'name', ''),
              shipToAddress: get(contact, 'address', ''),
              shipToEmail: get(contact, 'email', ''),
              shipToPhone: get(contact, 'phone', ''),
              shipToFax: get(contact, 'fax', ''),
            })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            size="small"
            variant="outlined"
            name="shipToAddress"
            label="Ship to address"
            rows={3}
            multiline
            fullWidth
            value={get(updatedQuote, 'shipToAddress', '')}
            onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, shipToAddress: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'shipToAddress')}
          />
          <TextField
            size="small"
            variant="outlined"
            name="shipToEmail"
            label="Email"
            fullWidth
            value={get(updatedQuote, 'shipToEmail', '')}
            onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, shipToEmail: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'shipToEmail')}
          />
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="shipToPhone"
                label="Phone"
                fullWidth
                value={get(updatedQuote, 'shipToPhone', '')}
                onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, shipToPhone: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'shipToPhone')}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="shipToFax"
                label="Fax"
                fullWidth
                value={get(updatedQuote, 'shipToFax', '')}
                onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, shipToFax: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'shipToFax')}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '50%' }}>
          <Dropdown
            button={(
              <TextField
                size="small"
                variant="outlined"
                name="shipVia"
                label="Ship Via"
                fullWidth
                value={get(updatedQuote, 'shipVia', '')}
                onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, shipVia: value })}
                InputProps={{
                  endAdornment: (
                    <>
                      {get(updatedQuote, 'shipVia') && (
                        <Checkbox
                          size="small"
                          checked={prefs.shipVia}
                          onChange={({ target: { checked } }) => setPrefs({ ...prefs, shipVia: checked ? updatedQuote.shipVia : '' })}
                          label={<Typography variant="body2">Remember</Typography>}
                          onClick={e => e.stopPropagation()}
                        />
                      )}
                      <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>
                    </>
                  ),
                }}
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'shipVia')}
              />
            )}
            items={['UPS','FedEx','Our Truck'].map(value => ({ label: value, value }))}
            onChange={({ value }) => setUpdatedQuote({ ...updatedQuote, shipVia: value })}
          />
          <TextField
            size="small"
            variant="outlined"
            name="terms"
            label="Terms"
            fullWidth
            value={get(updatedQuote, 'terms', '')}
            onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, terms: value })}
            InputProps={{
              endAdornment: (
                <>
                  {get(updatedQuote, 'terms') && (
                    <Checkbox
                      size="small"
                      checked={prefs.terms}
                      onChange={({ target: { checked } }) => setPrefs({ ...prefs, terms: checked ? updatedQuote.terms : '' })}
                      label={<Typography variant="body2">Remember</Typography>}
                      onClick={e => e.stopPropagation()}
                    />
                  )}
                </>
              ),
            }}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'terms')}
          />
          <SelectContact
            companyId={quote.companyId}
            contactId={get(updatedQuote, 'billToContactId', '')}
            label="Bill to name"
            onChange={contact => setUpdatedQuote({
              ...updatedQuote,
              billToContactId: get(contact, 'id', ''),
              billToAttn: get(contact, 'contactName', ''),
              billToName: get(contact, 'name', ''),
              billToAddress: get(contact, 'address', ''),
              billToEmail: get(contact, 'email', ''),
              billToPhone: get(contact, 'phone', ''),
              billToFax: get(contact, 'fax', ''),
            })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            size="small"
            variant="outlined"
            name="billToAddress"
            label="Bill to address"
            rows={3}
            multiline
            fullWidth
            value={get(updatedQuote, 'billToAddress', '')}
            onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, billToAddress: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'billToAddress')}
          />
          <TextField
            size="small"
            variant="outlined"
            name="billToEmail"
            label="Email"
            fullWidth
            value={get(updatedQuote, 'billToEmail', '')}
            onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, billToEmail: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'billToEmail')}
          />
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="billToPhone"
                label="Phone"
                fullWidth
                value={get(updatedQuote, 'billToPhone', '')}
                onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, billToPhone: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'billToPhone')}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="billToFax"
                label="Fax"
                fullWidth
                value={get(updatedQuote, 'billToFax', '')}
                onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, billToFax: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'billToFax')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!isEqual(quote, updatedQuote) && (
        <Button
          variant="outlined"
          startIcon={<Undo />}
          onClick={() => setUpdatedQuote(quote)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        style={{ marginRight: '5px' }}
        disabled={isEqual(quote, updatedQuote)}
        disableElevation
      >
        Save Details
      </Button>
      <ChangeLogs
        col="quoteLogs"
        id={quote.id}
        button={(
          <Button
            variant="outlined"
            disableElevation
            startIcon={<ListAlt />}
          >
            Changelog
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
      />
    </>
  )
}

export default EditQuoteDetails