import { useState } from 'react'
import { Box, Skeleton } from '@mui/material'
import { reduce } from 'lodash'
import { useAsyncFunc, findFileRefs } from '../functions'
import { getJob } from './Jobs/functions'
import { getPart } from './Parts/functions'

function JobUsage({ fileId }) {
  const [fileRefs, setFileRefs] = useState()
  const [jobs, setJobs] = useState([])
  const [showingQuantity, setShowingQuantity] = useState(true)

  useAsyncFunc(async fileId => {
    if (fileId) return findFileRefs({ fileId, refFromType: 'job', orderBy: ['updatedAt','desc'] })
    return () => null
  }, fileId, setFileRefs, [fileId])

  useAsyncFunc(async fileRefs => {
    return Promise.all((fileRefs || []).map(({ refFromId }) => getJob(refFromId)))
  }, fileRefs, setJobs, [fileRefs])

  const quantity = reduce(jobs, (sum, { quantity }) => sum + quantity, 0)

  return <Box style={{ cursor: 'pointer' }} onClick={() => setShowingQuantity(!showingQuantity)}>{showingQuantity ? `${quantity} pcs` : `${jobs.length}x`}</Box>
}

function PartUsage({ fileId }) {
  const [fileRefs, setFileRefs] = useState()
  const [parts, setParts] = useState([])

  useAsyncFunc(async fileId => {
    if (fileId) return findFileRefs({ fileId, refFromType: 'part', orderBy: ['updatedAt','desc'] })
    return () => null
  }, fileId, setFileRefs, [fileId])

  useAsyncFunc(async fileRefs => {
    return Promise.all((fileRefs || []).map(({ refFromId }) => getPart(refFromId)))
  }, fileRefs, setParts, [fileRefs])

  return `${parts.length}x`
}

function FileUsage({ fileId }) {
  const [fileRefs, setFileRefs] = useState()

  useAsyncFunc(async fileId => {
    if (fileId) return findFileRefs({ fileId, orderBy: ['updatedAt','desc'] })
    return () => null
  }, fileId, setFileRefs, [fileId])

  return fileRefs ? `${fileRefs.length}x` : <Skeleton variant="rectangular" animation="wave" />
}

export { JobUsage, PartUsage, FileUsage }