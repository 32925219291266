import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Search, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { findPartOrders } from './functions'
import PartName from '../Parts/PartName'

function OrderResult({ item, selected, onSelect }) {
  const { id, number, numberRef } = item
  const [partOrders, setPartOrders] = useState([])

  useAsyncFunc(findPartOrders, { orderId: id, orderBy: ['createdAt','asc'] }, setPartOrders, [id])

  return (
    <ListItem
      disablePadding
      {...get(selected, 'id') === id && {
        secondaryAction: (
          <Tooltip title="In View">
            <IconButton edge="end">
              <Visibility />
            </IconButton>
          </Tooltip>
        )
      }}
      onClick={() => onSelect(item)}
    >
      <ListItemButton>
        <ListItemIcon>
          <Badge
            variant="dot"
            color="success"
          >
            <InsertDriveFile />
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={`O/N ${numberRef || number}`}
          secondary={partOrders.length > 0 ? (
            <>
              <PartName partId={partOrders[0].partId} />
              {partOrders.length > 1 ? ` + ${partOrders.length - 1}` : ''}
            </>
          ) : ''}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default OrderResult