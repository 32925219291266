import { useState, useEffect, useRef } from 'react'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Tabs, Tab, Chip, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material'
import { Add, Remove, Inbox, FilterList, Upload as UploadIcon } from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print'
import { sortBy } from 'lodash'
import { useAsyncFunc } from '../../functions'
import Accordion from '../Accordion'
import Confirm from '../Confirm'
import Dropdown from '../Dropdown'
import EditInternalMemo from '../EditInternalMemo'
import EditFootnote from '../EditFootnote'
import EditDisclaimer from '../EditDisclaimer'
import PermissionedTabs from '../PermissionedTabs'
import { updateOrder, findPartOrders } from './functions'
import Actions from './Actions'
import EditOrderDetails from './EditDetails'
import CreateEditPartOrder from './CreateEditPartOrder'
import PartOrderRow from './PartOrderRow'
import NewPartOrderRow from './NewPartOrderRow'

function Order({ companyId, button, buttonStyle, inline, forceOpen, setForceOpen, onSelect, onUpdate, onRemove, ...order }) {
  const { id, isPurchase, footnote, disclaimer, status } = order
  const [isOpen, setIsOpen] = useState(false)
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [partOrders, setPartOrders] = useState([])
  const [view, setView] = useState('SALES')
  const [updates, setUpdates] = useState()
  
  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  useAsyncFunc(async args => {
    if (id) return findPartOrders(args)
    return () => null
  }, { orderId: id, statusNotIn: ['ARCHIVED'], orderBy: ['lineNumber','asc'] }, setPartOrders, [id])

  const fetchPartOrders = () => findPartOrders({ orderId: id, statusNotIn: ['ARCHIVED'], orderBy: ['lineNumber','asc'] }).then(setPartOrders)

  const content = (
    <>
      <Box style={{ flexGrow: 1 }}>
        <Box style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: 0, padding: '10px', background: '#efefef', borderRadius: '5px', zIndex: 3 }}>
          <Actions item={order} onPrint={print} onSelect={onSelect} onRemove={onRemove} />
        </Box>
        <Stack {...!inline && { style: { marginTop: '5px' } }}>
          <Alert
            severity={status === 'DRAFT' ? 'warning' : 'info'}
            action={(
              <>
                <Dropdown
                  button={(
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<Inbox />}
                      disableElevation
                    >
                      Change Status
                    </Button>
                  )}
                  buttonStyle={{ marginLeft: '5px' }}
                  items={[
                    { label: 'Draft', value: 'DRAFT' },
                    { label: 'Open', value: 'OPEN' },
                    { label: 'Closed', value: 'CLOSED' },
                  ]}
                  onChange={({ value }) => setUpdates({ ...updates, status: value })}
                  disableCloseOnSelect
                />
                <Confirm
                  forceOpen={updates}
                  setForceOpen={() => setUpdates()}
                  title="Confirm updates?"
                  content="Are you sure you want to apply all changes?"
                  onConfirm={() =>  updateOrder(id, updates).then(onUpdate)}
                />
              </>
            )}
          >
            {`${isPurchase ? 'Purchase' : 'Sales'} Order is ${status.replace('_',' ')}.`}
          </Alert>
        </Stack>
        <Box ref={contentRef}>
          <Accordion
            title="Order Details"
            content={(
              <EditOrderDetails
                order={{ companyId, ...order }}
                onUpdate={onUpdate}
              />
            )}
            defaultExpanded
          />
          <Accordion
            title="Internal Memo"
            content={(
              <>
                <Stack>
                  <Alert
                    severity="info"
                  >
                    For internal use only. Not visible to customer.
                  </Alert>
                </Stack>
                <EditInternalMemo
                  refFromId={id}
                />
              </>
            )}
          />
          <CreateEditPartOrder
            companyId={companyId}
            data={{
              orderId: id,
              unit: 'PIECE',
              status: 'OPEN',
              lineNumber: partOrders.length + 1,
            }}
            onCreate={fetchPartOrders}
            forceOpen={isAddOpen}
            setForceOpen={setIsAddOpen}
          />
          <Accordion
            title={(
              <>
                Order Items
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginLeft: '10px' }}
                  startIcon={<Add />}
                  onClick={e => {
                    e.stopPropagation()
                    setIsAdding(true)
                  }}
                >
                  Add Line Item
                </Button>
              </>
            )}
            afterTitle={(
              <Box onClick={e => e.stopPropagation()} style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <PermissionedTabs
                  options={[
                    { label: 'Sales', value: 'SALES', permission: 'SALES' },
                    { label: 'Quality', value: 'QUALITY', permission: 'QUALITY' },
                  ]}
                  view={view}
                  setView={setView}
                />
              </Box>
            )}
            content={(
              <Box style={{ minHeight: '450px', padding: '5px', border: '1px solid #ccc' }}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Line</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Part</TableCell>
                        {view === 'SALES' && (
                          <>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </>
                        )}
                        {view === 'QUALITY' && (
                          <>
                            <TableCell>Test Gages</TableCell>
                          </>
                        )}
                        <TableCell align="right">Due</TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortBy(partOrders || [], ['lineNumber']).map(partOrder => (
                        <PartOrderRow key={partOrder.id} companyId={companyId} {...partOrder} partOrders={partOrders} onUpdated={fetchPartOrders} view={view} />
                      ))}
                      <NewPartOrderRow
                        companyId={companyId}
                        forceOpen={isAdding}
                        setForceOpen={setIsAdding}
                        onCreate={fetchPartOrders}
                        data={{
                          lineNumber: partOrders.length + 1,
                          orderId: id,
                          partId: partOrders[partOrders.length - 1]?.partId,
                          unit: partOrders[partOrders.length - 1]?.unit,
                          unitPrice: partOrders[partOrders.length - 1]?.unitPrice,
                          status: 'OPEN',
                        }}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            defaultExpanded
          />
          <Accordion
            title="Footnote"
            content={(
              <EditFootnote
                note={footnote}
                onSubmit={footnote => updateOrder(id, { footnote }).then(onUpdate)}
              />
            )}
          />
          <Accordion
            title="Disclaimer"
            content={(
              <EditDisclaimer
                companyId={companyId}
                note={disclaimer}
                onSubmit={disclaimer => updateOrder(id, { disclaimer }).then(onUpdate)}
              />
            )}
          />
        </Box>
      </Box>
    </>
  )

  return inline ? content : (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={handleClose}
      >
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Order