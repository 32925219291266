import { useState } from 'react'
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip } from '@mui/material'
import { Visibility, InsertDriveFile } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { findPartQuotes } from './functions'
import PartName from '../Parts/PartName'

function QuoteResult({ item, selected, onSelect }) {
  const { id, number } = item
  const [partQuotes, setPartQuotes] = useState([])

  useAsyncFunc(findPartQuotes, { quoteId: id, orderBy: ['createdAt','asc'] }, setPartQuotes, [id])

  return (
    <ListItem
      disablePadding
      {...get(selected, 'id') === id && {
        secondaryAction: (
          <Tooltip title="In View">
            <IconButton edge="end">
              <Visibility />
            </IconButton>
          </Tooltip>
        )
      }}
      onClick={() => onSelect(item)}
    >
      <ListItemButton>
        <ListItemIcon>
          <Badge
            variant="dot"
            color="success"
          >
            <InsertDriveFile />
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={`Q/N ${number}`}
          secondary={partQuotes.length > 0 ? (
            <>
              <PartName partId={partQuotes[0].partId} />
              {partQuotes.length > 1 ? ` + ${partQuotes.length - 1}` : ''}
            </>
          ) : ''}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default QuoteResult