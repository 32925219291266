import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Check, Clear, Add } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps } from '../../functions'
import SelectDevice from '../Testing/SelectDevice'
import { updatePart } from './functions'

function EditPartDetails({ part, onUpdate }) {
  const [updatedPart, setUpdatedPart] = useState()
  const [newGage, setNewGage] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    if (!get(updatedPart, 'number')) errors.number = 'Required'
    if (!get(updatedPart, 'name')) errors.name = 'Required'
    if (!get(updatedPart, 'revision')) errors.revision = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updatePart(part.id, {
      number: updatedPart.number,
      name: updatedPart.name,
      revision: updatedPart.revision,
      ...updatedPart.description && { description: updatedPart.description },
      ...updatedPart.threadGages && { threadGages: updatedPart.threadGages },
    }).then(onUpdate)
      .then(() => enqueueSnackbar('Part updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedPart(part)
  }, [part])

  const addGage = () => {
    setUpdatedPart({ ...updatedPart, threadGages: [...get(updatedPart, 'threadGages', []), newGage] })
    setNewGage()
  }

  const updateGage = (index, updates) => setUpdatedPart({ ...updatedPart, threadGages: get(updatedPart, 'threadGages', []).map((g, i) => {
    if (i === index) return { ...g, ...updates }
    return g
  })})

  const removeGage = index => setUpdatedPart({ ...updatedPart, threadGages: get(updatedPart, 'threadGages', []).filter((_g, i) => i !== index) })

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        label="Part number"
        fullWidth
        value={get(updatedPart, 'number', 0)}
        onChange={({ target: { value } }) => setUpdatedPart({ ...updatedPart, number: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'number')}
      />
      <TextField
        size="small"
        variant="outlined"
        label="Revision"
        fullWidth
        value={get(updatedPart, 'revision', '')}
        onChange={({ target: { value } }) => setUpdatedPart({ ...updatedPart, revision: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'revision')}
      />
      <TextField
        size="small"
        variant="outlined"
        label="Part name"
        fullWidth
        value={get(updatedPart, 'name', '')}
        onChange={({ target: { value } }) => setUpdatedPart({ ...updatedPart, name: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'name')}
      />
      <TextField
        size="small"
        variant="outlined"
        label="Part description"
        rows={3}
        multiline
        fullWidth
        value={get(updatedPart, 'description', '')}
        onChange={({ target: { value } }) => setUpdatedPart({ ...updatedPart, description: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'description')}
      />
      {get(updatedPart, 'threadGages', []).map(({ deviceId, description, location }, index) => (
        <Box style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px' }}>
          <SelectDevice
            companyId={get(part, 'companyId')}
            findType="GAGE"
            deviceId={deviceId}
            onChange={device => updateGage(index, { deviceId: device?.id })}
            style={{ minWidth: '230px' }}
          />
          {/* <TextField
            size="small"
            variant="outlined"
            {...index === 0 && { label: 'Thread Gage' }}
            placeholder="Description"
            value={description || ''}
            onChange={({ target: { value } }) => updateGage(index, { description: value })}
          /> */}
          <TextField
            size="small"
            variant="outlined"
            {...index === 0 && { label: 'Location' }}
            placeholder="Location"
            value={location || ''}
            onChange={({ target: { value } }) => updateGage(index, { location: value })}
          />
          <Tooltip title="Delete">
            <IconButton size="small" edge="end" onClick={() => removeGage(index)}>
              <Clear fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ))}
      <Box style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px' }}>
        <SelectDevice
          companyId={get(part, 'companyId')}
          findType="GAGE"
          deviceId={get(newGage, 'deviceId')}
          onChange={device => setNewGage({ ...newGage, deviceId: device?.id })}
          style={{ minWidth: '230px' }}
        />
        {/* <TextField
          size="small"
          variant="outlined"
          {...get(updatedPart, 'threadGages', []).length === 0 && { label: 'Thread Gage' }}
          placeholder="Description"
          value={get(newGage, 'description', '')}
          onChange={({ target: { value } }) => setNewGage({ ...newGage, description: value })}
        /> */}
        <TextField
          size="small"
          variant="outlined"
          placeholder="Location"
          value={get(newGage, 'location', '')}
          onChange={({ target: { value } }) => setNewGage({ ...newGage, location: value })}
        />
        <Tooltip title="Add">
          <IconButton size="small" edge="end" onClick={addGage}>
            <Add fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {!isEqual(part, updatedPart) && (
        <Button
          variant="outlined"
          onClick={() => setUpdatedPart(part)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        disabled={isEqual(part, updatedPart)}
        disableElevation
      >
        Save Details
      </Button>
    </>
  )
}

export default EditPartDetails