import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getQuote } from './functions'

function QuoteProp({ quoteId, prop }) {
  const [quote, setQuote] = useState()

  useAsyncFunc(async quoteId => {
    if (quoteId) return getQuote(quoteId)
  }, quoteId, setQuote, [quoteId])

  return get(quote, prop) ? quote[prop] instanceof Date ? quote[prop].toLocaleDateString() : quote[prop] : <Skeleton variant="rectangular" animation="wave" />
}

export default QuoteProp