import { useState, useEffect } from 'react'
import { Avatar } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc, getCompanyRef } from '../../functions'

function Assignee({ id }) {
  const [assignee, setAssignee] = useState()

  useEffect(() => {
    const fetch = async () => {
      setAssignee(id ? await getCompanyRef(id) : null)
    }
    fetch()
  }, [id])

  return (
    <Avatar style={{ width: '36px', height: '36px', background: get(assignee, 'color', '#ccc'), fontSize: '1rem', transform: 'translateX(-5px)' }}>
      {get(assignee, 'name') ? assignee.name.substring(0, 2).toUpperCase() : null}
    </Avatar>
  )
}

export default Assignee