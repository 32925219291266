import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Chip, IconButton } from '@mui/material'
import { Check, Clear, Add, Remove, Visibility, CopyAll, Sort, ArrowDropDown, History, ContentCopy } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get, isEqual, sumBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt, positiveFloat, getDateDiffStr, getDate } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import OrderBy from '../OrderBy'
import Popover from '../Popover'
import PriceField from '../PriceField'
import { getPart } from '../Parts/functions'
import SelectPart from '../Parts/SelectPart'
import SelectPartOrder from '../Orders/SelectPartOrder'
import { addLineItem, updateLineItem, findLineItems, getNewLineNumber } from './functions'
import PartOrderInvoiceLineItems from './PartOrderInvoiceLineItems'

function CreateEditLineItem({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreated, onUpdated }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newLineItem, setNewLineItem] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])
  const [matches, setMatches] = useState([])
  const [isBusinessDays, setIsBusinessDays] = useState(true)
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    if (!get(newLineItem, 'partId')) errors.partId = 'Required'
    if (!get(newLineItem, 'referenceLocation')) errors.referenceLocation = 'Required'
    if (!get(newLineItem, 'requirement')) errors.requirement = 'Required'
    if (get(newLineItem, 'isMeasurable') && !get(newLineItem, 'requirementMax')) errors.requirementMax = 'Required'
    if (get(newLineItem, 'isMeasurable') && !get(newLineItem, 'requirementMin')) errors.requirementMin = 'Required'
    if (!get(newLineItem, 'actual')) errors.actual = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    if (data.id) {
      return updateLineItem(data.id, {
        partId: newLineItem.partId,
        referenceLocation: newLineItem.referenceLocation,
        requirement: newLineItem.requirement,
        isMeasurable: newLineItem.isMeasurable,
        ...newLineItem.requirementMax && { requirementMax: newLineItem.requirementMax },
        ...newLineItem.requirementMin && { requirementMin: newLineItem.requirementMin },
        actual: newLineItem.actual,
        ...newLineItem.files && { files: newLineItem.files },
      }).then(lineItem => {
        handleClose()
        enqueueSnackbar('Line item updated successfully.', { variant: 'success' })
        if (onUpdated) onUpdated(lineItem)
      })
    }
    return addLineItem({ ...newLineItem, lineNumber: await getNewLineNumber(data.journalEntryId), companyId })
      .then(lineItem => {
        handleClose()
        enqueueSnackbar('Line item created successfully.', { variant: 'success' })
        if (onCreated) onCreated(lineItem)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewLineItem(data)
  }, [data])

  useAsyncFunc(async args => {
    if (get(newLineItem, 'partId')) return findLineItems(args)
    return () => null
  }, { partId: get(newLineItem, 'partId'), ...get(data, 'journalEntryId') ? { excludeOrderIds: [], orderBy: ['journalEntryId','desc'] } : { orderBy } }, setMatches, [orderBy, newLineItem, data])

  return (
    <>
      {button && (
        <Box style={{ cursor: 'pointer', ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>{`${data.id ? 'Edit' : 'Create new'} line item`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ flexGrow: 1 }}>
                <Box style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
                  <SelectPartOrder
                    companyId={companyId}
                    partOrderLabelPrefix={({ id }) => (
                      <PartOrderInvoiceLineItems
                        partOrderId={id}
                        renderLineItems={(content, lineItems, partOrder) => {
                          const invoicedSum = sumBy(lineItems, ({ quantity, unitPrice }) => quantity * unitPrice)
                          const invoicedPercent = partOrder ? invoicedSum / (partOrder.quantity * partOrder.unitPrice) * 100 : 0
                          const chip = (
                            <Chip
                              color="primary"
                              {...lineItems.length === 0 && { variant: 'outlined' }}
                              label={`$${invoicedSum.toFixed(2)} (${Math.round(invoicedPercent)}%) invoiced`}
                              style={{ marginRight: '10px' }}
                            />
                          )
                          return lineItems.length > 0 ? (
                            <Popover
                              button={chip}
                              content={content}
                              trigger="hover"
                              clickableInside
                            />
                          ) : chip
                        }}
                      />
                    )}
                    onChange={async (partOrderId, { lineNumber: refLineNumber, partId, quantity, unit, unitPrice }, { numberRef: refPoNumber }) => setNewLineItem({
                      ...newLineItem, partOrderId, refPoNumber, refLineNumber, partId, quantity, unit, unitPrice, name: `P/N ${await getPart(partId).then(({ number }) => number)}`, notes: await getPart(partId).then(({ name }) => name) })}
                  />
                  or
                  <Box style={{ flexGrow: 1 }}>
                    <SelectPart
                      companyId={companyId}
                      partId={get(newLineItem, 'partId')}
                      onChange={part => setNewLineItem({ ...newLineItem, partId: get(part, 'id') })}
                      inputProps={{
                        ...addErrorProps(errors, 'partId')
                      }}
                    />
                  </Box>
                </Box>
                {(get(newLineItem, 'partId') || get(newLineItem, 'remarks')) && (
                  <>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="referenceLocation"
                      label="Reference Location"
                      fullWidth
                      style={{ marginBottom: '10px' }}
                      value={get(newLineItem, 'referenceLocation', '')}
                      onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, referenceLocation: value })}
                      {...addErrorProps(errors, 'referenceLocation')}
                      required
                    />
                    <Checkbox
                      checked={get(newLineItem, 'isMeasurable', false)}
                      onChange={({ target: { checked } }) => setNewLineItem({ ...newLineItem, isMeasurable: checked })}
                      label="Measurable specifications"
                      style={{ marginBottom: '10px' }}
                    />
                    <Box style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Specification or Drawing Requirement"
                        fullWidth
                        value={get(newLineItem, 'requirement', '')}
                        onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, requirement: value })}
                        {...addErrorProps(errors, 'requirement')}
                        required
                      />
                      {get(newLineItem, 'isMeasurable', false) && (
                        <Box style={{ display: 'flex', gap: '10px', width: '50%' }}>
                          <TextField
                            size="small"
                            variant="outlined"
                            label="Max"
                            fullWidth
                            value={get(newLineItem, 'requirementMax', '')}
                            onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, requirementMax: value })}
                            InputProps={{
                              startAdornment: <Add fontSize="small" />,
                            }}
                            {...addErrorProps(errors, 'requirementMax')}
                            required
                          />
                          <TextField
                            size="small"
                            variant="outlined"
                            label="Min"
                            fullWidth
                            value={get(newLineItem, 'requirementMin', '')}
                            onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, requirementMin: value })}
                            InputProps={{
                              startAdornment: <Remove fontSize="small" />,
                            }}
                            {...addErrorProps(errors, 'requirementMin')}
                            required
                          />
                        </Box>
                      )}
                    </Box>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Actual Results of Nonconformance"
                      fullWidth
                      style={{ marginBottom: '10px' }}
                      value={get(newLineItem, 'actual', '')}
                      onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, actual: value })}
                      InputProps={{
                        endAdornment: (
                          <Dropdown
                            button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                            beforeItems={<Typography variant="overline" display="block" color="GrayText">Notes history</Typography>}
                            items={(matches || []).map(({ notes, updatedAt }) => ({
                              label: (
                                <>
                                  {get(newLineItem, 'notes', '') === notes && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                                  {updatedAt.toLocaleString()}
                                </>
                              ),
                              value: notes,
                              tooltip: notes,
                            }))}
                            onChange={({ value }) => setNewLineItem({ ...newLineItem, actual: value })}
                            disableCloseOnSelect
                          />
                        ),
                      }}
                      {...addErrorProps(errors, 'actual')}
                      required
                    />
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Remarks"
                      multiline
                      rows={5}
                      fullWidth
                      style={{ marginBottom: '10px' }}
                      value={get(newLineItem, 'remarks', '')}
                      onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, remarks: value })}
                      InputProps={{
                        endAdornment: (
                          <Dropdown
                            button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                            beforeItems={<Typography variant="overline" display="block" color="GrayText">Notes history</Typography>}
                            items={(matches || []).map(({ notes, updatedAt }) => ({
                              label: (
                                <>
                                  {get(newLineItem, 'notes', '') === notes && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                                  {updatedAt.toLocaleString()}
                                </>
                              ),
                              value: notes,
                              tooltip: notes,
                            }))}
                            onChange={({ value }) => setNewLineItem({ ...newLineItem, remarks: value })}
                            disableCloseOnSelect
                          />
                        ),
                      }}
                      {...addErrorProps(errors, 'remarks')}
                      required={!get(newLineItem, 'isMeasurable', false)}
                    />
                  </>
                )}
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
            disabled={isEqual(data, newLineItem)}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateEditLineItem