import { useState, useEffect, useRef } from 'react'
import { Box, TextField, Button, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Print, Download, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import FileViewer from 'react-file-viewer'
import NumberFormat from 'react-number-format'
import { PDFDownloadLink, Document, Page, View, Text } from '@react-pdf/renderer'
import { useReactToPrint } from 'react-to-print'
import QRCode from 'react-qr-code'
import { get, sortBy } from 'lodash'
import { useAsyncFunc, getCompany } from '../../functions'
import Checkbox from '../Checkbox'
import ContactProp from '../Contacts/ContactProp'
import { getOrder, findPartOrders } from './functions'
import PartOrderRow from './PartOrderRow'

function Preview({ button, buttonStyle, orderId, forcePrint }) {
  const [isOpen, setIsOpen] = useState(false)
  const [order, setOrder] = useState()
  const [partOrders, setPartOrders] = useState()
  const [company, setCompany] = useState()
  const [isFooterIncluded, setIsFooterIncluded] = useState(true)
  const [isQRIncluded, setIsQRIncluded] = useState(false)

  useAsyncFunc(getOrder, orderId, setOrder, [orderId, isOpen])
  useAsyncFunc(findPartOrders, { orderId, statusNotIn: ['ARCHIVED'], orderBy: ['createdAt','asc'] }, setPartOrders, [orderId, isOpen])

  useEffect(() => {
    if (order) getCompany(order.companyId).then(setCompany)
  }, [order])

  useEffect(() => {
    if (isOpen && order && partOrders && forcePrint) setTimeout(() => print(), 500)
  }, [isOpen, order, partOrders, forcePrint])

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  const content = (
    <Box className="page" ref={contentRef} style={{ display: 'flex', flexDirection: 'column', gap: '10px', transform: 'scale(.9)' }}>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '145px' }}>
          <img alt="logo" src="https://i.imgur.com/CltCBmd.png" width="100%" />
        </Box>
        <Box style={{ flexGrow: 1, padding: '0 10px 5px' }}>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {get(company, 'address')}
          </Typography>
          <Typography variant="body2">
            {`Phone: `}
            <NumberFormat
              value={get(company, 'phone')}
              format="(###) ###-####"
              displayType="text"
            />
          </Typography>
          <Typography variant="body2">
            {`Email: ${get(company, 'email')}`}
          </Typography>
          <Typography variant="body2">
            {`Website: ${get(company, 'website')}`}
          </Typography>
          {/* <Typography variant="body2">
            {`Fax: ${get(company, 'fax')}`}
          </Typography> */}
        </Box>
        <Box>
          <Typography variant="h5" style={{ fontWeight: 'bold', marginRight: '10px', textAlign: 'right' }}>
            {`${get(order, 'isPurchase') ? 'Purchase' : 'Sales'} Order`}
          </Typography>
        </Box>
        <Box>
          <Box style={{ padding: '5px', border: '1px solid #455a64', borderRadius: '5px', textAlign: 'center' }}>
            <Typography variant="h4">
              {get(order, 'numberRef')}
            </Typography>
            <Typography variant="overline" display="block" style={{ padding: '0 20px', background: '#f2f2f2', borderRadius: '0 0 5px 5px' }}>
              Rev.1
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '33.33%', padding: '0 10px 5px' }}>
          <Typography variant="overline">
            {`ATTN: `}
            <ContactProp contactId={get(order, 'isPurchase') ? get(order, 'shipFromContactId') : get(order, 'shipToContactId')} prop="contactName" />
          </Typography>
          <Typography>
            {get(order, 'isPurchase') ? get(order, 'shipFromName') : get(order, 'shipToName')}
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {get(order, 'isPurchase') ? get(order, 'shipFromAddress') : get(order, 'shipToAddress')}
          </Typography>
          <Typography variant="body2">
            <NumberFormat
              value={get(order, 'isPurchase') ? get(order, 'shipFromPhone') : get(order, 'shipToPhone')}
              format="(###) ###-####"
              displayType="text"
            />
          </Typography>
        </Box>
        <Box style={{ width: '33.33%', padding: '0 10px 5px' }}>
          <Typography variant="overline">
            Bill To
          </Typography>
          <Typography>
            {get(order, 'billToName')}
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {get(order, 'billToAddress')}
          </Typography>
          <Typography variant="body2">
            <NumberFormat
              value={get(order, 'billToPhone')}
              format="(###) ###-####"
              displayType="text"
            />
          </Typography>
        </Box>
        <Box style={{ display: 'flex', width: '33.33%', padding: '0 10px 5px' }}>
          <Box style={{ width: '33.33%' }}>
            <Typography variant="overline">
              Date
            </Typography>
            <Typography variant="body2">
              {get(order, 'orderAt') ? order.orderAt.toLocaleDateString() : ''}
            </Typography>
          </Box>
          <Box style={{ width: '33.33%' }}>
            <Typography variant="overline">
              Ship Via
            </Typography>
            <Typography variant="body2">
              {get(order, 'shipVia')}
            </Typography>
          </Box>
          <Box style={{ width: '33.33%' }}>
            <Typography variant="overline">
              Terms
            </Typography>
            <Typography variant="body2">
              {get(order, 'terms')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box style={{ padding: '5px', border: '1px solid #ccc' }}>
        <TableContainer>
          <Table className="align-top" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Line</TableCell>
                <TableCell>Quantity</TableCell>
                {/* <TableCell>Unit</TableCell> */}
                <TableCell>Part</TableCell>
                <TableCell align="right">Unit Price</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Due</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortBy(partOrders || [], ['lineNumber']).map(partOrder => (
                <PartOrderRow key={partOrder.id} {...partOrder} readOnly view="PRINT" />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className="page-footer" style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px', flexGrow: 1, minHeight: '120px', padding: '0 10px 5px' }}>
          <Typography variant="overline">
            Footnote
          </Typography>
          {get(order, 'footnote') && (
            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
              {order.footnote}
            </Typography>
          )}
          {isFooterIncluded && (
            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
              {get(company, 'purchaseOrderFootnote')}
            </Typography>
          )}
          <Box style={{ display: 'flex', gap: '10px', width: '50%', marginTop: '5px' }}>
            <Box style={{ width: '50%', paddingBottom: '5px', borderBottom: '1px solid #000' }}>
              X.
            </Box>
            <Box style={{ width: '50%', paddingBottom: '5px', borderBottom: '1px solid #000' }}>
              Date.
            </Box>
          </Box>
        </Box>
        <Box>
          {isQRIncluded && <QRCode size={64} value={`edimfg.com/orders/${orderId}`} />}
        </Box>
      </Box>
    </Box>
  )

  const ContentDoc = () => (
    <Document>
      <Page size="LETTER">
        <View>
          <Text>
          </Text>
        </View>
      </Page>
    </Document>
  )

  return button ? (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{ zIndex: 1400 }}
      >
        <DialogTitle style={{ borderBottom: '1px solid #e6e6e6' }}>Preview</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px', boxShadow: '0 0 25px rgba(0, 0, 0, .125)', color: '#000' }}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Checkbox
            checked={isFooterIncluded}
            onChange={({ target: { checked } }) => setIsFooterIncluded(checked)}
            label="Include footer"
          />
          <Checkbox
            checked={isQRIncluded}
            onChange={({ target: { checked } }) => setIsQRIncluded(checked)}
            label="Include QR code"
          />
          {/* <PDFDownloadLink document={<ContentDoc />} fileName={`edi_order_${get(order, 'number')}.pdf`}>
            <Button
              size="small"
              variant="outlined"
              startIcon={<Download />}
              disableElevation
            >
              Download
            </Button>
          </PDFDownloadLink> */}
          <Button
            size="small"
            variant="contained"
            startIcon={<Print />}
            disableElevation
            onClick={print}
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : content
}

export default Preview