import { useState, useEffect } from 'react'
import { TextField, Button } from '@mui/material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, useAsyncFunc, findInternalMemos, addInternalMemo } from '../functions'

function EditInternalMemo({ refFromId, onUpdate }) {
  const [memo, setMemo] = useState()
  const [updatedMemo, setUpdatedMemo] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  useAsyncFunc(findInternalMemos, { refFromId, orderBy: ['createdAt','desc'], limit: 1 }, ([memo]) => setMemo(memo), [refFromId])

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return
    return addInternalMemo({
        refFromId,
        content: updatedMemo.content,
      })
      .then(memo => {
        setMemo(memo)
        return memo
      })
      .then(onUpdate)
      .then(() => enqueueSnackbar('Memo updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedMemo(memo)
  }, [memo])

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        value={get(updatedMemo, 'content', '')}
        onChange={({ target: { value } }) => setUpdatedMemo({ ...updatedMemo, content: value })}
        style={{ marginBottom: '10px' }}
      />
      {!isEqual(memo, updatedMemo) && (
        <Button
          variant="outlined"
          onClick={() => setUpdatedMemo(memo)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        disabled={isEqual(memo, updatedMemo)}
        disableElevation
      >
        Save Memo
      </Button>
    </>
  )
}

export default EditInternalMemo