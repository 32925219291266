import { useNavigate } from 'react-router-dom'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { KeyboardArrowDown, RocketLaunchOutlined, AssignmentOutlined, PrintOutlined, ContentCopy, DeleteOutline, Launch } from '@mui/icons-material'
import { get } from 'lodash'
import Confirm from '../Confirm'
import ShareLink from '../ShareLink'
import PartName from '../Parts/PartName'
import { updateJob } from './functions'
import CreateJob from './Create'
import InspectJob from './Inspect'

function Actions({ item, onPrint, onSelect, onRemove }) {

  const navigate = useNavigate()

  return item ? (
    <>
      <Tooltip title="Hide">
        <IconButton onClick={() => navigate('/')}>
          <KeyboardArrowDown />
        </IconButton>
      </Tooltip>
      <Button style={{ flexGrow: 1 }}>
        {`J/N ${get(item, 'number')} · ${get(item, 'quantity')}x `}
        <PartName partId={get(item, 'partId')} />
        {get(item, 'shipAt') && ` · ships ${item.shipAt.toLocaleDateString()}`}
      </Button>
      <Box>
        <Button
          startIcon={<RocketLaunchOutlined />}
          style={{ marginLeft: '5px' }}
        >
          Ship
        </Button>
        <InspectJob
          companyId={item.companyId}
          jobId={item.id}
          button={(
            <Button
              startIcon={<AssignmentOutlined />}
              style={{ marginLeft: '5px' }}
            >
              Inspect
            </Button>
          )}
          buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
        />
        <Button
          startIcon={<PrintOutlined />}
          onClick={onPrint}
          style={{ marginLeft: '5px' }}
        >
          Print
        </Button>
        <ShareLink
          button={(
            <Button
              startIcon={<Launch />}
              style={{ marginLeft: '5px' }}
            >
              Share
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          url={`edimfg.com/${item.id}`}
        />
        <CreateJob
          companyId={item.companyId}
          button={(
            <Button
              startIcon={<ContentCopy />}
              style={{ marginLeft: '5px' }}
            >
              Copy
            </Button>
          )}
          buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
          data={{
            copyFromId: item.id,
            isNumberAuto: false,
            quantity: item.quantity,
            dueAt: item.dueAt,
            ...item.poNumber && { poNumber: item.poNumber },
            ...item.lineNumber && { lineNumber: item.lineNumber },
            ...item.description && { description: item.description },
            status: item.status,
            partId: item.partId,
          }}
          onCreate={() => window.location.reload()}
        />
        <Confirm
          button={(
            <Button
              color="error"
              startIcon={<DeleteOutline />}
              style={{ marginLeft: '5px' }}
            >
              Delete
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          title="Confirm delete?"
          content="Are you sure you want to delete this job?"
          onConfirm={() => updateJob(item.id, { status: 'ARCHIVED' }).then(onRemove)}
        />
      </Box>
    </>
  ) : (
    <Button style={{ flexGrow: 1 }}>
      No item selected
    </Button>
  )
}

export default Actions