import { collection, query, where, orderBy, limit, startAfter, endBefore, limitToLast, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith, getCount } from '../../functions'

export const getShipment = id => get('shipments', id)

export const addShipment = data => add('shipments', data)

export const updateShipment = (id, updates) => update('shipments', id, updates, 'shipmentLogs')

export const findShipments = async ({ companyId, number, numberBeginsWith, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'shipments'), ...[
    where('companyId', '==', companyId),
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    orderBy(...order),
    limit(max || await getGlobal('shipmentsQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)

export const getNewShipmentNumber = companyId => getCount('shipments', companyId).then(count => count + 1)

export const getShipmentLine = id => get('shipmentLines', id)

export const addShipmentLine = data => add('shipmentLines', data)

export const updateShipmentLine = (id, updates) => update('shipmentLines', id, updates, 'shipmentLineLogs')

export const findShipmentLines = async ({ shipmentId, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'shipmentLines'), ...[
    where('shipmentId', '==', shipmentId),
    orderBy(...order),
    limit(max || await getGlobal('shipmentLinesQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)