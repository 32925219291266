import { Box, Button } from '@mui/material'
import { Visibility, PrintOutlined, ContentCopy, DeleteOutline, Launch } from '@mui/icons-material'
import { get } from 'lodash'
import { getDate } from '../../functions'
import Confirm from '../Confirm'
import ShareLink from '../ShareLink'
import { updateQuote } from './functions'
import Preview from './Preview'
import CreateQuote from './Create'

function Actions({ item }) {
  return item ? (
    <>
      <Button style={{ flexGrow: 1 }}>
        {`Quote Number ${get(item, 'number')}`}
      </Button>
      <Box>
        <Preview
          button={(
            <Button
              startIcon={<Visibility />}
              style={{ marginLeft: '5px' }}
            >
              Preview
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          quoteId={item.id}
        />
        <ShareLink
          button={(
            <Button
              startIcon={<Launch />}
              style={{ marginLeft: '5px' }}
            >
              Share
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          url={`edimfg.com/quotes/${item.id}`}
        />
        <Preview
          button={(
            <Button
              startIcon={<PrintOutlined />}
              style={{ marginLeft: '5px' }}
            >
              Print
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          quoteId={item.id}
          forcePrint
        />
        <CreateQuote
          companyId={item.companyId}
          button={(
            <Button
              startIcon={<ContentCopy />}
              style={{ marginLeft: '5px' }}
            >
              Copy
            </Button>
          )}
          buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
          data={{ id: item.id, companyId: item.companyId, status: 'ACTIVE', isNumberAuto: true, date: new Date(), dateExp: getDate(null, '+', 180, 'day') }}
          onCreate={() => window.location.reload()}
        />
        <Confirm
          button={(
            <Button
              color="error"
              startIcon={<DeleteOutline />}
              style={{ marginLeft: '5px' }}
            >
              Delete
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          title="Confirm delete?"
          content="Are you sure you want to delete this quote?"
          onConfirm={() => updateQuote(item.id, { status: 'INACTIVE' }).then(() => window.location.reload())}
        />
      </Box>
    </>
  ) : (
    <Button style={{ flexGrow: 1 }}>
      No item selected
    </Button>
  )
}

export default Actions