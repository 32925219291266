import { TableRow, TableCell } from '@mui/material'
import NumberFormat from 'react-number-format'
import { get, sumBy, minBy, maxBy } from 'lodash'

function MaterialsRow({ partQuote, partQuoteLines }) {
  const materials = partQuoteLines.filter(({ unit }) => ['PIECE'].includes(unit))

  if (materials.length === 0) return

  const sumQuantity = sumBy(materials, ({ quantity, quantityMade }) => quantity / (quantityMade || 1))
  const sumPrice = sumBy(materials, ({ quantity, quantityMade, unitPrice, markupRate }) => quantity*unitPrice*markupRate / (quantityMade || 1))
  const minUnitPrice = minBy(materials, ({ unitPrice, quantityMade }) => unitPrice / (quantityMade || 1)).unitPrice
  const maxUnitPrice = maxBy(materials, ({ unitPrice, quantityMade }) => unitPrice / (quantityMade || 1)).unitPrice
  const minMarkupRate = minBy(materials, ({ markupRate }) => markupRate).markupRate
  const maxMarkupRate = maxBy(materials, ({ markupRate }) => markupRate).markupRate
  return (
    <TableRow style={{ background: '#efefef' }}>
      <TableCell></TableCell>
      <TableCell colSpan={3}>{`Materials - for ${get(partQuote, 'quantityBase')} part(s) made`}</TableCell>
      {/* <TableCell align="right">{sumQuantity.toFixed(2)}</TableCell>
      <TableCell>PIECE(S)</TableCell> */}
      <TableCell align="right">{`$${minUnitPrice.toFixed(2)}${maxUnitPrice !== minUnitPrice ? ` — $${maxUnitPrice.toFixed(2)}` : ''}`}</TableCell>
      <TableCell align="right">{`${minMarkupRate}x${maxMarkupRate !== minMarkupRate ? ` — ${maxMarkupRate}x` : ''}`}</TableCell>
      <TableCell align="right">
        <NumberFormat
          value={sumPrice}
          prefix="$"
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          displayType="text"
        />
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

export default MaterialsRow