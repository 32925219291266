import { Box, Button, TextField, IconButton } from '@mui/material'
import { FilterList, Search } from '@mui/icons-material'
import { get } from 'lodash'
import { jobRanges as ranges, jobStatuses as statuses } from '../../functions'
import Checkbox from '../Checkbox'
import DateRange from '../DateRange'
import Dropdown from '../Dropdown'
import SelectPart from '../Parts/SelectPart'

function JobFilters({ companyId, beforeFilters, afterFilters, filterBy, setFilterBy, filterByValue, setFilterByValue, statusIn, setStatusIn }) {
  const toggleStatusIn = (value, checked) => {
    if (checked && !statusIn.includes(value)) return setStatusIn([...statusIn, value])
    if (!checked && statusIn.includes(value)) return setStatusIn(statusIn.filter(v => v !== value))
  }

  return (
    <Box style={{ display: 'flex', gap: '10px' }}>
      {beforeFilters}
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<FilterList />}
          >
            {filterBy.label}
          </Button>
        )}
        items={[
          { label: 'Ship Date', value: 'shipAt' },
          { label: 'Due Date', value: 'dueAt' },
          { label: 'PO Number', value: 'poNumber' },
          { label: 'Part Number', value: 'partId' },
        ]}
        onChange={setFilterBy}
      />
      {filterBy.value === 'shipAt' && (
        <DateRange
          value={[filterByValue.shipAtFrom, filterByValue.shipAtTo]}
          ranges={ranges}
          inputStyle={{ padding: '6px 12px', fontSize: '14px' }}
          onChange={([shipAtFrom, shipAtTo]) => setFilterByValue({ ...filterByValue, shipAtFrom, shipAtTo })}
        />
      )}
      {filterBy.value === 'dueAt' && (
        <DateRange
          value={[filterByValue.dueAtFrom, filterByValue.dueAtTo]}
          ranges={ranges}
          inputStyle={{ padding: '6px 12px', fontSize: '14px' }}
          onChange={([dueAtFrom, dueAtTo]) => setFilterByValue({ ...filterByValue, dueAtFrom, dueAtTo })}
        />
      )}
      {filterBy.value === 'poNumber' && (
        <>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search by PO"
            value={filterByValue.poNumberBeginsWith}
            onChange={({ target: { value } }) => setFilterByValue({ ...filterByValue, poNumberBeginsWith: value })}
            InputProps={{
              startAdornment: <IconButton edge="start"><Search /></IconButton>,
            }}
          />
        </>
      )}
      {filterBy.value === 'partId' && (
        <Box style={{ flexGrow: 1 }}>
          <SelectPart
            companyId={companyId}
            partId={filterByValue.partId}
            onChange={part => setFilterByValue({ ...filterByValue, partId: get(part, 'id') })}
          />
        </Box>
      )}
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<FilterList />}
          >
            Status
          </Button>
        )}
        items={statuses.map(({ label, value }) => ({
          label: (
            <Checkbox
              checked={statusIn.includes(value)}
              onChange={({ target: { checked } }) => toggleStatusIn(value, checked)}
              label={label}
            />
          ),
          value,
        }))}
        disableCloseOnSelect
      />
      {afterFilters}
    </Box>
  )
}

export default JobFilters