import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, IconButton, Button, Tooltip, TableRow, TableCell } from '@mui/material'
import { LocalShipping, ArrowDropDown, Visibility, CheckCircle } from '@mui/icons-material'
import { useAsyncFunc } from '../../functions'
import Checkbox from '../Checkbox'
import { findPartOrders } from '../Orders/functions'
import OrderNumber from '../Orders/OrderNumber'
import OrderContactProp from '../Orders/OrderContactProp'
import PartName from '../Parts/PartName'
import AllocateJobs from '../Jobs/Allocate'
import Ship from './Ship'

function ShipListRow({ companyId, shipping, setShipping, partOrder }) {
  const [siblings, setSiblings] = useState()
  const [isShowingSiblings, setIsShowingSiblings] = useState(false)
  const [isAllocating, setIsAllocating] = useState(false)
  const [isShipping, setIsShipping] = useState(false)
  const { id, orderId } = partOrder

  const navigate = useNavigate()

  useAsyncFunc(async args => findPartOrders(args).then(results => results.filter(p => p.id !== id)), { orderId, orderBy: ['dueAt','asc'] }, setSiblings, [orderId])

  const Row = ({ partOrder, isLeading }) => {
    const { id, orderId, partId, lineNumber, quantity, quantityBalance, status, dueAt, updatedAt } = partOrder
    return (
      <TableRow>
          <TableCell {...!isLeading && { style: { paddingLeft: '30px' } }}>
            <Checkbox
              checked={shipping.find(s => s.id === id)}
              onChange={({ target: { checked } }) => {
                if (checked) return setShipping([ ...shipping, partOrder ])
                return setShipping(shipping.filter(s => s.id !== id))
              }}
              label={dueAt.toLocaleDateString()}
              disabled={status !== 'COMPLETED'}
            />
          </TableCell>
          <TableCell>
            <Tooltip title="View order">
              <IconButton size="small" onClick={() => navigate(`/orders/${orderId}`)} style={{ marginLeft: '-10px', opacity: .325 }}>
                <Visibility fontSize="small" />
              </IconButton>
            </Tooltip>
            {`O/N `}
            <OrderNumber partOrderId={id} />
          </TableCell>
          <TableCell>
            {lineNumber}
            {isLeading && (
              <IconButton size="small" edge="end" onClick={() => setIsShowingSiblings(!isShowingSiblings)} style={{ marginRight: '-10px' }}>
                <ArrowDropDown fontSize="small" color="disabled" />
              </IconButton>
            )}
          </TableCell>
          <TableCell>
            <OrderContactProp partOrderId={id} contactIdField="shipToContactId" prop="name" />
          </TableCell>
          <TableCell>
            <Tooltip title={<PartName partId={partId} />}>
              <Typography variant="body2" style={{ width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <PartName partId={partId} />
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell>
            {`${quantity - quantityBalance} / ${quantity}`}
            <AllocateJobs
              partId={partId}
              forceOpen={isAllocating}
              setForceOpen={setIsAllocating}
            />
            <Ship
              companyId={companyId}
              partOrders={[partOrder]}
              forceOpen={isShipping}
              setForceOpen={setIsShipping}
            />
            <Button
              className="tiny"
              size="small"
              variant="outlined"
              {...status === 'OPEN' ? {
                color: 'warning',
                onClick: () => setIsAllocating(true),
              } : status === 'COMPLETED' ? {
                color: 'primary',
                startIcon: <LocalShipping />,
                onClick: () => setIsShipping(true),
              } : {
                color: 'success',
                startIcon: <CheckCircle />,
              }}
              style={{ minWidth: 'auto', marginLeft: '10px', padding: `0 5px 0${status !== 'OPEN' ? ` 12px` : ''}`, fontSize: '12px' }}
              disableElevation
            >
              {status === 'COMPLETED' ? 'Ready To Ship' : status}
            </Button>
          </TableCell>
          <TableCell>
            {updatedAt.toLocaleDateString()}
          </TableCell>
        </TableRow>
    )
  }

  return (
    <>
      <Row partOrder={partOrder} isLeading />
      {isShowingSiblings && (siblings || []).map(partOrder => <Row key={partOrder.id} partOrder={partOrder} />)}
    </>
  )
}

export default ShipListRow
