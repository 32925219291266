import { useState } from 'react'
import { Typography, Button, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@mui/material'
import { Outbound, CheckCircle, ArrowDropUp, ArrowDropDown } from '@mui/icons-material'
import { findPartOrders } from '../Orders/functions'
import { findJobs } from '../Jobs/functions'
import AllocateJobs from '../Jobs/Allocate'
import CreateJob from '../Jobs/Create'
import PartCount from './PartCount'

function PartInventory({ companyId, partId }) {
  const [isOutboundExpanded, setIsOutboundExpanded] = useState(false)

  return companyId && (
    <TableContainer style={{ border: '1px solid #d9d9d9', borderRadius: '5px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Status
            </TableCell>
            <TableCell>
              Quantity
            </TableCell>
            <TableCell>
              Ready
            </TableCell>
            <TableCell>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Outbound fontSize="small" style={{ marginRight: '5px', color: '#ff5722', verticalAlign: 'top' }} />
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 500 }}>
                Outbound
                <IconButton size="small" onClick={() => setIsOutboundExpanded(!isOutboundExpanded)}>
                  {isOutboundExpanded ? <ArrowDropUp fontSize="small" color="disabled" /> : <ArrowDropDown fontSize="small" color="disabled" />}
                </IconButton>
              </Typography>
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findPartOrders({ partId, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                suffix={() => ` pcs`}
              />
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findPartOrders({ partId, status: 'COMPLETED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                suffix={() => ` pcs`}
              />
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findPartOrders({ partId, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                subtractFunc={args => findPartOrders({ partId, status: 'COMPLETED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                display={({ quantityBalance }) => quantityBalance > 0 ? (
                  <AllocateJobs
                    partId={partId}
                    button={(
                      <Button
                        className="tiny"
                        size="small"
                        variant="contained"
                        style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                        disableElevation
                      >
                        Allocate
                      </Button>
                    )}
                  />
                ) : <Tooltip title="No action required"><CheckCircle fontSize="small" color="success" /></Tooltip>}
              />
            </TableCell>
          </TableRow>
          {isOutboundExpanded && (
            <>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 500 }}>
                    — To Customers
                  </Typography>
                </TableCell>
                <TableCell>
                  <PartCount
                    partId={partId}
                    addFunc={args => findPartOrders({ partId, isPurchase: false, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    suffix={() => ` pcs`}
                  />
                </TableCell>
                <TableCell>
                  <PartCount
                    partId={partId}
                    addFunc={args => findPartOrders({ partId, isPurchase: false, status: 'COMPLETED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    suffix={() => ` pcs`}
                  />
                </TableCell>
                <TableCell>
                  <PartCount
                    partId={partId}
                    addFunc={args => findPartOrders({ partId, isPurchase: false, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    subtractFunc={args => findPartOrders({ partId, isPurchase: false, status: 'COMPLETED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    display={({ quantityBalance }) => quantityBalance > 0 ? (
                      <AllocateJobs
                        partId={partId}
                        button={(
                          <Button
                            className="tiny"
                            size="small"
                            variant="contained"
                            style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                            disableElevation
                          >
                            Allocate
                          </Button>
                        )}
                      />
                    ) : <Tooltip title="No action required"><CheckCircle fontSize="small" color="success" /></Tooltip>}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 500 }}>
                    — To Vendors
                  </Typography>
                </TableCell>
                <TableCell>
                  <PartCount
                    partId={partId}
                    addFunc={args => findPartOrders({ partId, isPurchase: true, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    suffix={() => ` pcs`}
                  />
                </TableCell>
                <TableCell>
                  <PartCount
                    partId={partId}
                    addFunc={args => findPartOrders({ partId, isPurchase: true, status: 'COMPLETED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    suffix={() => ` pcs`}
                  />
                </TableCell>
                <TableCell>
                  <PartCount
                    partId={partId}
                    addFunc={args => findPartOrders({ partId, isPurchase: true, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    subtractFunc={args => findPartOrders({ partId, isPurchase: true, status: 'COMPLETED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                    display={({ quantityBalance }) => quantityBalance > 0 ? (
                      <AllocateJobs
                        partId={partId}
                        button={(
                          <Button
                            className="tiny"
                            size="small"
                            variant="contained"
                            style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                            disableElevation
                          >
                            Allocate
                          </Button>
                        )}
                      />
                    ) : <Tooltip title="No action required"><CheckCircle fontSize="small" color="success" /></Tooltip>}
                  />
                </TableCell>
              </TableRow>
            </>
          )}
          <TableRow>
            <TableCell>
              <CheckCircle fontSize="small" style={{ marginRight: '5px', color: '#4caf50', verticalAlign: 'top' }} />
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 500 }}>
                Stock
              </Typography>
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findJobs({ companyId, partId, orderBy: ['createdAt','desc'], limit: 9999, ...args })}
                suffix={() => ` pcs`}
              />
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findJobs({ companyId, partId, status: 'COMPLETED', orderBy: ['createdAt','desc'], limit: 9999, ...args })}
                suffix={() => ` pcs`}
              />
            </TableCell>
            <TableCell>
              {/* <PartCount
                partId={partId}
                addFunc={args => findJobs({ companyId, partId, orderBy: ['createdAt','desc'], limit: 9999, ...args })}
                subtractFunc={args => findJobs({ companyId, partId, status: 'COMPLETED', orderBy: ['createdAt','desc'], limit: 9999, ...args })}
                display={({ quantityBalance }) => quantityBalance > 0 ? (
                  <Button
                    className="tiny"
                    size="small"
                    variant="contained"
                    style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                    disableElevation
                  >
                    View
                  </Button>
                ) : <Tooltip title="No action required"><CheckCircle fontSize="small" color="success" /></Tooltip>}
              /> */}
              <CreateJob
                companyId={companyId}
                button={(
                  <Button
                    className="tiny"
                    size="small"
                    variant="contained"
                    style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                    disableElevation
                  >
                    Add
                  </Button>
                )}
                data={{
                  isNumberAuto: true,
                  quantity: 1,
                  status: 'COMPLETED',
                  partId: partId,
                }}
                onCreate={() => {}}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Outbound fontSize="small" style={{ marginRight: '5px', color: '#03a9f4', verticalAlign: 'top', transform: 'rotateZ(90deg)' }} />
              <Typography variant="body2" sx={{ display: 'inline-block', fontWeight: 500 }}>
                Inbound
              </Typography>
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findPartOrders({ partId, isPurchase: true, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                suffix={() => ` pcs`}
              />
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findPartOrders({ partId, isPurchase: true, status: 'SHIPPED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                suffix={() => ` pcs`}
              />
            </TableCell>
            <TableCell>
              <PartCount
                partId={partId}
                addFunc={args => findPartOrders({ partId, isPurchase: true, statusNotIn: ['SHIPPED'], orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                subtractFunc={args => findPartOrders({ partId, isPurchase: true, status: 'SHIPPED', orderBy: ['dueAt','asc'], limit: 9999, ...args })}
                display={({ quantityBalance }) => quantityBalance > 0 ? (
                  <Button
                    className="tiny"
                    size="small"
                    variant="contained"
                    style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px' }}
                    disableElevation
                  >
                    Track
                  </Button>
                ) : <Tooltip title="No action required"><CheckCircle fontSize="small" color="success" /></Tooltip>}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PartInventory