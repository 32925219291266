import { useState, useEffect } from 'react'
import { Box, Typography, Button, Tabs, Tab } from '@mui/material'
import { FilterList } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import Checkbox from '../Checkbox'
import DateRange from '../DateRange'
import Dropdown from '../Dropdown'
import { findContacts } from '../Contacts/functions'
import { findPartDetails } from '../Parts/functions'
import PartName from '../Parts/PartName'
import PartNumber from '../Parts/PartNumber'
import PartProp from '../Parts/PartProp'
import { findOrders, findPartOrders, getOrder } from './functions'
import OrderNumber from './OrderNumber'
import SearchOrders from './Search'
import CreateOrder from './Create'

const PartOrderOption = ({ partOrder, partOrderLabelPrefix, order }) => {
  const { id, lineNumber, partId, quantity, unit, unitPrice } = partOrder
  return ({
    label: (
      <>
        {partOrderLabelPrefix ? partOrderLabelPrefix(partOrder) : ''}
        {`Line ${lineNumber} · `}
        {`P/N `}
        <PartNumber partId={partId} />
        {` · `}
        <PartName partId={partId} />
        {` · ${quantity} ${unit}${quantity > 1 ? 's' : ''} · $${unitPrice} ea`}
      </>
    ),
    value: id,
    partOrder,
    order,
  })
}

function SelectPartOrder({ companyId, button, buttonStyle, partId, partOrderId, partOrderLabelPrefix, query, isPurchase, onChange }) {
  const [orders, setOrders] = useState([])
  const [options, setOptions] = useState([])
  const [detailParts, setDetailParts] = useState()
  const [viewOptions, setViewOptions] = useState(query ? { view: 'SEARCH' } : { view: 'PART', includesPart: true, includesPartDetails: true })

  useAsyncFunc(findPartDetails, { partId, orderBy: ['createdAt','desc'] }, setDetailParts, [partId])

  useAsyncFunc(async () => {
    return findOrders({
      companyId,
      orderBy: ['createdAt','desc'],
      ...isPurchase && {
        shipFromContactIdNotIn: await findContacts({ companyId, type: 'VEND', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)),
      },
    })
  }, null, setOrders, [companyId])

  useEffect(() => {
    async function fetchPartOrders() {
      setOptions([
        ...partId ? [{
          label: (
            <Tabs value={viewOptions.view} onChange={(event, newValue) => setViewOptions({ ...viewOptions, view: newValue })}>
              <Tab label="Search" value="SEARCH" disableRipple />
              <Tab label={(
                <>
                  {`P/N `}
                  <PartNumber partId={partId} />
                </>
              )} value="PART" disableRipple />
              {(detailParts || []).length > 0 &&  <Tab label="Components & materials" value="DETAILS" disableRipple />}
            </Tabs>
          ),
          value: '',
          disabled: true,
        }] : [],
        ...viewOptions.view === 'SEARCH' ? [
          {
            label: (
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <SearchOrders
                    companyId={companyId}
                    onResult={results => setOrders(results)}
                    {...isPurchase && { isPurchase, newOrderProps: { isPurchasing: true } }}
                    query={query}
                  />
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Checkbox
                    checked={viewOptions.includesPart}
                    onChange={({ target: { checked } }) => setViewOptions({ ...viewOptions, includesPart: checked })}
                    label={(
                      <>
                        {`Includes only P/N `}
                        <PartNumber partId={partId} />
                      </>
                    )}
                  />
                  <Checkbox
                    checked={viewOptions.includesPartDetails}
                    onChange={({ target: { checked } }) => setViewOptions({ ...viewOptions, includesPartDetails: checked })}
                    label="Includes components & materials"
                  />
                  <Checkbox
                    checked={isPurchase}
                    label="Only purchases"
                    disabled
                  />
                </Box>
              </Box>
            ),
            value: '',
            disabled: true,
          },
          ...(await Promise.all(orders.map(async order => {
            const { id } = order
            const partOrders = await findPartOrders({
              orderId: id,
              isPurchase,
              orderBy: ['lineNumber','asc'],
              partIdIn: [
                ...viewOptions.includesPart ? [partId] : [],
                ...viewOptions.includesPartDetails ? detailParts.map(({ detailPartId }) => detailPartId) : [],
              ],
            })
            return [
              {
                label: (
                  <Typography variant="overline">
                    {`P/O `}<OrderNumber orderId={id} />
                  </Typography>
                ),
                value: id,
                disabled: true,
                dividedAfter: true,
              },
              ...partOrders.map(partOrder => <PartOrderOption partOrder={partOrder} partOrderLabelPrefix={partOrderLabelPrefix} order={order} />),
            ]
          }))).flat(),
        ] : [
          {
            label: (
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                {viewOptions.view === 'DETAILS' && detailParts.map(({ detailPartId }) => (
                  <Checkbox
                    checked={isPurchase}
                    label={(
                      <>
                        <PartNumber partId={detailPartId} />
                        <Button
                          className="tiny"
                          size="small"
                          variant="outlined"
                          style={{ minWidth: 'auto', marginLeft: '10px', padding: '0 5px', fontSize: '10px' }}
                          disableElevation
                        >
                          <PartProp partId={detailPartId} prop="type" />
                        </Button>
                      </>
                    )}
                    disabled
                  />
                ))}
                <Box style={{ display: 'flex' }}>
                  <Checkbox
                    checked={isPurchase}
                    label="Only purchases"
                    disabled
                  />
                </Box>
              </Box>
            ),
            value: '',
            disabled: true,
          },
          ...await Promise.all(await findPartOrders({
            isPurchase,
            orderBy: ['createdAt','desc'],
            ...viewOptions.view === 'DETAILS' ? { partIdIn: detailParts.map(({ detailPartId }) => detailPartId) } : { partId },
          })
          .then(partOrders => {
            if (partOrders.length === 0) return [{
              label: (
                <>
                  {`No orders found. `}
                  <CreateOrder
                    companyId={companyId}
                    button={(
                      <Button
                        size="small"
                      >
                        Add New
                      </Button>
                    )}
                    buttonStyle={{ display: 'inline-block' }}
                    data={{ companyId, status: 'OPEN', isNumberAuto: true, orderAt: new Date(), isPurchasing: isPurchase, partOrders: [{ lineNumber: 1, partId, quantity: 1, unit: 'PIECE', unitPrice: 1, dueAt: new Date() }] }}
                  />
                </>
              ),
              value: '',
              disabled: true,
            }]
            return partOrders.map(async partOrder => <PartOrderOption partOrder={partOrder} partOrderLabelPrefix={partOrderLabelPrefix} order={await getOrder(partOrder.orderId)} />)
          })),
        ],
      ])
    }
    fetchPartOrders()
  }, [companyId, partId, query, isPurchase, partOrderLabelPrefix, orders, detailParts, viewOptions])

  return (
    <Box style={{ display: 'flex', gap: '10px' }}>
      <Dropdown
        button={button || (
          <Button
            size="small"
            variant="outlined"
            startIcon={<FilterList />}
          >
            Select PO line item
          </Button>
        )}
        buttonStyle={buttonStyle}
        items={options}
        onChange={({ value, partOrder, order }) => onChange(value, partOrder, order)}
      />
    </Box>
  )
}

export default SelectPartOrder