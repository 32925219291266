import { useState, useEffect } from 'react'
import { Box, TextField, Button, IconButton, Tooltip } from '@mui/material'
import { Undo, ListAlt, ArrowDropDown, OpenInNew } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt, validUpsTracking } from '../../functions'
import ChangeLogs from '../ChangeLogs'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import PhoneField from '../PhoneField'
import SelectContact from '../Contacts/SelectContact'
import { updateShipment } from './functions'

function EditShipmentDetails({ shipment, onUpdate }) {
  const [updatedShipment, setUpdatedShipment] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    if (!get(updatedShipment, 'number')) errors.number = 'Required'
    if (!get(updatedShipment, 'date')) errors.date = 'Required'
    if (!get(updatedShipment, 'shipToName')) errors.shipToName = 'Required'
    if (!get(updatedShipment, 'shipToAddress')) errors.shipToAddress = 'Required'
    if (!get(updatedShipment, 'shipToPhone')) errors.shipToPhone = 'Required'
    if (!get(updatedShipment, 'billToName')) errors.billToName = 'Required'
    if (!get(updatedShipment, 'billToAddress')) errors.billToAddress = 'Required'
    if (!get(updatedShipment, 'billToPhone')) errors.billToPhone = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateShipment(shipment.id, {
      number: updatedShipment.number,
      ...updatedShipment.date && { date: updatedShipment.date },
      ...updatedShipment.shipVia && { shipVia: updatedShipment.shipVia },
      ...updatedShipment.terms && { terms: updatedShipment.terms },
      ...updatedShipment.shipToContactId && { shipToContactId: updatedShipment.shipToContactId },
      ...updatedShipment.shipToAttn && { shipToAttn: updatedShipment.shipToAttn },
      ...updatedShipment.shipToName && { shipToName: updatedShipment.shipToName },
      ...updatedShipment.shipToAddress && { shipToAddress: updatedShipment.shipToAddress },
      ...updatedShipment.shipToEmail && { shipToEmail: updatedShipment.shipToEmail },
      ...updatedShipment.shipToPhone && { shipToPhone: updatedShipment.shipToPhone },
      ...updatedShipment.shipToFax && { shipToFax: updatedShipment.shipToFax },
      ...updatedShipment.billToContactId && { billToContactId: updatedShipment.billToContactId },
      ...updatedShipment.billToAttn && { billToAttn: updatedShipment.billToAttn },
      ...updatedShipment.billToName && { billToName: updatedShipment.billToName },
      ...updatedShipment.billToAddress && { billToAddress: updatedShipment.billToAddress },
      ...updatedShipment.billToEmail && { billToEmail: updatedShipment.billToEmail },
      ...updatedShipment.billToPhone && { billToPhone: updatedShipment.billToPhone },
      ...updatedShipment.billToFax && { billToFax: updatedShipment.billToFax },
    }).then(onUpdate)
      .then(() => enqueueSnackbar('Quote updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedShipment(shipment)
  }, [shipment])

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Quote number"
            fullWidth
            value={get(updatedShipment, 'number', 0)}
            onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, number: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'number')}
          />
          <DateField
            label="Date"
            value={get(updatedShipment, 'date')}
            onChange={date => setUpdatedShipment({ ...updatedShipment, date })}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                fullWidth
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'date')}
              />
            )}
          />
          <SelectContact
            companyId={shipment.companyId}
            contactId={get(updatedShipment, 'shipToContactId', '')}
            label="Ship to name"
            onChange={contact => setUpdatedShipment({
              ...updatedShipment,
              shipToContactId: get(contact, 'id', ''),
              shipToAttn: get(contact, 'contactName', ''),
              shipToName: get(contact, 'name', ''),
              shipToAddress: get(contact, 'address', ''),
              shipToEmail: get(contact, 'email', ''),
              shipToPhone: get(contact, 'phone', ''),
              shipToFax: get(contact, 'fax', ''),
            })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            size="small"
            variant="outlined"
            name="shipToAddress"
            label="Ship to address"
            rows={3}
            multiline
            fullWidth
            value={get(updatedShipment, 'shipToAddress', '')}
            onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, shipToAddress: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'shipToAddress')}
          />
          <TextField
            size="small"
            variant="outlined"
            name="shipToEmail"
            label="Email"
            fullWidth
            value={get(updatedShipment, 'shipToEmail', '')}
            onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, shipToEmail: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'shipToEmail')}
          />
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="shipToPhone"
                label="Phone"
                fullWidth
                value={get(updatedShipment, 'shipToPhone', '')}
                onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, shipToPhone: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'shipToPhone')}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="shipToFax"
                label="Fax"
                fullWidth
                value={get(updatedShipment, 'shipToFax', '')}
                onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, shipToFax: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'shipToFax')}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '50%' }}>
          <Dropdown
            button={(
              <TextField
                size="small"
                variant="outlined"
                name="shipVia"
                label="Ship via"
                fullWidth
                value={get(updatedShipment, 'shipVia', '')}
                onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, shipVia: value })}
                InputProps={{
                  endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                }}
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'shipVia')}
              />
            )}
            items={['UPS','FedEx','Our Truck'].map(value => ({ label: value, value }))}
            onChange={({ value }) => setUpdatedShipment({ ...updatedShipment, shipVia: value })}
          />
          <TextField
            size="small"
            variant="outlined"
            name="trackingNumber"
            label="Tracking number"
            fullWidth
            value={get(updatedShipment, 'trackingNumber', '')}
            onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, trackingNumber: value })}
            style={{ marginBottom: '10px' }}
            {...get(updatedShipment, 'trackingNumber') && validUpsTracking.test(updatedShipment.trackingNumber) && {
              InputProps: {
                endAdornment: (
                  <Tooltip title="Track">
                    <a target="_blank" rel="noopener noreferrer" href={`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${updatedShipment.trackingNumber}`}>
                      <IconButton edge="end">
                        <OpenInNew fontSize="small" />
                      </IconButton>
                    </a>
                  </Tooltip>
                ),
              }
            }}
            {...addErrorProps(errors, 'trackingNumber')}
          />
          <SelectContact
            companyId={shipment.companyId}
            contactId={get(updatedShipment, 'billToContactId', '')}
            label="Bill to name"
            onChange={contact => setUpdatedShipment({
              ...updatedShipment,
              billToContactId: get(contact, 'id', ''),
              billToAttn: get(contact, 'contactName', ''),
              billToName: get(contact, 'name', ''),
              billToAddress: get(contact, 'address', ''),
              billToEmail: get(contact, 'email', ''),
              billToPhone: get(contact, 'phone', ''),
              billToFax: get(contact, 'fax', ''),
            })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            size="small"
            variant="outlined"
            name="billToAddress"
            label="Bill to address"
            rows={3}
            multiline
            fullWidth
            value={get(updatedShipment, 'billToAddress', '')}
            onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, billToAddress: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'billToAddress')}
          />
          <TextField
            size="small"
            variant="outlined"
            name="billToEmail"
            label="Email"
            fullWidth
            value={get(updatedShipment, 'billToEmail', '')}
            onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, billToEmail: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'billToEmail')}
          />
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="billToPhone"
                label="Phone"
                fullWidth
                value={get(updatedShipment, 'billToPhone', '')}
                onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, billToPhone: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'billToPhone')}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="billToFax"
                label="Fax"
                fullWidth
                value={get(updatedShipment, 'billToFax', '')}
                onChange={({ target: { value } }) => setUpdatedShipment({ ...updatedShipment, billToFax: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'billToFax')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!isEqual(shipment, updatedShipment) && (
        <Button
          variant="outlined"
          startIcon={<Undo />}
          onClick={() => setUpdatedShipment(shipment)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        style={{ marginRight: '5px' }}
        disabled={isEqual(shipment, updatedShipment)}
        disableElevation
      >
        Save Details
      </Button>
      <ChangeLogs
        col="shipmentLogs"
        id={shipment.id}
        button={(
          <Button
            variant="outlined"
            disableElevation
            startIcon={<ListAlt />}
          >
            Changelog
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
      />
    </>
  )
}

export default EditShipmentDetails