import { useState, useEffect } from 'react'
import { TextField, Typography, Badge, Chip, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { get, isEqual } from 'lodash'
import { addErrorProps, useAsyncFunc } from '../../functions'
import Checkbox from '../Checkbox'
import { findContacts, getContact } from './functions'
import CreateEditContact from './CreateEdit'

const filter = createFilterOptions()

function SelectContact({ companyId, label, contactId, onChange, style, selectedLabel, textFieldProps, findType, disabled }) {
  const [isCreating, setIsCreating] = useState(false)
  const [query, setQuery] = useState()
  const [contacts, setContacts] = useState([])
  const [contact, setContact] = useState()
  const [newContact, setNewContact] = useState()
  const [isShowingInternal, setIsShowingInternal] = useState(false)

  useAsyncFunc(findContacts, { companyId, searchIndicesContain: query, orderBy: ['updatedAt','desc'], ...findType && { type: findType }, ...isShowingInternal && { isInternal: true } }, setContacts, [companyId, findType, query, isCreating, isShowingInternal])

  useEffect(() => {
    onChange(contact)
  }, [contact])

  useEffect(() => {
    if (contactId) getContact(contactId).then(setContact)
    else setContact()
  }, [contactId])

  useEffect(() => {
    setQuery()
  }, [isShowingInternal])

  return (
    <>
      <CreateEditContact
        companyId={companyId}
        forceOpen={isCreating}
        setForceOpen={setIsCreating}
        data={{ ...newContact, ...findType && { type: findType } }}
        onCreate={setContact}
        onUpdate={setContact}
      />
      <Autocomplete
        value={contact || ''}
        options={contacts}
        onInputChange={(event, value, reason) => setQuery(value)}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              setIsCreating(true)
              setNewContact({ name: newValue })
            })
          } else if (newValue && newValue.inputValue) {
            setIsCreating(true)
            setNewContact({ name: newValue.inputValue })
          } else {
            setContact(newValue)
          }
        }}
        renderInput={params => {
          const props = {
            ...params,
            size: 'small',
            variant: 'outlined',
            label,
            placeholder: 'Enter company name, contact person name or email...',
            fullWidth: true,
            InputProps: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {contact && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setNewContact(contact)
                        setIsCreating(true)
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
            style: {
              ...params.style,
              ...style,
            },
          }
          return (
            <TextField
              {...props}
              {...textFieldProps}
            />
          )
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            })
          }
          filtered.unshift({
            render: (
              <Checkbox
                checked={isShowingInternal}
                onChange={({ target: { checked } }) => setIsShowingInternal(checked)}
                style={{ paddingLeft: '15px' }}
                label={`Show internal ${findType === 'CUST' ? 'purchasing' : 'sales'} team`}
              />
            ),
          })
          return filtered
        }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return selectedLabel ? selectedLabel(option) : `${option.contactName || ''} · ${option.name}`
        }}
        renderOption={(props, option) => option.render || (
          <li {...props} {...option.inputValue && { style: { ...props.style, color: '#1a73e8' } }}>
            {option.type && (
              <Chip
                color={option.type === 'CUST' ? 'secondary' : 'primary'}
                variant="outlined"
                label={option.isInternal ? option.type === 'CUST' ? 'PURCHASING' : 'SALES' : option.type}
                style={{ marginRight: '10px', borderWidth: '2px', fontSize: '.75rem', fontWeight: 'bold' }}
              />
            )}
            {`${option.contactName || ''} · ${option.name}`}
          </li>
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        disabled={disabled}
      />
    </>
  )
}

export default SelectContact