import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { OfflinePin, Outbound, Contrast, FilterList, Visibility, Add, ArrowBack, ArrowForward } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import Paginate from '../Paginate'
import OrderNumber from '../Orders/OrderNumber'
import PartOrderProp from '../Orders/PartOrderProp'
import { findJobAllocations } from './functions'
import CreateJob from './Create'

function JobAllocations({ jobId }) {
  const [allocations, setAllocations] = useState([])
  const [startAfter, setStartAfter] = useState()
  const [endBefore, setEndBefore] = useState()
  const [limit, setLimit] = useState(5)

  const navigate = useNavigate()

  useAsyncFunc(findJobAllocations, {
    jobId,
    orderBy: ['createdAt','desc'],
    startAfter,
    endBefore,
    limit,
  }, setAllocations, [jobId, startAfter, endBefore, limit])

  return (
    <>
      <TableContainer style={{ marginBottom: '5px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Due
              </TableCell>
              <TableCell>
                Reference Number
              </TableCell>
              <TableCell>
                Quantity
              </TableCell>
              <TableCell>
                Allocated On
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allocations.map(({ id, partOrderId, quantity, updatedAt }) => (
              <TableRow key={id} sx={{ verticalAlign: 'baseline' }}>
                <TableCell>
                  <PartOrderProp partOrderId={partOrderId} prop="dueAt" />
                </TableCell>
                <TableCell>
                  {/* <Tooltip title="View order">
                    <IconButton size="small" onClick={() => navigate(`/orders/${orderId}`)} style={{ marginLeft: '-10px', opacity: .325 }}>
                      <Visibility fontSize="small" />
                    </IconButton>
                  </Tooltip> */}
                  {`O/N `}
                  <OrderNumber partOrderId={partOrderId} />
                  {` · Line #`}
                  <PartOrderProp partOrderId={partOrderId} prop="lineNumber" />
                  <Button
                    className="tiny"
                    size="small"
                    variant="outlined"
                    style={{ minWidth: 'auto', marginTop: '5px', padding: '0 10px', fontSize: '12px' }}
                    disableElevation
                  >
                    <PartOrderProp partOrderId={partOrderId} prop="status" />
                  </Button>
                </TableCell>
                <TableCell>
                  {`${quantity} pc${quantity > 1 ? 's' : ''}`}
                </TableCell>
                <TableCell>
                  {updatedAt.toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4}>
                <Box style={{ display: 'flex', gap: '5px' }}>
                {(allocations || []).length > 0 && (
                  <Box style={{ display: 'flex', gap: '5px', flexGrow: 1 }}>
                    <Paginate
                      label="Prev"
                      size="small"
                      variant="outlined"
                      startIcon={<ArrowBack />}
                      disableElevation
                      onClick={() => {
                        setStartAfter()
                        setEndBefore(allocations[0]._snap)
                      }}
                      query={() => findJobAllocations({
                        jobId,
                        orderBy: ['createdAt','desc'],
                        endBefore: get(allocations[0], '_snap'),
                        limit,
                      })}
                    />
                    <Paginate
                      label="Next"
                      size="small"
                      variant="outlined"
                      endIcon={<ArrowForward />}
                      disableElevation
                      onClick={() => {
                        setStartAfter(allocations[allocations.length - 1]._snap)
                        setEndBefore()
                      }}
                      query={() => findJobAllocations({
                        jobId,
                        orderBy: ['createdAt','desc'],
                        startAfter: get(allocations[allocations.length - 1], '_snap'),
                        limit,
                      })}
                    />
                  </Box>
                )}
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Add />}
                  disableElevation
                >
                  Add Job
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Add />}
                  disableElevation
                >
                  Add Order
                </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default JobAllocations