import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore, Timestamp } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyCbJDmhJrh0V5OEI2LomZ_RTbeokQycr7A",
  authDomain: "smartio-cloud.firebaseapp.com",
  projectId: "smartio-cloud",
  storageBucket: "smartio-cloud.appspot.com",
  messagingSenderId: "262916926116",
  appId: "1:262916926116:web:f6f76c7e03c6d90f881431",
  measurementId: "G-LN8GP0HQ3N"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const analytics = getAnalytics(app)

export { auth, db, storage, analytics, Timestamp }