import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { RequestQuote, Visibility, OpenInNew } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { findLineItems } from './functions'
import PartName from '../Parts/PartName'

function InvoiceResult({ item, selected, onSelect }) {
  const { id, number, numberRef } = item
  const [lineItems, setLineItems] = useState([])
  const [isHovering, setIsHovering] = useState(false)

  useAsyncFunc(findLineItems, { invoiceId: id, orderBy: ['createdAt','asc'] }, setLineItems, [id])

  return (
    <ListItem
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      disablePadding
      {...get(selected, 'id') === id && {
        secondaryAction: (
          <IconButton edge="end">
            <Visibility />
          </IconButton>
        )
      }}
      {...isHovering && {
        secondaryAction: (
          <Tooltip title="Open In New Window">
            <Link to={`/invoices/${id}`} target="_blank" rel="noopener noreferrer">
              <IconButton edge="end">
                <OpenInNew />
              </IconButton>
            </Link>
          </Tooltip>
        )
      }}
      onClick={() => onSelect(item)}
    >
      <ListItemButton>
        <ListItemIcon>
          <Badge
            variant="dot"
            color="success"
          >
            <RequestQuote />
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={`I/N ${numberRef ? `${numberRef} (${number})` : number}`}
          secondary={lineItems.length > 0 ? (
            <>
              <PartName partId={lineItems[0].partId} />
              {lineItems.length > 1 ? ` + ${lineItems.length - 1}` : ''}
            </>
          ) : ''}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default InvoiceResult