import { useState, useEffect, useCallback } from 'react'
import { Box, TextField, Typography, Badge, IconButton, Button, Tabs, Tab, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Drawer } from '@mui/material'
import { Search, Check, KeyboardArrowRight, InsertDriveFile, Edit, AddLink, LinkOff, ArrowBack, ArrowForward } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import { get, uniqBy, differenceBy } from 'lodash'
import { useAsyncFunc, findFileRefs, findFiles, getFile, addFileRef, addUniqueFileRef, updateFileRef } from '../functions'
import Confirm from './Confirm'
import AddRemoveFileRefs from './AddRemoveFileRefs'
import FileRefResult from './FileRefResult'
import UploadFiles from './UploadFiles'
import Paginate from './Paginate'
import SelectPart from './Parts/SelectPart'

function SearchFiles({ companyId, refFroms, refFromIds, onChange, onUpdated, inline, button, buttonStyle, categories }) {
  const [query, setQuery] = useState('')
  const [queryBy, setQueryBy] = useState('recent')
  const [results, setResults] = useState([])
  const [files, setFiles] = useState([])
  const [updatedFiles, setUpdatedFiles] = useState([])
  const [startAfter, setStartAfter] = useState()
  const [endBefore, setEndBefore] = useState()
  const [limit, setLimit] = useState(10)

  const [isOpen, setIsOpen] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)

  const [part, setPart] = useState()
  const [fileRefs, setFileRefs] = useState([])

  useAsyncFunc(async part => {
    if (part?.id) return findFileRefs({ refFromId: part.id, orderBy: ['createdAt','desc'] })
    return []
  }, part, setFileRefs, [part])

  const adding = differenceBy(updatedFiles, files, ({ id }) => id)
  const removing = differenceBy(files, updatedFiles, ({ id }) => id)

  useAsyncFunc(async args => {
    return findFileRefs(args).then(fileRefs => Promise.all(uniqBy(fileRefs, ({ fileId }) => fileId).map(({ fileId }) => getFile(fileId))))
  }, { refFromIds, orderBy: ['updatedAt','desc'] }, setFiles, [refFromIds])

  useAsyncFunc(findFiles, {
    ...queryBy === 'name' && { nameBeginsWith: query },
    ...queryBy === 'reference_number' && { refNumbersContain: query.split(',') },
    orderBy: ['createdAt','desc'],
    startAfter,
    endBefore,
    limit,
  }, setResults, [query, queryBy, startAfter, endBefore, limit])

  const fetch = () => findFiles({
    ...queryBy === 'name' && { nameBeginsWith: query },
    ...queryBy === 'reference_number' && { refNumbersContain: query.split(',') },
    orderBy: ['createdAt','desc'],
    startAfter,
    endBefore,
    limit,
  }).then(setResults)

  const fetchFileRefs = () => findFileRefs({ refFromIds, orderBy: ['updatedAt','desc'] })
    .then(fileRefs => Promise.all(uniqBy(fileRefs, ({ fileId }) => fileId).map(({ fileId }) => getFile(fileId)))).then(setFiles)

  useEffect(() => {
    setUpdatedFiles(files)
  }, [files])

  useEffect(() => {
    if (onChange && (adding.length > 0 || removing.length > 0)) onChange([
      ...adding.map(item => ({ ...item, action: 'add' })),
      ...removing.map(item => ({ ...item, action: 'remove' })),
    ])
  }, [adding, removing, onChange])

  const content = (
    <>
      <UploadFiles
        companyId={companyId}
        onUploaded={uploads => {
          setUpdatedFiles(uniqBy([ ...updatedFiles, ...uploads ], ({ url }) => url))
          setQueryBy('recent')
          fetch()
        }}
        skipReviewing
      />
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2" color="GrayText" style={{ marginRight: '10px' }}>
          OR
        </Typography>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search existing files"
          value={query}
          onChange={({ target: { value } }) => setQuery(value)}
          fullWidth
          InputProps={{
            startAdornment: <IconButton edge="start"><Search /></IconButton>,
          }}
        />
      </Box>
      {(adding.length > 0 || removing.length > 0) ? (
        <Box style={{ display: 'flex', alignItems: 'center', marginTop: '10px', padding: '10px', background: '#1a73e8', borderRadius: '5px' }}>
          <Box style={{ flexGrow: 1, paddingLeft: '5px', color: '#fff' }}>
            <Typography>
              {[
                adding.length > 0 ? `Adding ${adding.length} file${adding.length > 1 ? 's' : ''}` : '',
                (adding.length > 0 && removing.length > 0) ? ` · ` : '',
                removing.length > 0 ? `Removing ${removing.length} file${removing.length > 1 ? 's' : ''}` : '',
              ]}
            </Typography>
          </Box>
          <AddRemoveFileRefs
            button={(
              <Button
                size="small"
                variant="outlined"
                style={{ borderColor: '#fff', color: '#fff' }}
              >
                Save Changes
              </Button>
            )}
            categories={categories}
            adding={adding}
            removing={removing}
            refFroms={refFroms}
            refFromIds={refFromIds}
            onUpdated={() => {
              fetch()
              fetchFileRefs()
              if (onUpdated) onUpdated()
            }}
          />
        </Box>
      ) : (
        <Tabs value={queryBy} onChange={(event, newValue) => setQueryBy(newValue)}>
          <Tab label="Recent" value="recent" />
          <Tab label="By name" value="name" />
          <Tab label="By part" value="part" />
          <Tab label="By reference number" value="reference_number" />
        </Tabs>
      )}
      {queryBy === 'part' && (
        <SelectPart
          companyId={companyId}
          partId={get(part, 'id')}
          onChange={setPart}
          AutocompleteProps={{ disablePortal: true }}
        />
      )}
      <List>
        {queryBy === 'part' ? fileRefs.map(item => (
          <FileRefResult
            key={item.id}
            {...item}
            isAdded={files.find(({ id }) => id === item.fileId)}
            isAdding={adding.find(({ id }) => id === item.id)}
            isRemoving={removing.find(({ id }) => id === item.id)}
            onAdd={item => setUpdatedFiles([ ...updatedFiles, item ])}
            onRemove={item => setUpdatedFiles(updatedFiles.filter(({ id }) => id !== item.id))}
            onUpdated={fetch}
          />
        )) : results.map(item => (
          <FileRefResult
            key={item.id}
            {...item}
            isFile
            isAdded={files.find(({ id }) => id === item.id)}
            isAdding={adding.find(({ id }) => id === item.id)}
            isRemoving={removing.find(({ id }) => id === item.id)}
            onAdd={item => setUpdatedFiles([ ...updatedFiles, item ])}
            onRemove={item => setUpdatedFiles(updatedFiles.filter(({ id }) => id !== item.id))}
            onUpdated={fetch}
          />
        ))}
      </List>
      {results.length > 0 && (
        <Box style={{ display: 'flex', gap: '5px', padding: '5px' }}>
          <Paginate
            label="Prev"
            size="small"
            variant="outlined"
            startIcon={<ArrowBack />}
            disableElevation
            onClick={() => {
              setStartAfter()
              setEndBefore(get(results[0], '_snap'))
            }}
            query={() => findFiles({
              orderBy: ['createdAt','desc'],
              endBefore: get(results[0], '_snap'),
              limit,
            })}
          />
          <Paginate
            label="Next"
            size="small"
            variant="outlined"
            endIcon={<ArrowForward />}
            disableElevation
            onClick={() => {
              setStartAfter(get(results[results.length - 1], '_snap'))
              setEndBefore()
            }}
            query={() => findFiles({
              orderBy: ['createdAt','desc'],
              startAfter: get(results[results.length - 1], '_snap'),
              limit,
            })}
          />
        </Box>
      )}
    </>
  )

  return (
    <>
      <Confirm
        title="Abandon changes?"
        content="Are you sure you want to abandon changes?"
        forceOpen={isConfirming}
        setForceOpen={setIsConfirming}
        onConfirm={() => setIsOpen(false)}
        confirmButton={confirm => (
          <Button
            variant="contained"
            color="error"
            startIcon={<Check />}
            disableElevation
            onClick={confirm}
          >
            Abandon
          </Button>
        )}
        cancelButton={cancel => (
          <Button
            variant="outlined"
            startIcon={<KeyboardArrowRight />}
            disableElevation
            onClick={cancel}
          >
            Back
          </Button>
        )}
      />
      {inline ? content : (
        <>
          {button && (
            <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
              {button}
            </Box>
          )}
          <Drawer
            anchor="right"
            open={isOpen}
            onClose={() => {
              if (adding.length > 0 || removing.length > 0) return setIsConfirming(true)
              return setIsOpen(false)
            }}
            style={{ zIndex: 1400 }}
          >
            <Box style={{ width: '35vw', padding: '15px' }}>
              {content}
            </Box>
          </Drawer>
        </>
      )}
    </>
  )
}

export default SearchFiles