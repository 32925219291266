import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Search, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get } from 'lodash'
import PartIcon from './PartIcon'

function PartResult({ item, selected, onSelect }) {
  const { id, number, name } = item

  return (
    <ListItem
      disablePadding
      {...get(selected, 'id') === id && {
        secondaryAction: (
          <Tooltip title="In View">
            <IconButton edge="end">
              <Visibility />
            </IconButton>
          </Tooltip>
        )
      }}
      onClick={() => onSelect(item)}
    >
      <ListItemButton>
        <ListItemIcon>
          <PartIcon partId={id} />
        </ListItemIcon>
        <ListItemText
          primary={`P/N ${number}`}
          secondary={name}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default PartResult