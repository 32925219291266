import { useState, useEffect, useCallback } from 'react'
import { Box, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Skeleton, DialogActions, Badge, IconButton, Tabs, Tab, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Search, InsertDriveFile, Download } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Autocomplete from '@mui/material/Autocomplete'
import { useDropzone } from 'react-dropzone'
import FileViewer from 'react-file-viewer'
import { useAsyncFunc, uploadFile, findFileRefs, findFiles } from '../functions'
import UpdateFile from './UpdateFile'

function UploadFiles({ onSelected, onUploaded, skipReviewing }) {
  const [files, setFiles] = useState([])
  const [selected, setSelected] = useState()

  const onDrop = useCallback(async acceptedFiles => {
    if (onSelected) onSelected(acceptedFiles)
    setFiles(acceptedFiles)
    const uploads = await Promise.all(acceptedFiles.map(uploadFile))
    if (onUploaded) onUploaded(uploads)
    setFiles(uploads)
  }, [])
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    setSelected(files[0])
    onUploaded(files.filter(({ url }) => url))
  }, [files])

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={!skipReviewing && files.length > 0}
        onClose={() => setFiles([])}
        style={{ zIndex: 1400 }}
      >
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText>
            {selected && (
              <TabContext value={selected}>
                <TabList onChange={(event, newValue) => setSelected(newValue)}>
                  {files.map(file => {
                    const { name, path, url } = file
                    return <Tab key={file.name} label={name || path} value={file} />
                  })}
                </TabList>
                {files.map(file => (
                  <TabPanel key={file.name} value={file}>
                    <UpdateFile file={file} inline />
                  </TabPanel>
                ))}
              </TabContext>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Box {...getRootProps()} style={{ display: 'flex', alignItems: 'center', height: '150px', marginBottom: '15px', border: '1px dashed #999', borderRadius: '5px' }}>
        <input {...getInputProps()} />
        <Typography variant="subtitle2" color="GrayText" style={{ flexGrow: 1, textAlign: 'center' }}>
          <Download color="disabled" />
          <br />
          Drag & drop files here to upload
        </Typography>
      </Box>
    </>
  )
}

export default UploadFiles