import { useState } from 'react'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useAsyncFunc } from '../../functions'
import { findPartQuotes } from './functions'

function SelectPartQuote({ partId, onChange }) {
  const [partQuotes, setPartQuotes] = useState([])

  useAsyncFunc(findPartQuotes, { partId, orderBy: ['updatedAt','desc'] }, setPartQuotes, [partId])

  return (
    <>
      <Autocomplete
        options={partQuotes}
        onChange={(event, newValue) => onChange(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus
            size="small"
            variant="outlined"
            label="Base on previous"
            fullWidth
          />
        )}
        getOptionLabel={option => option.updatedAt.toLocaleDateString()}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.updatedAt.toLocaleDateString()}
          </li>
        )}
        openOnFocus
      />
    </>
  )
}

export default SelectPartQuote