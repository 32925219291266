import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio as MuiRadio } from '@mui/material'

function Radio({ label, value, options, onChange, style, optionCtnStyle, optionStyle, disabled }) {
  return (
    <FormControl style={{ ...style }} disabled={disabled} >
      <FormLabel>{label}</FormLabel>
      <RadioGroup value={value} onChange={onChange} sx={{ ...optionCtnStyle }}>
        {options.map(({ label, value }) => (
          <FormControlLabel key={value} value={value} control={<MuiRadio />} label={label} sx={{ ...optionStyle }} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default Radio