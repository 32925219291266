import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Tooltip, Alert, Chip, Dialog, DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { OpenInNew, Add, Remove, Inbox, FilterList, Upload as UploadIcon } from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print'
import NumberFormat from 'react-number-format'
import { get, sortBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addFileRef, getPartInventory } from '../../functions'
import Toggles from '../Toggles'
import PartNumber from '../Parts/PartNumber'
import PartName from '../Parts/PartName'
import { findJournalEntries, findLineItems } from './functions'
import JournalEntryNumber from './JournalEntryNumber'

function RelatedJournalEntries({ companyId, partIds, poNumbers, excludeIds }) {
  const [journalEntries, setJournalEntries] = useState([])
  const [lineItems, setLineItems] = useState([])
  const [byPartIds, setByPartIds] = useState([])
  const [byPoNumbers, setByPoNumbers] = useState([])
  const [journalEntryStatusIn, setJournalEntryStatusIn] = useState(['All'])

  const toggleFilter = (id, type) => {
    if (type ==='partId') {
      if (byPartIds.length === 0) {
        setByPoNumbers([])
        return setByPartIds([id])
      }
      if (byPartIds.includes(id)) {
        return setByPartIds(byPartIds.filter(partId => partId !== id))
      }
      return setByPartIds([...byPartIds, id])
    }
    if (type ==='poNumber') {
      if (byPoNumbers.length === 0) {
        setByPartIds([])
        return setByPoNumbers([id])
      }
      if (byPoNumbers.includes(id)) {
        return setByPoNumbers(byPoNumbers.filter(partId => partId !== id))
      }
      return setByPoNumbers([...byPoNumbers, id])
    }
  }

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  useAsyncFunc(findJournalEntries, {
    companyId,
    ...byPartIds.length > 0 && { partIdIn: partIds },
    ...!journalEntryStatusIn.includes('All') && { statusIn: journalEntryStatusIn },
    orderBy: ['updatedAt','desc'],
  }, setJournalEntries, [companyId, partIds, poNumbers, byPartIds, byPoNumbers, journalEntryStatusIn])

  return (
    <Box style={{ minHeight: '250px', margin: '0 5px 15px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
      <Stack direction="row" spacing={1} style={{ margin: '10px 10px 0', alignItems: 'baseline' }}>
        {partIds && partIds.map(partId => (
          <Chip
            key={partId}
            color="primary"
            variant={byPartIds.includes(partId) ? 'contained' : 'outlined'}
            label={(
              <>
                {`P/N `}
                <PartNumber partId={partId} />
              </>
            )}
            onClick={() => toggleFilter(partId, 'partId')}
          />
        ))}
        {poNumbers && poNumbers.map(poNumber => (
          <Chip
            key={poNumber}
            color="primary"
            variant={byPoNumbers.includes(poNumber) ? 'contained' : 'outlined'}
            label={`O/N ${poNumber}`}
            onClick={() => toggleFilter(poNumber, 'poNumber')}
          />
        ))}
        <Toggles
          options={[
            { label: 'All', value: 'All' },
            { label: 'Open', value: 'OPEN' },
          ]}
          selected={journalEntryStatusIn}
          setSelected={setJournalEntryStatusIn}
        />
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>JournalEntry Number</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Received</TableCell>
              <TableCell align="right">Inspected</TableCell>
              <TableCell align="right">Accepted</TableCell>
              <TableCell align="right">Rejected</TableCell>
              <TableCell align="right">Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {journalEntries.filter(({ id }) => !excludeIds.includes(id))
              .map(journalEntry => {
                const { id, numberRef, partId, quantityReceived, quantityInspected, quantityAccepted, quantityRejected, status, updatedAt } = journalEntry
                return (
                  <TableRow key={id}>
                    <TableCell>
                      {numberRef}
                      <Tooltip title="Open In New Window">
                        <Link to={`/journal/${id}`} target="_blank" rel="noopener noreferrer">
                          <IconButton size="small">
                            <OpenInNew fontSize="small" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {`P/N `}
                      <PartNumber partId={partId} />
                    </TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell align="right">{quantityReceived}</TableCell>
                    <TableCell align="right">{quantityInspected}</TableCell>
                    <TableCell align="right">{quantityAccepted}</TableCell>
                    <TableCell align="right">{quantityRejected}</TableCell>
                    <TableCell align="right">{updatedAt.toLocaleDateString()}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default RelatedJournalEntries