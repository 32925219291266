import { useState, useEffect, useCallback } from 'react'
import { Box, Backdrop, Typography, Button } from '@mui/material'
import { Download, Sync, Clear } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import { useAsyncFunc, uploadFile, findFileRefs, findFiles } from '../functions'
import UpdateFile from './UpdateFile'

const Dropzone = ({ getRootProps, getInputProps, label, files }) => (
  <Box {...getRootProps()} style={{ display: 'flex', flexGrow: 1, alignItems: 'center', marginBottom: '15px', border: '2px dashed #fff', borderRadius: '15px' }}>
    <input {...getInputProps()} />
    <Typography variant="button" color="#fff" style={{ flexGrow: 1, textAlign: 'center' }}>
      {files.length > 0 ? (
        <>
          <Sync className="spin" fontSize="large" color="#fff" />
          <br />
          Uploading...
          <br />
          {files.map(({ name }) => name).join(', ')}
        </>
      ) : (
        <>
          <Download fontSize="large" color="#fff" />
          <br />
          {label}
        </>
      )}
    </Typography>
  </Box>
)

function Upload({ button, buttonStyle, forceOpen, setForceOpen, onSelected, onUploaded }) {
  const [isOpen, setIsOpen] = useState(false)
  const [files, setFiles] = useState([])

  const handleClose = () => {
    setFiles([])
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  const onDrop = useCallback(async acceptedFiles => {
    if (onSelected) onSelected(acceptedFiles)
    setFiles(acceptedFiles)
    const uploads = await Promise.all(acceptedFiles.map(uploadFile))
    if (onUploaded) onUploaded(uploads)
    setFiles(uploads)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }}>
          {button}
        </Box>
      )}
      <Backdrop
        open={isOpen}
        style={{ background: 'rgba(0, 0, 0, 0.9)', zIndex: 1400 }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', width: '80vw', height: '80vh' }}>
          <Dropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            label="Drop files here to upload"
            files={files}
          />
          <Box style={{ display: 'flex', alignItems: 'center', height: '15vh' }} onClick={handleClose}>
            <Typography variant="button" color="#fff" style={{ flexGrow: 1, textAlign: 'center' }}>
              <Clear fontSize="large" color="#fff" />
              <br />
              Cancel
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </>
  )
}

export default Upload