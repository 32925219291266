import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAt, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith, getCount } from '../../functions'

export const dispositionTypes = ['Return to Vendor','Rework','Repair','Use As Is','Scrap','Hold','No Defect']

export const findJournalEntries = async ({ companyId, number, numberBeginsWith, numberRef, numberRefBeginsWith, titleBeginsWith, partId, partIdIn, type, status, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'journalEntries'), ...[
    where('companyId', '==', companyId),
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    ...numberRef ? [where('numberRef', '==', numberRef)]
    : numberRefBeginsWith ? beginsWith('numberRef', numberRefBeginsWith)
    : [],
    ...titleBeginsWith ? beginsWith('title', titleBeginsWith) : [],
    ...partId ? [where('partId', '==', partId)] : [],
    ...partIdIn ? [where('partId', 'in', partIdIn)] : [],
    ...type ? [where('type', '==', type)] : [],
    ...status ? [where('status', '==', status)] : [],
    orderBy(...order),
    limit(max || await getGlobal('journalEntriesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getJournalEntry = id => get('journalEntries', id)

export const addJournalEntry = data => add('journalEntries', data)

export const updateJournalEntry = (id, updates) => update('journalEntries', id, updates, 'journalEntryLogs')

export const getLastJournalEntry = (companyId, args) => findJournalEntries({ companyId, orderBy: ['number','desc'], limit: 1, ...args }).then(([lastEntry]) => lastEntry)

export const getNewJournalEntryNumber = companyId => getLastJournalEntry(companyId).then(lastEntry => parseInt(lastEntry ? lastEntry.number : 0) + 1)

export const validateJournalEntryNumber = (number, companyId) => findJournalEntries({ companyId, number, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const findLineItems = async ({ journalEntryId, partId, partIdIn, partOrderId, partOrderIdIn, refPoNumber, refPoNumberIn, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'journalEntryItems'), ...[
    ...journalEntryId ? [where('journalEntryId', '==', journalEntryId)] : [],
    ...partId ? [where('partId', '==', partId)] : [],
    ...partIdIn ? [where('partId', 'in', partIdIn)] : [],
    ...partOrderId ? [where('partOrderId', '==', partOrderId)] : [],
    ...partOrderIdIn ? [where('partOrderId', 'in', partOrderIdIn)] : [],
    ...refPoNumber ? [where('refPoNumber', '==', refPoNumber)] : [],
    ...refPoNumberIn ? [where('refPoNumber', 'in', refPoNumberIn)] : [],
    orderBy(...order),
    limit(max || await getGlobal('journalEntryItemsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getLineItem = id => get('journalEntryItems', id)

export const addLineItem = data => add('journalEntryItems', data)

export const getNewLineNumber = journalEntryId => findLineItems({ journalEntryId, orderBy: ['lineNumber','desc'], limit: 1 }).then(([lastLineItem]) => _.get(lastLineItem, 'lineNumber', 0) + 1)

export const updateLineItem = (id, updates) => update('journalEntryItems', id, updates, 'journalEntryItemLogs')