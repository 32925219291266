import { useState } from 'react'
import { Box, IconButton, Popover as MuiPopover } from '@mui/material'
import { Clear, Check } from '@mui/icons-material'

function Popover({ button, buttonStyle, content, trigger, onConfirm, onClose, noBubble, clickableInside, forceClosingOnClick, anchorOrigin, transformOrigin }) {
  const [anchorEl, setAnchorEl] = useState()

  const setAnchor = e => {
    if (noBubble) e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const unsetAnchor = () => {
    if (onClose) onClose()
    setAnchorEl()
  }

  return (
    <>
      <Box
        style={{ ...buttonStyle }}
        {...trigger === 'hover' && content && {
          onMouseEnter: setAnchor,
        }}
        {...(!trigger || trigger === 'click') && content && {
          onClick: setAnchor,
        }}>
        {button}
      </Box>
      <MuiPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
          ...transformOrigin,
        }}
        open={!!anchorEl}
        onClose={unsetAnchor}
      >
        <Box
          style={{ display: 'flex', flexDirection: 'row-reverse' }}
          {...!forceClosingOnClick && { onMouseLeave: () => unsetAnchor() }}
          {...clickableInside && { onClick: e => e.stopPropagation() }}
        >
          <Box style={{ display: 'flex', flexDirection: 'column', padding: '5px', background: onConfirm ? '#1976d2' : '#f2f2f2' }}>
            <IconButton size="small" onClick={unsetAnchor}>
              <Clear fontSize="small" {...onConfirm && { style: { color: '#fff' } }} />
            </IconButton>
            {onConfirm && (
              <IconButton size="small" onClick={() => {
                onConfirm()
                unsetAnchor()
              }}>
                <Check fontSize="small" {...onConfirm && { style: { color: '#fff' } }} />
              </IconButton>
            )}
          </Box>
          {content}
        </Box>
      </MuiPopover>
    </>
  )
}

export default Popover