import { useState } from 'react'
import { Box, Typography, IconButton, Button, TableRow, TableCell, Skeleton, Tooltip, TextField, Badge } from '@mui/material'
import { RocketLaunchOutlined, SettingsOutlined, SignalCellularAlt1Bar, SignalCellularAlt2Bar, SignalCellularAlt, Edit, Search, Check, ArrowDropUp, ArrowDropDown } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, jobStatuses } from '../../functions'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import Popover from '../Popover'
import PartName from '../Parts/PartName'
import PartNumber from '../Parts/PartNumber'
import { getPartOrder } from '../Orders/functions'
import OrderLookup from '../Orders/Lookup'
import OrderNumber from '../Orders/OrderNumber'
import PartOrderProp from '../Orders/PartOrderProp'
import PartOrderAction from '../Orders/PartOrderAction'
import Ship from '../Shipments/Ship'
import { findJobLines, updateJob, findJobAllocations } from './functions'
import AllocateJobs from './Allocate'
import JournalEntriesCount from '../Journal/JournalEntriesCount'

const PartOrderRow = ({ id: jobId, companyId, poNumber, numberRef, partOrderId, onPartOpen }) => {
  const [partOrder, setPartOrder] = useState()

  useAsyncFunc(getPartOrder, partOrderId, setPartOrder, [partOrderId])

  return partOrder && (
    <TableRow style={{ background: '#efefef' }}>
      <TableCell />
      <TableCell />
      <TableCell>
        <OrderLookup
          companyId={companyId}
          button={(
            <Tooltip title="Lookup order">
              <IconButton size="small" edge="start">
                <Search fontSize="small" color="disabled" />
              </IconButton>
            </Tooltip>
          )}
          buttonStyle={{ display: 'inline', marginLeft: '-10px' }}
          query={poNumber}
          partOrderId={partOrderId}
        />
        <OrderNumber partOrderId={partOrderId} />
      </TableCell>
      <TableCell>
        {partOrder.lineNumber}
      </TableCell>
      <TableCell>
        <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
          <Tooltip title="View part">
            <IconButton size="small" onClick={() => onPartOpen(partOrder.partId)} style={{ marginLeft: '-10px' }}>
              <SettingsOutlined fontSize="small" color="disabled" />
            </IconButton>
          </Tooltip>
          <PartNumber partId={partOrder.partId} />
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={<PartName partId={partOrder.partId} suffix={numberRef ? `J/R/N ${numberRef}` : ''} />}>
          <Typography variant="body2" style={{ width: '170px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <PartName partId={partOrder.partId} />
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        {partOrder.quantity - (partOrder.quantityBalance !== undefined ? partOrder.quantityBalance : partOrder.quantity)}
        {` / `}
        {partOrder.quantity}
        <PartOrderAction companyId={companyId} partOrderId={partOrderId} jobId={jobId} />
      </TableCell>
      <TableCell>
        {partOrder.dueAt.toLocaleDateString()}
      </TableCell>
      <TableCell />
      <TableCell>
        {partOrder.updatedAt.toLocaleDateString()}
      </TableCell>
    </TableRow>
  )
}

function JobRow({ isSelected, selectIcon, selectTooltip, onSelect, onUpdate, onPartOpen, ...job }) {
  const [jobLines, setJobLines] = useState([])
  const [updates, setUpdates] = useState()
  const [allocations, setAllocations] = useState()
  const [isAllocating, setIsAllocating] = useState(false)
  const [isShowingPartOrders, setIsShowingPartOrders] = useState(false)
  const [partOrders, setPartOrders] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const { id, companyId, number, numberRef, poNumber, lineNumber, partId, quantity, quantityAvailable, status, color, dueAt, shipAt, updatedAt } = job

  const update = updates => updateJob(id, updates).then(updatedJob => {
    enqueueSnackbar('Job updated successfully.', { variant: 'success' })
    onUpdate(updatedJob)
  })

  // useAsyncFunc(findJobLines, { jobId: id, orderBy: ['number','asc'] }, setJobLines, [updatedAt])

  useAsyncFunc(findJobAllocations, { jobId: id, orderBy: ['createdAt','asc'] }, setAllocations, [updatedAt])

  useAsyncFunc(async () => {
    return Promise.all((allocations || []).map(({ partOrderId }) => getPartOrder(partOrderId)))
  }, null, setPartOrders, [allocations])

  return (
    <>
      <TableRow className="job-row" {...color && { style: { background: `${color}66` } }}>
        <TableCell>
          {partOrders && (
            <Ship
              companyId={companyId}
              partOrders={partOrders}
              button={(
                <Tooltip title="Ship">
                  <IconButton size="small" style={{ marginRight: '3px', marginLeft: '-10px' }}>
                    <RocketLaunchOutlined fontSize="small" color="disabled" />
                  </IconButton>
                </Tooltip>
              )}
              buttonStyle={{ display: 'inline-block' }}
              onSubmit={onUpdate}
            />
          )}
          <Typography variant="body2" color={new Date() < shipAt ? 'GrayText' : '#ff5722'} sx={{ display: 'inline', fontWeight: 'bold' }}>
            {shipAt.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' })}
          </Typography>
          <Popover
            button={(
              <Tooltip title="Edit ship date">
                <IconButton className="b-edit" size="small">
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            buttonStyle={{ position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)' }}
            content={(
              <Box style={{ padding: '15px' }}>
                <DateField
                  label="Ship date"
                  value={get(updates, 'shipAt', shipAt)}
                  onChange={shipAt => setUpdates({ ...updates, shipAt })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      {...addErrorProps(errors, 'shipAt')}
                    />
                  )}
                />
              </Box>
            )}
            {...get(updates, 'shipAt') && { onConfirm: () => update(updates) }}
            onClose={() => setUpdates()}
            forceClosingOnClick
          />
        </TableCell>
        <TableCell>
          <Tooltip title={selectTooltip}>
            <IconButton size="small" onClick={() => onSelect(job)} style={{ marginLeft: '-10px', opacity: isSelected ? 1 : .325 }}>
              {selectIcon}
            </IconButton>
          </Tooltip>
          {number}
        </TableCell>
        <TableCell>
          {allocations && allocations.length > 1 && 'Multiple'}
          {allocations && allocations.length === 1 && (
            <>
              <OrderLookup
                companyId={companyId}
                button={(
                  <Tooltip title="Lookup order">
                    <IconButton size="small" edge="start">
                      <Search fontSize="small" color="disabled" />
                    </IconButton>
                  </Tooltip>
                )}
                buttonStyle={{ display: 'inline', marginLeft: '-10px' }}
                query={poNumber}
                partOrderId={allocations[0].partOrderId}
              />
              <OrderNumber partOrderId={allocations[0].partOrderId} />
            </>
          )}
        </TableCell>
        <TableCell>
          {allocations && allocations.length > 1 && (
            <>
              Multiple
              <IconButton size="small" onClick={() => setIsShowingPartOrders(!isShowingPartOrders)}>
                {isShowingPartOrders ? <ArrowDropUp fontSize="small" color="disabled" /> : <ArrowDropDown fontSize="small" color="disabled" />}
              </IconButton>
            </>
          )}
          {allocations && allocations.length === 1 && (
            <>
              <PartOrderProp partOrderId={allocations[0].partOrderId} prop="lineNumber" />
              <PartOrderAction companyId={companyId} partOrderId={allocations[0].partOrderId} />
            </>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
            <Tooltip title="View part">
              <IconButton size="small" onClick={() => onPartOpen(partId)} style={{ marginLeft: '-10px' }}>
                <JournalEntriesCount
                  companyId={companyId}
                  partId={partId}
                  renderCount={count => (
                    <Badge badgeContent={count} invisible={count === 0} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} color="error">
                      <SettingsOutlined fontSize="small" color="disabled" />
                    </Badge>
                  )}
                />
              </IconButton>
            </Tooltip>
            <PartNumber partId={partId} />
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title={<PartName partId={partId} suffix={numberRef ? `J/R/N ${numberRef}` : ''} />}>
            <Typography variant="body2" style={{ width: '170px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <PartName partId={partId} />
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          {quantityAvailable}
          {` / `}
          {quantity}
          <AllocateJobs
            jobId={id}
            forceOpen={isAllocating}
            setForceOpen={setIsAllocating}
            onAllocated={onUpdate}
          />
          <Button
            className="tiny"
            size="small"
            variant="outlined"
            {...quantityAvailable < 0 ? {
              color: 'warning',
              onClick: () => setIsAllocating(true),
            } : quantityAvailable > 0 ? {
              color: 'primary',
              onClick: () => setIsAllocating(true),
            } : {
              color: 'success',
              startIcon: <Check fontSize="small" />,
            }}
            style={{ minWidth: 'auto', marginLeft: '10px', padding: `0 5px 0${quantityAvailable === 0 ? ` 12px` : ''}`, fontSize: '12px' }}
            disableElevation
          >
            {quantityAvailable === 0 ? 'Fully Allocated' : 'Allocate'}
          </Button>
        </TableCell>
        <TableCell>
          {dueAt.toLocaleDateString()}
          <Popover
            button={(
              <Tooltip title="Edit due date">
                <IconButton className="b-edit" size="small">
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            buttonStyle={{ position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)' }}
            content={(
              <Box style={{ padding: '15px' }}>
                <DateField
                  label="Due date"
                  value={get(updates, 'dueAt', dueAt)}
                  onChange={dueAt => setUpdates({ ...updates, dueAt })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      {...addErrorProps(errors, 'shipAt')}
                    />
                  )}
                />
              </Box>
            )}
            {...get(updates, 'dueAt') && { onConfirm: () => update(updates) }}
            onClose={() => setUpdates()}
            forceClosingOnClick
          />
        </TableCell>
        <TableCell>
          {jobLines ? (
            <Dropdown
              button={(
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={jobLines.length === 0 ?
                    status === 'NOT_STARTED' ? <SignalCellularAlt1Bar fontSize="small" /> :
                    status === 'IN_PROGRESS' ? <SignalCellularAlt2Bar fontSize="small" /> :
                    <SignalCellularAlt fontSize="small" /> : (
                      <PieChart width={50} height={40}>
                        <Pie
                          data={jobLines.map(l => ({ ...l, point: 1 }))}
                          dataKey="point"
                          startAngle={180}
                          endAngle={0}
                          cx="50%"
                          cy="75%"
                          outerRadius={18}
                          fill="#1a73e8"
                        >
                          {jobLines.map(({ id, completedAt }) => (
                            <Cell key={id} {...!completedAt && { fill: '#fff' }} />
                          ))}
                        </Pie>
                      </PieChart>
                    )}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {status.replace('_',' ')}
                </Button>
              )}
              items={jobStatuses}
              onChange={({ value }) => update({ status: value })}
            />
          ) : <Skeleton width={170} variant="rectangular" animation="wave" />}
        </TableCell>
        <TableCell>
          {updatedAt.toLocaleDateString()}
          <Dropdown
            button={(
              <Box className="color-chip" style={{ background: color }} />
            )}
            buttonStyle={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
            items={[
              { label: <Box className="color-chip" style={{ background: '#4caf50' }} />, value: '#4caf50' },
              { label: <Box className="color-chip" style={{ background: '#ffc107' }} />, value: '#ffc107' },
              { label: <Box className="color-chip" style={{ background: '#ff5722' }} />, value: '#ff5722' },
              { label: <Box className="color-chip" style={{ background: '#90a4ae' }} />, value: '#90a4ae' },
              { label: <Box className="color-chip" style={{ background: '#ffffff' }} />, value: '#ffffff' },
            ]}
            onChange={({ value }) => update({ color: value })}
          />
        </TableCell>
      </TableRow>
      {isShowingPartOrders && allocations.map(({ id, partOrderId }) => (
        <PartOrderRow key={id} partOrderId={partOrderId} onPartOpen={onPartOpen} {...job} />
      ))}
    </>
  )
}

export default JobRow