import { useState, useEffect } from 'react'
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt } from '../../functions'
import Checkbox from '../Checkbox'
import Radio from '../Radio'
import PhoneField from '../PhoneField'
import { addContact, updateContact } from './functions'

function CreateEditContact({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate, onUpdate }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newContact, setNewContact] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    // if (!get(newContact, 'contactName')) errors.contactName = 'Required'
    if (!get(newContact, 'name')) errors.name = 'Required'
    if (!get(newContact, 'email')) errors.email = 'Required'
    if (!get(newContact, 'phone')) errors.phone = 'Required'
    if (!get(newContact, 'type')) errors.type = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    if (data.id) {
      return updateContact(data.id, {
        contactName: newContact.contactName,
        name: newContact.name,
        ...newContact.address && { address: newContact.address },
        email: newContact.email,
        phone: newContact.phone,
        ...newContact.fax && { fax: newContact.fax },
        type: newContact.type,
      }).then(contact => {
        handleClose()
        enqueueSnackbar('Contact updated successfully.', { variant: 'success' })
        if (onUpdate) onUpdate(contact)
      })
    }
    return addContact({ ...newContact, companyId })
      .then(contact => {
        handleClose()
        enqueueSnackbar('Contact created successfully.', { variant: 'success' })
        if (onCreate) onCreate(contact)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewContact(data)
  }, [data])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>{`${data?.id ? 'Edit' : 'Create new'} contact`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <TextField
              size="small"
              variant="outlined"
              name="contactName"
              label="Contact name"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newContact, 'contactName', '')}
              onChange={({ target: { value } }) => setNewContact({ ...newContact, contactName: value })}
              {...addErrorProps(errors, 'contactName')}
              // required
            />
            <TextField
              size="small"
              variant="outlined"
              name="name"
              label="Company name"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newContact, 'name', '')}
              onChange={({ target: { value } }) => setNewContact({ ...newContact, name: value })}
              {...addErrorProps(errors, 'name')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              label="Address"
              multiline
              rows={3}
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newContact, 'address', '')}
              onChange={({ target: { value } }) => setNewContact({ ...newContact, address: value })}
              {...addErrorProps(errors, 'address')}
            />
            <TextField
              size="small"
              variant="outlined"
              name="email"
              label="Email"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newContact, 'email', '')}
              onChange={({ target: { value } }) => setNewContact({ ...newContact, email: value })}
              {...addErrorProps(errors, 'email')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              name="phone"
              label="Phone"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newContact, 'phone', '')}
              onChange={({ target: { value } }) => setNewContact({ ...newContact, phone: value })}
              InputProps={{
                inputComponent: PhoneField,
              }}
              {...addErrorProps(errors, 'phone')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              name="fax"
              label="Fax"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newContact, 'fax', '')}
              onChange={({ target: { value } }) => setNewContact({ ...newContact, fax: value })}
              InputProps={{
                inputComponent: PhoneField,
              }}
              {...addErrorProps(errors, 'fax')}
            />
            <Checkbox
              checked={get(newContact, 'isInternal', false)}
              onChange={({ target: { checked } }) => setNewContact({ ...newContact, isInternal: checked })}
              label="Internal team"
            />
            <Radio
              options={[
                { label: get(newContact, 'isInternal') ? 'Purchasing' : 'Customer', value: 'CUST' },
                { label: get(newContact, 'isInternal') ? 'Sales' : 'Vendor', value: 'VEND' },
              ]}
              value={get(newContact, 'type', '')}
              onChange={({ target: { value } }) => setNewContact({ ...newContact, type: value })}
              style={{ marginBottom: '10px' }}
              {...addErrorProps(errors, 'type')}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateEditContact