import { useState, useEffect } from 'react'
import { Box, Typography, Chip, Badge, IconButton, Button, Tabs, Tab, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { InsertDriveFile, Edit, AddLink, LinkOff } from '@mui/icons-material'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { getDateDiff } from '../functions'
import UpdateFile from './UpdateFile'
import UpdateFileRef from './UpdateFileRef'

function FileRefResult({ readOnly, isFile, icon, isAdded, isAdding, isRemoving, onAdd, onRemove, onUpdated, ...item }) {
  const [isEditing, setIsEditing] = useState(false)

  const { name, fileId } = item
  const ext = name.split('.')[name.split('.').length - 1]

  return (
    <ListItem
      disablePadding
      secondaryAction={(
        <>
          {isAdded && (
            <Chip
              color={isRemoving ? 'error' : 'primary'}
              variant="outlined"
              label={isRemoving ? 'Removing' : 'Referenced'}
            />
          )}
          {isFile && onUpdated && (
            <UpdateFile
              forceOpen={isEditing}
              setForceOpen={setIsEditing}
              buttonStyle={{ display: 'inline-block' }}
              file={item}
              onUpdated={onUpdated}
            />
          )}
          {fileId && onUpdated && (
            <UpdateFileRef
              forceOpen={isEditing}
              setForceOpen={setIsEditing}
              fileRef={item}
              startingIndex={0}
            />
          )}
          {!readOnly && (
            <Tooltip title={((isAdded && !isRemoving) || isAdding) ? 'Unlink' : 'Link'}>
              {((isAdded && !isRemoving) || isAdding) ? (
                <IconButton edge="end" onClick={() => onRemove(item)}>
                  <LinkOff />
                </IconButton>
              ) : (
                <IconButton edge="end" onClick={() => onAdd(item)}>
                  <AddLink />
                </IconButton>
              )}
            </Tooltip>
          )}
        </>
      )}
    >
      <ListItemButton onClick={() => setIsEditing(true)}>
        <Tooltip title="View file">
          <ListItemIcon>
            {icon || (
              <Badge
                {...getDateDiff(item.createdAt, new Date()).minutes < 30 && { color: 'primary', badgeContent: 'New' }}
              >
                <Box style={{ width: '32px' }}>
                  <FileIcon extension={ext} {...defaultStyles[ext]} />
                </Box>
              </Badge>
            )}
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={item.name}
          secondary={`Uploaded ${item.createdAt.toLocaleDateString()}`}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default FileRefResult