import { useState } from 'react'
import { Box, TextField, Skeleton } from '@mui/material'
import { get } from 'lodash'
import { addErrorProps } from '../../functions'
import PhoneField from '../PhoneField'
import SelectContact from './SelectContact'

function ContactFields({ companyId, data, setData, errors, contactLabel, addressLabel, emailLabel, phoneLabel, faxLabel, idField, nameField, addressField, emailField, phoneField, faxField }) {

  return (
    <>
      <SelectContact
        companyId={companyId}
        contactId={get(data, idField, '')}
        label={contactLabel || 'Name'}
        onChange={contact => setData({
          ...data,
          [idField]: get(contact, 'id', ''),
          [nameField]: get(contact, 'name', ''),
          [addressField]: get(contact, 'address', ''),
          [emailField]: get(contact, 'email', ''),
          [phoneField]: get(contact, 'phone', ''),
          [faxField]: get(contact, 'fax', ''),
        })}
        style={{ marginBottom: '10px' }}
      />
      <TextField
        size="small"
        variant="outlined"
        name={addressField}
        label={addressLabel || 'Address'}
        rows={3}
        multiline
        fullWidth
        value={get(data, addressField, '')}
        onChange={({ target: { value } }) => setData({ ...data, [addressField]: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, addressField)}
      />
      <TextField
        size="small"
        variant="outlined"
        name={emailField}
        label={emailLabel || 'Email'}
        fullWidth
        value={get(data, emailField, '')}
        onChange={({ target: { value } }) => setData({ ...data, [emailField]: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, emailField)}
      />
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            name={phoneField}
            label={phoneLabel || 'Phone'}
            fullWidth
            value={get(data, phoneField, '')}
            onChange={({ target: { value } }) => setData({ ...data, [phoneField]: value })}
            style={{ marginBottom: '10px' }}
            InputProps={{
              inputComponent: PhoneField,
            }}
            {...addErrorProps(errors, phoneField)}
          />
        </Box>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            name={faxField}
            label={faxLabel || 'Fax'}
            fullWidth
            value={get(data, faxField, '')}
            onChange={({ target: { value } }) => setData({ ...data, [faxField]: value })}
            style={{ marginBottom: '10px' }}
            InputProps={{
              inputComponent: PhoneField,
            }}
            {...addErrorProps(errors, faxField)}
          />
        </Box>
      </Box>
    </>
  )
}

export default ContactFields