import { useState } from 'react'
import { Button } from '@mui/material'
import { CheckCircle, LocalShipping } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import AllocateJobs from '../Jobs/Allocate'
import Ship from '../Shipments/Ship'
import { getPartOrder } from './functions'

function PartOrderAction({ companyId, partOrderId, jobId, shippingDisabled, allocatingDisabled }) {
  const [partOrder, setPartOrder] = useState()
  const [isAllocating, setIsAllocating] = useState(false)
  const [isShipping, setIsShipping] = useState(false)

  useAsyncFunc(async partOrderId => {
    if (partOrderId) return getPartOrder(partOrderId)
  }, partOrderId, setPartOrder, [partOrderId])

  return partOrder && (
    <>
      <AllocateJobs
        jobId={jobId}
        partId={partOrder.partId}
        forPartOrderId={partOrderId}
        forceOpen={isAllocating}
        setForceOpen={setIsAllocating}
      />
      <Ship
        companyId={companyId}
        partOrders={[partOrder]}
        forceOpen={isShipping}
        setForceOpen={setIsShipping}
      />
      <Button
        className="tiny"
        size="small"
        variant="outlined"
        {...partOrder.status === 'OPEN' ? {
          color: 'warning',
          onClick: () => !allocatingDisabled && setIsAllocating(true),
        } : partOrder.status === 'COMPLETED' ? {
          color: 'primary',
          startIcon: <LocalShipping />,
          onClick: () => !shippingDisabled && setIsShipping(true),
        } : {
          color: 'success',
          startIcon: <CheckCircle />,
        }}
        style={{ minWidth: 'auto', marginLeft: '10px', padding: `0 5px 0${partOrder.status !== 'OPEN' ? ` 12px` : ''}`, fontSize: '12px' }}
        disableElevation
      >
        {partOrder.status === 'COMPLETED' ? 'Ready' : partOrder.status}
      </Button>
    </>
  )
}

export default PartOrderAction