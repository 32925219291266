import { useState } from 'react'
import { Box, Typography, TextField, Button } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import { useAsyncFunc } from '../functions'
import Popover from './Popover'
import { getInvoice, getLineItem } from './Invoices/functions'
import { getJob, getJobLine } from './Jobs/functions'
import { getJournalEntry } from './Journal/functions'
import { getOrder, getPartOrder } from './Orders/functions'
import { getPart } from './Parts/functions'
import { getQuote, getPartQuote } from './Quotes/functions'
import { getShipment, getShipmentLine } from './Shipments/functions'

function ReferenceHelper({ refFroms }) {
  const [numberRefs, setNumberRefs] = useState()

  useAsyncFunc(async () => {
    if (refFroms) {
      return await Promise.all(refFroms.map(async refFrom => ({
        ...refFrom,
        numberRef:
          refFrom.type === 'invoiceItem' ?
            await getLineItem(refFrom.id).then(({ invoiceId }) => getInvoice(invoiceId)).then(({ numberRef }) => numberRef) :
          refFrom.type === 'jobLine' ?
            await getJobLine(refFrom.id).then(({ jobId }) => getJob(jobId)).then(({ numberRef }) => numberRef) :
          refFrom.type === 'journalEntry' ?
            await getJournalEntry(refFrom.id).then(({ numberRef }) => numberRef) :
          refFrom.type === 'partOrder' ?
            await getPartOrder(refFrom.id).then(({ orderId }) => getOrder(orderId)).then(({ numberRef }) => numberRef) :
          refFrom.type === 'part' ?
            await getPart(refFrom.id).then(({ number }) => number) :
          refFrom.type === 'partQuote' ?
            await getPartQuote(refFrom.id).then(({ quoteId }) => getQuote(quoteId)).then(({ numberRef }) => numberRef) :
          refFrom.type === 'shipmentLine' ?
            await getShipmentLine(refFrom.id).then(({ shipmentId }) => getShipment(shipmentId)).then(({ numberRef }) => numberRef) :
          '',
      })))
    }
    return () => null
  }, null, setNumberRefs, [refFroms])

  return (
    <Popover
      button={(
        <Button
          size="small"
          variant="outlined"
          startIcon={<HelpOutline />}
          disableElevation
        >
          Help
        </Button>
      )}
      content={(
        <>
          {(numberRefs || []).map(({ id, numberRef }) => (
            <Box key={id} style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '15px' }}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                value={`#${numberRef}`}
                disabled
                InputProps={{
                  endAdornment: <Typography variant="overline" color="GrayText" sx={{ whiteSpace: 'nowrap' }}>In subject</Typography>,
                }}
              />
              <Typography variant="overline" color="GrayText" sx={{ textAlign: 'center' }}>— or —</Typography>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                value={`<<${numberRef}>>`}
                disabled
                InputProps={{
                  endAdornment: <Typography variant="overline" color="GrayText" sx={{ whiteSpace: 'nowrap' }}>In body</Typography>,
                }}
              />
            </Box>
          ))}
        </>
      )}
    />
  )
}

export default ReferenceHelper