import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@mui/material'
import { PrintOutlined } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import Checkbox from './Checkbox'
import Dropdown from './Dropdown'
import InspectJob from './Jobs/Inspect'

const docs = ['Packing List','FAI','Certificates','Invoice']

function Documents({ companyId, button, buttonStyle, jobAllocations, disabled }) {
  const [prints, setPrints] = useState(docs)

  const togglePrint = print => {
    if (prints.includes(print)) {
      return setPrints(prints.filter(p => p !== print))
    }
    return setPrints([ ...prints, print ])
  }

  return (
    <Dropdown
      button={button}
      buttonStyle={buttonStyle}
      items={[
        {
          label: (
            <Checkbox
              checked={isEqual(prints, docs)}
              onChange={() => setPrints(isEqual(prints, docs) ? [] : docs)}
              label="All"
            />
          )
        },
        ...docs.map(doc => ({
          label: (
            <Checkbox
              checked={prints.includes(doc)}
              onChange={() => togglePrint(doc)}
              label={doc}
            />
          ),
          value: doc.toUpperCase(),
        })),
      ]}
      afterItems={(
        <InspectJob
          companyId={companyId}
          jobIds={(jobAllocations || []).map(({ jobId }) => jobId)}
          button={(
            <Button
              fullWidth
              variant="contained"
              disableElevation
              disabled={prints.length === 0}
            >
              Print
            </Button>
          )}
          buttonStyle={{ width: '100%' }}
        />
      )}
      disableCloseOnSelect
      disabled={disabled}
    />
  )
}

export default Documents