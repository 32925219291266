import { useState, useEffect } from 'react'
import { TextField, IconButton, Button, List } from '@mui/material'
import { Search, Add, Sort } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import OrderBy from '../OrderBy'
import { findShipments } from './functions'
import ShipmentResult from './ShipmentResult'

function SearchShipments({ companyId, selected, onSelect }) {
  const [shipments, setShipments] = useState([])
  const [query, setQuery] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])

  useAsyncFunc(async args => {
    if (companyId) return findShipments(args)
    return () => []
  }, { companyId, orderBy, ...query && { numberBeginsWith: query } }, setShipments, [companyId, query, orderBy])

  const fetch = () => findShipments({ companyId, orderBy }).then(setShipments)

  useEffect(() => {
    const match = shipments.find(({ id }) => id === get(selected, 'id'))
    if (match && match.updatedAt < selected.updatedAt) {
      setShipments(shipments.map(q => q.id !== match.id ? q : selected))
    }
  }, [shipments, selected])

  return (
    <>
      <TextField
        type="number"
        size="small"
        variant="outlined"
        placeholder="Search shipments..."
        fullWidth
        onChange={({ target: { value } }) => setQuery(value)}
        style={{ marginBottom: '5px' }}
        InputProps={{
          startAdornment: <IconButton edge="start"><Search /></IconButton>,
        }}
      />
      <OrderBy
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Sort />}
          >
            {`Sort by: ${orderBy[0]}`}
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
        onChange={({ value }) => setOrderBy(value)}
      />
      <List>
        {shipments.map(item => (
          <ShipmentResult
            key={item.id}
            item={item}
            selected={selected}
            onSelect={onSelect}
          />
        ))}
      </List>
    </>
  )
}

export default SearchShipments