import { useState } from 'react'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getOrder, getPartOrder } from './functions'

function OrderNumber({ orderId, partOrderId }) {
  const [order, setOrder] = useState()

  useAsyncFunc(async args => {
    if (orderId) return getOrder(args)
    return () => null
  }, orderId, setOrder, [orderId])

  useAsyncFunc(async args => {
    if (partOrderId) {
      const { orderId } = await getPartOrder(args)
      return getOrder(orderId)
    }
    return () => null
  }, partOrderId, setOrder, [partOrderId])

  return get(order, 'numberRef', get(order, 'number'))
}

export default OrderNumber