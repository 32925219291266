import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import { addQuote, getNewQuoteNumber, validateQuoteNumber, getNewQuoteFootnote, findPartQuotes, copyPartQuote } from './functions'
import PartName from '../Parts/PartName'

function CreateQuote({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate, noBubble }) {
  const [isCreating, setIsCreating] = useState(false)
  const [quote, setQuote] = useState()
  const [partQuotes, setPartQuotes] = useState([])
  const [selected, setSelected] = useState([])
  const [errors, setErrors] = useState({})

  const [isOpening, setIsOpening] = useState(true)

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  const toggleSelect = (item, checked) => {
    if (checked && !selected.find(i => i.id === item.id)) {
      return setSelected([...selected, item])
    }
    return setSelected(selected.filter(i => i.id !== item.id))
  }

  const update = updates => {
    const { isNumberAuto, number } = updates
    setQuote({
      ...quote,
      ...updates,
      ...!isNumberAuto && number && { isNumberAuto: false },
    })
  }

  const validate = async () => {
    const errors = {}
    if (!get(quote, 'number')) errors.number = 'Required'
    if (get(quote, 'number') && !await validateQuoteNumber(quote.number, companyId)) errors.number = 'Conflicted'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return addQuote({ ...quote, footnote: await getNewQuoteFootnote(companyId) })
      .then(async newQuote => {
        enqueueSnackbar('Quote created successfully.', { variant: 'success' })
        handleClose()
        await Promise.all(selected
          .map(async ({ id, partId, quantityBase, leadTime, leadTimeUnit, dateExpiration, combos }) =>
            copyPartQuote(id, {
              quoteId: newQuote.id,
              partId,
              quantityBase,
              leadTime,
              leadTimeUnit,
              dateExpiration,
              combos,
              status: 'ACTIVE',
            })
          ))
        if (onCreate) onCreate(newQuote)
        if (isOpening) navigate(`/quotes/${newQuote.id}`)
      })
  }

  useEffect(() => {
    setSelected(partQuotes)
  }, [partQuotes])

  useEffect(() => {
    if (data.id) findPartQuotes({ quoteId: data.id, orderBy: ['createdAt','asc'] }).then(setPartQuotes)
  }, [data])

  useEffect(() => {
    if (isCreating) setQuote(data)
  }, [isCreating, data])

  useEffect(() => {
    if (quote) {
      const { number, isNumberAuto } = quote
      if (isNumberAuto && !number) {
        getNewQuoteNumber(companyId).then(number => setQuote({ ...quote, number }))
      }
    }
  }, [quote])

  return (
    <>
      <Box style={{ ...buttonStyle }} onClick={e => {
        if (noBubble) e.stopPropagation()
        setIsCreating(true)
      }}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>Create new quote</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <TextField
              size="small"
              variant="outlined"
              label="Quote number"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(quote, 'number', '')}
              onChange={({ target: { value } }) => update({ number: value })}
              InputProps={{
                endAdornment: (
                  <Checkbox
                    checked={get(quote, 'isNumberAuto', false)}
                    onChange={async ({ target: { checked } }) => update({ isNumberAuto: checked, ...checked && { number: await getNewQuoteNumber(companyId) } })}
                    style={{ marginRight: '-15px', whiteSpace: 'nowrap' }}
                    label="Auto-generate"
                  />
                ),
              }}
              {...addErrorProps(errors, 'number')}
              disabled
            />
            <TextField
              variant="outlined"
              name="numberRef"
              label="Quote reference number"
              fullWidth
              style={{ marginTop: '10px' }}
              value={get(quote, 'numberRef', '')}
              onChange={({ target: { value } }) => update({ numberRef: value })}
              {...addErrorProps(errors, 'numberRef')}
            />
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ width: '50%' }}>
                <DateField
                  label="Date"
                  value={get(quote, 'date')}
                  onChange={date => update({ date })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      style={{ marginTop: '10px' }}
                    />
                  )}
                />
              </Box>
              <Box style={{ width: '50%' }}>
                <DateField
                  label="Expiration"
                  value={get(quote, 'dateExp')}
                  onChange={dateExp => update({ dateExp })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      style={{ marginTop: '10px' }}
                    />
                  )}
                />
              </Box>
            </Box>
            <List>
              {partQuotes.map(item => {
                const { id, partId, quantityBase, leadTime, leadTimeUnit } = item
                const isSelected = !!selected.find(i => i.id === id)
                return (
                  <ListItem key={id} disablePadding>
                    <ListItemButton onClick={() => toggleSelect(item, !isSelected)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={isSelected}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={<PartName partId={partId} />}
                        secondary={`Base: ${quantityBase} · Lead time: ${leadTime} ${leadTimeUnit}`}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box style={{ flexGrow: 1, paddingLeft: '12px' }}>
            <Checkbox
              checked={isOpening}
              onChange={({ target: { checked } }) => setIsOpening(checked)}
              label="Open quote after creation"
            />
          </Box>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={() => setIsCreating(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateQuote