import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAt, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith, getCount } from '../../functions'

export const findQuotes = async ({ companyId, number, numberBeginsWith, status, statusIn, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'quotes'), ...[
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    where('companyId', '==', companyId),
    ...statusIn ? [where('status', 'in', statusIn)]
    : status ? [where('status', '==', status)]
    : [where('status', '==', 'ACTIVE')],
    orderBy(...order),
    limit(max || await getGlobal('quotesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getQuote = id => get('quotes', id)

export const addQuote = data => add('quotes', data)

export const updateQuote = (id, updates) => update('quotes', id, updates, 'quoteLogs')

export const getLastQuote = (companyId, args) => findQuotes({ companyId, orderBy: ['number','desc'], limit: 1, ...args }).then(([lastQuote]) => lastQuote)

export const getNewQuoteNumber = companyId => getLastQuote(companyId).then(lastQuote => parseInt(lastQuote ? lastQuote.number : 0) + 1)

export const validateQuoteNumber = (number, companyId) => findQuotes({ companyId, number, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const getNewQuoteFootnote = companyId => getLastQuote(companyId).then(lastQuote => _.get(lastQuote, 'footnote', ''))

export const findPartQuotes = async ({ partId, quoteId, status, statusIn, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'partQuotes'), ...[
    ...quoteId ? [where('quoteId', '==', quoteId)] : [],
    ...partId ? [where('partId', '==', partId)] : [],
    ...statusIn ? [where('status', 'in', statusIn)]
    : status ? [where('status', '==', status)]
    : [where('status', '==', 'ACTIVE')],
    orderBy(...order),
    limit(max || await getGlobal('partQuotesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getPartQuote = id => get('partQuotes', id)

export const addPartQuote = data => add('partQuotes', data)

export const updatePartQuote = (id, updates) => update('partQuotes', id, updates, 'partQuoteLogs')

export const findPartQuoteLines = async ({ partQuoteId, status, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'partQuoteLines'), ...[
    where('partQuoteId', '==', partQuoteId),
    ...status ? [where('status', '==', status)] : [],
    orderBy(...order),
    limit(max || await getGlobal('partQuoteLinesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const addPartQuoteLine = data => add('partQuoteLines', data)

export const updatePartQuoteLine = (id, updates) => update('partQuoteLines', id, updates)

export const getPartQuoteCombos = async partId => {
  const [match] = await findPartQuotes({ partId, orderBy: ['updatedAt','desc'], limit: 1 })
  if (_.get(match, 'combos', []).length > 0) return match.combos
  return await getGlobal('partQuoteDefaultCombos')
}

export const getCpu = async partQuoteId => {
  const { quantityBase } = await getPartQuote(partQuoteId)
  return _.sumBy(await findPartQuoteLines({ partQuoteId, status: 'ACTIVE', orderBy: ['number','asc'] }), ({ quantity, unitPrice, markupRate }) => quantity*unitPrice*markupRate) / quantityBase
}

export const copyPartQuote = async (id, data) => {
  const { id: partQuoteId } = await addPartQuote(data)
  return Promise.all((await findPartQuoteLines({ partQuoteId: id, status: 'ACTIVE', orderBy: ['number','asc'] }))
    .map(async line => addPartQuoteLine({
      number: line.number,
      description: line.description,
      quantity: line.quantity,
      unit: line.unit,
      unitPrice: line.unitPrice,
      markupRate: line.markupRate,
      partQuoteId,
    })))
}