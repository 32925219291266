import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getJob, getJobLine } from './functions'

function JobNumber({ jobId, jobLineId }) {
  const [job, setJob] = useState()

  useAsyncFunc(async ({ jobId, jobLineId }) => {
    if (jobId) return getJob(jobId)
    const jobLine = await getJobLine(jobLineId)
    return getJob(jobLine.jobId)
  }, { jobId, jobLineId }, setJob, [jobId, jobLineId])

  return get(job, 'number', <Skeleton variant="rectangular" animation="wave" />)
}

export default JobNumber