import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Book, Visibility, OpenInNew } from '@mui/icons-material'
import { get } from 'lodash'

function ManualEntryResult({ item, selected, onSelect }) {
  const { id, properties: { Page: { title } }, last_edited_time } = item
  const [lineItems, setLineItems] = useState([])
  const [isHovering, setIsHovering] = useState(false)

  return (
    <ListItem
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      disablePadding
      {...get(selected, 'id') === id && {
        secondaryAction: (
          <IconButton edge="end">
            <Visibility />
          </IconButton>
        )
      }}
      {...isHovering && {
        secondaryAction: (
          <Tooltip title="Open In New Window">
            <Link to={`/manual/${id}`} target="_blank" rel="noopener noreferrer">
              <IconButton edge="end">
                <OpenInNew />
              </IconButton>
            </Link>
          </Tooltip>
        )
      }}
      onClick={() => onSelect(item)}
    >
      <ListItemButton>
        <ListItemIcon>
          <Badge
            variant="dot"
            color="success"
          >
            <Book />
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={<Typography style={{ textTransform: 'capitalize' }}>{get(title, '[0].plain_text', 'Untitled').replace(/ *\([^)]*\) */g, "").toLowerCase()}</Typography>}
          secondary={(
            <>
              {`Updated ${(new Date(last_edited_time)).toLocaleDateString()}`}
              {lineItems.length > 1 ? ` + ${lineItems.length - 1}` : ''}
            </>
          )}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default ManualEntryResult