import { useState } from 'react'
import { Button, Badge, Tooltip, TableRow, TableCell } from '@mui/material'
import { Edit } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getPart } from '../Parts/functions'
import { getCpu } from './functions'
import EditPartQuote from './EditPartQuote'

function PartQuoteRow({ companyId, id, partId, quoteId, combos, unitPrice, leadTime: defaultLeadTime, leadTimeUnit: defaultLeadTimeUnit, dateExpiration, onUpdated, readOnly }) {
  const [part, setPart] = useState()
  const [cpu, setCpu] = useState(0)

  useAsyncFunc(getPart, partId, setPart, [partId])
  useAsyncFunc(getCpu, id, setCpu, [id])

  return (
    <>
      {combos.map(({ quantity, markupRate, leadTime, leadTimeUnit }, index) => (
        <TableRow key={`${id}-${quantity}`}>
          <TableCell>
            {index === 0 ? `${get(part, 'number')} Rev.${get(part, 'revision')}` : ''}
          </TableCell>
          <TableCell>
            {index === 0 ? get(part, 'name') : ''}
            {index === 1 && !readOnly && (
              <EditPartQuote
                companyId={companyId}
                quoteId={quoteId}
                partQuoteId={id}
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<Edit />}
                  >
                    Edit
                  </Button>
                )}
                onUpdated={onUpdated}
              />
            )}
          </TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>piece</TableCell>
          <TableCell align="right">
            <NumberFormat
              value={unitPrice || cpu*markupRate}
              prefix="$"
              thousandSeparator
              fixedDecimalScale
              decimalScale={2}
              displayType="text"
            />
          </TableCell>
          <TableCell align="right">
            <NumberFormat
              value={unitPrice ? unitPrice*quantity : cpu*markupRate*quantity}
              prefix="$"
              thousandSeparator
              fixedDecimalScale
              decimalScale={2}
              displayType="text"
            />
          </TableCell>
          <TableCell align="right">
            {`${leadTime || defaultLeadTime} ${leadTimeUnit || defaultLeadTimeUnit}`}
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

export default PartQuoteRow