import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAt, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith, getCount } from '../../functions'

export const findInvoices = async ({ companyId, number, numberBeginsWith, numberRef, numberRefBeginsWith, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'invoices'), ...[
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    ...numberRef ? [where('numberRef', '==', numberRef)]
    : numberRefBeginsWith ? beginsWith('numberRef', numberRefBeginsWith)
    : [],
    where('companyId', '==', companyId),
    where('status', '==', 'OPEN'),
    orderBy(...order),
    limit(max || await getGlobal('invoicesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getInvoice = id => get('invoices', id)

export const addInvoice = data => add('invoices', data)

export const updateInvoice = (id, updates) => update('invoices', id, updates, 'invoiceLogs')

export const getLastInvoice = (companyId, args) => findInvoices({ companyId, orderBy: ['number','desc'], limit: 1, ...args }).then(([lastInvoice]) => lastInvoice)

export const getNewInvoiceNumber = companyId => getLastInvoice(companyId).then(lastInvoice => parseInt(lastInvoice ? lastInvoice.number : 0) + 1)

export const validateInvoiceNumber = (number, companyId) => findInvoices({ companyId, number, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const findLineItems = async ({ invoiceId, partId, partIdIn, partOrderId, partOrderIdIn, refPoNumber, refPoNumberIn, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'invoiceItems'), ...[
    ...invoiceId ? [where('invoiceId', '==', invoiceId)] : [],
    ...partId ? [where('partId', '==', partId)] : [],
    ...partIdIn ? [where('partId', 'in', partIdIn)] : [],
    ...partOrderId ? [where('partOrderId', '==', partOrderId)] : [],
    ...partOrderIdIn ? [where('partOrderId', 'in', partOrderIdIn)] : [],
    ...refPoNumber ? [where('refPoNumber', '==', refPoNumber)] : [],
    ...refPoNumberIn ? [where('refPoNumber', 'in', refPoNumberIn)] : [],
    orderBy(...order),
    limit(max || await getGlobal('invoiceItemsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getLineItem = id => get('invoiceItems', id)

export const addLineItem = data => add('invoiceItems', data)

export const getNewLineNumber = invoiceId => findLineItems({ invoiceId, orderBy: ['lineNumber','desc'], limit: 1 }).then(([lastLineItem]) => _.get(lastLineItem, 'lineNumber', 0) + 1)

export const updateLineItem = (id, updates) => update('invoiceItems', id, updates, 'invoiceItemLogs')