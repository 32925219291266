import { useState, useEffect, forwardRef } from 'react'
import { Box, TextField, Button, IconButton } from '@mui/material'
import { Undo, ListAlt, ArrowDropDown } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt } from '../../functions'
import ChangeLogs from '../ChangeLogs'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import PhoneField from '../PhoneField'
import SelectContact from '../Contacts/SelectContact'
import { updateInvoice } from './functions'

function EditInvoiceDetails({ invoice, onUpdate }) {
  const [updatedInvoice, setUpdatedInvoice] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    if (!get(updatedInvoice, 'number')) errors.number = 'Required'
    if (!get(updatedInvoice, 'invoiceAt')) errors.invoiceAt = 'Required'
    if (!get(updatedInvoice, 'dueAt')) errors.dueAt = 'Required'
    if (!get(updatedInvoice, 'shipToName')) errors.shipToName = 'Required'
    if (!get(updatedInvoice, 'shipToAddress')) errors.shipToAddress = 'Required'
    if (!get(updatedInvoice, 'shipToPhone')) errors.shipToPhone = 'Required'
    if (!get(updatedInvoice, 'billToName')) errors.billToName = 'Required'
    if (!get(updatedInvoice, 'billToAddress')) errors.billToAddress = 'Required'
    if (!get(updatedInvoice, 'billToPhone')) errors.billToPhone = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateInvoice(invoice.id, {
      number: updatedInvoice.number,
      ...updatedInvoice.invoiceAt && { invoiceAt: updatedInvoice.invoiceAt },
      ...updatedInvoice.dueAt && { dueAt: updatedInvoice.dueAt },
      ...updatedInvoice.shipVia && { shipVia: updatedInvoice.shipVia },
      ...updatedInvoice.terms && { terms: updatedInvoice.terms },
      ...updatedInvoice.shipToContactId && { shipToContactId: updatedInvoice.shipToContactId },
      ...updatedInvoice.shipToAttn && { shipToAttn: updatedInvoice.shipToAttn },
      ...updatedInvoice.shipToName && { shipToName: updatedInvoice.shipToName },
      ...updatedInvoice.shipToAddress && { shipToAddress: updatedInvoice.shipToAddress },
      ...updatedInvoice.shipToEmail && { shipToEmail: updatedInvoice.shipToEmail },
      ...updatedInvoice.shipToPhone && { shipToPhone: updatedInvoice.shipToPhone },
      ...updatedInvoice.shipToFax && { shipToFax: updatedInvoice.shipToFax },
      ...updatedInvoice.billToContactId && { billToContactId: updatedInvoice.billToContactId },
      ...updatedInvoice.billToAttn && { billToAttn: updatedInvoice.billToAttn },
      ...updatedInvoice.billToName && { billToName: updatedInvoice.billToName },
      ...updatedInvoice.billToAddress && { billToAddress: updatedInvoice.billToAddress },
      ...updatedInvoice.billToEmail && { billToEmail: updatedInvoice.billToEmail },
      ...updatedInvoice.billToPhone && { billToPhone: updatedInvoice.billToPhone },
      ...updatedInvoice.billToFax && { billToFax: updatedInvoice.billToFax },
    }).then(onUpdate)
      .then(() => enqueueSnackbar('Invoice updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedInvoice(invoice)
  }, [invoice])

  return (
    <>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Invoice number"
            fullWidth
            value={get(updatedInvoice, 'numberRef', '')}
            onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, numberRef: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'numberRef')}
          />
          <DateField
            label="Invoice date"
            value={get(updatedInvoice, 'invoiceAt')}
            onChange={invoiceAt => setUpdatedInvoice({ ...updatedInvoice, invoiceAt })}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                fullWidth
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'invoiceAt')}
              />
            )}
          />
          <SelectContact
            companyId={invoice.companyId}
            contactId={get(updatedInvoice, 'shipToContactId', '')}
            label="Ship to name"
            onChange={contact => setUpdatedInvoice({
              ...updatedInvoice,
              shipToContactId: get(contact, 'id', ''),
              shipToAttn: get(contact, 'contactName', ''),
              shipToName: get(contact, 'name', ''),
              shipToAddress: get(contact, 'address', ''),
              shipToEmail: get(contact, 'email', ''),
              shipToPhone: get(contact, 'phone', ''),
              shipToFax: get(contact, 'fax', ''),
            })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            size="small"
            variant="outlined"
            name="shipToAddress"
            label="Ship to address"
            rows={3}
            multiline
            fullWidth
            value={get(updatedInvoice, 'shipToAddress', '')}
            onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, shipToAddress: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'shipToAddress')}
          />
          <TextField
            size="small"
            variant="outlined"
            name="shipToEmail"
            label="Email"
            fullWidth
            value={get(updatedInvoice, 'shipToEmail', '')}
            onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, shipToEmail: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'shipToEmail')}
          />
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="shipToPhone"
                label="Phone"
                fullWidth
                value={get(updatedInvoice, 'shipToPhone', '')}
                onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, shipToPhone: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'shipToPhone')}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="shipToFax"
                label="Fax"
                fullWidth
                value={get(updatedInvoice, 'shipToFax', '')}
                onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, shipToFax: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'shipToFax')}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '50%' }}>
          <Box style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <Dropdown
                button={(
                  <TextField
                    size="small"
                    variant="outlined"
                    name="terms"
                    label="Terms"
                    fullWidth
                    value={get(updatedInvoice, 'terms', '')}
                    onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, terms: value })}
                    InputProps={{
                      endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                    }}
                    style={{ marginBottom: '10px' }}
                    {...addErrorProps(errors, 'terms')}
                  />
                )}
                items={['Net 10','Net 30','Net 45','Net 60','Net 90'].map(value => ({ label: value, value }))}
                onChange={({ value }) => setUpdatedInvoice({ ...updatedInvoice, terms: value })}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <Dropdown
                button={(
                  <TextField
                    size="small"
                    variant="outlined"
                    name="shipVia"
                    label="Ship Via"
                    fullWidth
                    value={get(updatedInvoice, 'shipVia', '')}
                    onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, shipVia: value })}
                    InputProps={{
                      endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                    }}
                    style={{ marginBottom: '10px' }}
                    {...addErrorProps(errors, 'shipVia')}
                  />
                )}
                items={['UPS','FedEx','Our Truck'].map(value => ({ label: value, value }))}
                onChange={({ value }) => setUpdatedInvoice({ ...updatedInvoice, shipVia: value })}
              />
            </Box>
          </Box>
          <DateField
            label="Due date"
            value={get(updatedInvoice, 'dueAt')}
            onChange={dueAt => setUpdatedInvoice({ ...updatedInvoice, dueAt })}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                fullWidth
                style={{ marginBottom: '10px' }}
                {...addErrorProps(errors, 'dueAt')}
              />
            )}
          />
          <SelectContact
            companyId={invoice.companyId}
            contactId={get(updatedInvoice, 'billToContactId', '')}
            label="Bill to name"
            onChange={contact => setUpdatedInvoice({
              ...updatedInvoice,
              billToContactId: get(contact, 'id', ''),
              billToAttn: get(contact, 'contactName', ''),
              billToName: get(contact, 'name', ''),
              billToAddress: get(contact, 'address', ''),
              billToEmail: get(contact, 'email', ''),
              billToPhone: get(contact, 'phone', ''),
              billToFax: get(contact, 'fax', ''),
            })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            size="small"
            variant="outlined"
            name="billToAddress"
            label="Bill to address"
            rows={3}
            multiline
            fullWidth
            value={get(updatedInvoice, 'billToAddress', '')}
            onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, billToAddress: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'billToAddress')}
          />
          <TextField
            size="small"
            variant="outlined"
            name="billToEmail"
            label="Email"
            fullWidth
            value={get(updatedInvoice, 'billToEmail', '')}
            onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, billToEmail: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'billToEmail')}
          />
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="billToPhone"
                label="Phone"
                fullWidth
                value={get(updatedInvoice, 'billToPhone', '')}
                onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, billToPhone: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'billToPhone')}
              />
            </Box>
            <Box style={{ width: '50%' }}>
              <TextField
                size="small"
                variant="outlined"
                name="billToFax"
                label="Fax"
                fullWidth
                value={get(updatedInvoice, 'billToFax', '')}
                onChange={({ target: { value } }) => setUpdatedInvoice({ ...updatedInvoice, billToFax: value })}
                style={{ marginBottom: '10px' }}
                InputProps={{
                  inputComponent: PhoneField,
                }}
                {...addErrorProps(errors, 'billToFax')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!isEqual(invoice, updatedInvoice) && (
        <Button
          variant="outlined"
          startIcon={<Undo />}
          onClick={() => setUpdatedInvoice(invoice)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        style={{ marginRight: '5px' }}
        disabled={isEqual(invoice, updatedInvoice)}
        disableElevation
      >
        Save Details
      </Button>
      <ChangeLogs
        col="invoiceLogs"
        id={invoice.id}
        button={(
          <Button
            variant="outlined"
            disableElevation
            startIcon={<ListAlt />}
          >
            Changelog
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
      />
    </>
  )
}

export default EditInvoiceDetails