import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import { addInvoice, getNewInvoiceNumber, validateInvoiceNumber, findLineItems, addLineItem } from './functions'
import SelectContact from '../Contacts/SelectContact'
import PartName from '../Parts/PartName'
import { findPartOrders, getOrder } from '../Orders/functions'

function CreateInvoice({ companyId, orderId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate }) {
  const [isCreating, setIsCreating] = useState(false)
  const [invoice, setInvoice] = useState()
  const [lineItems, setLineItems] = useState([])
  const [selected, setSelected] = useState([])
  const [errors, setErrors] = useState({})

  const [isOpening, setIsOpening] = useState(true)

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  const toggleSelect = (item, checked) => {
    if (checked && !selected.find(i => i.id === item.id)) {
      return setSelected([...selected, item])
    }
    return setSelected(selected.filter(i => i.id !== item.id))
  }

  const update = updates => {
    const { isNumberAuto, number } = updates
    setInvoice({
      ...invoice,
      ...updates,
      ...!isNumberAuto && number && { isNumberAuto: false },
    })
  }

  const validate = async () => {
    const errors = {}
    if (!get(invoice, 'number')) errors.number = 'Required'
    if (get(invoice, 'number') && !await validateInvoiceNumber(invoice.number, companyId)) errors.number = 'Conflicted'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return addInvoice(invoice)
      .then(async newInvoice => {
        enqueueSnackbar('Invoice created successfully.', { variant: 'success' })
        handleClose()
        await Promise.all(selected
          .map(async ({ id: partOrderId, partId, quantity, unit, unitPrice }, index) =>
            addLineItem({
              invoiceId: newInvoice.id,
              partOrderId,
              partId,
              lineNumber: index + 1,
              quantity,
              unit,
              unitPrice,
              dueAt: newInvoice.dueAt,
              status: 'OPEN',
            })
          ))
        if (onCreate) onCreate(newInvoice)
        if (isOpening) navigate(`/invoices/${newInvoice.id}`)
      })
  }

  useEffect(() => {
    setSelected(lineItems)
  }, [lineItems])

  useEffect(() => {
    if (data.id) findLineItems({ invoiceId: data.id, orderBy: ['createdAt','asc'] }).then(setLineItems)
  }, [data])

  useEffect(() => {
    if (orderId) {
      findPartOrders({ orderId, orderBy: ['lineNumber','asc'] }).then(setLineItems)
      getOrder(orderId).then(({ billToContactId, shipToContactId }) => setInvoice({ ...invoice, billToContactId, shipToContactId }))
    }
  }, [orderId])

  useEffect(() => {
    if (isCreating) setInvoice(data)
  }, [isCreating, data])

  useEffect(() => {
    if (invoice) {
      const { number, isNumberAuto } = invoice
      if (isNumberAuto && !number) {
        getNewInvoiceNumber(companyId).then(number => setInvoice({ ...invoice, number }))
      }
    }
  }, [invoice])

  return (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsCreating(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>Create new invoice</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <TextField
              size="small"
              variant="outlined"
              label="Invoice number"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(invoice, 'number', '')}
              onChange={({ target: { value } }) => update({ number: value })}
              InputProps={{
                endAdornment: (
                  <Checkbox
                    checked={get(invoice, 'isNumberAuto', false)}
                    onChange={async ({ target: { checked } }) => update({ isNumberAuto: checked, ...checked && { number: await getNewInvoiceNumber(companyId) } })}
                    style={{ marginRight: '-15px', whiteSpace: 'nowrap' }}
                    label="Auto-generate number"
                  />
                ),
              }}
              {...addErrorProps(errors, 'number')}
              disabled
            />
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ width: '50%' }}>
                <DateField
                  label="Date"
                  value={get(invoice, 'date')}
                  onChange={date => update({ date })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      style={{ marginTop: '10px' }}
                    />
                  )}
                />
                <SelectContact
                  companyId={companyId}
                  contactId={get(invoice, 'shipToContactId', '')}
                  label="Ship to name"
                  onChange={contact => contact && update({
                    shipToContactId: get(contact, 'id', ''),
                    shipToName: get(contact, 'name', ''),
                    shipToAddress: get(contact, 'address', ''),
                    shipToEmail: get(contact, 'email', ''),
                    shipToPhone: get(contact, 'phone', ''),
                    shipToFax: get(contact, 'fax', ''),
                  })}
                  style={{ marginTop: '10px' }}
                  textFieldProps={{
                    ...addErrorProps(errors, 'shipToContactId')
                  }}
                  findType="CUST"
                />
              </Box>
              <Box style={{ width: '50%' }}>
                <DateField
                  label="Due"
                  value={get(invoice, 'dueAt')}
                  onChange={dueAt => update({ dueAt })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      style={{ marginTop: '10px' }}
                    />
                  )}
                />
                <SelectContact
                  companyId={companyId}
                  contactId={get(invoice, 'billToContactId', '')}
                  label="Bill to name"
                  onChange={contact => contact && update({
                    billToContactId: get(contact, 'id', ''),
                    billToName: get(contact, 'name', ''),
                    billToAddress: get(contact, 'address', ''),
                    billToEmail: get(contact, 'email', ''),
                    billToPhone: get(contact, 'phone', ''),
                    billToFax: get(contact, 'fax', ''),
                  })}
                  style={{ marginTop: '10px' }}
                  textFieldProps={{
                    ...addErrorProps(errors, 'billToContactId')
                  }}
                />
              </Box>
            </Box>
            <List>
              {lineItems.map(item => {
                const { id, partId, status, dueAt } = item
                const isSelected = !!selected.find(i => i.id === id)
                return (
                  <ListItem key={id} disablePadding>
                    <ListItemButton onClick={() => toggleSelect(item, !isSelected)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={isSelected}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={<PartName partId={partId} />}
                        secondary={`${status} · Due: ${(dueAt || new Date()).toLocaleDateString()}`}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box style={{ flexGrow: 1, paddingLeft: '12px' }}>
            <Checkbox
              checked={isOpening}
              onChange={({ target: { checked } }) => setIsOpening(checked)}
              label="Open invoice after creation"
            />
          </Box>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateInvoice