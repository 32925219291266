import { useState } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { EditOutlined, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, RequestQuoteOutlined, LocalShippingOutlined, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { Sankey, Layer, Rectangle, Tooltip as Charttip } from 'recharts'
import NumberFormat from 'react-number-format'
import { get, lowerCase, startCase } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc } from '../../functions'
import { getPart } from '../Parts/functions'
import PartName from '../Parts/PartName'
import PartNumber from '../Parts/PartNumber'
import { getJob } from '../Jobs/functions'
import CreateEditLineItem from './CreateEditLineItem'

function LineItemRow({ onUpdated, readOnly, isLineIncluded, ...item }) {
  const { companyId, referenceLocation, requirement, isMeasurable, requirementMax, requirementMin, actual, lineNumber } = item

  return (
    <TableRow>
      {isLineIncluded && (
        <TableCell>
          <Typography variant="body2">
            {lineNumber}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography variant="body2">
          {referenceLocation}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {requirement}
          {isMeasurable && requirementMax && ` +${requirementMax < 1 ? requirementMax.toString().substring(1) : requirementMax} `}
          {isMeasurable && requirementMin && ` -${requirementMin < 1 ? requirementMin.toString().substring(1) : requirementMin} `}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {actual}
        </Typography>
      </TableCell>
      <TableCell>
          <CreateEditLineItem
            companyId={companyId}
            data={item}
            button={(
              <Tooltip title="Edit">
                <IconButton className="b-edit" size="small">
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            onUpdated={onUpdated}
          />
      </TableCell>
    </TableRow>
  )
}

export default LineItemRow