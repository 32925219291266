import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, Tooltip } from '@mui/material'
import { Check, Clear, ArrowDropDown, History } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt, positiveFloat, getDate } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import PriceField from '../PriceField'
import { findContacts } from '../Contacts/functions'
import SelectPart from '../Parts/SelectPart'
import { addJob, getNewJobNumber, upsertJobAllocation, findJobs, findJobLines } from '../Jobs/functions'
import { addPartOrder, updatePartOrder, findPartOrders, getOrder, getNewLineNumber, validatePartOrderLineNumber } from './functions'

function CreateEditPartOrder({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate, onUpdate }) {
  const [isCreating, setIsCreating] = useState(false)
  const [isCreatingJob, setIsCreatingJob] = useState(false)
  const [order, setOrder] = useState()
  const [newPartOrder, setNewPartOrder] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])
  const [matches, setMatches] = useState([])
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    if (!get(newPartOrder, 'partId')) errors.partId = 'Required'
    if (!get(newPartOrder, 'lineNumber')) errors.lineNumber = 'Required'
    if (!data.id && get(newPartOrder, 'lineNumber') && !await validatePartOrderLineNumber(newPartOrder.orderId, newPartOrder.lineNumber, newPartOrder.id)) errors.lineNumber = 'Conflicted'
    if (!get(newPartOrder, 'quantity')) errors.quantity = 'Required'
    if (!get(newPartOrder, 'unit')) errors.unit = 'Required'
    if (!get(newPartOrder, 'isPriceTbd') && get(newPartOrder, 'unitPrice') === undefined) errors.unitPrice = 'Required'
    if (!get(newPartOrder, 'isDueAsap') && !get(newPartOrder, 'dueAt')) errors.dueAt = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    const addAndAllocateJob = async partOrder => addJob({
      companyId,
      number: await getNewJobNumber(companyId),
      shipAt: getDate(partOrder.dueAt, '-', 7, 'day'),
      partId: partOrder.partId,
      quantity: partOrder.quantity,
      quantityAvailable: partOrder.quantity,
      dueAt: partOrder.dueAt,
      status: 'IN_PROGRESS',
    }).then(({ id: jobId }) => upsertJobAllocation({ jobId, partOrderId: partOrder.id, quantity: partOrder.quantity }))
    const appendPartOrderProps = async orderId => ({
      isPurchase: !(await findContacts({ companyId, type: 'VEND', isInternal: true, orderBy: ['createdAt','desc'] })
                          .then(contacts => contacts.map(({ id }) => id)))
                          .includes(await getOrder(orderId).then(({ shipFromContactId }) => shipFromContactId)),
    })
    if (data.id) {
      return updatePartOrder(data.id, {
        partId: newPartOrder.partId,
        lineNumber: newPartOrder.lineNumber,
        quantity: newPartOrder.quantity,
        unit: newPartOrder.unit,
        ...newPartOrder.unitPrice && { unitPrice: newPartOrder.unitPrice },
        ...newPartOrder.dueAt && { dueAt: newPartOrder.dueAt },
        isPriceTbd: !!newPartOrder.isPriceTbd,
        isDueAsap: !!newPartOrder.isDueAsap,
        ...newPartOrder.notes && { notes: newPartOrder.notes },
        ...await appendPartOrderProps(newPartOrder.orderId),
      }).then(async partOrder => {
        handleClose()
        enqueueSnackbar('Order item updated successfully.', { variant: 'success' })
        if (onUpdate) onUpdate(partOrder)
        if (isCreatingJob) addAndAllocateJob(partOrder)
      })
    }
    return addPartOrder({ ...newPartOrder, quantityBalance: newPartOrder.quantity, companyId, ...await appendPartOrderProps(newPartOrder.orderId) })
      .then(partOrder => {
        handleClose()
        enqueueSnackbar('Order item created successfully.', { variant: 'success' })
        if (onCreate) onCreate(partOrder)
        if (isCreatingJob) addAndAllocateJob(partOrder)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewPartOrder(data)
  }, [data])

  // useAsyncFunc(async args => {
  //   if (get(newPartOrder, 'partId')) {
  //     const [job] = await findJobs({ companyId, partId: newPartOrder.partId, orderBy: ['createdAt','desc'] })
  //     if (job && !get(newPartOrder, 'notes')) {
  //       const jobLines = await findJobLines({ jobId: job.id, orderBy: ['number','asc'] })
  //       setNewPartOrder({ ...newPartOrder, notes: `${jobLines.map(({ description }) => `${description}\n`).join('')}` })
  //     }
  //     return findPartOrders(args)
  //   }
  //   return () => null
  // }, { partId: get(newPartOrder, 'partId'), ...get(data, 'orderId') ? { excludeOrderIds: [], orderBy: ['orderId','desc'] } : { orderBy } }, setMatches, [orderBy, newPartOrder, data])

  useAsyncFunc(getOrder, data.orderId, setOrder, [data.orderId])

  return (
    <>
      {button && (
        <Box style={{ cursor: 'pointer', ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>{data.id ? 'Edit' : 'Create new'} order item</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ flexGrow: 1 }}>
                <SelectPart
                  companyId={companyId}
                  partId={get(newPartOrder, 'partId')}
                  onChange={part => setNewPartOrder({ ...newPartOrder, partId: get(part, 'id') })}
                  style={{ marginBottom: '10px' }}
                  inputProps={{
                    ...addErrorProps(errors, 'partId')
                  }}
                />
                {get(newPartOrder, 'partId') && (
                  <>
                    <Box style={{ display: 'flex', gap: '10px' }}>
                      <Box style={{ width: '50%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="poNumber"
                          label="PO Number"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(order, 'numberRef', get(order, 'number', ''))}
                          disabled
                        />
                      </Box>
                      <Box style={{ width: '50%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="lineNumber"
                          label="Line Number"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newPartOrder, 'lineNumber', '')}
                          onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, lineNumber: positiveInt(value) })}
                          {...addErrorProps(errors, 'lineNumber')}
                          required
                        />
                      </Box>
                    </Box>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Notes"
                      multiline
                      rows={5}
                      fullWidth
                      style={{ marginBottom: '10px' }}
                      value={get(newPartOrder, 'notes', '')}
                      onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, notes: value })}
                      InputProps={{
                        endAdornment: (
                          <Dropdown
                            button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                            beforeItems={<Typography variant="overline" display="block" color="GrayText">Notes history</Typography>}
                            items={(matches || []).map(({ notes, updatedAt }) => ({
                              label: (
                                <>
                                  {get(newPartOrder, 'notes', '') === notes && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                                  {updatedAt.toLocaleString()}
                                </>
                              ),
                              value: notes,
                              tooltip: notes,
                            }))}
                            onChange={({ value }) => setNewPartOrder({ ...newPartOrder, notes: value })}
                            disableCloseOnSelect
                          />
                        ),
                      }}
                      {...addErrorProps(errors, 'notes')}
                    />
                    <Box style={{ display: 'flex', gap: '10px' }}>
                      <Box style={{ width: '50%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="quantity"
                          label="Quantity"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newPartOrder, 'quantity', '')}
                          onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, quantity: positiveInt(value) })}
                          {...addErrorProps(errors, 'quantity')}
                          required
                        />
                      </Box>
                      <Box>
                        <Dropdown
                          button={(
                            <TextField
                              size="small"
                              variant="outlined"
                              value={get(newPartOrder, 'unit', '')}
                              InputProps={{
                                endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                              }}
                              style={{ width: '120px' }}
                            />
                          )}
                          items={[
                            { label: 'LOT', value: 'LOT' },
                            { label: 'PIECE', value: 'PIECE' },
                            { label: 'HOUR', value: 'HOUR' },
                          ]}
                          onChange={({ value }) => setNewPartOrder({ ...newPartOrder, unit: value })}
                        />
                      </Box>
                      <Box style={{ flexGrow: 1 }}>
                        <Tooltip title="Hit 'Enter' to confirm & save" placement="top">
                          <TextField
                            size="small"
                            variant="outlined"
                            name="unitPrice"
                            label="Unit price"
                            fullWidth
                            style={{ marginBottom: '10px' }}
                            value={get(newPartOrder, 'unitPrice', '')}
                            onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, unitPrice: positiveFloat(value) })}
                            InputProps={{
                              inputComponent: PriceField,
                              endAdornment: (
                                <>
                                  <Checkbox
                                    checked={get(newPartOrder, 'isPriceTbd', false)}
                                    onChange={({ target: { checked } }) => setNewPartOrder({ ...newPartOrder, isPriceTbd: checked })}
                                    label="TBD"
                                  />
                                  <Dropdown
                                    button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                                    beforeItems={<Typography variant="overline" display="block" color="GrayText">Pricing history</Typography>}
                                    items={(matches || []).map(({ quantity, unitPrice, unit, updatedAt }) => ({
                                      label: (
                                        <>
                                          {get(newPartOrder, 'quantity', 0) === quantity
                                          && get(newPartOrder, 'unitPrice', 0) === unitPrice
                                          && get(newPartOrder, 'unit', '') === unit
                                          && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                                          {updatedAt.toLocaleString()}
                                        </>
                                      ),
                                      value: { quantity, unitPrice, unit },
                                      tooltip: `${quantity} ${unit} at $${unitPrice}/${unit}`,
                                    }))}
                                    onChange={({ value }) => setNewPartOrder({ ...newPartOrder, ...value })}
                                    disableCloseOnSelect
                                  />
                                </>
                              ),
                              disabled: get(newPartOrder, 'isPriceTbd', false),
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault()
                                return submit()
                              }
                              return e
                            }}
                            {...addErrorProps(errors, 'unitPrice')}
                            required
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', gap: '10px' }}>
                      <Box style={{ flexGrow: 1 }}>
                        <DateField
                          label="Due date"
                          value={get(newPartOrder, 'dueAt')}
                          onChange={date => setNewPartOrder({ ...newPartOrder, dueAt: date })}
                          renderInput={params => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              style={{ marginBottom: '10px' }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    <Checkbox
                                      checked={get(newPartOrder, 'isDueAsap', false)}
                                      onChange={({ target: { checked } }) => setNewPartOrder({ ...newPartOrder, isDueAsap: checked, ...checked && !get(newPartOrder, 'dueAt') && { dueAt: new Date() } })}
                                      label="ASAP"
                                    />
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                                disabled: get(newPartOrder, 'isDueAsap', false),
                              }}
                              {...addErrorProps(errors, 'dueAt')}
                            />
                          )}
                        />
                      </Box>
                      <Dropdown
                        button={(
                          <TextField
                            size="small"
                            variant="outlined"
                            label="Status"
                            value={get(newPartOrder, 'status', '')}
                            InputProps={{
                              endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                            }}
                          />
                        )}
                        items={[
                          { label: 'OPEN', value: 'OPEN' },
                          { label: 'COMPLETED', value: 'COMPLETED' },
                          { label: 'SHIPPED', value: 'SHIPPED' },
                          { label: 'INVOICED', value: 'INVOICED' },
                          { label: 'PAID', value: 'PAID' },
                        ]}
                        onChange={({ value }) => setNewPartOrder({ ...newPartOrder, status: value })}
                      />
                    </Box>
                    {!data.id && (
                      <Checkbox
                        checked={isCreatingJob}
                        onChange={({ target: { checked } }) => setIsCreatingJob(checked)}
                        label="Create new job for this line item"
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
            disabled={isEqual(data, newPartOrder)}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateEditPartOrder