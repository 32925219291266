import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List } from '@mui/material'
import { Search, Add, Sort, DownloadForOfflineOutlined, FilterAltOutlined, Outbox } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc, partTypes } from '../../functions'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'
import OrderBy from '../OrderBy'
import Paginate from '../Paginate'
import Export from '../Export'
import { findParts } from './functions'
import CreatePart from './Create'
import PartResult from './PartResult'
import ImportParts from './Import'

function SearchParts({ companyId, selected, onSelect }) {
  const [parts, setParts] = useState([])
  const [query, setQuery] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])
  const [typeIn, setTypeIn] = useState(['ASSY','PART','COTS'])
  const [limit, setLimit] = useState(10)

  useAsyncFunc(async args => {
    if (companyId) return findParts(args)
    return () => []
  }, { companyId, orderBy, typeIn, limit, ...query && { numberBeginsWith: query } }, setParts, [companyId, query, orderBy, typeIn, limit])

  const fetch = () => findParts({ companyId, orderBy, typeIn, limit, ...query && { numberBeginsWith: query } }).then(setParts)

  useEffect(() => {
    const match = parts.find(({ id }) => id === get(selected, 'id'))
    if (match && match.updatedAt < selected.updatedAt) {
      setParts(parts.map(q => q.id !== match.id ? q : selected))
    }
  }, [parts, selected])

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search parts..."
        fullWidth
        onChange={({ target: { value } }) => setQuery(value)}
        style={{ marginBottom: '5px' }}
        InputProps={{
          startAdornment: <IconButton edge="start"><Search /></IconButton>,
        }}
      />
      <OrderBy
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Sort />}
          >
            {`Sort by: ${orderBy[0]}`}
          </Button>
        )}
        buttonStyle={{ display: 'inline-block', marginRight: '5px' }}
        onChange={({ value }) => setOrderBy(value)}
      />
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<FilterAltOutlined />}
          >
            {`Filter by: Type`}
          </Button>
        )}
        buttonStyle={{ display: 'inline-block', marginRight: '5px' }}
        items={[
          {
            label: (
              <Checkbox
                checked={partTypes.every(({ value }) => typeIn.includes(value))}
                onChange={({ target: { checked } }) => {
                  if (checked) return setTypeIn(partTypes.map(({ value }) => value))
                  return setTypeIn([])
                }}
                label="All"
              />
            )
          },
          ...partTypes.map(type => ({
            label: (
              <Checkbox
                checked={typeIn.includes(type.value)}
                onChange={({ target: { checked } }) => {
                  if (checked && !typeIn.includes(type.value)) return setTypeIn([...typeIn, type.value])
                  return setTypeIn(typeIn.filter(value => value !== type.value))
                }}
                label={type.label}
              />
            ),
            value: type.value,
          })),
        ]}
        disableCloseOnSelect
      />
      <CreatePart
        companyId={companyId}
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Add />}
          >
            New Part
          </Button>
        )}
        buttonStyle={{ display: 'inline-block', marginRight: '5px' }}
        data={{ companyId, status: 'ACTIVE' }}
        onCreate={fetch}
      />
      <Export
        companyId={companyId}
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Outbox />}
          >
            Export
          </Button>
        )}
        buttonStyle={{ display: 'inline-block', marginRight: '5px' }}
      />
      {/* <ImportParts companyId={companyId} /> */}
      <List>
        {parts.map(item => (
          <PartResult
            key={item.id}
            item={item}
            selected={selected}
            onSelect={onSelect}
          />
        ))}
        <Paginate
          label="Load more"
          size="small"
          variant="outlined"
          startIcon={<DownloadForOfflineOutlined />}
          disableElevation
          onClick={() => setLimit(limit + 10)}
          query={() => findParts({
            companyId,
            numberBeginsWith: query,
            typeIn,
            limit,
            orderBy: ['updatedAt','desc'],
            startAfter: get(parts[parts.length - 1], '_snap'),
          })}
          fullWidth
        />
      </List>
    </>
  )
}

export default SearchParts