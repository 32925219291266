import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getContact } from './functions'

function ContactProp({ contactId, prop }) {
  const [contact, setContact] = useState()

  useAsyncFunc(async contactId => {
    if (contactId) return getContact(contactId)
  }, contactId, setContact, [contactId])

  return get(contact, prop, <Skeleton variant="rectangular" animation="wave" />)
}

export default ContactProp