import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip } from '@mui/material'
import { LocalShipping, Visibility, OpenInNew, OutboundOutlined } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { findShipmentLines } from './functions'
import PartName from '../Parts/PartName'
import ShipmentBound from './ShipmentBound'

function ShipmentResult({ item, selected, onSelect }) {
  const { id, number } = item
  const [lineItems, setLineItems] = useState([])
  const [isHovering, setIsHovering] = useState(false)

  useAsyncFunc(findShipmentLines, { shipmentId: id, orderBy: ['createdAt','asc'] }, setLineItems, [id])

  return (
    <ListItem
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      disablePadding
      {...get(selected, 'id') === id && {
        secondaryAction: (
          <IconButton edge="end">
            <Visibility />
          </IconButton>
        )
      }}
      {...isHovering && {
        secondaryAction: (
          <Tooltip title="Open In New Window">
            <Link to={`/shipments/${id}`} target="_blank" rel="noopener noreferrer">
              <IconButton edge="end">
                <OpenInNew />
              </IconButton>
            </Link>
          </Tooltip>
        )
      }}
      onClick={() => onSelect(item)}
    >
      <ListItemButton>
        <ListItemIcon>
          <Badge
            variant="dot"
            color="success"
          >
            <LocalShipping />
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary={(
            <>
              {`S/N ${number}`}
              <ShipmentBound
                companyId={item.companyId}
                shipmentId={item.id}
                inbound={(
                  <Tooltip title="Inbound">
                    <Button
                      className="tiny"
                      size="small"
                      color="success"
                      variant="contained"
                      startIcon={<OutboundOutlined style={{ transform: 'rotateZ(90deg)' }} />}
                      style={{ minWidth: 'auto', marginLeft: '10px', padding: '0 8px 0 12px', fontSize: '12px' }}
                      disableElevation
                    >
                      Inbound
                    </Button>
                  </Tooltip>
                )}
                outbound={(
                  <Tooltip title="Outbound">
                    <Button
                      className="tiny"
                      size="small"
                      color="warning"
                      variant="contained"
                      startIcon={<OutboundOutlined />}
                      style={{ minWidth: 'auto', marginLeft: '10px', padding: '0 8px 0 12px', fontSize: '12px' }}
                      disableElevation
                    >
                      Outbound
                    </Button>
                  </Tooltip>
                )}
              />
            </>
          )}
          secondary={lineItems.length > 0 ? (
            <>
              <PartName partId={lineItems[0].partId} />
              {lineItems.length > 1 ? ` + ${lineItems.length - 1}` : ''}
            </>
          ) : ''}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default ShipmentResult