import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { OpenInNew, DownloadForOfflineOutlined } from '@mui/icons-material'
import { get } from 'lodash'
import Toggles from '../Toggles'
import Paginate from '../Paginate'
import { useAsyncFunc, getDateDiffStr, jobStatuses } from '../../functions'
import { findJobs } from '../Jobs/functions'

function PartJobs({ companyId, partId }) {
  const [partJobs, setPartJobs] = useState()
  const [partJobStatusIn, setPartJobStatusIn] = useState(['All'])
  const [limit, setLimit] = useState(10)

  useAsyncFunc(async () => {
    if (partId) {
      return findJobs({ companyId, partId, limit, orderBy: ['createdAt','desc'], ...!partJobStatusIn.includes('All') && { statusIn: partJobStatusIn } })
    }
    return () => []
  }, null, setPartJobs, [companyId, partId, partJobStatusIn, limit])

  return (
    <Box style={{ minHeight: '250px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
      <Toggles
        options={[
          { label: 'All', value: 'All' },
          ...jobStatuses,
        ]}
        selected={partJobStatusIn}
        setSelected={setPartJobStatusIn}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ship date</TableCell>
              <TableCell>Job number</TableCell>
              {/* <TableCell>PO number</TableCell> */}
              <TableCell>Quantity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(partJobs || []).map(({ id, number, poNumber, quantity, status, shipAt, updatedAt }) => (
              <TableRow key={id}>
                <TableCell>{shipAt.toLocaleDateString()}</TableCell>
                <TableCell>
                  {number}
                  <Tooltip title="Open In New Window">
                    <Link to={`/${id}`} target="_blank" rel="noopener noreferrer">
                      <IconButton edge="end" size="small">
                        <OpenInNew fontSize="small" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </TableCell>
                {/* <TableCell>{`P/O ${poNumber}`}</TableCell> */}
                <TableCell>{quantity}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="outlined"
                  >
                    {status}
                  </Button>
                </TableCell>
                <TableCell>{getDateDiffStr(null, updatedAt)}</TableCell>
              </TableRow>
            ))}
            {partJobs && (
              <TableRow>
                <TableCell colSpan={11} style={{ textAlign: 'center' }}>
                  <Paginate
                    label="Load more"
                    size="small"
                    variant="outlined"
                    startIcon={<DownloadForOfflineOutlined />}
                    disableElevation
                    onClick={() => setLimit(limit + 10)}
                    query={() => findJobs({
                      companyId,
                      statusIn: partJobStatusIn,
                      limit: 1,
                      startAfter: get(partJobs[partJobs.length - 1], '_snap'),
                    })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PartJobs