import { useState, useEffect } from 'react'
import { Box, TextField, Typography, Badge, Chip, Button, IconButton } from '@mui/material'
import { Edit, DownloadForOfflineOutlined } from '@mui/icons-material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { get, isEqual } from 'lodash'
import { addErrorProps, useAsyncFunc } from '../../functions'
import Checkbox from '../Checkbox'
import Paginate from '../Paginate'
import { findDevices, getDevice } from './functions'
import CreateEditDevice from './CreateEdit'

const filter = createFilterOptions()

function SelectDevice({ companyId, label, deviceId, onChange, style, selectedLabel, textFieldProps, findType, findQuery, disabled, disableClearable }) {
  const [isCreating, setIsCreating] = useState(false)
  const [query, setQuery] = useState(findQuery)
  const [devices, setDevices] = useState([])
  const [device, setDevice] = useState()
  const [newDevice, setNewDevice] = useState()
  const [limit, setLimit] = useState(10)

  useAsyncFunc(findDevices, { companyId, numberBeginsWith: query, orderBy: ['updatedAt','desc'], ...findType && { type: findType }, limit }, setDevices, [companyId, findType, query, isCreating, limit])

  useEffect(() => {
    onChange(device)
  }, [device])

  useEffect(() => {
    if (deviceId) getDevice(deviceId).then(setDevice)
    else setDevice()
  }, [deviceId])

  return (
    <>
      <Autocomplete
        value={device || ''}
        options={devices}
        onInputChange={(event, value, reason) => setQuery(value)}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              setIsCreating(true)
              setNewDevice({ name: newValue })
            })
          } else if (newValue && newValue.inputValue) {
            setIsCreating(true)
            setNewDevice({ name: newValue.inputValue })
          } else {
            setDevice(newValue)
          }
        }}
        renderInput={params => {
          const props = {
            ...params,
            size: 'small',
            variant: 'outlined',
            label,
            placeholder: 'Enter control number',
            fullWidth: true,
            InputProps: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {device && (
                      <CreateEditDevice
                        companyId={companyId}
                        button={(
                          <IconButton size="small">
                            <Edit fontSize="small" />
                          </IconButton>
                        )}
                        data={device}
                      />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
            style: {
              ...params.style,
              ...style,
            },
          }
          return (
            <TextField
              {...props}
              {...textFieldProps}
            />
          )
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            })
          }
          if (filtered.length > 0) {
            filtered.push({
              label: (
                <Paginate
                  label="Load more"
                  size="small"
                  variant="outlined"
                  startIcon={<DownloadForOfflineOutlined />}
                  disableElevation
                  onClick={() => setLimit(limit + 10)}
                  query={() => findDevices({
                    companyId, numberBeginsWith: query, orderBy: ['updatedAt','desc'], ...findType && { type: findType },
                    startAfter: get(devices[devices.length - 1], '_snap'),
                  })}
                  fullWidth
                />
              ),
            })
          }
          return filtered
        }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return selectedLabel ? selectedLabel(option) : `${option.number} · ${option.numberSerial}`
        }}
        renderOption={(props, option) => option.render || (
          <li {...props} {...option.inputValue && { style: { ...props.style, color: '#1a73e8' } }}>
            {option.id ? (
              <>
                <Button
                  className="tiny"
                  size="small"
                  variant="outlined"
                  {...option.status === 'INACTIVE' && { color: 'secondary' }}
                  style={{ minWidth: 'auto', marginRight: '5px', padding: '0 10px', fontSize: '12px', whiteSpace: 'nowrap' }}
                  disableElevation
                >
                  {option.status === 'INACTIVE' ? 'Bonded' : 'Active'}
                </Button>
                {option.number}
                <Chip size="small" label={option.numberSerial} sx={{ margin: '0 3px' }} />
                {`(${option.range})`}
              </>
            ) : (
              <Box onClick={e => e.stopPropagation()}>
                {option.label}
              </Box>
            )}
          </li>
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        disabled={disabled}
        disableClearable={disableClearable}
      />
    </>
  )
}

export default SelectDevice