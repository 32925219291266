import { Box, Button } from '@mui/material'
import { Visibility, PrintOutlined, ContentCopy, DeleteOutline, Launch } from '@mui/icons-material'
import { get } from 'lodash'
import Confirm from '../Confirm'
import ShareLink from '../ShareLink'
import { updateInvoice } from './functions'
import Preview from './Preview'
import CreateInvoice from './Create'

function Actions({ item }) {
  return item ? (
    <>
      <Button style={{ flexGrow: 1 }}>
        {`Invoice Number ${get(item, 'numberRef') ? `${item.numberRef} (${item.number})` : get(item, 'number')}`}
      </Button>
      <Box>
        <Preview
          button={(
            <Button
              startIcon={<Visibility />}
              style={{ marginLeft: '5px' }}
            >
              Preview
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          invoiceId={item.id}
        />
        <ShareLink
          button={(
            <Button
              startIcon={<Launch />}
              style={{ marginLeft: '5px' }}
            >
              Share
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          url={`edimfg.com/invoices/${item.id}`}
        />
        <Preview
          button={(
            <Button
              startIcon={<PrintOutlined />}
              style={{ marginLeft: '5px' }}
            >
              Print
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          invoiceId={item.id}
          forcePrint
        />
        <CreateInvoice
          companyId={item.companyId}
          button={(
            <Button
              startIcon={<ContentCopy />}
              style={{ marginLeft: '5px' }}
            >
              Copy
            </Button>
          )}
          buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
          data={{ id: item.id, companyId: item.companyId, status: 'OPEN', isNumberAuto: true, date: new Date() }}
          onCreate={() => window.location.reload()}
        />
        <Confirm
          button={(
            <Button
              color="error"
              startIcon={<DeleteOutline />}
              style={{ marginLeft: '5px' }}
            >
              Void
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
          title="Confirm void?"
          content="Are you sure you want to void this invoice?"
          onConfirm={() => updateInvoice(item.id, { status: 'VOID' }).then(() => window.location.reload())}
        />
      </Box>
    </>
  ) : (
    <Button style={{ flexGrow: 1 }}>
      No item selected
    </Button>
  )
}

export default Actions