import { useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { ArrowRight } from '@mui/icons-material'
import { useAsyncFunc } from '../functions'
import Dropdown from './Dropdown'

function Paginate({ query, limit, label, ...props }) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [delta, setDelta] = useState(10)

  useAsyncFunc(async () => {
    if (query) {
      const results = await query()
      return results.length === 0
    }
    return () => false
  }, null, setIsDisabled, [query])

  return (
    <Button
      {...props}
      onClick={() => props.onClick(delta)}
      endIcon={(
        <Box onClick={e => e.stopPropagation()}>
          <Dropdown
            button={(
              <ArrowRight fontSize="small" />
            )}
            buttonStyle={{ margin: '0 -3px -5px -5px' }}
            items={[
              { label: '10', value: 10 },
              { label: '50', value: 50 },
              { label: '100', value: 100 },
              { label: '500', value: 500 },
              { label: '1000', value: 1000 },
            ]}
            onChange={({ value }) => {
              setDelta(value)
              props.onClick(value)
            }}
          />
        </Box>
      )}
      disabled={isDisabled}
    >
      {label}
    </Button>
  )
}

export default Paginate