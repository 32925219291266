import { useState, useEffect, useRef } from 'react'
import { Box, TextField, Button, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack, Chip, Tooltip } from '@mui/material'
import { DeleteOutline, ArrowBack, ArrowForward, PrintOutlined, PersonOutline, LocalOfferOutlined } from '@mui/icons-material'
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab'
import { useReactToPrint } from 'react-to-print'
import Autocomplete from '@mui/material/Autocomplete'
import { get, isEqual, range } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, getFile, updateFileRef, getFileRefCategories, getUser } from '../functions'
import UserName from './User/UserName'
import Accordion from './Accordion'
import Confirm from './Confirm'
import File from './File'
import RefInventory from './RefInventory'
import Timeline from './Timeline'

function UpdateFileRef({ button, buttonStyle, forceOpen, setForceOpen, fileRef, fileRefs, startingIndex, onUpdated, onRemoved, forcePrint }) {
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState()
  const [updatedFileRef, setUpdatedFileRef] = useState({})
  const [fileRefCategories, setFileRefCategories] = useState([])
  const [isPrintReady, setIsPrintReady] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(startingIndex)

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  const { id, name, refFromType, fileId, createdByUserId, createdAt } = fileRefs ? fileRefs[currentIndex] : fileRef

  const remove = () => updateFileRef(id, { status: 'REMOVED', removedAt: new Date() })
    .then(() => {
      enqueueSnackbar('File removed successfully.', { variant: 'success' })
      if (onRemoved) onRemoved()
      handleClose()
    })

  const submit = () => updateFileRef(id, { name: updatedFileRef.name, categories: updatedFileRef.categories })
    .then(ref => {
      enqueueSnackbar('File updated successfully.', { variant: 'success' })
      if (onUpdated) onUpdated(ref)
      handleClose()
    })

  const content = (
    <Box style={{ display: 'flex' }}>
      <Box ref={contentRef} key={get(file, 'url')} style={{ flexGrow: 1, minHeight: '60vh' }}>
        <File fileId={fileId} onRendered={() => setIsPrintReady(true)} />
      </Box>
      <Box style={{ minWidth: '300px', paddingLeft: '20px', borderLeft: '1px solid #ccc' }}>
        <Box style={{ position: 'sticky', top: '5px' }}>
          <Accordion
            title="Details"
            content={(
              <>
                <Stack direction="row" sx={{ marginBottom: '10px', flexWrap: 'wrap', gap: .5 }}>
                  <Chip icon={<LocalOfferOutlined fontSize="small" />} size="small" label={refFromType} />
                </Stack>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Name"
                  fullWidth
                  value={updatedFileRef.name || name}
                  onChange={({ target: { value } }) => setUpdatedFileRef({ ...updatedFileRef, name: value })}
                  style={{ marginBottom: '10px' }}
                />
                <Autocomplete
                  value={get(updatedFileRef, 'categories', [])}
                  options={fileRefCategories}
                  onChange={(event, newValue) => setUpdatedFileRef({ ...updatedFileRef, categories: newValue })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Categories"
                      style={{ marginBottom: '10px' }}
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  multiple
                  disablePortal
                />
                <Button
                  variant="contained"
                  disableElevation
                  onClick={submit}
                  disabled={isEqual(fileRefs ? fileRefs[currentIndex] : fileRef, updatedFileRef)}
                >
                  Update & Close
                </Button>
                <Confirm
                  button={(
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteOutline />}
                      disableElevation
                    >
                      Remove
                    </Button>
                  )}
                  buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
                  title="Confirm remove"
                  content="Are you sure you want to remove this file?"
                  onConfirm={remove}
                  confirmButton={confirm => (
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<DeleteOutline />}
                      disableElevation
                      onClick={confirm}
                    >
                      Remove
                    </Button>
                  )}
                />
              </>
            )}
            defaultExpanded
          />
          <Accordion
            title="Usage"
            content={<RefInventory fileId={fileId} />}
          />
          <Accordion
            title="History"
            content={(
              <Timeline
                items={[
                  { label: (
                    <>
                      <UserName userId={createdByUserId} prefix={<PersonOutline fontSize="small" style={{ marginRight: '5px', verticalAlign: 'sub' }} />} />
                      <Typography>{`Referenced on ${createdAt.toLocaleDateString()}`}</Typography>
                    </>
                  )},
                  ...file ? [
                    { label: (
                      <>
                        <UserName userId={file.createdByUserId} prefix={<PersonOutline fontSize="small" style={{ marginRight: '5px', verticalAlign: 'sub' }} />} />
                        <Typography>{`Uploaded on ${file.createdAt.toLocaleDateString()}`}</Typography>
                      </>
                    )},
                  ] : [],
                ]}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  )

  useAsyncFunc(getFileRefCategories, null, setFileRefCategories, [])

  useEffect(() => {
    getFile(fileId).then(setFile)
  }, [fileId])

  useEffect(() => {
    setUpdatedFileRef(fileRefs ? fileRefs[currentIndex] : fileRef)
  }, [fileRef, fileRefs, currentIndex])

  useEffect(() => {
    if (isOpen && isPrintReady && forcePrint) setTimeout(() => print(), 500)
  }, [isOpen, isPrintReady, forcePrint])

  return (button || setForceOpen) ? (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={handleClose}
        style={{ zIndex: 1400 }}
      >
        <DialogTitle>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Box style={{ flexGrow: 1 }}>
              Update reference
            </Box>
            <Button
              startIcon={<PrintOutlined />}
              onClick={print}
            >
              Print
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        {fileRefs && (
          <DialogActions>
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              disableElevation
              onClick={() => currentIndex > 0 && setCurrentIndex(currentIndex - 1)}
              disabled={currentIndex === 0}
            >
              Prev
            </Button>
            <Button
              variant="outlined"
              endIcon={<ArrowForward />}
              disableElevation
              onClick={() => currentIndex < fileRefs.length - 1 && setCurrentIndex(currentIndex + 1)}
              disabled={currentIndex === fileRefs.length - 1}
            >
              Next
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  ) : content
}

export default UpdateFileRef