import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { OpenInNew, DownloadForOfflineOutlined } from '@mui/icons-material'
import { get } from 'lodash'
import Toggles from '../Toggles'
import Paginate from '../Paginate'
import { useAsyncFunc, getDateDiffStr } from '../../functions'
import { findPartOrders } from '../Orders/functions'
import OrderNumber from '../Orders/OrderNumber'

function PartOrders({ partId }) {
  const [partOrders, setPartOrders] = useState([])
  const [partOrderStatusIn, setPartOrderStatusIn] = useState(['All'])
  const [limit, setLimit] = useState(10)

  useAsyncFunc(async () => {
    if (partId) {
      return findPartOrders({ partId, limit, orderBy: ['createdAt','desc'] })
    }
    return () => []
  }, null, setPartOrders, [partId])

  return (
    <Box style={{ minHeight: '250px', margin: '0 5px 15px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order date</TableCell>
              <TableCell>Order number</TableCell>
              <TableCell>Ship to</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partOrders.map(({ id, orderId, number, poNumber, quantity, status, createdAt, updatedAt }) => (
              <TableRow key={id}>
                <TableCell>{createdAt.toLocaleDateString()}</TableCell>
                <TableCell>
                  <OrderNumber partOrderId={id} />
                  <Tooltip title="Open In New Window">
                    <Link to={`/orders/${orderId}`} target="_blank" rel="noopener noreferrer">
                      <IconButton edge="end" size="small">
                        <OpenInNew fontSize="small" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>{quantity}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="outlined"
                  >
                    {status}
                  </Button>
                </TableCell>
                <TableCell>{getDateDiffStr(null, updatedAt)}</TableCell>
              </TableRow>
            ))}
            {partOrders && (
              <TableRow>
                <TableCell colSpan={11} style={{ textAlign: 'center' }}>
                  <Paginate
                    label="Load more"
                    size="small"
                    variant="outlined"
                    startIcon={<DownloadForOfflineOutlined />}
                    disableElevation
                    onClick={() => setLimit(limit + 10)}
                    query={() => findPartOrders({
                      partId,
                      statusIn: partOrderStatusIn,
                      limit: 1,
                      startAfter: get(partOrders[partOrders.length - 1], '_snap'),
                    })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PartOrders