import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, signInWithEmailAndPassword } from 'firebase/auth'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Search, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { addErrorProps } from '../../functions'
import { sendSignInEmail } from '.'

function Request() {
  const [view, setView] = useState('LINK')
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    if (!email) errors.email = 'Required'
    if (view === 'PASSWORD' && !password) errors.password = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return
    if (view === 'PASSWORD') {
      return signInWithEmailAndPassword(getAuth(), email, password)
    }
    return sendSignInEmail(email, location.state?.from?.pathname || '/')
      .then(() => {
        enqueueSnackbar('Please check your email for sign-in link.', { variant: 'success' })
      })
  }

  useEffect(() => {
    const url = window.location.href
    if (isSignInWithEmailLink(getAuth(), url)) {
      const email = window.localStorage.getItem('email')
      signInWithEmailLink(getAuth(), email, url).then(() => {
        window.localStorage.removeItem('email')
        navigate(location.state?.from?.pathname || '/', { replace: true })
      })
    }
  }, [])

  return (
    <Box>
      <TextField
        size="small"
        variant="outlined"
        label="Email"
        fullWidth
        style={{ marginBottom: '10px' }}
        value={email || ''}
        onChange={({ target: { value } }) => setEmail(value)}
        {...addErrorProps(errors, 'email')}
      />
      {view === 'PASSWORD' && (
        <TextField
          size="small"
          variant="outlined"
          label="Password"
          type="password"
          fullWidth
          style={{ marginBottom: '10px' }}
          value={password || ''}
          onChange={({ target: { value } }) => setPassword(value)}
          {...addErrorProps(errors, 'password')}
        />
      )}
      {view === 'LINK' && (
        <>
          <Button
            variant="contained"
            onClick={submit}
            disableElevation
          >
            Get Sign In Link
          </Button>
          <Button
            onClick={() => setView('PASSWORD')}
          >
            Have a password?
          </Button>
        </>
      )}
      {view === 'PASSWORD' && (
        <>
          <Button
            variant="contained"
            onClick={submit}
            disableElevation
          >
            Sign In
          </Button>
          <Button
            onClick={() => setView('LINK')}
          >
            Request a link instead?
          </Button>
        </>
      )}
    </Box>
  )
}

export default Request
