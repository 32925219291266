import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAfter, endBefore, limitToLast, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith } from '../../functions'

export const getDevice = id => get('devices', id)

export const addDevice = data => add('devices', data)

export const updateDevice = (id, updates) => update('devices', id, updates, 'deviceLogs')

export const updateDeviceUsage = (id, usage) => get('devices', id).then(device => updateDevice(id, {
  countOfUseSinceCreated: get(device, 'countOfUseSinceCreated', 0) + usage,
  countOfUseSinceCalibrated: get(device, 'countOfUseSinceCalibrated', 0) + usage,
}))

export const findDevices = async ({ companyId, number, numberBeginsWith, numberSerial, name, nameBeginsWith, type, typeIn, createdAtFrom, createdAtTo, updatedAtFrom, updatedAtTo, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'devices'), ...[
    where('companyId', '==', companyId),
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    ...numberSerial ? [where('numberSerial', '==', numberSerial)] : [],
    ...name ? [where('name', '==', name)]
    : nameBeginsWith ? beginsWith('name', nameBeginsWith)
    : [],
    ...type ? [where('type', '==', type)]
    : typeIn ? [where('type', 'in', typeIn)]
    : [],
    ...createdAtFrom ? [where('createdAt', '>=', createdAtFrom)] : [],
    ...createdAtTo ? [where('createdAt', '<', createdAtTo)] : [],
    ...updatedAtFrom ? [where('updatedAt', '>=', updatedAtFrom)] : [],
    ...updatedAtTo ? [where('updatedAt', '<', updatedAtTo)] : [],
    ...(!createdAtFrom && !createdAtTo && !updatedAtFrom && !updatedAtTo) ? [orderBy(...order)] : [],
    limit(max || await getGlobal('devicesQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)

export const validateDeviceNumber = (number, numberSerial, companyId) => findDevices({ companyId, number, numberSerial, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)