import { useState, useEffect } from 'react'
import { Box, Typography, TableRow, TableCell, Button, IconButton, Tooltip, Drawer, Avatar, Stack, Alert } from '@mui/material'
import { DownloadForOfflineOutlined, ArrowDropDown, ArrowDropUp, ChatOutlined, ChatBubbleOutline, MarkUnreadChatAltOutlined } from '@mui/icons-material'
import { get, difference, isEqual, compact, sortBy } from 'lodash'
import { useAsyncFunc, findLogs } from '../functions'
import UserAvatar from './User/UserAvatar'
import UserName from './User/UserName'
import Paginate from './Paginate'

function ChangeLogs({ companyId, button, buttonStyle, forceOpen, setForceOpen, beforeChanges, afterChanges, col, id, field }) {
  const [isLogOpen, setIsLogOpen] = useState(false)
  const [logs, setLogs] = useState([])
  const [limit, setLimit] = useState(5)

  const handleClose = () => {
    setIsLogOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isLogOpen !== forceOpen) setIsLogOpen(forceOpen)
  }, [forceOpen, isLogOpen])

  useAsyncFunc(findLogs, { col, id, field, orderBy: ['createdAt','desc'], limit }, setLogs, [col, id, isLogOpen, limit])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsLogOpen(true)}>
          {button}
        </Box>
      )}
      <Drawer
        anchor="right"
        open={isLogOpen}
        onClose={handleClose}
        style={{ zIndex: 1400 }}
      >
        <Stack>
          <Alert
            severity="info"
          >
            {logs.length === 0 ? `No changes made yet.` : `Most recent changes made ${logs[0].createdAt.toLocaleString()}.`}
          </Alert>
        </Stack>
        <Box style={{ maxWidth: '600px', padding: '15px' }}>
          {logs.map(log => {
            const { id, before, after, createdByUserId } = log
            const { createdAt, updatedAt, ...beforeData } = before
            const { createdAt: _createdAt, updatedAt: _updatedAt, ...afterData } = after
            const beforeFields = Object.keys(beforeData)
            const afterFields = Object.keys(afterData)
            const added = difference(afterFields, beforeFields).map(field => ({ [field]: afterData[field] }))
            const removed = difference(beforeFields, afterFields).map(field => ({ [field]: beforeData[field] }))
            const updated = compact(beforeFields.map(field => {
              const before = beforeData[field]
              const after = afterData[field]
              if (isEqual(before, after)) return null
              return { field, before, after }
            }))
            return (
              <Box key={id} style={{ marginBottom: '30px' }}>
                <Box style={{ display: 'flex', gap: '15px' }}>
                  <UserAvatar userId={createdByUserId} />
                  <Box>
                    <Box>
                      <Typography variant="body2">
                        <UserName userId={createdByUserId} />
                        {` updated`}
                      </Typography>
                      <Typography variant="body2" color="GrayText">{_updatedAt.toLocaleString()}</Typography>
                    </Box>
                    {beforeChanges && beforeChanges(log)}
                    {sortBy(updated, ({ field }) => field).map(({ field, before, after }) => (
                      <Box key={`${id}-${field}`}>
                        <Typography variant="overline" display="block" color="GrayText">{field}</Typography>
                        <Typography variant="body2" display="block">
                          {before instanceof Date ? before.toLocaleDateString() : before} → {after instanceof Date ? after.toLocaleDateString() : after}
                        </Typography>
                      </Box>
                    ))}
                    {afterChanges && afterChanges(log)}
                  </Box>
                </Box>
              </Box>
            )
          })}
          <Paginate
            label="Load more"
            size="small"
            variant="outlined"
            startIcon={<DownloadForOfflineOutlined />}
            disableElevation
            onClick={() => setLimit(limit + 5)}
            query={() => findLogs({
              col,
              id,
              field,
              orderBy: ['createdAt','desc'],
              limit,
              startAfter: get(logs[logs.length - 1], '_snap'),
            })}
            fullWidth
          />
        </Box>
      </Drawer>
    </>
  )
}

export default ChangeLogs