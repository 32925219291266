import { useState, useEffect, useRef } from 'react'
import { Box, TextField, Button, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableFooter, TableBody, TableRow, TableCell } from '@mui/material'
import { Print, Download, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear, Visibility } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import FileViewer from 'react-file-viewer'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import NumberFormat from 'react-number-format'
import { useReactToPrint } from 'react-to-print'
import QRCode from 'react-qr-code'
import { get, isEqual } from 'lodash'
import { useAsyncFunc, getCompany } from '../../functions'
import Checkbox from '../Checkbox'
import UserName from '../User/UserName'
import { getQuote, findPartQuotes } from './functions'
import PartQuoteRow from './PartQuoteRow'

function Preview({ button, buttonStyle, quoteId, forcePrint }) {
  const [isOpen, setIsOpen] = useState(false)
  const [quote, setQuote] = useState()
  const [partQuotes, setPartQuotes] = useState()
  const [company, setCompany] = useState()
  const [includeChangelog, setIncludeChangelog] = useState(false)
  const [isQRIncluded, setIsQRIncluded] = useState(false)
  const [isRevIncluded, setIsRevIncluded] = useState(false)
  const [isShipViaIncluded, setIsShipViaIncluded] = useState(false)
  const [isTermIncluded, setIsTermIncluded] = useState(false)
  const [isFootnoteIncluded, setIsFootnoteIncluded] = useState(true)
  const [isShipToAttnIncluded, setShipToAttnIncluded] = useState(false)

  useAsyncFunc(getQuote, quoteId, setQuote, [quoteId, isOpen])
  useAsyncFunc(findPartQuotes, { quoteId, orderBy: ['updatedAt','desc'] }, setPartQuotes, [quoteId, isOpen])

  useEffect(() => {
    if (quote) getCompany(quote.companyId).then(setCompany)
  }, [quote])

  useEffect(() => {
    if (isOpen && quote && partQuotes && forcePrint) setTimeout(() => print(), 500)
  }, [isOpen, quote, partQuotes, forcePrint])

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  const content = (
    <Box ref={contentRef} style={{ display: 'flex', flexDirection: 'column', gap: '10px', transform: 'scale(.9)' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <Box style={{ display: 'flex', gap: '10px', paddingBottom: '20px' }}>
              <Box style={{ width: '50%', display: 'flex' }}>
                <Box style={{ width: '160px' }}>
                  <img alt="logo" src="https://i.imgur.com/CltCBmd.png" width="100%" />
                </Box>
                <Box style={{ flexGrow: 1, padding: '0 10px 5px' }}>
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {get(company, 'address')}
                  </Typography>
                  <Typography variant="body2">
                    {`Phone: `}
                    <NumberFormat
                      format="(###) ###-####"
                      value={get(company, 'phone')}
                      displayType="text"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box style={{ width: '50%' }}>
                <Box style={{ border: '1px solid #ccc' }}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead sx={{ background: '#efefef' }}>
                        <TableRow>
                          <TableCell>Quote Number</TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {get(quote, 'number')}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Reference</TableCell>
                          <TableCell>{get(quote, 'numberRef')}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>By</TableCell>
                          <TableCell>
                            <UserName userId={get(quote, 'createdByUserId')} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Quote Date</TableCell>
                          <TableCell>{get(quote, 'date') ? quote.date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) : ''}</TableCell>
                        </TableRow>
                        {isRevIncluded && (
                          <TableRow>
                            <TableCell>Revision</TableCell>
                            <TableCell>0</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </TableHead>
          <TableFooter>
            <Box style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px', flexGrow: 1, minHeight: '120px', padding: '0 10px 5px' }}>
                {isFootnoteIncluded && (
                  <Box>
                    <Typography variant="overline">
                      Footnote
                    </Typography>
                    <Typography variant="body2">
                      {get(quote, 'footnote') || get(company, 'quoteFootnote')}
                    </Typography>
                  </Box>
                )}
                {get(quote, 'disclaimer') && (
                  <Typography variant="body2">
                    {quote.disclaimer}
                  </Typography>
                )}
              </Box>
              <Box>
                {isQRIncluded && <QRCode size={64} value={`edimfg.com/quotes/${quoteId}`} />}
              </Box>
            </Box>
          </TableFooter>
          <TableBody>
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ width: '50%', height: '120px', padding: '0 10px 5px' }}>
                <Typography variant="overline">
                  Ship To
                </Typography>
                {isShipToAttnIncluded && get(quote, 'shipToAttn') && (
                  <Typography>
                    {`ATTN: ${get(quote, 'shipToAttn')}`}
                  </Typography>
                )}
                <Typography>
                  {get(quote, 'shipToName')}
                </Typography>
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {get(quote, 'shipToAddress')}
                </Typography>
              </Box>
              <Box style={{ width: '50%', height: '120px', padding: '0 10px 5px' }}>
                <Typography variant="overline">
                  Quote To
                </Typography>
                {get(quote, 'billToAttn') && (
                  <Typography>
                    {`ATTN: ${get(quote, 'billToAttn')}`}
                  </Typography>
                )}
                <Typography>
                  {get(quote, 'billToName')}
                </Typography>
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {get(quote, 'billToAddress')}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ width: '50%', height: '70px', padding: '0 10px 5px' }}>
                <Typography variant="overline">
                  Notes
                </Typography>
                <Typography variant="body2">
                  {get(quote, 'customerNote')}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', width: '50%', height: '70px', padding: '0 10px 5px' }}>
                <Box style={{ width: '30%' }}>
                  <Typography variant="overline">
                    Expiration Date
                  </Typography>
                  <Typography variant="body2">
                    {get(quote, 'date') ? quote.date.toLocaleDateString() : ''}
                  </Typography>
                </Box>
                <Box style={{ width: '30%' }}>
                  {isShipViaIncluded && (
                    <>
                      <Typography variant="overline">
                        Ship Via
                      </Typography>
                      <Typography variant="body2">
                        {get(quote, 'shipVia')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box style={{ width: '40%' }}>
                  {isTermIncluded && (
                    <>
                      <Typography variant="overline">
                        Terms
                      </Typography>
                      <Typography variant="body2">
                        {get(quote, 'terms')}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box style={{ minHeight: '450px', border: '1px solid #ccc' }}>
              <TableContainer>
                <Table size="small">
                  <TableHead sx={{ background: '#efefef' }}>
                    <TableRow>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell align="right">Unit Price</TableCell>
                      <TableCell align="right">Extended Price</TableCell>
                      <TableCell align="right">Lead Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(partQuotes || []).map(partQuote => (
                      <PartQuoteRow key={partQuote.id} {...partQuote} readOnly />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )

  return button ? (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{ zIndex: 1400 }}
      >
        <DialogTitle style={{ borderBottom: '1px solid #e6e6e6' }}>Preview</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px', boxShadow: '0 0 25px rgba(0, 0, 0, .125)', color: '#000' }}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Checkbox
            checked={isShipToAttnIncluded}
            onChange={({ target: { checked } }) => setShipToAttnIncluded(checked)}
            label="Ship To Attn."
          />
          <Checkbox
            checked={isRevIncluded}
            onChange={({ target: { checked } }) => setIsRevIncluded(checked)}
            label="Rev."
          />
          <Checkbox
            checked={isShipViaIncluded}
            onChange={({ target: { checked } }) => setIsShipViaIncluded(checked)}
            label="Ship Via"
          />
          <Checkbox
            checked={isTermIncluded}
            onChange={({ target: { checked } }) => setIsTermIncluded(checked)}
            label="Terms"
          />
          <Checkbox
            checked={isFootnoteIncluded}
            onChange={({ target: { checked } }) => setIsFootnoteIncluded(checked)}
            label="Footnote"
          />
          <Checkbox
            checked={isQRIncluded}
            onChange={({ target: { checked } }) => setIsQRIncluded(checked)}
            label="QR code"
          />
          <Checkbox
            checked={includeChangelog}
            onChange={({ target: { checked } }) => setIncludeChangelog(checked)}
            label="Changelog"
          />
          {/* <Button
            size="small"
            variant="outlined"
            startIcon={<Download />}
            disableElevation
            onClick={() => {}}
          >
            Download
          </Button> */}
          <Button
            size="small"
            variant="contained"
            startIcon={<Print />}
            disableElevation
            onClick={print}
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : content
}

export default Preview