import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc, getUser } from '../../functions'

function UserName({ userId, prefix, suffix }) {
  const [user, setUser] = useState()

  useAsyncFunc(getUser, userId, setUser, [userId])

  return (
    <>
      {prefix}
      {get(user, 'displayName', <Skeleton variant="rectangular" animation="wave" />)}
      {suffix}
    </>
  )
}

export default UserName