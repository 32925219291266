import { useState } from 'react'
import { Box, TextField, IconButton, Tooltip } from '@mui/material'
import { FileCopy } from '@mui/icons-material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Popover from './Popover'

function ShareLink({ button, buttonStyle, url }) {
  const [isCopied, setIsCopied] = useState(false)

  return (
    <Popover
      button={button}
      buttonStyle={buttonStyle}
      content={(
        <Box style={{ padding: '15px' }}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={url}
            disabled
            InputProps={{
              endAdornment: (
                <CopyToClipboard text={url} onCopy={() => setIsCopied(true)} onMouseEnter={() => setIsCopied(false)}>
                  <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                    <IconButton edge="end">
                      <FileCopy fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              ),
            }}
          />
        </Box>
      )}
    />
  )
}

export default ShareLink