import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Tooltip, Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import Timeline from '../Timeline'
import { getPart, findParts } from './functions'

function PartVersions({ partId }) {
  const [part, setPart] = useState()
  const [versions, setVersions] = useState()

  useAsyncFunc(async partId => {
    if (partId) {
      const part = await getPart(partId)
      const { companyId, number } = part
      setPart(part)
      return findParts({ companyId, numberBeginsWith: number, orderBy: ['revision','desc'] })
    }
    return null
  }, partId, setVersions, [partId])

  return versions ? (
    <Timeline
      items={[
        ...versions.map(({ id, revision, createdAt }) => ({ id, label: (
          <Typography key={id}>
            <Tooltip title="Open In New Window">
              <Link to={`/parts/${id}`} target="_blank" rel="noopener noreferrer">
                {`Rev.${revision}`}
              </Link>
            </Tooltip>
            {` added on ${createdAt.toLocaleDateString()}`}
          </Typography>
        )})),
        { isEnd: true, label: `P/N ${part.number} added on ${part.createdAt.toLocaleDateString()}` },
      ]}
    />
  ) : <Skeleton variant="rectangular" animation="wave" />
}

export default PartVersions