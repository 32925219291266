import { useState, useEffect } from 'react'
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { addErrorProps, partTypes } from '../../functions'
import Radio from '../Radio'
import { addPart, validatePartNumber } from './functions'

function CreatePart({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newPart, setNewPart] = useState()
  const [errors, setErrors] = useState({})

  const validate = async () => {
    const errors = {}
    if (!get(newPart, 'number')) errors.number = 'Required'
    if (get(newPart, 'number') && !await validatePartNumber(newPart.number, get(newPart, 'revision', '-'), companyId)) errors.number = 'Conflicted'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    return addPart({ ...newPart, revision: newPart.revision || '-', companyId })
      .then(part => {
        handleClose()
        if (onCreate) onCreate(part)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewPart(data)
  }, [data])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>Create new part</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <TextField
              size="small"
              variant="outlined"
              label="Part number"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newPart, 'number', '')}
              onChange={({ target: { value } }) => setNewPart({ ...newPart, number: value })}
              {...addErrorProps(errors, 'number')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              label="Revision"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newPart, 'revision', '')}
              onChange={({ target: { value } }) => setNewPart({ ...newPart, revision: value })}
              {...addErrorProps(errors, 'revision')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              label="Name"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newPart, 'name', '')}
              onChange={({ target: { value } }) => setNewPart({ ...newPart, name: value })}
              {...addErrorProps(errors, 'name')}
              required
            />
            <Radio
              options={partTypes}
              value={get(newPart, 'type')}
              onChange={({ target: { value } }) => setNewPart({ ...newPart, type: value })}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Description"
              multiline
              rows={3}
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newPart, 'description', '')}
              onChange={({ target: { value } }) => setNewPart({ ...newPart, description: value })}
              {...addErrorProps(errors, 'description')}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreatePart