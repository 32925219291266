import { useState } from 'react'
import { Avatar } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc, getUser } from '../../functions'

function UserAvatar({ userId }) {
  const [user, setUser] = useState()

  useAsyncFunc(getUser, userId, setUser, [userId])

  return (
    <Avatar style={{ width: '36px', height: '36px', background: '#1976d2', fontSize: '1rem' }}>
      {get(user, 'displayName') ? user.displayName.split(' ').map(str  => str.substring(0, 1)).join('').substring(0, 2).toUpperCase() : null}
    </Avatar>
  )
}

export default UserAvatar