import { useState } from 'react'
import { Box, TextField, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { get } from 'lodash'
import Toggles from '../Toggles'
import { useAsyncFunc, getDateDiffStr, jobStatuses } from '../../functions'
import { findJobs, findJobLines } from '../Jobs/functions'
import JobLines from '../Jobs/JobLines'

function PartTraveler({ companyId, partId }) {
  const [partJobs, setPartJobs] = useState()
  const [selectedJob, setSelectedJob] = useState()
  const [partJobLines, setPartJobLines] = useState()
  const [partJobStatusIn, setPartJobStatusIn] = useState(['All'])

  useAsyncFunc(async () => {
    if (partId) {
      const jobs = await findJobs({ companyId, partId, orderBy: ['createdAt','desc'], ...!partJobStatusIn.includes('All') && { statusIn: partJobStatusIn } })
      setSelectedJob(jobs[0])
      return jobs
    }
    return () => []
  }, null, setPartJobs, [companyId, partId, partJobStatusIn])

  useAsyncFunc(async () => {
    if (selectedJob) {
      return findJobLines({ jobId: selectedJob.id, orderBy: ['number','asc'] })
    }
    return () => []
  }, null, setPartJobLines, [selectedJob])

  return (
    <Box>
      <Autocomplete
        value={selectedJob || ''}
        options={(partJobs || [])}
        onChange={(event, newValue) => setSelectedJob(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            style={{ marginBottom: '10px' }}
            fullWidth
          />
        )}
        getOptionLabel={option => option && `J/N ${option.number} created on ${option.createdAt.toLocaleDateString()}`}
      />
      {selectedJob && (
        <JobLines
          companyId={companyId}
          jobId={selectedJob.id}
          partId={partId}
          quantity={selectedJob.quantity}
          onUpdate={() => {}}
        />
      )}
    </Box>
  )
}

export default PartTraveler