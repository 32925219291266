import { useState, useEffect } from 'react'
import { Box, TextField, Button, ListItem, ListItemIcon, ListItemText, Stack, Chip } from '@mui/material'
import { Architecture, DataUsage, Check } from '@mui/icons-material'
import { Sankey } from 'recharts'
import { get, sumBy } from 'lodash'
import { useAsyncFunc, getDateDiffStr, positiveInt } from '../../functions'
import Popover from '../Popover'
import PartNumber from '../Parts/PartNumber'

function JobWithConnections({ connectionsQuery, connectionNode, nodePadding, onConnect, ...job }) {
  const [connections, setConnections] = useState([])
  const { id, number, partId, quantity, poNumber, lineNumber, shipAt, updatedAt } = job

  const [newConnection, setNewConnection] = useState()

  useAsyncFunc(connectionsQuery, { jobId: id, orderBy: ['updatedAt','asc'] }, setConnections, [id])

  const fetch = () => connectionsQuery({ jobId: id, orderBy: ['updatedAt','asc'] }).then(setConnections)

  const unallocatedCount = quantity - sumBy(connections, ({ quantity }) => quantity)

  useEffect(() => {
    setNewConnection(newConnection => ({ ...newConnection, quantity: unallocatedCount }))
  }, [unallocatedCount])

  return (
    <ListItem
      secondaryAction={(
        <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {unallocatedCount > 0 && (
            <Stack direction="row" spacing={1}>
              <Popover
                button={(
                  <Chip
                    color="primary"
                    variant="outlined"
                    icon={<DataUsage fontSize="small" style={{ marginLeft: '8px' }} />}
                    label="Allocate"
                  />
                )}
                content={(
                  <Box style={{ padding: '15px' }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Quantity"
                      fullWidth
                      value={get(newConnection, 'quantity', 0)}
                      onChange={({ target: { value } }) => setNewConnection({ quantity: positiveInt(value) })}
                    />
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<Check />}
                      style={{ marginTop: '5px' }}
                      disableElevation
                      onClick={() => onConnect({ ...newConnection, jobId: id, status: 'ACTIVE' }).then(fetch)}
                    >
                      Confirm
                    </Button>
                  </Box>
                )}
              />
            </Stack>
          )}
          <Sankey
            width={450}
            height={110}
            margin={{
              top: 20,
              bottom: 20,
              right: 180,
            }}
            node={connectionNode}
            {...nodePadding && { nodePadding }}
            link={{ stroke: '#808080' }}
            data={{
              nodes: [
                { type: 'TOTAL' },
                ...connections.map(connection => ({ connection })),
                ...unallocatedCount > 0 ? [{ type: 'UNALLOCATED' }] : [],
              ],
              links: [
                ...connections.map(({ quantity }, index) => ({
                  source: 0,
                  target: index + 1,
                  value: quantity,
                })),
                ...unallocatedCount > 0 ? [{
                  source: 0,
                  target: connections.length + 1,
                  value: unallocatedCount,
                }] : [],
              ],
            }}
          />
        </Box>
      )}
    >
      <ListItemIcon>
        <Architecture />
      </ListItemIcon>
      <ListItemText
        primary={`J/N ${number} · Ships ${shipAt.toLocaleDateString()}`}
        secondary={(
          <>
            {`P/N: `}
            <PartNumber partId={partId} />
            {` · Qty: ${quantity} · ${poNumber ? `PO ${poNumber} Line ${lineNumber}` : ''} · Updated ${getDateDiffStr(null, updatedAt)}`}
          </>
        )}
      />
    </ListItem>
  )
}

export default JobWithConnections