import { useState, useEffect } from 'react'
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { get } from 'lodash'
import { addErrorProps, addCompanyRef } from '../../functions'
import Radio from '../Radio'

function CreateAssignee({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newAssignee, setNewAssignee] = useState()
  const [errors, setErrors] = useState({})

  const validate = async () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    return addCompanyRef({ ...newAssignee, companyId, status: 'ACTIVE' })
    .then(companyRef => {
      handleClose()
      if (onCreate) onCreate(companyRef)
    })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewAssignee(data)
  }, [data])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>Create new assignee</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <TextField
              size="small"
              variant="outlined"
              label="Name"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newAssignee, 'name', '')}
              onChange={({ target: { value } }) => setNewAssignee({ ...newAssignee, name: value })}
              {...addErrorProps(errors, 'name')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              label="Contact name"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newAssignee, 'contactName', '')}
              onChange={({ target: { value } }) => setNewAssignee({ ...newAssignee, contactName: value })}
              {...addErrorProps(errors, 'contactName')}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Contact email"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newAssignee, 'contactEmail', '')}
              onChange={({ target: { value } }) => setNewAssignee({ ...newAssignee, contactEmail: value })}
              {...addErrorProps(errors, 'contactEmail')}
            />
            <Radio
              options={[
                { label: 'Customer', value: 'CUSTOMER' },
                { label: 'Vendor', value: 'VENDOR' },
              ]}
              onChange={({ target: { value } }) => setNewAssignee({ ...newAssignee, role: value })}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Description"
              multiline
              rows={3}
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newAssignee, 'description', '')}
              onChange={({ target: { value } }) => setNewAssignee({ ...newAssignee, description: value })}
              {...addErrorProps(errors, 'description')}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateAssignee