import { useState, useEffect } from 'react'
import { Box, TextField, Button, Tabs, Tab, IconButton, Typography, Skeleton, Stack, Alert, AlertTitle, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Add } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt } from '../../functions'
import Accordion from '../Accordion'
import { findJobs, findJobAllocations, getJob } from '../Jobs/functions'
import NewJobRow from '../Jobs/NewJobRow'
import { findOrders, findPartOrders, getOrder, getPartOrder, addPartOrder } from './functions'
import CreateOrder from './Create'
import EditOrderDetails from './EditDetails'
import LookupRow from './LookupRow'

function OrderLookup({ companyId, button, buttonStyle, inline, forceOpen, setForceOpen, query: search, partOrderId }) {
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState(search)
  const [view, setView] = useState('JOBS')
  const [order, setOrder] = useState()
  const [partOrders, setPartOrders] = useState()
  const [jobs, setJobs] = useState()
  const [isAddingJob, setIsAddingJob] = useState(false)
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  useAsyncFunc(async partOrderId => {
    if (partOrderId) {
      const { orderId } = await getPartOrder(partOrderId)
      const order = await getOrder(orderId)
      setOrder(order)
      setQuery(order.numberRef)
    }
    return
  }, partOrderId, () => {}, [partOrderId])

  const fetchJobs = async () => {
    const [order] = query ? await findOrders({ companyId, numberRef: query, orderBy: ['createdAt','desc'], limit: 1 }) : []
    setOrder(order)
    if (order) {
      const partOrders = await findPartOrders({ orderId: order.id, orderBy: ['lineNumber','asc'] })
      const jobs = await Promise.all((await findJobAllocations({ partOrderIdIn: partOrders.map(({ id }) => id), orderBy: ['createdAt','desc'] })).map(async ({ jobId }) => getJob(jobId)))
      setPartOrders(partOrders)
      setJobs(jobs)
    } else {
      setPartOrders()
      setJobs()
    }
  }

  useAsyncFunc(fetchJobs, null, () => {}, [query])

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return
  }

  const content = (
    <>
      <TextField
        variant="outlined"
        name="numberRef"
        label="Order number lookup"
        fullWidth
        value={query}
        onChange={({ target: { value } }) => setQuery(value)}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'numberRef')}
      />
      {query && !order && (
        <Alert
          severity="warning"
          action={(
            <>
              <CreateOrder
                companyId={companyId}
                button={(
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    startIcon={<Add />}
                    style={{ marginLeft: '5px' }}
                    disableElevation
                    disabled={isAddingJob}
                  >
                    Create Order
                  </Button>
                )}
                data={{ companyId, numberRef: query, status: 'OPEN', isNumberAuto: true, date: new Date() }}
                onCreate={setOrder}
                jobs={jobs}
              />
            </>
          )}
          style={{ marginBottom: '10px' }}
        >
          <AlertTitle>
            Order not in system
          </AlertTitle>
          Create order to keep track of shipments & invoices.
        </Alert>
      )}
      {query && order && (
        <>
          <Alert
            severity="success"
            style={{ marginBottom: '10px' }}
          >
            <AlertTitle>
              Order tracking enabled
            </AlertTitle>
          </Alert>
          <Accordion
            title="Order Details"
            content={(
              <EditOrderDetails
                order={order}
                onUpdate={setOrder}
              />
            )}
          />
        </>
      )}
      <Tabs value={view} onChange={(event, newValue) => setView(newValue)}>
        <Tab label="Orders" value="ORDERS" />
        <Tab label="Jobs" value="JOBS" />
      </Tabs>
      <Box style={{ minHeight: '300px', padding: '5px', border: '1px solid #ccc' }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Line</TableCell>
                <TableCell colSpan={isAddingJob ? 2 : 1}>Part</TableCell>
                <TableCell>Quantity</TableCell>
                {order && (
                  <>
                    <TableCell align="right">Unit Price</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </>
                )}
                <TableCell align="right">Due Date</TableCell>
                <TableCell align="right">Ship Date</TableCell>
                {order && (
                  <>
                    <TableCell>Shipment</TableCell>
                    <TableCell>Invoice</TableCell>
                  </>
                )}
                {isAddingJob && <TableCell align="right">Last Updated</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs && jobs.length === 0 && <TableRow><TableCell colSpan={6}>No results found.</TableCell></TableRow>}
              {jobs && jobs.map(job => <LookupRow key={job.id} isAddingJob={isAddingJob} isShowingOrderData={order} {...job} />)}
              {query && (
                <NewJobRow
                  companyId={companyId}
                  {...jobs && jobs.length > 0 && { data: {
                    poNumber: query,
                    lineNumber: jobs[jobs.length - 1].lineNumber + 1,
                    partId: jobs[jobs.length - 1].partId,
                  }}}
                  fields={['lineNumber', 'partId:2', 'quantity', ...order ? ['unitPrice','unit'] : [], 'dueAt', 'shipAt', 'confirm:3']}
                  onCreate={async ({ lineNumber, partId, quantity, unit, unitPrice, dueAt }) => {
                    if (order) await addPartOrder({
                      orderId: order.id,
                      lineNumber,
                      partId,
                      quantity,
                      unit,
                      unitPrice,
                      dueAt,
                      status: 'OPEN',
                    })
                    return fetchJobs()
                  }}
                  forceOpen={isAddingJob}
                  setForceOpen={setIsAddingJob}
                  orderExists={order}
                  dense
                />
              )}
              {!query && (
                <TableRow>
                  <TableCell colSpan={6}>Enter an order number.</TableCell>
                </TableRow>
              )}
              {query && !isAddingJob && (
                <TableRow>
                  <TableCell colSpan={order ? 9 : 6}>
                    <Button
                      size="small"
                      variant="outlined"
                      style={{ marginLeft: '-10px' }}
                      onClick={() => setIsAddingJob(true)}
                      startIcon={<Add />}
                    >
                      {`Add ${order ? 'Line Item' : 'Job'}`}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )

  return inline ? content : (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth={isAddingJob ? 'xl' : 'lg'}
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Find orders</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            {content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OrderLookup