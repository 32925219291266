import Dropdown from './Dropdown'

function OrderBy({ button, buttonStyle, options, onChange }) {
  return (
    <Dropdown
      button={button}
      buttonStyle={buttonStyle}
      items={[
        { label: 'Newest → Oldest', value: ['createdAt', 'desc'] },
        { label: 'Oldest → Newest', value: ['createdAt', 'asc'] },
        { label: 'Last Updated', value: ['updatedAt', 'desc'] },
        ...options ? options : [],
      ]}
      onChange={onChange}
    />
  )
}

export default OrderBy