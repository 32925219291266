import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAt, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, includeSearchIndices } from '../../functions'

export const getContact = id => get('contacts', id)

export const addContact = data => add('contacts', includeSearchIndices(data, ['name','email']))

export const updateContact = (id, updates) => update('contacts', id, updates)
  .then(({ _snap, ...data }) => update('contacts', id, includeSearchIndices(data, ['name','email'])))

export const findContacts = async ({ companyId, searchIndicesContain, type, isInternal, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'contacts'), ...[
    where('companyId', '==', companyId),
    ...searchIndicesContain ? [where('searchIndices', 'array-contains-any', [searchIndicesContain])] : [],
    ...type ? [where('type', '==', type)] : [],
    ...isInternal !== undefined ? [where('isInternal', '==', isInternal)] : [],
    orderBy(...order),
    limit(max || await getGlobal('contactsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getQuestion = id => get('questions', id)

export const addQuestion = data => add('questions', includeSearchIndices(data, ['label','instructions']))

export const updateQuestion = (id, updates) => update('questions', id, updates)
  .then(({ _snap, ...data }) => update('questions', id, includeSearchIndices(data, ['label','instructions'])))

export const findQuestions = async ({ companyId, searchIndicesContain, type, status, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'questions'), ...[
    where('companyId', '==', companyId),
    ...searchIndicesContain ? [where('searchIndices', 'array-contains-any', [searchIndicesContain])] : [],
    ...type ? [where('type', '==', type)] : [],
    ...status ? [where('status', '==', status)] : [],
    orderBy(...order),
    limit(max || await getGlobal('questionsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const findSubmissions = async ({ companyId, forContactId, status, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'submissions'), ...[
    where('companyId', '==', companyId),
    where('forContactId', '==', forContactId),
    ...status ? [where('status', '==', status)] : [],
    orderBy(...order),
    limit(max || await getGlobal('submissionsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getSubmission = id => get('submissions', id)

export const addSubmission = data => add('submissions', data)