import { useState, useEffect } from 'react'
import { TextField, IconButton, Button, List } from '@mui/material'
import { Search, Add, Sort } from '@mui/icons-material'
import { get } from 'lodash'
import { getDate, useAsyncFunc } from '../../functions'
import OrderBy from '../OrderBy'
import { findQuotes } from './functions'
import CreateQuote from './Create'
import QuoteResult from './QuoteResult'

function SearchQuotes({ companyId, selected, onSelect }) {
  const [quotes, setQuotes] = useState([])
  const [query, setQuery] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])

  useAsyncFunc(async args => {
    if (companyId) return findQuotes(args)
    return () => []
  }, { companyId, orderBy, ...query && { numberBeginsWith: query } }, setQuotes, [companyId, query, orderBy])

  const fetch = () => findQuotes({ companyId, orderBy }).then(setQuotes)

  useEffect(() => {
    const match = quotes.find(({ id }) => id === get(selected, 'id'))
    if (match && match.updatedAt < selected.updatedAt) {
      setQuotes(quotes.map(q => q.id !== match.id ? q : selected))
    }
  }, [quotes, selected])

  return (
    <>
      <TextField
        type="number"
        size="small"
        variant="outlined"
        placeholder="Search quotes..."
        fullWidth
        onChange={({ target: { value } }) => setQuery(value)}
        style={{ marginBottom: '5px' }}
        InputProps={{
          startAdornment: <IconButton edge="start"><Search /></IconButton>,
        }}
      />
      <OrderBy
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Sort />}
          >
            {`Sort by: ${orderBy[0]}`}
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
        onChange={({ value }) => setOrderBy(value)}
      />
      <CreateQuote
        companyId={companyId}
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Add />}
          >
            New Quote
          </Button>
        )}
        buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
        data={{ companyId, status: 'ACTIVE', isNumberAuto: true, date: new Date(), dateExp: getDate(null, '+', 180, 'day') }}
        onCreate={fetch}
      />
      <List>
        {quotes.map(item => (
          <QuoteResult
            key={item.id}
            item={item}
            selected={selected}
            onSelect={onSelect}
          />
        ))}
      </List>
    </>
  )
}

export default SearchQuotes