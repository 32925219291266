import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List } from '@mui/material'
import { Search, Add, Sort, DownloadForOfflineOutlined } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import OrderBy from '../OrderBy'
import Paginate from '../Paginate'
import { findJournalEntries } from './functions'
import CreateJournalEntry from './Create'
import JournalEntryResult from './JournalEntryResult'

function SearchJournalEntries({ companyId, selected, onSelect }) {
  const [journalEntries, setJournalEntries] = useState([])
  const [query, setQuery] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])
  const [limit, setLimit] = useState(10)

  useAsyncFunc(async args => {
    if (companyId) return findJournalEntries(args)
    return () => []
  }, { companyId, orderBy, limit, ...query && { numberRefBeginsWith: query } }, setJournalEntries, [companyId, query, orderBy, limit])

  const fetch = () => findJournalEntries({ companyId, orderBy, limit, ...query && { numberRefBeginsWith: query } }).then(setJournalEntries)

  useEffect(() => {
    const match = journalEntries.find(({ id }) => id === get(selected, 'id'))
    if (match && match.updatedAt < selected.updatedAt) {
      setJournalEntries(journalEntries.map(q => q.id !== match.id ? q : selected))
    }
  }, [journalEntries, selected])

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search entries by reference number..."
        fullWidth
        onChange={({ target: { value } }) => setQuery(value)}
        style={{ marginBottom: '5px' }}
        InputProps={{
          startAdornment: <IconButton edge="start"><Search /></IconButton>,
        }}
      />
      <OrderBy
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Sort />}
          >
            {`Sort by: ${orderBy[0]}`}
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
        onChange={({ value }) => setOrderBy(value)}
      />
      <CreateJournalEntry
        companyId={companyId}
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<Add />}
          >
            New Entry
          </Button>
        )}
        buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
        data={{ companyId, status: 'OPEN', isNumberAuto: true, eventAt: new Date(), type: 'INCIDENT_REPORT' }}
        onCreate={fetch}
      />
      <List>
        {journalEntries.map(item => (
          <JournalEntryResult
            key={item.id}
            item={item}
            selected={selected}
            onSelect={onSelect}
          />
        ))}
        <Paginate
          label="Load more"
          size="small"
          variant="outlined"
          startIcon={<DownloadForOfflineOutlined />}
          disableElevation
          onClick={() => setLimit(limit + 10)}
          query={() => findJournalEntries({
            companyId,
            numberBeginsWith: query,
            limit,
            orderBy: ['updatedAt','desc'],
            ...query && { numberRefBeginsWith: query },
            startAfter: get(journalEntries[journalEntries.length - 1], '_snap'),
          })}
          fullWidth
        />
      </List>
    </>
  )
}

export default SearchJournalEntries