import { useState, createContext, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { signInWithEmailLink, signOut as authSignOut } from 'firebase/auth'
import { Box, Typography, TextField, IconButton, Button, Alert, Card, CardContent, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Skeleton } from '@mui/material'
import { DownloadForOfflineOutlined, EditOutlined, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get, compact } from 'lodash'
import { SnackbarProvider } from 'notistack'
import { useAsyncFunc, getLocal, setLocal, jobRanges as ranges } from '../../functions'
import Dropdown from '../Dropdown'
import Paginate from '../Paginate'
import EditInternalMemo from '../EditInternalMemo'
import EditFootnote from '../EditFootnote'
import { findDevices } from './functions'
import CreateEditDevice from './CreateEdit'
import DeviceFilters from './Filters'
import ImportDevices from './Import'

function Testing({ companyId }) {
  const [devices, setDevices] = useState([])
  const [devicesMostUsedSinceCreated, setDevicesMostUsedSinceCreated] = useState([])
  const [devicesMostUsedSinceCalibrated, setDevicesMostUsedSinceCalibrated] = useState([])
  const [limit, setLimit] = useState(20)

  const [todayFrom, todayTo] = ranges.find(({ label }) => label === 'Today').value

  const [filterDevicesBy, setFilterDevicesBy] = useState(
    getLocal('devicesFilterBy', {
      label: 'First Service Date',
      value: 'firstServiceAt',
    }))
  const [filterDevicesByValue, setFilterDevicesByValue] = useState(
    getLocal('devicesFilterByValue', {
      firstServiceAtFrom: todayFrom,
      firstServiceAtTo: todayTo,
    }))

  const navigate = useNavigate()
  const { deviceId } = useParams()

  const getFilterArgs = (field, value) => {
    const { firstServiceAtFrom, firstServiceAtTo, calibratedAtFrom, calibratedAtTo, numberRefBeginsWith, partId } = value
    return {
      ...field === 'firstServiceAt' && { firstServiceAtFrom, firstServiceAtTo },
      ...field === 'calibratedAt' && { calibratedAtFrom, calibratedAtTo },
      ...field === 'poNumber' && { numberRefBeginsWith, orderBy: ['updatedAt','desc'] },
      ...field === 'partId' && { partId },
    }
  }

  useAsyncFunc(async args => {
    setLocal('purchaseOrdersFilterBy', filterDevicesBy)
    setLocal('purchaseOrdersFilterByValue', filterDevicesByValue)
    if (companyId) {
      return findDevices({
        ...args,
        ...getFilterArgs(filterDevicesBy.value, filterDevicesByValue),
      })
    }
    return () => []
  }, { companyId, type: 'GAGE', orderBy: ['createdAt','desc'], limit }, setDevices, [companyId, filterDevicesBy, filterDevicesByValue, limit])

  const fetch = () => findDevices({
    companyId, type: 'GAGE', orderBy: ['createdAt','desc'], limit,
    ...getFilterArgs(filterDevicesBy.value, filterDevicesByValue),
  }).then(setDevices)

  useAsyncFunc(async () => {
    if (companyId) {
      return findDevices({
        companyId,
        orderBy: ['countOfUseSinceCreated','desc'],
      })
    }
    return () => []
  }, null, setDevicesMostUsedSinceCreated, [companyId])

  useAsyncFunc(async () => {
    if (companyId) {
      return findDevices({
        companyId,
        orderBy: ['countOfUseSinceCalibrated','desc'],
      })
    }
    return () => []
  }, null, setDevicesMostUsedSinceCalibrated, [companyId])

  return (
    <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
      <Box style={{ width: '25%' }}>
        <Box style={{ position: 'sticky', top: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Card variant="outlined">
            <CardContent>
              <Typography>Most Used</Typography>
              <Typography>{get(devicesMostUsedSinceCreated, '[0].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCreated, '[1].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCreated, '[2].number', '—')}</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Typography>Since Calibrated</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[0].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[1].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[2].number', '—')}</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Typography>Upcoming Orders</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[0].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[1].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[2].number', '—')}</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Typography>Upcoming Quotes</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[0].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[1].number', '—')}</Typography>
              <Typography>{get(devicesMostUsedSinceCalibrated, '[2].number', '—')}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box style={{ width: '75%' }}>
        <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold' }}>
          <Architecture style={{ marginRight: '5px', verticalAlign: 'sub' }} />
          Thread Gages
        </Typography>
        <Box style={{ margin: '10px 0' }}>
          <DeviceFilters
            companyId={companyId}
            beforeFilters={(
              <Box>
                <CreateEditDevice
                  companyId={companyId}
                  button={(
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Add />}
                    >
                      New
                    </Button>
                  )}
                  data={{ companyId, type: 'GAGE', status: 'ACTIVE' }}
                  onCreate={fetch}
                />
              </Box>
            )}
            filterBy={filterDevicesBy}
            setFilterBy={setFilterDevicesBy}
            filterByValue={filterDevicesByValue}
            setFilterByValue={setFilterDevicesByValue}
          />
        </Box>
        <TableContainer style={{ marginTop: '10px', background: '#efefef', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
          <Table size="small">
            <TableHead style={{ background: '#fff' }}>
              <TableRow>
                <TableCell>Control Number</TableCell>
                <TableCell>Serial</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Range</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>First Service</TableCell>
                <TableCell>Last Calibration</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {devices && devices.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    No devices found.
                  </TableCell>
                </TableRow>
              )}
              {(devices || []).map(device => {
                const { id, number, numberSerial, name, range, status, firstServiceAt, calibratedAt } = device
                return (
                  <TableRow key={id}>
                    <TableCell>
                      {number}
                    </TableCell>
                    <TableCell>
                      {numberSerial}
                    </TableCell>
                    <TableCell>
                      {name}
                    </TableCell>
                    <TableCell>
                      {range}
                    </TableCell>
                    <TableCell>
                      {status}
                    </TableCell>
                    <TableCell>
                      {firstServiceAt.toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {calibratedAt.toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <CreateEditDevice
                        companyId={companyId}
                        button={(
                          <IconButton size="small">
                            <EditOutlined fontSize="small" />
                          </IconButton>
                        )}
                        data={device}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
              {devices && (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    <Paginate
                      label="Load more"
                      size="small"
                      variant="outlined"
                      startIcon={<DownloadForOfflineOutlined />}
                      disableElevation
                      onClick={delta => setLimit(limit + delta)}
                      query={() => findDevices({
                        companyId, type: 'GAGE', orderBy: ['createdAt','desc'], limit: 1,
                        startAfter: get(devices[devices.length - 1], '_snap'),
                        ...getFilterArgs(filterDevicesBy.value, filterDevicesByValue),
                      })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default Testing
