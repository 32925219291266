import { useState, createContext, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { signInWithEmailLink, signOut as authSignOut } from 'firebase/auth'
import { Box, Typography, TextField, IconButton, Button, Alert, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Send, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get, compact } from 'lodash'
import { useSnackbar } from 'notistack'
import { Html } from '@react-email/html'
import { Button as EmailButton } from '@react-email/button'
import { render } from '@react-email/render'
import { useAsyncFunc, sendEmail } from '../../functions'
import Accordion from '../Accordion'
import EditInternalMemo from '../EditInternalMemo'
import EditFootnote from '../EditFootnote'
import Actions from './Actions'
import SearchJournalEntries from './Search'
import EditJournalEntryDetails from './EditDetails'
import CreateEditLineItem from './CreateEditLineItem'
import LineItemRow from './LineItemRow'
import RelatedJournalEntries from './RelatedJournalEntries'
import { getJournalEntry, findLineItems, updateJournalEntry } from './functions'

const EmailRequestApproval = ({ id, number, numberRef }) => (
  <Html lang="en">
    {`Approval requested on Report Number ${number} (${numberRef})`}
    <br/>
    <Button href={`https://app.edimfg.com/journal/${id}`}>View Report</Button>
  </Html>
)

function Journal({ companyId }) {
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [journalEntry, setJournalEntry] = useState()
  const [lineItems, setLineItems] = useState()
  const [update, setUpdate] = useState()

  const navigate = useNavigate()
  const { journalEntryId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  useAsyncFunc(async args => {
    if (journalEntryId) return getJournalEntry(args)
    return () => null
  }, journalEntryId, setJournalEntry, [journalEntryId])

  useAsyncFunc(async args => {
    if (journalEntryId) return findLineItems(args)
    return () => null
  }, { journalEntryId, orderBy: ['lineNumber','asc'] }, setLineItems, [journalEntryId])

  const fetch = () => getJournalEntry(get(journalEntry, 'id')).then(setJournalEntry)

  const fetchLineItems = () => findLineItems({ journalEntryId: get(journalEntry, 'id'), orderBy: ['createdAt','asc'] }).then(setLineItems)

  return (
    <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
      <Box style={{ width: '23vw', paddingTop: '10px' }}>
        <Box style={{ position: 'sticky', top: '10px', height: 'calc(100vh - 90px)', overflowY: 'scroll' }}>
          <SearchJournalEntries
            companyId={companyId}
            selected={journalEntry}
            onSelect={({ id }) => navigate(`/journal/${id}`)}
          />
        </Box>
      </Box>
      <Box style={{ flexGrow: 1, minHeight: '100vh', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
        {journalEntry && (
          <>
            <Box style={{ position: 'sticky', top: 0, zIndex: 3 }}>
              <Stack>
                <Alert
                  severity="info"
                  action={(
                    <>
                      {update || (
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            startIcon={<Send />}
                            onClick={() => sendEmail({
                              to: 'onezincmedia@gmail.com',
                              subject: `Approval requested on Report Number ${journalEntry.number} (${journalEntry.numberRef})`,
                              text: `Approval requested on Report Number ${journalEntry.number} (${journalEntry.numberRef})`,
                              html: render(EmailRequestApproval(journalEntry)),
                            }).then(() => enqueueSnackbar('Feedback captured successfully.', { variant: 'success' }))}
                            style={{ marginLeft: '5px' }}
                            disableElevation
                          >
                            Send For Approval
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            style={{ marginLeft: '5px' }}
                            disableElevation
                          >
                            Close
                          </Button>
                        </>
                      )}
                    </>
                  )}
                >
                  {`Report is ${journalEntry.status.replace('_',' ')}.`}
                </Alert>
              </Stack>
            </Box>
            <Box style={{ padding: '10px' }}>
              <Accordion
                title="Report Details"
                content={(
                  <EditJournalEntryDetails
                    journalEntry={journalEntry}
                    onUpdate={setUpdate}
                    onUpdated={setJournalEntry}
                    afterDetails={(
                      <Accordion
                        title={(
                          <>
                            Report Items
                            <Button
                              size="small"
                              variant="outlined"
                              style={{ marginLeft: '10px' }}
                              startIcon={<Add />}
                              onClick={e => {
                                e.stopPropagation()
                                setIsAddOpen(true)
                              }}
                            >
                              Add Line Item
                            </Button>
                          </>
                        )}
                        afterTitle={(
                          <Box style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                          </Box>
                        )}
                        content={(
                          <Box style={{ minHeight: '450px', padding: '5px', border: '1px solid #ccc' }}>
                            <TableContainer>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Line</TableCell>
                                    <TableCell>Reference Location</TableCell>
                                    <TableCell>Specification or Drawing Requirement</TableCell>
                                    <TableCell>Actual Results of Nonconformance</TableCell>
                                    <TableCell />
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(lineItems || []).map(lineItem => (
                                    <LineItemRow
                                      key={lineItem.id}
                                      {...lineItem}
                                      journalEntry={journalEntry}
                                      onUpdated={fetchLineItems}
                                      isLineIncluded
                                    />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        )}
                        defaultExpanded
                      />
                    )}
                  />
                )}
                defaultExpanded
              />
              <CreateEditLineItem
                companyId={companyId}
                data={{
                  journalEntryId: journalEntry.id,
                  isMeasurable: true,
                  ...journalEntry.partId && { partId: journalEntry.partId },
                }}
                onCreated={fetchLineItems}
                forceOpen={isAddOpen}
                setForceOpen={setIsAddOpen}
              />
              <Accordion
                title="Related Journal Entries"
                content={(
                  <RelatedJournalEntries
                    companyId={companyId}
                    partIds={(lineItems || []).map(({ partId }) => partId)}
                    poNumbers={compact((lineItems || []).map(({ refPoNumber }) => refPoNumber))}
                    excludeIds={(lineItems || []).map(({ id }) => id)}
                  />
                )}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Journal
