import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Button, List, ListItem } from '@mui/material'
import { Add, Check, Clear, DeleteOutline } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, getCurrentUserId } from '../../functions'
import ApprovedByOn from '../ApprovedByOn'
import Checkbox from '../Checkbox'
import Confirm from '../Confirm'
import Radio from '../Radio'
import { findQuestions, addQuestion, updateQuestion, findSubmissions, updateContact } from './functions'
import SelectContact from './SelectContact'

function NewQuestionRow({ companyId, order, onAdded, style }) {
  const [isAdding, setIsAdding] = useState(false)
  const [updatedQuestion, setUpdatedQuestion] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return
    return addQuestion({ ...updatedQuestion, companyId, status: 'ACTIVE', order }).then(question => {
      enqueueSnackbar('Question added successfully.', { variant: 'success' })
      handleClose()
      if (onAdded) onAdded(question)
    })
  }

  const handleClose = () => {
    setIsAdding(false)
    setUpdatedQuestion()
  }

  return isAdding ? (
    <Box style={{ paddingTop: '15px', ...style }}>
      <TextField
        size="small"
        variant="outlined"
        name="label"
        label="Label"
        fullWidth
        value={get(updatedQuestion, 'label', '')}
        onChange={({ target: { value } }) => setUpdatedQuestion({ ...updatedQuestion, label: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'label')}
        autoFocus
      />
      <TextField
        size="small"
        variant="outlined"
        name="instructions"
        label="Instructions"
        rows={3}
        multiline
        fullWidth
        value={get(updatedQuestion, 'instructions', '')}
        onChange={({ target: { value } }) => setUpdatedQuestion({ ...updatedQuestion, instructions: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'instructions')}
      />
      <Box style={{ display: 'flex' }}>
        <Radio
          options={[
            { label: 'Yes/No', value: 'BOOLEAN' },
            { label: 'Select One', value: 'SELECT_ONE' },
            { label: 'Select Many', value: 'SELECT_MULTIPLE' },
            { label: 'Capabilities', value: 'CAPABILITIES' },
            { label: 'Freeform', value: 'TEXT' },
          ]}
          value={get(updatedQuestion, 'type', '')}
          onChange={({ target: { value } }) => setUpdatedQuestion({ ...updatedQuestion, type: value })}
          style={{ marginBottom: '10px' }}
        />
        {(get(updatedQuestion, 'type') === 'SELECT_ONE' || get(updatedQuestion, 'type') === 'SELECT_MULTIPLE' || get(updatedQuestion, 'type') === 'CAPABILITIES') && (
          <Box style={{ flexGrow: 1, marginLeft: '30px', paddingLeft: '30px', borderLeft: '1px solid #efefef' }}>
            <List>
              {get(updatedQuestion, 'options', []).map((option, index) => (
                <ListItem>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="New option"
                    value={option}
                    onChange={({ target: { value } }) => setUpdatedQuestion({ ...updatedQuestion, options: get(updatedQuestion, 'options', []).map((o, i) => i === index ? value : o) })}
                    InputProps={{
                      endAdornment: option ? (
                        <Confirm
                          button={(
                            <IconButton size="small">
                              <DeleteOutline fontSize="small" />
                            </IconButton>
                          )}
                          buttonStyle={{ display: 'inline-block' }}
                          title="Confirm remove?"
                          content="Are you sure you want to remove this option?"
                          onConfirm={() => setUpdatedQuestion({ ...updatedQuestion, options: get(updatedQuestion, 'options', []).filter((o, i) => i !== index) })}
                        />
                      ) : (
                        <IconButton size="small" onClick={() => setUpdatedQuestion({ ...updatedQuestion, options: get(updatedQuestion, 'options', []).filter((o, i) => i !== index) })}>
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                      )
                    }}
                    autoFocus
                  />
                </ListItem>
              ))}
              <Button
                size="small"
                variant="outlined"
                startIcon={<Add />}
                disableElevation
                onClick={() => setUpdatedQuestion({ ...updatedQuestion, options: [ ...get(updatedQuestion, 'options', []), '' ] })}
                style={{ marginTop: '15px' }}
              >
                Add Option
              </Button>
            </List>
          </Box>
        )}
      </Box>
      <Box style={{ marginBottom: '10px' }}>
        {get(updatedQuestion, 'type') === 'BOOLEAN' && (
          <Checkbox
            checked={get(updatedQuestion, 'requireExplainOnNo', false)}
            onChange={({ target: { checked } }) => setUpdatedQuestion({ ...updatedQuestion, requireExplainOnNo: checked })}
            label="Require explanation on No"
          />
        )}
        <Checkbox
          checked={get(updatedQuestion, 'allowUpload', false)}
          onChange={({ target: { checked } }) => setUpdatedQuestion({ ...updatedQuestion, allowUpload: checked })}
          label="Allow file upload"
        />
        {get(updatedQuestion, 'allowUpload', false) && (
          <Checkbox
            checked={get(updatedQuestion, 'allowUploadMultiple', false)}
            onChange={({ target: { checked } }) => setUpdatedQuestion({ ...updatedQuestion, allowUploadMultiple: checked })}
            label="Multiple files"
            style={{ paddingLeft: '15px' }}
          />
        )}
      </Box>
      <Button
        variant="contained"
        startIcon={<Check />}
        disableElevation
        onClick={submit}
        style={{ marginRight: '10px' }}
      >
        Save
      </Button>
      <Confirm
        button={(
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Discard
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
        title="Confirm remove?"
        content="Are you sure you want to remove this question?"
        onConfirm={handleClose}
      />
    </Box>
  ) : (
    <Button
      variant="outlined"
      startIcon={<Add />}
      disableElevation
      onClick={() => setIsAdding(true)}
      style={{ ...style }}
    >
      Add Question
    </Button>
  )
}

export default NewQuestionRow