import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { EditOutlined, ContentCopy, DeleteOutline, ArrowDropDown, History, Check, VisibilityOutlined } from '@mui/icons-material'
import { Sankey, Layer, Rectangle, Tooltip as Charttip } from 'recharts'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt, positiveFloat, getDate } from '../../functions'
import Checkbox from '../Checkbox'
import Confirm from '../Confirm'
import Dropdown from '../Dropdown'
import DateField from '../DateField'
import PriceField from '../PriceField'
import Popover from '../Popover'
import SelectPart from '../Parts/SelectPart'
import { findContacts } from '../Contacts/functions'
import { addJob, getNewJobNumber, upsertJobAllocation, findJobs, findJobLines } from '../Jobs/functions'
import { addPartOrder, updatePartOrder, findPartOrders, getOrder, getNewLineNumber, validatePartOrderLineNumber } from './functions'

function NewPartOrderRow({ companyId, data, dense, onCreate, onUpdate, forceOpen, setForceOpen }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newPartOrder, setNewPartOrder] = useState()
  const [partJobs, setPartJobs] = useState()
  const [matches, setMatches] = useState([])
  const [isCreatingJob, setIsCreatingJob] = useState(false)
  const [removing, setRemoving] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    if (!get(newPartOrder, 'partId')) errors.partId = 'Required'
    if (!get(newPartOrder, 'lineNumber')) errors.lineNumber = 'Required'
    if (get(newPartOrder, 'lineNumber') && !await validatePartOrderLineNumber(newPartOrder.orderId, newPartOrder.lineNumber, newPartOrder.id)) errors.lineNumber = 'Conflicted'
    if (!get(newPartOrder, 'quantity')) errors.quantity = 'Required'
    if (!get(newPartOrder, 'unit')) errors.unit = 'Required'
    if (!get(newPartOrder, 'isPriceTbd') && get(newPartOrder, 'unitPrice') === undefined) errors.unitPrice = 'Required'
    if (!get(newPartOrder, 'isDueAsap') && !get(newPartOrder, 'dueAt') && !get(newPartOrder, 'isDueAsap', false)) errors.dueAt = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    const addAndAllocateJob = async partOrder => addJob({
      companyId,
      number: await getNewJobNumber(companyId),
      shipAt: getDate(partOrder.dueAt, '-', 7, 'day'),
      partId: partOrder.partId,
      quantity: partOrder.quantity,
      quantityAvailable: partOrder.quantity,
      dueAt: partOrder.dueAt,
      status: 'IN_PROGRESS',
    }).then(({ id: jobId }) => upsertJobAllocation({ jobId, partOrderId: partOrder.id, quantity: partOrder.quantity }))
    const appendPartOrderProps = async orderId => ({
      isPurchase: !(await findContacts({ companyId, type: 'VEND', isInternal: true, orderBy: ['createdAt','desc'] })
                          .then(contacts => contacts.map(({ id }) => id)))
                          .includes(await getOrder(orderId).then(({ shipFromContactId }) => shipFromContactId)),
    })
    if (data.id) {
      return updatePartOrder(data.id, {
        partId: newPartOrder.partId,
        lineNumber: newPartOrder.lineNumber,
        quantity: newPartOrder.quantity,
        unit: newPartOrder.unit,
        unitPrice: newPartOrder.unitPrice,
        dueAt: newPartOrder.dueAt,
        isPriceTbd: !!newPartOrder.isPriceTbd,
        isDueAsap: !!newPartOrder.isDueAsap,
        ...newPartOrder.notes && { notes: newPartOrder.notes },
        ...await appendPartOrderProps(newPartOrder.orderId),
      }).then(async partOrder => {
        handleClose()
        enqueueSnackbar('Order item updated successfully.', { variant: 'success' })
        if (onUpdate) onUpdate(partOrder)
        if (isCreatingJob) addAndAllocateJob(partOrder)
      })
    }
    return addPartOrder({ ...newPartOrder, quantityBalance: newPartOrder.quantity, companyId, ...await appendPartOrderProps(newPartOrder.orderId) })
      .then(partOrder => {
        handleClose()
        enqueueSnackbar('Order item created successfully.', { variant: 'success' })
        if (onCreate) onCreate(partOrder)
        if (isCreatingJob) addAndAllocateJob(partOrder)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewPartOrder(data)
  }, [data])

  useAsyncFunc(async newPartOrder => {
    if (companyId && newPartOrder?.partId) {
      return findJobs({ companyId, partId: newPartOrder.partId, orderBy: ['createdAt','desc'] })
        .then(async jobs => await Promise.all(jobs.map(async job => ({ ...job, jobLines: await findJobLines({ jobId: job.id, orderBy: ['number','asc'] }) }))))
    }
    return () => null
  }, newPartOrder, setPartJobs, [companyId, newPartOrder])

  return isCreating && (
    <TableRow>
      <TableCell>
        <TextField
          size="small"
          variant="outlined"
          name="lineNumber"
          label="Line Number"
          fullWidth
          value={get(newPartOrder, 'lineNumber', '')}
          onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, lineNumber: positiveInt(value) })}
          {...addErrorProps(errors, 'lineNumber')}
          required
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          variant="outlined"
          name="quantity"
          label="Quantity"
          fullWidth
          style={{ marginBottom: '10px' }}
          value={get(newPartOrder, 'quantity', '')}
          onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, quantity: positiveInt(value) })}
          {...addErrorProps(errors, 'quantity')}
          required
        />
        <Dropdown
          button={(
            <TextField
              size="small"
              variant="outlined"
              value={get(newPartOrder, 'unit', '')}
              InputProps={{
                endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
              }}
            />
          )}
          items={[
            { label: 'LOT', value: 'LOT' },
            { label: 'PIECE', value: 'PIECE' },
            { label: 'HOUR', value: 'HOUR' },
          ]}
          onChange={({ value }) => setNewPartOrder({ ...newPartOrder, unit: value })}
        />
      </TableCell>
      <TableCell colSpan={3}>
        <SelectPart
          companyId={companyId}
          partId={get(newPartOrder, 'partId')}
          onChange={part => setNewPartOrder({ ...newPartOrder, partId: get(part, 'id') })}
          style={{ minWidth: '400px', marginBottom: '10px' }}
          inputProps={{
            ...addErrorProps(errors, 'partId')
          }}
        />
        <TextField
          size="small"
          variant="outlined"
          label="Notes"
          multiline
          rows={5}
          fullWidth
          value={get(newPartOrder, 'notes', '')}
          onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, notes: value })}
          InputProps={{
            endAdornment: (
              <Dropdown
                button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                beforeItems={<Typography variant="overline" display="block" color="GrayText">Notes history</Typography>}
                items={(matches || []).map(({ notes, updatedAt }) => ({
                  label: (
                    <>
                      {get(newPartOrder, 'notes', '') === notes && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                      {updatedAt.toLocaleString()}
                    </>
                  ),
                  value: notes,
                  tooltip: notes,
                }))}
                onChange={({ value }) => setNewPartOrder({ ...newPartOrder, notes: value })}
                disableCloseOnSelect
              />
            ),
          }}
          {...addErrorProps(errors, 'notes')}
        />
        <Confirm
          forceOpen={!!removing}
          setForceOpen={() => setRemoving()}
          title="Confirm remove?"
          content="Are you sure you want to remove this traveler?"
          onConfirm={() => setNewPartOrder({ ...newPartOrder, notes: newPartOrder.notes.replace(removing.traveler, '')})}
          onCancel={() => setRemoving()}
        />
        <Stack sx={{ marginBottom: '10px' }}>
          <Alert
            severity="info"
            sx={{ alignItems: 'center' }}
          >
            {(partJobs || []).map(({ id, number, jobLines }) => {
              const traveler = jobLines.map(({ description }) => `- ${description}`).join('\n')
              const isIncluded = (newPartOrder.notes || '').includes(traveler)
              return jobLines.length > 0 ? (
                <Button
                  key={id}
                  size="small"
                  variant="contained"
                  startIcon={isIncluded ? <Check /> : <ContentCopy />}
                  endIcon={(
                    <Popover
                      button={(
                        <IconButton size="small" edge="end">
                          <VisibilityOutlined fontSize="small" sx={{ fontSize: '1.125rem', color: '#fff' }} />
                        </IconButton>
                      )}
                      content={(
                        <Box style={{ padding: '15px', whiteSpace: 'pre-line' }}>
                          {traveler}
                        </Box>
                      )}
                      trigger="hover"
                    />
                  )}
                  onClick={() => isIncluded ? setRemoving({ traveler }) : setNewPartOrder({ ...newPartOrder, notes: `${newPartOrder.notes ? `${newPartOrder.notes}\n` : ''}${traveler}` })}
                  style={{ marginRight: '5px' }}
                  disableElevation
                  {...isIncluded && { color: 'success' }}
                >
                  {`J/N ${number}`}
                </Button>
              ) : ''
            })}
          </Alert>
        </Stack>
        <Checkbox
          checked={isCreatingJob}
          onChange={({ target: { checked } }) => setIsCreatingJob(checked)}
          label="Create new job for this line item"
        />
      </TableCell>
      <TableCell colSpan={2} align="right">
        <TextField
          size="small"
          variant="outlined"
          name="unitPrice"
          label="Unit price"
          fullWidth
          style={{ marginBottom: '10px' }}
          value={get(newPartOrder, 'unitPrice', '')}
          onChange={({ target: { value } }) => setNewPartOrder({ ...newPartOrder, unitPrice: positiveFloat(value) })}
          InputProps={{
            inputComponent: PriceField,
            endAdornment: (
              <>
                <Checkbox
                  checked={get(newPartOrder, 'isPriceTbd', false)}
                  onChange={({ target: { checked } }) => setNewPartOrder({ ...newPartOrder, isPriceTbd: checked })}
                  label="TBD"
                />
                <Dropdown
                  button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                  beforeItems={<Typography variant="overline" display="block" color="GrayText">Pricing history</Typography>}
                  items={(matches || []).map(({ quantity, unitPrice, unit, updatedAt }) => ({
                    label: (
                      <>
                        {get(newPartOrder, 'quantity', 0) === quantity
                        && get(newPartOrder, 'unitPrice', 0) === unitPrice
                        && get(newPartOrder, 'unit', '') === unit
                        && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                        {updatedAt.toLocaleString()}
                      </>
                    ),
                    value: { quantity, unitPrice, unit },
                    tooltip: `${quantity} ${unit} at $${unitPrice}/${unit}`,
                  }))}
                  onChange={({ value }) => setNewPartOrder({ ...newPartOrder, ...value })}
                  disableCloseOnSelect
                />
              </>
            ),
            disabled: get(newPartOrder, 'isPriceTbd', false),
          }}
          {...addErrorProps(errors, 'unitPrice')}
          required
        />
        <DateField
          label="Due date"
          value={get(newPartOrder, 'dueAt')}
          onChange={date => setNewPartOrder({ ...newPartOrder, dueAt: date })}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Checkbox
                      checked={get(newPartOrder, 'isDueAsap', false)}
                      onChange={({ target: { checked } }) => setNewPartOrder({ ...newPartOrder, isDueAsap: checked })}
                      label="ASAP"
                    />
                    {params.InputProps.endAdornment}
                  </>
                ),
                disabled: get(newPartOrder, 'isDueAsap', false),
              }}
              {...addErrorProps(errors, 'dueAt')}
            />
          )}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton size="small" onClick={submit}>
          <Check fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={handleClose}>
          <DeleteOutline fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default NewPartOrderRow