import { useState, useEffect } from 'react'
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, Tooltip } from '@mui/material'
import { Check, Clear, ArrowDropDown, AddLink } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, deviceTypes, gageTypes } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import Radio from '../Radio'
import FileRefs from '../FileRefs'
import { addDevice, updateDevice, validateDeviceNumber } from './functions'

function CreateEditDevice({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate, onUpdate }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newDevice, setNewDevice] = useState()
  const [filesFetchedAt, setFilesFetchedAt] = useState(new Date())
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    if (!get(newDevice, 'type')) errors.type = 'Required'
    if (!get(newDevice, 'number')) errors.number = 'Required'
    if (!get(newDevice, 'numberSerial')) errors.numberSerial = 'Required'
    if (!get(newDevice, 'name')) errors.name = 'Required'
    if (!get(newDevice, 'range')) errors.range = 'Required'
    // if (!get(newDevice, 'gageType')) errors.gageType = 'Required'
    if (!get(newDevice, 'status')) errors.status = 'Required'
    if (!get(newDevice, 'firstServiceAt')) errors.firstServiceAt = 'Required'
    if (!get(newDevice, 'calibratedAt')) errors.calibratedAt = 'Required'
    if (!data.id && get(newDevice, 'number') && !await validateDeviceNumber(newDevice.number, get(newDevice, 'numberSerial'), companyId)) errors.number = 'Conflicted'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    if (data.id) {
      return updateDevice(data.id, {
        type: newDevice.type,
        number: newDevice.number,
        numberSerial: newDevice.numberSerial,
        name: newDevice.name,
        range: newDevice.range,
        gageType: newDevice.gageType,
        ...newDevice.description && { description: newDevice.description },
        status: newDevice.status,
        ...newDevice.bondedAt && { bondedAt: newDevice.bondedAt },
        firstServiceAt: newDevice.firstServiceAt,
        calibratedAt: newDevice.calibratedAt,
        inCalibration: !!newDevice.inCalibration,
      }).then(async device => {
        handleClose()
        enqueueSnackbar('Device updated successfully.', { variant: 'success' })
        if (onUpdate) onUpdate(device)
      })
    }
    return addDevice({ ...newDevice, companyId })
      .then(device => {
        handleClose()
        enqueueSnackbar('Device created successfully.', { variant: 'success' })
        if (onCreate) onCreate(device)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewDevice(data)
  }, [data])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>{`${data?.id ? 'Edit' : 'Create new'} device`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <Radio
              options={deviceTypes}
              value={get(newDevice, 'type')}
              onChange={({ target: { value } }) => setNewDevice({ ...newDevice, type: value })}
              style={{ marginBottom: '10px' }}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Control number"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newDevice, 'number', '')}
              onChange={({ target: { value } }) => setNewDevice({ ...newDevice, number: value })}
              {...addErrorProps(errors, 'number')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              label="Serial number"
              placeholder="Enter - if unavailable"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newDevice, 'numberSerial', '')}
              onChange={({ target: { value } }) => setNewDevice({ ...newDevice, numberSerial: value })}
              {...addErrorProps(errors, 'numberSerial')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              label="Model number"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newDevice, 'name', '')}
              onChange={({ target: { value } }) => setNewDevice({ ...newDevice, name: value })}
              {...addErrorProps(errors, 'name')}
              required
            />
            <TextField
              size="small"
              variant="outlined"
              label="Range"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newDevice, 'range', '')}
              onChange={({ target: { value } }) => setNewDevice({ ...newDevice, range: value })}
              {...addErrorProps(errors, 'range')}
              required
            />
            {get(newDevice, 'type') === 'GAGE' && (
              <Dropdown
                button={(
                  <TextField
                    size="small"
                    variant="outlined"
                    name="gageType"
                    label="Gage Type"
                    fullWidth
                    value={get(newDevice, 'gageType', '')}
                    onChange={({ target: { value } }) => setNewDevice({ ...newDevice, gageType: value })}
                    InputProps={{
                      endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                    }}
                    style={{ marginBottom: '10px' }}
                    {...addErrorProps(errors, 'gageType')}
                  />
                )}
                items={gageTypes}
                onChange={({ value }) => setNewDevice({ ...newDevice, gageType: value })}
              />
            )}
            <TextField
              size="small"
              variant="outlined"
              label="Description"
              multiline
              rows={3}
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(newDevice, 'description', '')}
              onChange={({ target: { value } }) => setNewDevice({ ...newDevice, description: value })}
              {...addErrorProps(errors, 'description')}
            />
            {get(newDevice, 'type') === 'GAGE' && (
              <Radio
                options={[
                  { label: 'Active', value: 'ACTIVE' },
                  { label: 'In Bond', value: 'INACTIVE' },
                ]}
                value={get(newDevice, 'status')}
                onChange={({ target: { value } }) => setNewDevice({ ...newDevice, status: value })}
                style={{ marginBottom: '10px' }}
              />
            )}
            {get(newDevice, 'type') === 'GAGE' && get(newDevice, 'status') === 'INACTIVE' && (
              <DateField
                label="Date bonded"
                value={get(newDevice, 'bondedAt')}
                onChange={bondedAt => setNewDevice({ ...newDevice, bondedAt })}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    style={{ marginTop: '10px' }}
                  />
                )}
              />
            )}
            <DateField
              label="Date of first service"
              value={get(newDevice, 'firstServiceAt')}
              onChange={firstServiceAt => setNewDevice({ ...newDevice, firstServiceAt })}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  style={{ marginTop: '10px' }}
                />
              )}
            />
            <DateField
              label="Date of last calibration"
              value={get(newDevice, 'calibratedAt')}
              onChange={calibratedAt => setNewDevice({ ...newDevice, calibratedAt })}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  style={{ marginTop: '10px' }}
                />
              )}
            />
            {get(newDevice, 'type') === 'GAGE' && (
              <Checkbox
                checked={get(newDevice, 'inCalibration')}
                onChange={({ target: { checked } }) => setNewDevice({ ...newDevice, inCalibration: checked })}
                label="In Calibration"
              />
            )}
            {data.id && (
              <FileRefs
                key={filesFetchedAt}
                companyId={companyId}
                refFroms={[
                  { id: data.id, type: 'device' },
                ]}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            {data.id ? 'Update' : 'Confirm'}
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateEditDevice