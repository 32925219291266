import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { Add, Remove, Inbox, ListAlt } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc, getCompany } from '../../functions'
import PartNumber from '../Parts/PartNumber'
import Accordion from '../Accordion'
import ChangeLogs from '../ChangeLogs'
import Checkbox from '../Checkbox'
import EditInternalMemo from '../EditInternalMemo'
import EditDisclaimer from '../EditDisclaimer'
import Actions from './Actions'
import { findPartQuotes, getQuote, updateQuote } from './functions'
import SearchQuotes from './Search'
import EditQuoteDetails from './EditDetails'
import EditCustomerNote from './EditCustomerNote'
import EditFootnote from './EditFootnote'
import PartQuoteRow from './PartQuoteRow'
import EditPartQuote from './EditPartQuote'

function Quotes({ companyId }) {
  const [quote, setQuote] = useState()
  const [partQuotes, setPartQuotes] = useState([])
  const [isPartQuoteLogsOpen, setIsPartQuoteLogsOpen] = useState(false)
  const [useStandardFootnote, setUseStandardFootnote] = useState(true)

  const navigate = useNavigate()
  const { quoteId } = useParams()

  const fetch = () => findPartQuotes({ quoteId, orderBy: ['updatedAt','desc'] }).then(setPartQuotes)

  useAsyncFunc(findPartQuotes, { quoteId, orderBy: ['updatedAt','desc'] }, setPartQuotes, [quote])

  useAsyncFunc(async args => {
    if (quoteId) {
      const quote = await getQuote(args)
      return { ...quote, ...useStandardFootnote && { footnote: await getQuoteFootnote(quote.companyId) } }
    }
    return () => null
  }, quoteId, setQuote, [quoteId])

  const getQuoteFootnote = companyId => getCompany(companyId).then(({ quoteFootnote }) => quoteFootnote)

  return (
    <>
      <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
        <Box style={{ width: '23vw', paddingTop: '10px' }}>
          <Box style={{ position: 'sticky', top: '10px' }}>
            <SearchQuotes
              companyId={companyId}
              selected={quote}
              onSelect={({ id }) => navigate(`/quotes/${id}`)}
            />
          </Box>
        </Box>
        <Box style={{ flexGrow: 1, minHeight: '100vh', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
          <Box style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: 0, padding: '10px', background: '#efefef', zIndex: 2 }}>
            <Actions item={quote} />
          </Box>
          {quote && (
            <>
              <Stack>
                <Alert
                  severity="info"
                  action={(
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<Inbox />}
                        style={{ marginLeft: '5px' }}
                        disableElevation
                      >
                        Move To Order
                      </Button>
                    </>
                  )}
                >
                  {`Quote is ${quote.status.replace('_',' ')}.`}
                </Alert>
              </Stack>
              <Box style={{ padding: '10px' }}>
                <Accordion
                  title="Quote Details"
                  content={(
                    <EditQuoteDetails
                      quote={quote}
                      onUpdate={setQuote}
                    />
                  )}
                  defaultExpanded
                />
                <Accordion
                  title="Internal Memo"
                  content={(
                    <>
                      <Stack>
                        <Alert
                          severity="info"
                        >
                          For internal use only. Not visible to customer.
                        </Alert>
                      </Stack>
                      <EditInternalMemo
                        refFromId={quote.id}
                      />
                    </>
                  )}
                />
                <Accordion
                  title="Customer Notes"
                  content={(
                    <>
                      <Stack>
                        <Alert
                          severity="warning"
                        >
                          For print. Visible to customer.
                        </Alert>
                      </Stack>
                      <EditCustomerNote
                        quote={quote}
                        onUpdate={setQuote}
                      />
                    </>
                  )}
                />
                <ChangeLogs
                  col="partQuoteLogs"
                  id={quote.id}
                  field="before.quoteId"
                  forceOpen={isPartQuoteLogsOpen}
                  setForceOpen={setIsPartQuoteLogsOpen}
                  beforeChanges={log => (
                    <Box style={{ marginLeft: '-15px', padding: '0 0 5px 15px', borderLeft: '2px solid #4caf50' }}>
                      <Typography variant="overline" display="block">
                        Line item #
                      </Typography>
                      <Typography variant="body2" display="block">
                        {`P/N `}
                        <PartNumber partId={log.before.partId} />
                      </Typography>
                    </Box>
                  )}
                />
                <Accordion
                  title={(
                    <>
                      Quote Items
                      <Box onClick={e => e.stopPropagation()} sx={{ display: 'inline-block', marginLeft: '10px' }}>
                        <EditPartQuote
                          companyId={companyId}
                          quoteId={quoteId}
                          button={(
                            <Button
                              size="small"
                              variant="outlined"
                              startIcon={<Add />}
                            >
                              Add Part
                            </Button>
                          )}
                          onUpdated={fetch}
                        />
                      </Box>
                    </>
                  )}
                  afterTitle={(
                    <Box style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                      <Button
                        size="small"
                        variant="outlined"
                        disableElevation
                        startIcon={<ListAlt />}
                        onClick={e => {
                          e.stopPropagation()
                          setIsPartQuoteLogsOpen(true)
                        }}
                      >
                        Quote Item Changelog
                      </Button>
                    </Box>
                  )}
                  content={(
                    <Box style={{ minHeight: '250px', margin: '0 5px 15px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Part Number</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Unit</TableCell>
                              <TableCell align="right">Unit Price</TableCell>
                              <TableCell align="right">Extended Price</TableCell>
                              <TableCell align="right">Lead Time</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {partQuotes.map(partQuote => (
                              <PartQuoteRow key={partQuote.id} companyId={companyId} {...partQuote} onUpdated={fetch} />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  defaultExpanded
                />
                <Accordion
                  title="Footnote"
                  afterTitle={(
                    <Box style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                      <Checkbox
                        checked={useStandardFootnote}
                        onChange={({ target: { checked } }) => {
                          getQuoteFootnote(quote.companyId).then(footnote => setQuote({ ...quote, footnote }))
                          setUseStandardFootnote(checked)
                        }}
                        label="Standard (8 - 16 weeks)"
                        onClick={e => e.stopPropagation()}
                      />
                    </Box>
                  )}
                  content={(
                    <EditFootnote
                      quote={quote}
                      onUpdate={setQuote}
                      {...useStandardFootnote && { textFieldProps: { disabled: true } }}
                    />
                  )}
                />
                <Accordion
                  title="Disclaimer"
                  content={(
                    <EditDisclaimer
                      companyId={companyId}
                      note={quote.disclaimer}
                      onSubmit={disclaimer => updateQuote(quote.id, { disclaimer }).then(setQuote)}
                    />
                  )}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      </>
  )
}

export default Quotes