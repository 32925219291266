import { useState } from 'react'
import { Card, CardContent, Box, Typography } from '@mui/material'
import { Email, LocalPhone } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { useAsyncFunc } from '../../functions'
import { findOrders } from '../Orders/functions'
import { findInvoices } from '../Invoices/functions'

function ContactProp({ id: contactId, companyId, name, contactName, email, address, phone, onSelect }) {
  const [purchases, setPurchases] = useState()
  const [sales, setSales] = useState()
  const [invoices, setInvoices] = useState()

  useAsyncFunc(async contactId => {
    if (contactId) return findOrders({ companyId, shipFromContactIdIn: [contactId], orderBy: ['createdAt','desc'], limit: 99 })
    return () => null
  }, contactId, setPurchases, [contactId])

  useAsyncFunc(async contactId => {
    if (contactId) return findOrders({ companyId, shipToContactIdIn: [contactId], orderBy: ['createdAt','desc'], limit: 99 })
    return () => null
  }, contactId, setSales, [contactId])

  return (
    <Card sx={{ mb: 1.5 }} variant="outlined" onClick={onSelect}>
      <CardContent>
        <Box style={{ display: 'flex', gap: '15px' }}>
          <Box style={{ width: '70%' }}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {name}
            </Typography>
            <Typography variant="h5" component="div">
              {contactName}
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body2" style={{ whiteSpace: 'pre-line' }}>
              {address}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              <Email fontSize="small" style={{ marginRight: '8px', verticalAlign: 'sub' }} />
              <a href={`mailto:${email}`}>{email}</a>
            </Typography>
            <Typography sx={{ mt: 1.5 }} color="text.secondary">
              <LocalPhone fontSize="small" style={{ marginRight: '8px', verticalAlign: 'sub' }} />
              <NumberFormat
                value={phone}
                format="(###) ###-####"
                displayType="text"
              />
            </Typography>
          </Box>
          <Box style={{ width: '30%' }}>
            <Box sx={{ mb: 1.5 }} style={{ display: 'flex', gap: '15px' }}>
              <Box style={{ width: '50%' }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Purchases
                </Typography>
                <Typography variant="h5" component="div">
                  {(purchases || []).length}
                </Typography>
              </Box>
              <Box style={{ width: '50%' }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Sales
                </Typography>
                <Typography variant="h5" component="div">
                  {(sales || []).length}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: 'flex', gap: '15px' }}>
              <Box style={{ width: '50%' }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Invoices
                </Typography>
                <Typography variant="h5" component="div">
                  {(invoices || []).length}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ContactProp