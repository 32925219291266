import { useState, createContext, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { signInWithEmailLink, signOut as authSignOut } from 'firebase/auth'
import { Box, Typography, TextField, IconButton, Button, Alert, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Skeleton } from '@mui/material'
import { Search, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get, compact } from 'lodash'
import { SnackbarProvider } from 'notistack'
import { useAsyncFunc, getLocal, setLocal } from '../../functions'
import Accordion from '../Accordion'
import EditInternalMemo from '../EditInternalMemo'
import EditFootnote from '../EditFootnote'
import { findManuals } from './functions'
import ManualEntryResult from './ManualEntryResult'

export const NotionBlock = block => {
  const [blocks, setBlocks] = useState()

  useAsyncFunc(async block => {
    if (block.has_children) return findManuals({ blockId: block.id })
    return () => null
  }, block, setBlocks, [block])

  return (
    <>
      <Typography key={block.id}>{(block[block.type].rich_text || []).map(rt => rt[rt.type].content).join(' ')}</Typography>
      {(blocks || []).map(block => <NotionBlock key={block.id} {...block} />)}
      {block.has_children && !blocks && <Skeleton variant="rectangular" animation="wave" />}
    </>
  )
}

function Manual({ companyId }) {
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [manualEntries, setManualEntries] = useState()
  const [manualEntry, setManualEntry] = useState()
  const [blocks, setBlocks] = useState()

  const navigate = useNavigate()
  const { manualEntryId } = useParams()

  useAsyncFunc(async companyId => {
    if (companyId) return findManuals({})
    return () => null
  }, companyId, setManualEntries, [companyId])

  useAsyncFunc(async manualEntry => {
    if (manualEntry?.id) return findManuals({ blockId: manualEntry.id })
    return () => null
  }, manualEntry, setBlocks, [manualEntry])

  return (
    <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
      <Box style={{ width: '23vw' }}>
        <Box style={{ position: 'sticky', top: '10px', height: '90vh', overflowY: 'scroll' }}>
          <List>
            {(manualEntries || []).map(item => (
              <ManualEntryResult
                key={item.id}
                item={item}
                selected={manualEntry}
                onSelect={setManualEntry}
              />
            ))}
          </List>
        </Box>
      </Box>
      <Box style={{ flexGrow: 1, minHeight: '100vh', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
        <Box style={{ width: '960px', margin: '0 auto', whiteSpace: 'pre-line' }}>
          {manualEntry && (
            <Stack direction="row" sx={{ margin: '10px 0', flexWrap: 'wrap', gap: .5 }}>
              {manualEntry.properties.Tags[manualEntry.properties.Tags.type].map(({ id, name }) => <Chip key={id} size="small" label={name} />)}
            </Stack>
          )}
          {(blocks || []).map(block => <NotionBlock key={block.id} {...block} />)}
        </Box>
      </Box>
    </Box>
  )
}

export default Manual
