import { useState, useEffect } from 'react'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Sync, Check, Clear } from '@mui/icons-material'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { sequence } from '../../functions'
import { updatePartOrder, getOrder } from '../Orders/functions'
import { addShipment, getNewShipmentNumber, addShipmentLine } from './functions'
import ShipmentRow from './ShipmentRow'

function Ship({ companyId, partOrders, button, buttonStyle, forceOpen, setForceOpen, onSubmit }) {
  const [isAllocating, setIsAllocating] = useState(false)
  const [shipments, setShipments] = useState()
  const [isDroppable, setIsDroppable] = useState(false)
  const [dragoverId, setDragoverId] = useState()
  const [isProcessing, setIsProcessing] = useState(false)
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsAllocating(false)
    if (setForceOpen) setForceOpen(false)
  }

  const validateMerge = async partOrder => get(await getOrder(partOrder.orderId), 'shipToContactId') === get(await getOrder(dragoverId.split('-')[0]), 'shipToContactId')

  const dragStart = () => setIsDroppable(true)
  const dragEnter = (_e, { id }) => setDragoverId(id)
  const dragEnd = async (_e, partOrder) => {
    if (!await validateMerge(partOrder)) return enqueueSnackbar('Shipment does not support multiple recipients.', { variant: 'error' })
    setShipments(shipments.map(s => {
      if (s.id === dragoverId && !s.partOrders.find(p => p === partOrder)) return { ...s, partOrders: [ ...s.partOrders, partOrder ] }
      if (s.id !== dragoverId && s.partOrders.find(p => p === partOrder)) return { ...s, partOrders: s.partOrders.filter(p => p !== partOrder) }
      return s
    }))
    setDragoverId()
    setIsDroppable(false)
  }

  const updateShipment = (id, updates) => setShipments(shipments.map(s => {
    if (s.id === id) {
      if (s.partOrders.find(({ status }) => status === 'OPEN')) {
        enqueueSnackbar('Shipment cannot contain open orders.', { variant: 'error' })
        return s
      }
      return { ...s, ...updates }
    }
    return s
  }))

  useEffect(() => {
    if (forceOpen !== undefined && isAllocating !== forceOpen) setIsAllocating(forceOpen)
  }, [forceOpen, isAllocating])

  useEffect(() => {
    if (isAllocating) setShipments(
      (partOrders || []).filter(({ status }) => status !== 'SHIPPED')
        .map((partOrder, index) => ({ id: `${partOrder.orderId}-${index}`, partOrders: [partOrder], status: 'NOT_SHIPPED' })))
  }, [partOrders, isAllocating])

  const validate = async () => {
    const errors = {}
    await Promise.all((partOrders || []).map(async ({ id, orderId }) => {
      const { billToContactId, shipToContactId } = await getOrder(orderId)
      if (!billToContactId || !shipToContactId) errors[id] = 'Shipment missing shipping/billing information'
    }))
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    const process = async ({ partOrders, status, shipVia, trackingNumber }, index) => {
      const { billToContactId, shipToContactId } = await getOrder(partOrders[0].orderId)
      if (!billToContactId || !shipToContactId) return enqueueSnackbar('Shipment missing shipping/billing information.', { variant: 'error' })
      const { id: shipmentId } = await addShipment({
        companyId,
        number: await getNewShipmentNumber(companyId),
        billToContactId,
        shipToContactId,
        status,
        ...shipVia && { shipVia },
        ...trackingNumber && { trackingNumber },
      })
      await Promise.all(
        partOrders.map(async ({ id: partOrderId, partId, quantity }) => {
          await addShipmentLine({ shipmentId, partOrderId, partId, quantity })
          await updatePartOrder(partOrderId, { status: 'SHIPPED' })
        })
      )
    }
    setIsProcessing(true)
    await sequence(shipments.filter(({ partOrders }) => partOrders.length > 0), process)
    enqueueSnackbar('Shipments created successfully.', { variant: 'success' })
    handleClose()
    setIsProcessing(false)
    setShipments([])
    if (onSubmit) onSubmit()
  }

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsAllocating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isAllocating}
        onClose={handleClose}
      >
        <DialogTitle>Record shipments</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {(shipments || []).map((shipment, index) => (
              <ShipmentRow
                key={shipment.id}
                companyId={companyId}
                index={index}
                {...shipment}
                isDroppable={isDroppable}
                isDragover={dragoverId === shipment.id}
                onDragStart={dragStart}
                onDragEnter={dragEnter}
                onDragEnd={dragEnd}
                onUpdate={updateShipment}
                onRemove={({ id }) => setShipments(shipments.filter(s => s.id !== id))}
                errors={errors}
              />
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isProcessing ? (
            <Button
              variant="contained"
              startIcon={<Sync />}
              disableElevation
              disabled
            >
              Processing...
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<Check />}
              disableElevation
              onClick={submit}
              disabled={(shipments || []).length === 0 || Object.keys(errors).length > 0}
            >
              {`Record ${(shipments || []).filter(({ partOrders }) => partOrders.length > 0).length} shipment${(shipments || []).filter(({ partOrders }) => partOrders.length > 0).length > 1 ? 's' : ''}`}
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Ship