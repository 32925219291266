import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getJobLine } from './functions'

function JobLineNumber({ jobLineId }) {
  const [jobLine, setJobLine] = useState()

  useAsyncFunc(getJobLine, jobLineId, setJobLine, [jobLineId])

  return get(jobLine, 'number', <Skeleton variant="rectangular" animation="wave" />)
}

export default JobLineNumber