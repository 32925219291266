import { useState, useEffect } from 'react'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, Tooltip } from '@mui/material'
import { Add, Undo, Download } from '@mui/icons-material'
import { sortBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, getCompanyRef } from '../../functions'
import Confirm from '../Confirm'
import ImportPartTraveler from '../Parts/ImportPartTraveler'
import { findJobs, findJobLines, addJobLine, updateJobLine, updateJob } from './functions'
import JobLine from './JobLine'

function JobLines({ companyId, jobId, partId, quantity, isDetailed, onUpdate, onFetched, isDroppable, dragoverId, dragEnter }) {
  const [jobLines, setJobLines] = useState([])
  const [updates, setUpdates] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  const updatedJobLines = jobLines.map(line => {
    const match = updates.find(({ id }) => id === line.id)
    if (match) {
      return { ...line, ...match }
    }
    return line
  })

  const updateLine = (id, changes) => setUpdates(
    updates.map(u => u.id === id ? { ...u, ...changes } : u)
  )

  const moveLine = (id, delta) => {
    const { number } = updatedJobLines.find(l => l.id === id)
    if ((number === 1 && delta < 0) || (number === jobLines.length && delta > 0)) return
    const newNumber = number + delta
    const conflict = updatedJobLines.find(l => l.number === newNumber)
    return setUpdates([
      ...updates.map(u => {
        if (u.id === id) return { ...u, number: newNumber }
        if (conflict && u.id === conflict.id) return { ...u, number }
        return u
      }),
      ...!updates.find(u => u.id === id) ? [{ id, number: newNumber }] : [],
      ...conflict && !updates.find(u => u.id === conflict.id) ? [{ id: conflict.id, number }] : [],
    ])
  }

  const fetch = () => findJobLines({ jobId, orderBy: ['number','asc'] }).then(setJobLines)

  const add = () => addJobLine({ jobId, number: jobLines.length + 1, quantity, unit: 'piece', status: 'ACTIVE' }).then(fetch)

  const update = async () => await Promise.all(updates.map(async ({ id, ...u }) => updateJobLine(id, u))).then(fetch).then(() => {
    enqueueSnackbar('Job updated successfully.', { variant: 'success' })
    setUpdates([])
    onUpdate()
  })

  useAsyncFunc(findJobLines, { jobId, orderBy: ['updatedAt','desc'] }, setJobLines, [jobId])

  useEffect(() => {
    if (onFetched) onFetched(jobLines)
  }, [jobLines])

  return (
    <>
      <ImportPartTraveler companyId={companyId} partId={partId} jobId={jobId} onImported={fetch} />
      {updates.length > 0 && (
        <Box style={{ display: 'flex', alignItems: 'center', marginTop: '10px', padding: '10px', background: '#1a73e8', borderRadius: '5px' }}>
          <Confirm
            button={(
              <Button
                variant="contained"
                startIcon={<Undo />}
                style={{ marginRight: '10px' }}
                disableElevation
              >
                Revert
              </Button>
            )}
            buttonStyle={{ display: 'inline-block', marginRight: '10px' }}
            title="Abandon changes?"
            content="Are you sure you want to abandon changes?"
            onConfirm={() => {
              setUpdates([])
              setJobLines([])
              fetch()
            }}
          />
          <Button
            variant="outlined"
            onClick={update}
            style={{ borderColor: '#fff', color: '#fff' }}
            disableElevation
          >
            Save Job
          </Button>
        </Box>
      )}
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Line #</TableCell>
              <TableCell>Description</TableCell>
              {isDetailed && (
                <>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Sub</TableCell>
                </>
              )}
              <TableCell align="right">Completed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(updatedJobLines, ['number']).map((data, i) => (
              <JobLine
                key={data.id}
                {...data}
                companyId={companyId}
                partId={partId}
                onUpdate={changes => {
                  if (!updates.find(u => u.id === data.id)) {
                    return setUpdates([ ...updates, { id: data.id, ...changes } ])
                  }
                  return updateLine(data.id, changes)
                }}
                onMove={moveLine}
                isDetailed={isDetailed}
                isDroppable={isDroppable}
                isDragover={dragoverId === data.id}
                rowProps={{
                  onDragEnter: dragEnter,
                }}
                onNext={() => i === updatedJobLines.length - 1 && add()}
                onRemove={() => updateLine(data.id, { status: 'INACTIVE' })}
                onRestore={() => updateLine(data.id, { status: 'ACTIVE' })}
              />
            ))}
            <TableRow>
              <TableCell colSpan={8}>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={add}
                  disableElevation
                >
                  Add New Line
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default JobLines