import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAt, startAfter, endBefore, limitToLast, getDocs, onSnapshot } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith, getCount } from '../../functions'

export const getJob = id => get('jobs', id)

export const addJob = data => add('jobs', data)

export const updateJob = (id, updates) => update('jobs', id, updates, 'jobLogs')

export const findJobs = async ({ companyId, number, numberBeginsWith, partId, poNumber, poNumberBeginsWith, lineNumber, quantityAvailableMin, status, statusIn, statusNotIn, shipAtFrom, shipAtTo, dueAtFrom, dueAtTo, orderBy: order, limit: max, startAfter: after, endBefore: before, onChange }) => {
  const q = query(collection(db, 'jobs'), ...[
    where('companyId', '==', companyId),
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    ...partId ? [where('partId', '==', partId)] : [],
    ...poNumber ? [where('poNumber', '==', poNumber)]
    : poNumberBeginsWith ? beginsWith('poNumber', poNumberBeginsWith)
    : [],
    ...poNumber && lineNumber ? [where('lineNumber', '==', lineNumber)] : [],
    ...quantityAvailableMin ? [where('quantityAvailable', '>=', quantityAvailableMin)] : [],
    ...status ? [where('status', '==', status)] : [],
    ...statusIn ? [where('status', 'in', statusIn)] : [],
    ...statusNotIn ? [where('status', 'not-in', statusNotIn)] : [],
    ...shipAtFrom ? [where('shipAt', '>=', shipAtFrom)] : [],
    ...shipAtTo ? [where('shipAt', '<', shipAtTo)] : [],
    ...dueAtFrom ? [where('dueAt', '>=', dueAtFrom)] : [],
    ...dueAtTo ? [where('dueAt', '<', dueAtTo)] : [],
    ...!shipAtFrom && !shipAtTo && !quantityAvailableMin && !statusIn && !statusNotIn ? [orderBy(...order)] : [],
    limit(max || await getGlobal('jobsQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ])
  if (onChange) {
    onSnapshot(q, snap => onChange(snap.docChanges().map(change => collect(change.doc))))
  }
  return getDocs(q).then(collect)
}

export const getNewJobNumber = companyId => getCount('jobs', companyId).then(count => count + 1)

export const validateJobNumber = (number, companyId) => findJobs({ companyId, number, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const validateLineNumber = (poNumber, lineNumber, companyId) => findJobs({ companyId, poNumber, lineNumber, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const getJobLine = id => get('jobLines', id)

export const addJobLine = data => add('jobLines', data)

export const updateJobLine = (id, updates) => update('jobLines', id, updates, 'jobLineLogs')

export const findJobLines = async ({ jobId, descriptionBeginsWith, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'jobLines'), ...[
    where('jobId', '==', jobId),
    where('status', '==', 'ACTIVE'),
    ...descriptionBeginsWith ? beginsWith('description', descriptionBeginsWith) : [],
    orderBy(...order),
    limit(max || await getGlobal('jobLinesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const findJobLogs = async ({ jobId, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'jobLogs'), ...[
    where('before.id', '==', jobId),
    orderBy(...order),
    limit(max || await getGlobal('jobLogsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const findJobAllocations = async ({ jobId, jobIdIn, partOrderId, partOrderIdIn, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'jobAllocations'), ...[
    ...jobId ? [where('jobId', '==', jobId)] : [],
    ...jobIdIn ? [where('jobId', 'in', jobIdIn)] : [],
    ...partOrderId ? [where('partOrderId', '==', partOrderId)] : [],
    ...partOrderIdIn ? [where('partOrderId', 'in', partOrderIdIn)] : [],
    orderBy(...order),
    limit(max || await getGlobal('jobAllocationsQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)

export const upsertJobAllocation = async data => {
  const [allocation] = await findJobAllocations({ jobId: data.jobId, partOrderId: data.partOrderId, orderBy: ['createdAt','desc'], limit: 1 })
  if (allocation) return updateJobAllocation(allocation.id, { quantity: allocation.quantity + data.quantity })
  return add('jobAllocations', data)
}

export const updateJobAllocation = (id, updates) => update('jobAllocations', id, updates, 'jobAllocationLogs')