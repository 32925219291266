import { useState } from 'react'
import { Tabs, Tab } from '@mui/material'
import { compact } from 'lodash'
import { getCurrentUserId, getUser, useAsyncFunc } from '../functions'

function PermissionedTabs({ options, view, setView }) {
  const [permedOptions, setPermedOptions] = useState()

  useAsyncFunc(async () => {
    const user = await getUser(await getCurrentUserId())
    return compact(options.map(({ label, value, permission }) => user.permissions.includes(permission) ? { label, value } : null))
  }, null, setPermedOptions, [options])

  return (
    <Tabs value={view} onChange={(event, newValue) => setView(newValue)}>
      {(permedOptions || []).map(({ label, value }) => <Tab label={label} value={value} />)}
    </Tabs>
  )
}

export default PermissionedTabs