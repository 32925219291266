import { forwardRef } from 'react'
import NumberFormat from 'react-number-format'

const PriceField = forwardRef((props, ref) => {
  const { onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      prefix="$"
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      getInputRef={ref}
      onValueChange={({ value }) => onChange({
        target: {
          name: props.name,
          value,
        },
      })}
    />
  )
})

export default PriceField