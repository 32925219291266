import { useState } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Badge, Skeleton } from '@mui/material'
import { RequestQuote } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import PartName from '../Parts/PartName'
import { getPartOrder } from '../Orders/functions'
import { findLineItems } from './functions'
import InvoiceNumber from './InvoiceNumber'

function PartOrderInvoiceLineItems({ partOrderId, renderLineItems }) {
  const [lineItems, setLineItems] = useState([])
  const [partOrder, setPartOrder] = useState()

  useAsyncFunc(async args => {
    setPartOrder(await getPartOrder(partOrderId))
    return findLineItems(args)
  }, { partOrderId, orderBy: ['lineNumber','asc'] }, setLineItems, [partOrderId])

  const content = (
    <List>
      {lineItems.map(({ id, invoiceId, lineNumber, partId, quantity, unit, unitPrice }) => (
        <ListItem key={id}>
          <ListItemIcon>
            <Badge
              variant="dot"
              color="success"
            >
              <RequestQuote />
            </Badge>
          </ListItemIcon>
          <ListItemText
            primary={(
              <>
                {`Invoice number `}
                <InvoiceNumber invoiceId={invoiceId} />
              </>
            )}
            secondary={(
              <>
                {`Line ${lineNumber} · `}
                <PartName partId={partId} />
                {` · ${quantity} ${unit} x $${unitPrice.toFixed(2)} ea = $${(quantity * unitPrice).toFixed(2)}`}
              </>
            )}
          />
        </ListItem>
      ))}
    </List>
  )

  return renderLineItems ? renderLineItems(content, lineItems, partOrder) : content
}

export default PartOrderInvoiceLineItems