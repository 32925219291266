import { useState, createContext, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { signInWithEmailLink, signOut as authSignOut } from 'firebase/auth'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, AlertTitle, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Outbound, KeyboardArrowDown, Settings, Add, DownloadForOfflineOutlined, ListAlt, Inbox, Visibility, ArrowDropDown, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get } from 'lodash'
import { SnackbarProvider } from 'notistack'
import { useAsyncFunc, getLocal, setLocal, getDateDiffStr, jobRanges as ranges } from '../../functions'
import Dropdown from '../Dropdown'
import Paginate from '../Paginate'
import { findContacts } from '../Contacts/functions'
import { getOrder, findPartOrders, updateOrder, findOrders } from './functions'
import CreateOrder from './Create'
import SearchOrders from './Search'
import OrderFilters from './Filters'
import Order from './Order'
import OrderRow, { PartOrderRow } from './OrderRow'

const orderDatesOptions = [
  { label: 'Due Date', value: 'dueAt' },
  { label: 'Order Date', value: 'createdAt' },
]

function Orders({ companyId }) {
  const [showingPurchases, setShowingPurchases] = useState('orders')
  const [showingSales, setShowingSales] = useState('orders')
  const [selected, setSelected] = useState()
  const [purchases, setPurchases] = useState([])
  const [sales, setSales] = useState([])
  const [viewOptions, setViewOptions] = useState({ showDate: 'dueAt' })
  const [limit, setLimit] = useState(20)

  const [todayFrom, todayTo] = ranges.find(({ label }) => label === 'Today').value

  const [filterPurchasesBy, setFilterPurchasesBy] = useState(
    getLocal('purchaseOrdersFilterBy', {
      label: 'Order Date',
      value: 'orderAt',
    }))
  const [filterPurchasesByValue, setFilterPurchasesByValue] = useState(
    getLocal('purchaseOrdersFilterByValue', {
      orderAtFrom: todayFrom,
      orderAtTo: todayTo,
    }))

  const [filterSalesBy, setFilterSalesBy] = useState(
    getLocal('salesOrdersFilterBy', {
      label: 'Order Date',
      value: 'orderAt',
    }))
  const [filterSalesByValue, setFilterSalesByValue] = useState(
    getLocal('salesOrdersFilterByValue', {
      orderAtFrom: todayFrom,
      orderAtTo: todayTo,
    }))

  const navigate = useNavigate()
  const { orderId } = useParams()

  const getFilterArgs = (field, value) => {
    const { orderAtFrom, orderAtTo, dueAtFrom, dueAtTo, numberBeginsWith, numberRefBeginsWith, partId, shipFromContactId, shipToContactId } = value
    return {
      ...field === 'orderAt' && { orderAtFrom, orderAtTo },
      ...field === 'dueAt' && { dueAtFrom, dueAtTo },
      ...field === 'poNumber' && { numberRefBeginsWith, orderBy: ['updatedAt','desc'] },
      ...field === 'partId' && { partId },
      ...field === 'shipFromContactId' && { shipFromContactId, orderBy: ['updatedAt','desc'] },
      ...field === 'shipToContactId' && { shipToContactId, orderBy: ['updatedAt','desc'] },
    }
  }

  useAsyncFunc(async args => {
    if (orderId) return getOrder(args)
    return () => null
  }, orderId, setSelected, [orderId])

  const fetch = () => getOrder(orderId).then(setSelected)

  useAsyncFunc(async args => {
    setLocal('purchaseOrdersFilterBy', filterPurchasesBy)
    setLocal('purchaseOrdersFilterByValue', filterPurchasesByValue)
    if (companyId) {
      if (['dueAt','partId'].includes(filterPurchasesBy.value)) {
        setShowingPurchases('partOrders')
        return findPartOrders({
          ...args,
          ...getFilterArgs(filterPurchasesBy.value, filterPurchasesByValue),
          isPurchase: true,
        })
      }
      setShowingPurchases('orders')
      return findOrders({
        ...args,
        ...getFilterArgs(filterPurchasesBy.value, filterPurchasesByValue),
        shipToContactIdIn: await findContacts({ companyId, type: 'CUST', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)),
      })
    }
    return () => []
  }, { companyId, orderBy: ['createdAt','desc'], limit }, setPurchases, [companyId, filterPurchasesBy, filterPurchasesByValue, limit])

  useAsyncFunc(async args => {
    setLocal('salesOrdersFilterBy', filterSalesBy)
    setLocal('salesOrdersFilterByValue', filterSalesByValue)
    if (companyId) {
      if (['dueAt','partId'].includes(filterSalesBy.value)) {
        setShowingSales('partOrders')
        return findPartOrders({
          ...args,
          ...getFilterArgs(filterSalesBy.value, filterSalesByValue),
          isPurchase: false,
        })
      }
      setShowingSales('orders')
      return findOrders({
        ...args,
        ...getFilterArgs(filterSalesBy.value, filterSalesByValue),
        shipFromContactIdIn: await findContacts({ companyId, type: 'VEND', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)),
      })
    }
    return () => []
  }, { companyId, orderBy: ['createdAt','desc'], limit }, setSales, [companyId, filterSalesBy, filterSalesByValue, limit])

  return (
    <>
      <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
        <Box style={{ width: '50%' }}>
          <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold' }}>
            <Outbound style={{ marginRight: '5px', verticalAlign: 'sub', color: '#4caf50', transform: 'rotateZ(90deg)' }} />
            Purchases
          </Typography>
          <Box style={{ margin: '10px 0' }}>
            <OrderFilters
              companyId={companyId}
              beforeFilters={(
                <Box>
                  <CreateOrder
                    companyId={companyId}
                    button={(
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<Add />}
                      >
                        New
                      </Button>
                    )}
                    data={{ companyId, status: 'OPEN', isNumberAuto: true, orderAt: new Date(), isPurchasing: true }}
                    onCreate={setSelected}
                  />
                </Box>
              )}
              filterBy={filterPurchasesBy}
              setFilterBy={setFilterPurchasesBy}
              filterByValue={filterPurchasesByValue}
              setFilterByValue={setFilterPurchasesByValue}
            />
          </Box>
          {/* <SearchOrders
            companyId={companyId}
            onResult={results => setPurchases(results)}
            inline
            isPurchase
          /> */}
          <TableContainer style={{ marginTop: '10px', background: '#efefef', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
            <Table size="small">
              <TableHead style={{ background: '#fff' }}>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>PO</TableCell>
                  <TableCell>Ship From</TableCell>
                  <TableCell>
                    <Dropdown
                      button={(
                        <>
                          {orderDatesOptions.find(({ value }) => value === viewOptions.showDate).label} <KeyboardArrowDown fontSize="small" style={{ verticalAlign: 'text-bottom' }} />
                        </>
                      )}
                      items={orderDatesOptions}
                      onChange={({ value }) => setViewOptions({ ...viewOptions, showDate: value })}
                    />
                  </TableCell>
                  <TableCell>Received</TableCell>
                  <TableCell>Ext. / Invoiced / Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchases && purchases.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      No purchase orders found.
                    </TableCell>
                  </TableRow>
                )}
                {(purchases || []).map(purchase => showingPurchases === 'orders' ? (
                  <OrderRow key={purchase.id} {...purchase} viewOptions={viewOptions} isPurchase />
                ) : (
                  <PartOrderRow key={purchase.id} {...purchase} companyId={companyId} viewOptions={viewOptions} />
                ))}
                {purchases && (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      <Paginate
                        label="Load more"
                        size="small"
                        variant="outlined"
                        startIcon={<DownloadForOfflineOutlined />}
                        disableElevation
                        onClick={() => setLimit(limit + 10)}
                        query={async () => showingPurchases === 'orders' ? findOrders({
                          companyId,
                          shipToContactIdIn: await findContacts({ companyId, type: 'CUST', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)),
                          orderBy: ['createdAt','desc'],
                          limit: 1,
                          startAfter: get(purchases[purchases.length - 1], '_snap'),
                          ...getFilterArgs(filterPurchasesBy.value, filterPurchasesByValue),
                        }) : findPartOrders({
                          isPurchase: true,
                          limit: 1,
                          startAfter: get(purchases[purchases.length - 1], '_snap'),
                          ...getFilterArgs(filterPurchasesBy.value, filterPurchasesByValue),
                        })}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box style={{ width: '50%' }}>
          <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold' }}>
            <Outbound style={{ marginRight: '5px', verticalAlign: 'sub', color: '#03a9f4' }} />
            Sales
          </Typography>
          <Box style={{ margin: '10px 0' }}>
            <OrderFilters
              companyId={companyId}
              beforeFilters={(
                <Box>
                  <CreateOrder
                    companyId={companyId}
                    button={(
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<Add />}
                      >
                        New
                      </Button>
                    )}
                    data={{ companyId, status: 'OPEN', isNumberAuto: true, orderAt: new Date() }}
                    onCreate={setSelected}
                  />
                </Box>
              )}
              filterBy={filterSalesBy}
              setFilterBy={setFilterSalesBy}
              filterByValue={filterSalesByValue}
              setFilterByValue={setFilterSalesByValue}
            />
          </Box>
          {/* <SearchOrders
            companyId={companyId}
            onResult={results => setSales(results)}
            inline
          /> */}
          <TableContainer style={{ marginTop: '10px', background: '#efefef', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
            <Table size="small">
              <TableHead style={{ background: '#fff' }}>
                <TableRow>
                  {showingSales === 'orders' ? (
                    <>
                      <TableCell>Number</TableCell>
                      <TableCell>PO</TableCell>
                      <TableCell>Ship To</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>PO</TableCell>
                      <TableCell>Line</TableCell>
                      {filterSalesBy.value === 'partId' ? (
                        <TableCell>Ship To</TableCell>
                      ) : (
                        <TableCell>Part</TableCell>
                      )}
                    </>
                  )}
                  <TableCell>
                    <Dropdown
                      button={(
                        <>
                          {orderDatesOptions.find(({ value }) => value === viewOptions.showDate).label} <KeyboardArrowDown fontSize="small" style={{ verticalAlign: 'text-bottom' }} />
                        </>
                      )}
                      items={orderDatesOptions}
                      onChange={({ value }) => setViewOptions({ ...viewOptions, showDate: value })}
                    />
                  </TableCell>
                  <TableCell>Shipped</TableCell>
                  <TableCell>Ext. / Invoiced / Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales && sales.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      No sales orders found.
                    </TableCell>
                  </TableRow>
                )}
                {(sales || []).map(sale =>  showingSales === 'orders' ? (
                  <OrderRow key={sale.id} {...sale} viewOptions={viewOptions} />
                ) : (
                  <PartOrderRow key={sale.id} {...sale} companyId={companyId} viewOptions={viewOptions} />
                ))}
                {sales && (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      <Paginate
                        label="Load more"
                        size="small"
                        variant="outlined"
                        startIcon={<DownloadForOfflineOutlined />}
                        disableElevation
                        onClick={() => setLimit(limit + 10)}
                        query={async () => showingSales === 'orders' ? findOrders({
                          companyId,
                          shipFromContactIdIn: await findContacts({ companyId, type: 'VEND', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)),
                          orderBy: ['createdAt','desc'],
                          limit: 1,
                          startAfter: get(sales[sales.length - 1], '_snap'),
                          ...getFilterArgs(filterSalesBy.value, filterSalesByValue),
                        }) : findPartOrders({
                          isPurchase: false,
                          limit: 1,
                          startAfter: get(sales[sales.length - 1], '_snap'),
                          ...getFilterArgs(filterSalesBy.value, filterSalesByValue),
                        })}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {selected && (
        <Order
          {...selected}
          companyId={companyId}
          onSelect={setSelected}
          onUpdate={fetch}
          onRemove={() => fetch().then(navigate('/orders'))}
          forceOpen={!!selected}
          setForceOpen={open => !open && navigate('/orders')}
        />
      )}
    </>
  )
}

export default Orders
