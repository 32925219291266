import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { get } from 'lodash'
import Toggles from '../Toggles'
import { useAsyncFunc, getDateDiffStr } from '../../functions'
import { findPartQuotes } from '../Quotes/functions'

function PartQuotes({ partId }) {
  const [partQuotes, setPartQuotes] = useState([])
  const [partQuoteStatusIn, setPartQuoteStatusIn] = useState(['All'])

  useAsyncFunc(async () => {
    if (partId) {
      return findPartQuotes({ partId, orderBy: ['createdAt','desc'] })
    }
    return () => []
  }, null, setPartQuotes, [partId])

  return (
    <Box style={{ minHeight: '250px', margin: '0 5px 15px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Quote date</TableCell>
              <TableCell>Quote number</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last updated</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partQuotes.map(({ id, quoteId, quantity, status, createdAt, updatedAt }) => (
              <TableRow key={id}>
                <TableCell>{createdAt.toLocaleDateString()}</TableCell>
                <TableCell>{`Q/N `}</TableCell>
                <TableCell></TableCell>
                <TableCell>{quantity}</TableCell>
                <TableCell>{status}</TableCell>
                <TableCell>{getDateDiffStr(null, updatedAt)}</TableCell>
                <TableCell>
                  <Tooltip title="Open In New Window">
                    <Link to={`/quotes/${quoteId}`} target="_blank" rel="noopener noreferrer">
                      <IconButton edge="end">
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PartQuotes