import { useState, useEffect } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Box, Typography, TextField, Button, IconButton, Tooltip, Drawer, Chip, Stack, Alert, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import { ArrowBackIosNew, ThumbUpOffAlt, ThumbDownOffAlt, EditOutlined, Search, ArrowForward, AutoAwesome } from '@mui/icons-material'
import { get, difference, isEqual, compact, sortBy } from 'lodash'
import { useSnackbar } from 'notistack'
import ThreeDots from '../../images/three-dots.svg'
import { useAsyncFunc, sendEmail } from '../../functions'
import UserAvatar from '../User/UserAvatar'
import UserName from '../User/UserName'
import Confirm from '../Confirm'
import { findManuals, findAnswers } from './functions'
import { NotionBlock } from './'
import ManualEntryResult from './ManualEntryResult'

const Feedback = ({ button, buttonStyle, subject, selection, placeholder }) => {
  const [feedback, setFeedback] = useState()

  const { enqueueSnackbar } = useSnackbar()

  return (
    <Confirm
      button={button}
      buttonStyle={buttonStyle}
      title={subject}
      content={(
        <>
          {selection && (
            <Typography sx={{ maxHeight: '20vh', marginBottom: '15px', paddingLeft: '15px', borderLeft: '2px solid #efefef', whiteSpace: 'pre-line', overflowY: 'scroll' }}>
              {selection}
            </Typography>
          )}
          <TextField
            size="small"
            variant="outlined"
            placeholder={placeholder || 'Enter feedback...'}
            rows={3}
            multiline
            fullWidth
            value={feedback || ''}
            onChange={({ target: { value } }) => setFeedback(value)}
          />
        </>
      )}
      onConfirm={() => sendEmail({ to: 'onezincmedia@gmail.com', subject, text: `${selection}\n-----\n${feedback}` })
        .then(() => enqueueSnackbar('Feedback captured successfully.', { variant: 'success' }))}
    />
  )
}

function ManualDrawer({ companyId, button, buttonStyle, forceOpen, setForceOpen }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [manualEntries, setManualEntries] = useState([])
  const [manualEntry, setManualEntry] = useState()
  const [blocks, setBlocks] = useState()
  const [selection, setSelection] = useState()
  const [query, setQuery] = useState()
  const [tempQuery, setTempQuery] = useState()
  const [limit, setLimit] = useState(5)

  const [answer, setAnswer] = useState()
  const [faq, setFaq] = useState()
  const [isAsking, setIsAsking] = useState()

  const location = useLocation()
  const section = location.pathname.substring(1).split('/')[0]
  const title = manualEntry?.properties.Page.title[0].plain_text.replace(/ *\([^)]*\) */g, "")

  const handleClose = () => {
    setSelection()
    setIsDrawerOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isDrawerOpen !== forceOpen) setIsDrawerOpen(forceOpen)
  }, [forceOpen, isDrawerOpen])

  useAsyncFunc(findManuals, { query }, setManualEntries, [query, isDrawerOpen, limit])

  useAsyncFunc(async manualEntry => {
    if (manualEntry?.id) return findManuals({ blockId: manualEntry.id })
    return () => null
  }, manualEntry, setBlocks, [manualEntry])

  useEffect(() => {
    if (section) setQuery(`(${section.toUpperCase()})`)
  }, [section])

  const ask = async (query, faqQuery) => {
    setIsAsking(true)
    setAnswer()
    setAnswer(await findAnswers({ query }).then(({ choices }) => choices[0]))
    setIsAsking(false)
    if (faqQuery) setFaq(await findAnswers({ query: faqQuery, limit: 5 }).then(({ choices }) => choices))
  }

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsDrawerOpen(true)}>
          {!isDrawerOpen && button}
          {selection && (
            <>
              <Feedback
                button={(
                  <Tooltip title="Helpful">
                    <IconButton>
                      <ThumbUpOffAlt />
                    </IconButton>
                  </Tooltip>
                )}
                subject={`Helpful: ${title}`}
                selection={selection}
                buttonStyle={{ display: 'inline-block' }}
              />
              <Feedback
                button={(
                  <Tooltip title="Not Helpful">
                    <IconButton>
                      <ThumbDownOffAlt />
                    </IconButton>
                  </Tooltip>
                )}
                subject={`Not Helpful: ${title}`}
                selection={selection}
                buttonStyle={{ display: 'inline-block' }}
              />
              <Tooltip title="Edit">
                <IconButton>
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      )}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleClose}
        style={{ zIndex: 1400 }}
      >
        {manualEntry ? (
          <Box style={{ maxWidth: '720px', padding: '15px 60px' }}>
            <Box sx={{ display: 'flex', gap: '10px', margin: '15px 0', alignItems: 'center' }}>
              <Box>
                <IconButton edge="start" onClick={() => {
                  setManualEntry()
                  setSelection()
                }}>
                  <ArrowBackIosNew fontSize="small" />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="h5" style={{ margin: '10px 0', fontWeight: 'bold' }}>
                  {title}
                </Typography>
                <Stack direction="row" sx={{ margin: '10px 0', flexWrap: 'wrap', gap: .5 }}>
                  {manualEntry.properties.Tags[manualEntry.properties.Tags.type].map(({ id, name }) => <Chip key={id} size="small" label={name} />)}
                </Stack>
              </Box>
            </Box>
            <Box onMouseUp={() => setSelection(window.getSelection().toString())}>
              {(blocks || []).map(block => <NotionBlock key={block.id} {...block} />)}
            </Box>
            <Box sx={{ display: 'flex', gap: '5px', margin: '15px 0' }}>
              <Feedback
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ThumbUpOffAlt />}
                  >
                    Helpful
                  </Button>
                )}
                subject={`Helpful: ${title}`}
              />
              <Feedback
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ThumbDownOffAlt />}
                  >
                    Not Helpful
                  </Button>
                )}
                subject={`Not Helpful: ${title}`}
              />
            </Box>
          </Box>
        ) : (
          <Box style={{ width: '600px', padding: '15px' }}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search entries..."
              fullWidth
              value={tempQuery || ''}
              onChange={({ target: { value } }) => setTempQuery(value)}
              style={{ marginBottom: '5px' }}
              InputProps={{
                startAdornment: <IconButton edge="start"><Search /></IconButton>,
                ...tempQuery && { endAdornment: <IconButton edge="end" onClick={() => setQuery(tempQuery)}><ArrowForward /></IconButton> },
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  return setQuery(tempQuery)
                }
                return e
              }}
            />
            {(manualEntries || []).length < 3 && (
              <>
                {isAsking ? <Box sx={{ height: '200px', background: `url(${ThreeDots}) 50% 50% no-repeat #cfd8dc` }} /> : (
                  <Stack>
                    <Alert
                      severity="info"
                      action={(
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<AutoAwesome />}
                          onClick={() => ask(query, `What is a related question about ${query}? Do not repeat the question in response.`)}
                          disableElevation
                        >
                          Ask AI
                        </Button>
                      )}
                    >
                      Expand on this topic
                    </Alert>
                  </Stack>
                )}
                <Typography sx={{ padding: '15px', whiteSpace: 'pre-line' }}>
                  {answer?.message.content}
                  {answer?.message.content && (
                    <Box sx={{ display: 'flex', gap: '5px', margin: '15px 0' }}>
                      <Feedback
                        button={(
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ThumbUpOffAlt />}
                          >
                            Helpful
                          </Button>
                        )}
                        subject={`Helpful Addition: ${query}`}
                        selection={answer.message.content}
                      />
                    </Box>
                  )}
                </Typography>
                <List>
                  {faq?.map(({ id, message: { content } }) => content !== tempQuery && (
                    <ListItem key={id} disableGutters>
                      <ListItemButton onClick={() => {
                        setTempQuery(content)
                        ask(content)
                      }}>
                        <ListItemIcon>
                          <AutoAwesome />
                        </ListItemIcon>
                        <ListItemText
                          primary={content}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {(manualEntries || []).map(item => (
              <ManualEntryResult
                key={item.id}
                item={item}
                selected={manualEntry}
                onSelect={setManualEntry}
              />
            ))}
          </Box>
        )}
      </Drawer>
    </>
  )
}

export default ManualDrawer