import { Box, Button, TextField, IconButton } from '@mui/material'
import { FilterList, Search } from '@mui/icons-material'
import { get } from 'lodash'
import { jobRanges as ranges } from '../../functions'
import Checkbox from '../Checkbox'
import DateRange from '../DateRange'
import Dropdown from '../Dropdown'
import SelectPart from '../Parts/SelectPart'
import SelectContact from '../Contacts/SelectContact'

function DeviceFilters({ companyId, beforeFilters, afterFilters, filterBy, setFilterBy, filterByValue, setFilterByValue }) {
  return (
    <Box style={{ display: 'flex', gap: '10px' }}>
      {beforeFilters}
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<FilterList />}
          >
            {filterBy.label}
          </Button>
        )}
        items={[
          { label: 'First Service Date', value: 'firstServiceAt' },
          { label: 'Last Calibration Date', value: 'calibratedAt' },
          { label: 'PO Number', value: 'poNumber' },
          { label: 'Part Number', value: 'partId' },
        ]}
        onChange={setFilterBy}
      />
      {filterBy.value === 'firstServiceAt' && (
        <DateRange
          value={[filterByValue.firstServiceAtFrom, filterByValue.firstServiceAtTo]}
          ranges={ranges}
          inputStyle={{ padding: '6px 12px', fontSize: '14px' }}
          onChange={([firstServiceAtFrom, firstServiceAtTo]) => setFilterByValue({ ...filterByValue, firstServiceAtFrom, firstServiceAtTo })}
        />
      )}
      {filterBy.value === 'calibratedAt' && (
        <DateRange
          value={[filterByValue.calibratedAtFrom, filterByValue.calibratedAtTo]}
          ranges={ranges}
          inputStyle={{ padding: '6px 12px', fontSize: '14px' }}
          onChange={([calibratedAtFrom, calibratedAtTo]) => setFilterByValue({ ...filterByValue, calibratedAtFrom, calibratedAtTo })}
        />
      )}
      {filterBy.value === 'poNumber' && (
        <TextField
          size="small"
          variant="outlined"
          placeholder="PO Number"
          value={filterByValue.numberRefBeginsWith}
          onChange={({ target: { value } }) => setFilterByValue({ ...filterByValue, numberRefBeginsWith: value })}
          InputProps={{
            startAdornment: <IconButton edge="start"><Search /></IconButton>,
          }}
        />
      )}
      {filterBy.value === 'partId' && (
        <Box style={{ flexGrow: 1 }}>
          <SelectPart
            companyId={companyId}
            partId={filterByValue.partId}
            onChange={part => setFilterByValue({ ...filterByValue, partId: get(part, 'id') })}
          />
        </Box>
      )}
      {afterFilters}
    </Box>
  )
}

export default DeviceFilters