import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { EditOutlined, ContentCopy, DeleteOutline, Architecture, RequestQuoteOutlined, LocalShippingOutlined, Undo } from '@mui/icons-material'
import { Sankey, Layer, Rectangle, Tooltip as Charttip } from 'recharts'
import NumberFormat from 'react-number-format'
import { get, isEqual, reduce } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, positiveInt } from '../../functions'
import Confirm from '../Confirm'
import MultiplierField from '../MultiplierField'
import { getPart } from '../Parts/functions'
import PartName from '../Parts/PartName'
import PartNumber from '../Parts/PartNumber'
import { getJob } from '../Jobs/functions'
import ConnectJobs from '../Jobs/Connect'
import { updateDeviceUsage } from '../Testing/functions'
import SelectDevice from '../Testing/SelectDevice'
import { getPartOrder, findPartOrderJobConnections, addPartOrderJobConnection, updatePartOrderJobConnection, updatePartOrder } from './functions'
import CreateEditPartOrder from './CreateEditPartOrder'
import OrderConnectionNode from './OrderConnectionNode'
import PartOrderAction from './PartOrderAction'

function PartOrderRow({ id, companyId, partId, orderId, lineNumber, isPurchase, notes, quantity, unit, unitPrice, threadGages, status, dueAt, order, partOrders, onUpdated, readOnly, isShowingJobs, isShowingInvoices, isShowingShipments, isPriceTbd, isDueAsap, view }) {
  const [part, setPart] = useState()
  const [orderJobs, setOrderJobs] = useState([])
  const [updatedThreadGages, setUpdatedThreadGages] = useState()

  const { enqueueSnackbar } = useSnackbar()

  useAsyncFunc(getPart, partId, setPart, [partId])

  useAsyncFunc(findPartOrderJobConnections, { partOrderId: id, orderBy: ['updatedAt','desc'] }, setOrderJobs, [id, isShowingJobs])

  const fetch = () => findPartOrderJobConnections({ partOrderId: id, orderBy: ['updatedAt','desc'] }).then(setOrderJobs)

  const connect = async ({ jobId, partOrderId, data }) => {
    const job = await getJob(jobId)
    const partOrder = await getPartOrder(partOrderId)
    if (job.partId !== partOrder.partId) return enqueueSnackbar('Part number does not match.', { variant: 'warning' })

    return addPartOrderJobConnection({ ...data, jobId, partOrderId }).then(() => {
      enqueueSnackbar('Job allocated successfully.', { variant: 'success' })
      fetch()
    })
  }

  const assign = async (partOrders, threadGages) => {
    await Promise.all(partOrders.map(async ({ id, quantity }) => {
      await updatePartOrder(id, { threadGages })
      await Promise.all(Object.keys(threadGages).map(deviceId => updateDeviceUsage(deviceId, threadGages[deviceId] * quantity)))
    }))
  }

  useEffect(() => {
    setUpdatedThreadGages(threadGages || reduce(get(part, 'threadGages', []), (acc, { deviceId }) => ({ ...acc, [deviceId]: 1 }), {}))
  }, [threadGages, part])

  return (
    <TableRow>
      <TableCell>{lineNumber}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {quantity}
        {view === 'PRINT' && ` ${unit}`}
        {!readOnly && !isPurchase && <PartOrderAction companyId={companyId} partOrderId={id} />}
      </TableCell>
      {view !== 'PRINT' && <TableCell>{unit}</TableCell>}
      <TableCell>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {`P/N ${get(part, 'number')} Rev.${get(part, 'revision')}`}
        </Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {get(part, 'name')}
        </Typography>
        <Box>
          {isShowingJobs && (
            <ConnectJobs
              companyId={companyId}
              partId={partId}
              connected={orderJobs}
              connectionsQuery={findPartOrderJobConnections}
              connectionNode={OrderConnectionNode}
              nodePadding={25}
              button={(
                <Stack direction="row" spacing={1} style={{ marginTop: '5px' }}>
                  <Chip
                    color="primary"
                    variant="outlined"
                    icon={<Architecture fontSize="small" style={{ marginLeft: '8px' }} />}
                    label={`Jobs (${orderJobs.filter(({ status }) => status === 'ACTIVE').length})`}
                  />
                </Stack>
              )}
              onConnect={({ jobId, ...data }) => connect({ jobId, partOrderId: id, data })}
              createJobData={{
                quantity,
                dueAt,
                poNumber: get(order, 'number'),
                lineNumber,
                status: 'IN_PROGRESS',
                partId,
              }}
            />
          )}
          {isShowingInvoices && (
            <Stack direction="row" spacing={1} style={{ marginTop: '5px' }}>
              <Chip
                color="primary"
                variant="outlined"
                icon={<RequestQuoteOutlined fontSize="small" style={{ marginLeft: '8px' }} />}
                label="Invoices (0)"
              />
            </Stack>
          )}
          {isShowingShipments && (
            <Stack direction="row" spacing={1} style={{ marginTop: '5px' }}>
              <Chip
                color="primary"
                variant="outlined"
                icon={<LocalShippingOutlined fontSize="small" style={{ marginLeft: '8px' }} />}
                label="Shipments (1)"
              />
            </Stack>
          )}
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {notes}
          </Typography>
        </Box>
      </TableCell>
      {(view === 'SALES' || view === 'PRINT') && (
        <>
          <TableCell align="right">
            {isPriceTbd ? 'TBD' : (
              <NumberFormat
                value={unitPrice}
                prefix="$"
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
                displayType="text"
              />
            )}
          </TableCell>
          <TableCell align="right">
            {isPriceTbd ? 'TBD' : (
              <NumberFormat
                value={unitPrice*quantity}
                prefix="$"
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
                displayType="text"
              />
            )}
          </TableCell>
        </>
      )}
      {view === 'QUALITY' && (
        <>
          <TableCell>
            {get(part, 'threadGages', []).map(({ deviceId }) => (
              <Box style={{ display: 'flex', gap: '5px', marginBottom: '5px' }}>
                <TextField
                  size="small"
                  variant="outlined"
                  name="quantity"
                  value={updatedThreadGages[deviceId]}
                  onChange={({ target: { value } }) => setUpdatedThreadGages({ ...updatedThreadGages, [deviceId]: positiveInt(value) })}
                  style={{ width: '80px' }}
                  InputProps={{
                    inputComponent: MultiplierField,
                  }}
                />
                <SelectDevice
                  companyId={companyId}
                  findType="GAGE"
                  deviceId={deviceId}
                  onChange={device => {}}
                  style={{ minWidth: '200px' }}
                  disableClearable
                />
              </Box>
            ))}
            {!isEqual(threadGages, updatedThreadGages) && Object.keys(updatedThreadGages).length > 0 && (
              <>
                <Button
                  className="tiny"
                  size="small"
                  variant="outlined"
                  startIcon={<Undo />}
                  onClick={() => setUpdatedThreadGages(get(part, 'threadGages', []))}
                  style={{ minWidth: 'auto', marginRight: '5px', padding: '0 10px 0 15px', fontSize: '12px', whiteSpace: 'nowrap' }}
                  disableElevation
                >
                  Revert
                </Button>
                <Button
                  className="tiny"
                  size="small"
                  variant="contained"
                  onClick={() => assign(partOrders.filter(p => p.id === id), updatedThreadGages)}
                  style={{ minWidth: 'auto', marginRight: '5px', padding: '0 10px', fontSize: '12px', whiteSpace: 'nowrap' }}
                  disableElevation
                >
                  Assign
                </Button>
                <Button
                  className="tiny"
                  size="small"
                  variant="outlined"
                  onClick={() => assign(partOrders.filter(p => p.partId === partId), updatedThreadGages)}
                  style={{ minWidth: 'auto', marginRight: '5px', padding: '0 10px', fontSize: '12px', whiteSpace: 'nowrap' }}
                  disableElevation
                >
                  Assign to All ({partOrders.filter(p => p.partId === partId).length})
                </Button>
              </>
            )}
          </TableCell>
        </>
      )}
      <TableCell align="right">
        {isDueAsap ? 'ASAP' : dueAt.toLocaleDateString()}
      </TableCell>
      {!readOnly && (
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          <CreateEditPartOrder
            companyId={companyId}
            data={{
              id,
              partId,
              orderId,
              ...notes && { notes },
              lineNumber,
              quantity,
              unit,
              unitPrice,
              dueAt,
              status,
              isPriceTbd,
              isDueAsap,
            }}
            button={(
              <IconButton size="small">
                <EditOutlined fontSize="small" />
              </IconButton>
            )}
            buttonStyle={{ display: 'inline-block' }}
            onUpdate={onUpdated}
          />
          <CreateEditPartOrder
            companyId={companyId}
            data={{
              partId,
              orderId,
              ...notes && { notes },
              lineNumber,
              quantity,
              unit,
              unitPrice,
              dueAt,
              status: 'OPEN',
              isPriceTbd,
              isDueAsap,
            }}
            button={(
              <IconButton size="small">
                <ContentCopy fontSize="small" />
              </IconButton>
            )}
            buttonStyle={{ display: 'inline-block' }}
            onCreate={onUpdated}
          />
          <Confirm
            button={(
              <IconButton size="small">
                <DeleteOutline fontSize="small" />
              </IconButton>
            )}
            buttonStyle={{ display: 'inline-block' }}
            title="Confirm delete?"
            content="Are you sure you want to delete this line item?"
            onConfirm={() => updatePartOrder(id, { status: 'ARCHIVED', lineNumber: 0 }).then(onUpdated)}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

export default PartOrderRow