import { useState, createContext, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { signInWithEmailLink, signOut as authSignOut } from 'firebase/auth'
import { Box, Typography, TextField, IconButton, Button, Alert, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Search, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get, compact } from 'lodash'
import { SnackbarProvider } from 'notistack'
import { useAsyncFunc, getLocal, setLocal } from '../../functions'
import Accordion from '../Accordion'
import EditInternalMemo from '../EditInternalMemo'
import EditFootnote from '../EditFootnote'
import Actions from './Actions'
import SearchInvoices from './Search'
import EditInvoiceDetails from './EditDetails'
import CreateEditLineItem from './CreateEditLineItem'
import LineItemRow from './LineItemRow'
import RelatedInvoices from './RelatedInvoices'
import { getInvoice, findLineItems, updateInvoice } from './functions'

function Invoices({ companyId }) {
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [invoice, setInvoice] = useState()
  const [lineItems, setLineItems] = useState()

  const navigate = useNavigate()
  const { invoiceId } = useParams()

  useAsyncFunc(async args => {
    if (invoiceId) return getInvoice(args)
    return () => null
  }, invoiceId, setInvoice, [invoiceId])

  useAsyncFunc(async args => {
    if (invoiceId) return findLineItems(args)
    return () => null
  }, { invoiceId, orderBy: ['lineNumber','asc'] }, setLineItems, [invoiceId])

  const fetch = () => getInvoice(get(invoice, 'id')).then(setInvoice)

  const fetchLineItems = () => findLineItems({ invoiceId: get(invoice, 'id'), orderBy: ['createdAt','asc'] }).then(setLineItems)

  return (
    <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
      <Box style={{ width: '23vw', paddingTop: '10px' }}>
        <Box style={{ position: 'sticky', top: '10px' }}>
          <SearchInvoices
            companyId={companyId}
            selected={invoice}
            onSelect={({ id }) => navigate(`/invoices/${id}`)}
          />
        </Box>
      </Box>
      <Box style={{ flexGrow: 1, minHeight: '100vh', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
        <Box style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: 0, padding: '10px', background: '#efefef', zIndex: 2 }}>
          <Actions item={invoice} />
        </Box>
        {invoice && (
          <>
            <Stack>
              <Alert
                severity="info"
                action={(
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<Inbox />}
                      style={{ marginLeft: '5px' }}
                      disableElevation
                    >
                      Send To Customer
                    </Button>
                    <Button
                      size="small"
                      color="success"
                      variant="contained"
                      startIcon={<Inbox />}
                      style={{ marginLeft: '5px' }}
                      disableElevation
                    >
                      Post Payment
                    </Button>
                  </>
                )}
              >
                {`Invoice is ${invoice.status.replace('_',' ')}.`}
              </Alert>
            </Stack>
            <Box style={{ padding: '10px' }}>
              <Accordion
                title="Invoice Details"
                content={(
                  <EditInvoiceDetails
                    invoice={invoice}
                    onUpdate={setInvoice}
                  />
                )}
                defaultExpanded
              />
              <Accordion
                title="Internal Memo"
                content={(
                  <>
                    <Stack>
                      <Alert
                        severity="info"
                      >
                        For internal use only. Not visible to customer.
                      </Alert>
                    </Stack>
                    <EditInternalMemo
                      refFromId={invoice.id}
                    />
                  </>
                )}
              />
              <CreateEditLineItem
                companyId={companyId}
                data={{
                  invoiceId: invoice.id,
                  unit: 'PIECE',
                }}
                onCreate={fetchLineItems}
                forceOpen={isAddOpen}
                setForceOpen={setIsAddOpen}
              />
              <Accordion
                title={(
                  <>
                    Invoice Items
                    <Button
                      size="small"
                      variant="outlined"
                      style={{ marginLeft: '10px' }}
                      startIcon={<Add />}
                      onClick={e => {
                        e.stopPropagation()
                        setIsAddOpen(true)
                      }}
                    >
                      Add Line Item
                    </Button>
                  </>
                )}
                afterTitle={(
                  <Box style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  </Box>
                )}
                content={(
                  <Box style={{ minHeight: '450px', padding: '5px', border: '1px solid #ccc' }}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>PO</TableCell>
                            <TableCell>Line</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Part</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Extended Price</TableCell>
                            <TableCell align="right">Ship Date</TableCell>
                            <TableCell align="right">Due Date</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(lineItems || []).map(lineItem => (
                            <LineItemRow
                              key={lineItem.id}
                              {...lineItem}
                              invoice={invoice}
                              onUpdated={fetchLineItems}
                              isLineIncluded
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                defaultExpanded
              />
              <Accordion
                title="Footnote"
                content={(
                  <EditFootnote
                    note={invoice.footnote}
                    onSubmit={footnote => updateInvoice(invoice.id, { footnote }).then(fetch)}
                  />
                )}
              />
              <Accordion
                title="Related Invoices"
                content={(
                  <RelatedInvoices
                    companyId={companyId}
                    partIds={(lineItems || []).map(({ partId }) => partId)}
                    poNumbers={compact((lineItems || []).map(({ refPoNumber }) => refPoNumber))}
                    excludeIds={(lineItems || []).map(({ id }) => id)}
                  />
                )}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Invoices
