import { useState, useEffect } from 'react'
import { TextField, Typography, Chip, Tooltip } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { DownloadForOfflineOutlined } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { addErrorProps, useAsyncFunc } from '../../functions'
import Radio from '../Radio'
import Paginate from '../Paginate'
import { findParts, getPart, findPartDetails } from './functions'
import CreatePart from './Create'

const queryByOptions = [
  { label: 'Name', value: 'nameBeginsWith' },
  { label: 'Number', value: 'numberBeginsWith' },
]
const getLowerCaseQueryByLabel = value => queryByOptions.find(option => option.value === value).label.toLowerCase()

const filter = createFilterOptions()

function SelectPart({ companyId, partId, assyPartId, onChange, style, selectedLabel, AutocompleteProps, inputProps, dense }) {
  const [isCreating, setIsCreating] = useState(false)
  const [query, setQuery] = useState()
  const [queryBy, setQueryBy] = useState('numberBeginsWith')
  const [parts, setParts] = useState([])
  const [part, setPart] = useState()
  const [newPart, setNewPart] = useState()
  const [detailParts, setDetailParts] = useState()
  const [limit, setLimit] = useState(10)

  useAsyncFunc(findParts, { companyId, [queryBy]: query, limit, orderBy: ['updatedAt','desc'] }, setParts, [companyId, query, limit])

  useEffect(() => {
    onChange(part)
    if (part || assyPartId) findPartDetails({ partId: assyPartId || part.id, orderBy: ['createdAt','asc'] })
              .then(async partDetails => await Promise.all(partDetails.map(({ detailPartId }) => getPart(detailPartId))))
              .then(setDetailParts)
  }, [part, assyPartId])

  useEffect(() => {
    if (partId) getPart(partId).then(setPart)
    else setPart()
  }, [partId])

  return (
    <>
      <CreatePart
        companyId={companyId}
        forceOpen={isCreating}
        setForceOpen={setIsCreating}
        data={newPart}
        onCreate={setPart}
      />
      <Autocomplete
        open={partId}
        value={part || ''}
        options={parts}
        onInputChange={(event, value, reason) => setQuery(value)}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              setIsCreating(true)
              setNewPart({ [getLowerCaseQueryByLabel(queryBy)]: newValue })
            })
          } else if (newValue && newValue.inputValue) {
            setIsCreating(true)
            setNewPart({ [getLowerCaseQueryByLabel(queryBy)]: newValue.inputValue })
          } else {
            setPart(newValue)
          }
        }}
        renderInput={params => (
          <Tooltip title={get(part, 'id') ? `${part.number} · ${get(part, 'name', 'Unnamed')} · Rev.${get(part, 'revision', '-')}` : ''} placement="top">
            <TextField
              {...params}
              size="small"
              variant="outlined"
              // label="Part"
              placeholder="Select a part OR search parts by..."
              fullWidth
              style={{
                ...params.style,
                ...style,
              }}
              {...dense && { inputProps: { ...params.inputProps, style: { ...params.inputProps.style, padding: '0 0 0 5px' } } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <Radio
                      options={queryByOptions}
                      value={queryBy}
                      onChange={({ target: { value } }) => setQueryBy(value)}
                      optionCtnStyle={{ flexDirection: 'row', justifyContent: 'end' }}
                    />
                  </>
                ),
              }}
              {...inputProps}
            />
          </Tooltip>
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if ((part || assyPartId) && !query) return [
            {
              label: <Typography variant="overline">Components & materials</Typography>,
              disabled: true,
            },
            ...(detailParts || []),
          ]
          // if (filtered.length > 0) {
          //   filtered.unshift({
          //     label: <Typography variant="overline">Recent</Typography>,
          //     disabled: true,
          //   })
          // }
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add new part with ${getLowerCaseQueryByLabel(queryBy)} "${params.inputValue}"`,
            })
          }
          if (filtered.length > 0) {
            filtered.push({
              label: (
                <Paginate
                  label="Load more"
                  size="small"
                  variant="outlined"
                  startIcon={<DownloadForOfflineOutlined />}
                  disableElevation
                  onMouseEnter={() => setLimit(limit + 10)}
                  query={() => findParts({
                    companyId,
                    [queryBy]: query,
                    limit,
                    orderBy: ['updatedAt','desc'],
                    startAfter: get(parts[parts.length - 1], '_snap'),
                  })}
                  fullWidth
                />
              ),
            })
          }
          return filtered
        }}
        getOptionDisabled={option => option.disabled}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return selectedLabel ? selectedLabel(option) : `${option.number} · ${get(option, 'name', 'Unnamed')} · Rev.${get(option, 'revision', 0)}`
        }}
        renderOption={(props, option) => (
          <li {...props} {...option.inputValue && { style: { ...props.style, color: '#1a73e8' } }}>
            {option.id ? (
              <>
                <Chip
                  color={option.type === 'ASSY' ? 'secondary' : 'primary'}
                  variant="outlined"
                  label={option.type}
                  style={{ marginRight: '10px', borderWidth: '2px', fontSize: '.75rem', fontWeight: 'bold' }}
                />
                {queryBy === 'numberBeginsWith' && <span style={{ fontWeight: 'bold' }}>{!part && query}</span>}
                {`${option.number.replace(queryBy === 'numberBeginsWith' ? query : '', '')} · `}
                {queryBy === 'nameBeginsWith' && <span style={{ fontWeight: 'bold' }}>{!part && query}</span>}
                {`${get(option, 'name', 'Unnamed').replace(queryBy === 'nameBeginsWith' ? query : '', '')} · Rev.${get(option, 'revision', '-')}`}
              </>
            ) : (
              <>
                {option.label}
              </>
            )}
          </li>
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        {...AutocompleteProps}
      />
    </>
  )
}

export default SelectPart