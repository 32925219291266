import { useState, useEffect } from 'react'
import { Skeleton } from '@mui/material'
import { reduce } from 'lodash'
import { useAsyncFunc } from '../../functions'

function PartCount({ partId, addFunc, subtractFunc, countOn, prefix, display, suffix, onResultsAdded, onResultsSubtracted }) {
  const [resultsToAdd, setResultsToAdd] = useState()
  const [resultsToSubtract, setResultsToSubtract] = useState()

  useAsyncFunc(async () => {
    if (partId && addFunc) return addFunc(partId)
    return () => []
  }, null, setResultsToAdd, [partId, addFunc])

  useAsyncFunc(async () => {
    if (partId && subtractFunc) return subtractFunc(partId)
    return () => []
  }, null, setResultsToSubtract, [partId, subtractFunc])

  const count = results => reduce(results, (sum, { [countOn || 'quantity']: quantity }) => sum + quantity, 0)

  useEffect(() => {
    if (onResultsAdded) onResultsAdded(resultsToAdd)
  }, [resultsToAdd])

  useEffect(() => {
    if (onResultsSubtracted) onResultsSubtracted(resultsToSubtract)
  }, [resultsToSubtract])

  const quantityBalance = count(resultsToAdd) - count(resultsToSubtract)

  return (resultsToAdd && resultsToSubtract) ? (
    <>
      {prefix && prefix({ resultsToAdd, resultsToSubtract, quantityBalance })}
      {display ? display({ resultsToAdd, resultsToSubtract, quantityBalance }) : (count(resultsToAdd) - count(resultsToSubtract))}
      {suffix && suffix({ resultsToAdd, resultsToSubtract, quantityBalance })}
    </>
  ) : <Skeleton variant="rectangular" animation="wave" />
}

export default PartCount