import { useState, useEffect } from 'react'
import { Typography, IconButton, Accordion as MuiAccordion, AccordionSummary, AccordionDetails, Tooltip } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

function Accordion({ title, afterTitle, content, onToggle, ...props }) {
  const [isOpen, setIsOpen] = useState(!!props.defaultExpanded)

  useEffect(() => {
    setIsOpen(props.expanded !== undefined ? props.expanded : props.defaultExpanded)
  }, [props.expanded, props.defaultExpanded])

  return (
    <MuiAccordion
      expanded={isOpen}
      elevation={0}
      onChange={(e, expanded) => {
        setIsOpen(expanded)
        if (onToggle) onToggle(expanded)
      }}
      {...props}
    >
      <AccordionSummary>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          <Tooltip title={`Show ${isOpen ? 'less' : 'more'}`}>
            <IconButton size="large" edge="start">
              {isOpen ? <Remove /> : <Add />}
            </IconButton>
          </Tooltip>
          {title}
        </Typography>
        {afterTitle}
      </AccordionSummary>
      <AccordionDetails>
        {content}
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion