import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import ContactProp from '../Contacts/ContactProp'
import { getOrder, getPartOrder } from './functions'

function OrderContactProp({ orderId, partOrderId, contactIdField, prop }) {
  const [order, setOrder] = useState()

  useAsyncFunc(async args => {
    if (orderId) return getOrder(args)
    return () => null
  }, orderId, setOrder, [orderId])

  useAsyncFunc(async args => {
    if (partOrderId) {
      const { orderId } = await getPartOrder(args)
      return getOrder(orderId)
    }
    return () => null
  }, partOrderId, setOrder, [partOrderId])

  return get(order, contactIdField) ? <ContactProp contactId={get(order, contactIdField)} prop={prop} /> : <Skeleton variant="rectangular" animation="wave" />
}

export default OrderContactProp