import { useState } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import NumberFormat from 'react-number-format'
import { get, lowerCase, startCase } from 'lodash'
import { useAsyncFunc } from '../../functions'
import PartName from '../Parts/PartName'
import PartNumber from '../Parts/PartNumber'
import { findShipmentLines } from '../Shipments/functions'
import { findPartOrders } from './functions'

function LookupPrice({ partId, quantity, unit, unitPrice, onUpdate }) {
  const [partOrder, setPartOrder] = useState()

  useAsyncFunc(findPartOrders, { partId, quantity, orderBy: ['updatedAt','desc'] }, ([partOrder]) => setPartOrder(partOrder), [partId, quantity, unitPrice])

  return unitPrice === 1 && (
    <Button
      className="tiny"
      size="small"
      variant="outlined"
      onClick={() => onUpdate(get(partOrder, 'unitPrice', ''))}
      style={{ minWidth: 'auto', marginLeft: '5px', padding: '0 10px', fontSize: '12px', whiteSpace: 'nowrap' }}
      disableElevation
      disabled={!partOrder}
    >
      <NumberFormat
        value={get(partOrder, 'unitPrice', '')}
        prefix="$"
        thousandSeparator
        fixedDecimalScale
        decimalScale={2}
        displayType="text"
      />
    </Button>
  )
}

export default LookupPrice