import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { findJournalEntries } from './functions'

function JournalEntriesCount({ companyId, partId, type, renderCount, renderItems, limit }) {
  const [journalEntries, setJournalEntries] = useState()

  useAsyncFunc(async partId => {
    if (partId) return findJournalEntries({ companyId, partId, type, orderBy: ['createdAt','desc'], limit })
  }, partId, setJournalEntries, [partId])

  if (journalEntries) {
    if (renderItems) return renderItems(journalEntries)
    if (renderCount) return renderCount(journalEntries.length)
    return journalEntries.length
  }

  return <Skeleton variant="rectangular" animation="wave" />
}

export default JournalEntriesCount