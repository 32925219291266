import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getJob, getJobLine } from '../Jobs/functions'
import { getPart } from './functions'

function PartNumber({ partId, jobLineId }) {
  const [part, setPart] = useState()

  useAsyncFunc(async ({ partId, jobLineId }) => {
    if (partId) return getPart(partId)
    const { jobId } = await getJobLine(jobLineId)
    const job = await getJob(jobId)
    return getPart(job.partId)
  }, { partId, jobLineId }, setPart, [partId, jobLineId])

  return get(part, 'number', <Skeleton variant="rectangular" animation="wave" />)
}

export default PartNumber