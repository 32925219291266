import { useState } from 'react'
import { Button } from '@mui/material'
import { Add, DoNotDisturbAlt } from '@mui/icons-material'
import { useAsyncFunc, findCompanyRefs } from '../../functions'
import Dropdown from '../Dropdown'
import Assignee from './Assignee'
import CreateAssignee from './CreateAssignee'

function SelectAssignee({ companyId, button, onChange }) {
  const [assignees, setAssignees] = useState([])

  const fetch = () => findCompanyRefs({ companyId, role: 'VENDOR', orderBy: ['name','asc'] }).then(setAssignees)

  useAsyncFunc(findCompanyRefs, { companyId, role: 'VENDOR', orderBy: ['name','asc'] }, setAssignees, [companyId])

  return (
    <Dropdown
      button={button}
      beforeItems={(
        <Button
          startIcon={<DoNotDisturbAlt />}
          onClick={() => onChange({ value: null })}
        >
          Unassign
        </Button>
      )}
      closeOnBeforeItemsClick
      items={assignees.map(({ id, name }) => ({
        label: (
          <>
            <Assignee id={id} />
            {name}
          </>
        ),
        value: id,
      }))}
      afterItems={(
        <CreateAssignee
          companyId={companyId}
          button={(
            <Button
              startIcon={<Add />}
            >
              New Assignee
            </Button>
          )}
          onCreate={fetch}
        />
      )}
      onChange={onChange}
    />
  )
}

export default SelectAssignee