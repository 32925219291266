import { useState, useEffect } from 'react'
import { Box, TextField, Button, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Tooltip } from '@mui/material'
import { FilterList, Clear } from '@mui/icons-material'
import MUIDataTable from "mui-datatables"
import { xor, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { jobRanges, jobStatuses, partTypes, getDate } from '../functions'
import { findContacts } from './Contacts/functions'
import ContactProp from './Contacts/ContactProp'
import { findLineItems } from './Invoices/functions'
import { findJobs } from './Jobs/functions'
import { findJournalEntries } from './Journal/functions'
import { findManuals } from './Manual/functions'
import { findPartOrders } from './Orders/functions'
import { findParts } from './Parts/functions'
import PartProp from './Parts/PartProp'
import { findPartQuotes } from './Quotes/functions'
import { findShipmentLines } from './Shipments/functions'
import Accordion from './Accordion'
import Dropdown from './Dropdown'
import Radio from './Radio'
import RefInventory from './RefInventory'
import Timeline from './Timeline'

const defaultRanges = [
  { label: 'Created within',
    value: ['createdAtFrom', 'createdAtTo', 'orderBy'],
    options: [
      { label: 'Last year', value: [getDate(null, 'soy', -1), getDate(null, 'eoy', -1), ['createdAt','desc']] },
      { label: 'Last month', value: [getDate(null, 'som', -1), getDate(null, 'eom', -1), ['createdAt','desc']] },
      { label: 'Last week', value: [getDate(null, 'sow', -1), getDate(null, 'eow', -1), ['createdAt','desc']] },
      { label: 'Today', value: [getDate(null, 'sod'), getDate(null, 'eod'), ['createdAt','desc']] },
      { label: 'This week', value: [getDate(null, 'sow'), getDate(null, 'eow'), ['createdAt','desc']] },
      { label: 'This month', value: [getDate(null, 'som'), getDate(null, 'eom'), ['createdAt','desc']] },
      { label: 'This year', value: [getDate(null, 'soy'), getDate(null, 'eoy'), ['createdAt','desc']] },
    ],
  },
  { label: 'Last updated',
    value: ['updatedAtFrom', 'updatedAtTo', 'orderBy'],
    options: [
      { label: 'Last year', value: [getDate(null, 'soy', -1), getDate(null, 'eoy', -1), ['updatedAt','desc']] },
      { label: 'Last month', value: [getDate(null, 'som', -1), getDate(null, 'eom', -1), ['updatedAt','desc']] },
      { label: 'Last week', value: [getDate(null, 'sow', -1), getDate(null, 'eow', -1), ['updatedAt','desc']] },
      { label: 'Today', value: [getDate(null, 'sod'), getDate(null, 'eod'), ['updatedAt','desc']] },
      { label: 'This week', value: [getDate(null, 'sow'), getDate(null, 'eow'), ['updatedAt','desc']] },
      { label: 'This month', value: [getDate(null, 'som'), getDate(null, 'eom'), ['updatedAt','desc']] },
      { label: 'This year', value: [getDate(null, 'soy'), getDate(null, 'eoy'), ['updatedAt','desc']] },
    ],
  }]
const defaultColumns = [{ name: 'createdAt', label: 'Created', options: { customBodyRender: value => value.toLocaleString() } }]

const types = [
  { label: 'Parts', value: 'parts', query: findParts,
    columns: [
      { name: 'number', label: 'Number' },
      { name: 'name', label: 'Name' },
      { name: 'revision', label: 'Rev' },
      ...defaultColumns,
    ],
    filters: [{ label: 'Type', value: 'type', options: partTypes }, { label: 'Created by', value: 'createdByUserId', options: [] }],
    ranges: [...defaultRanges] },
  { label: 'Jobs', value: 'jobs', query: findJobs,
    filters: [{ label: 'Status', options: jobStatuses }, { label: 'Created by', options: [] }, { label: 'Part', options: [] }],
    ranges: [...defaultRanges] },
  { label: 'Orders', value: 'orders', query: findPartOrders,
    filters: [{ label: 'Status', options: jobStatuses }, { label: 'Created by', options: [] }, { label: 'Part', options: [] }],
    ranges: [...defaultRanges] },
  { label: 'Shipments', value: 'shipments', query: findShipmentLines,
    filters: [{ label: 'Status', options: jobStatuses }, { label: 'Created by', options: [] }, { label: 'Part', options: [] }],
    ranges: [...defaultRanges] },
  { label: 'Invoices', value: 'invoices', query: findLineItems,
    filters: [{ label: 'Status', options: jobStatuses }, { label: 'Created by', options: [] }, { label: 'Part', options: [] }],
    ranges: [...defaultRanges] },
  { label: 'Quotes', value: 'quotes', query: findPartQuotes,
    filters: [{ label: 'Status', options: jobStatuses }, { label: 'Created by', options: [] }, { label: 'Part', options: [] }],
    ranges: [...defaultRanges] },
  { label: 'Contacts', value: 'contacts', query: findContacts,
    columns: [
      { name: 'contactName', label: 'Contact name' },
      { name: 'name', label: 'Name' },
      { name: 'email', label: 'Email' },
      { name: 'phone', label: 'Phone' },
      { name: 'type', label: 'Type' },
      ...defaultColumns,
    ],
    ranges: [...defaultRanges] },
  { label: 'Journal Entries', value: 'journalEntries', query: findJournalEntries,
    columns: [
      { name: 'number', label: 'Number' },
      { name: 'numberRef', label: 'Reference number' },
      { name: 'eventAt', label: 'Incident date', options: { customBodyRender: value => value && value.toLocaleString() } },
      { name: 'createdAt', label: 'File date', options: { customBodyRender: value => value && value.toLocaleString() } },
      { name: 'dueAt', label: 'Due date', options: { customBodyRender: value => value && value.toLocaleString() } },
      { name: 'shipFromContactId', label: 'Ship from', options: { customBodyRender: value => value && <ContactProp contactId={value} prop="name" /> } },
      { name: 'shipToContactId', label: 'Ship to', options: { customBodyRender: value => value && <ContactProp contactId={value} prop="name" /> } },
      { name: 'partId', label: 'Part', options: { customBodyRender: value => value && <><PartProp partId={value} prop="number" /> Rev.<PartProp partId={value} prop="revision" /></> } },
      { name: 'quantityReceived', label: 'Received' },
      { name: 'quantityInspected', label: 'Inspected' },
      { name: 'quantityAccepted', label: 'Accepted' },
      { name: 'quantityRejected', label: 'Rejected' },
      { name: 'status', label: 'Status' },
      { name: 'type', label: 'Type' },
      { name: 'dispositionType', label: 'dispositionType' },
      { name: 'disposition', label: 'disposition' },
      { name: 'nonconformanceCauseRequired', label: 'nonconformanceCauseRequired' },
      { name: 'nonconformanceCauseIsolated', label: 'nonconformanceCauseIsolated' },
      { name: 'correctiveActionRequired', label: 'correctiveActionRequired' },
      { name: 'correctiveActionIsolated', label: 'correctiveActionIsolated' },
    ],
    filters: [{ label: 'Status', options: jobStatuses }, { label: 'Created by', options: [] }, { label: 'Part', options: [] }],
    ranges: [...defaultRanges] },
  { label: 'Manual Entries', value: 'manuals', query: findManuals,
    ranges: [...defaultRanges] },
]

function Export({ companyId, button, buttonStyle, inline, forceOpen, setForceOpen }) {
  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState(types[0])
  const [filterBy, setFilterBy] = useState({})
  const [rangeBy, setRangeBy] = useState()
  const [results, setResults] = useState()
  const [limit, setLimit] = useState(999)

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  useEffect(() => {
    if (companyId) {
      type.query({ companyId, ...filterBy, ...rangeBy || { orderBy: ['createdAt','desc'] }, limit }).then(setResults)
    }
  }, [companyId, type, filterBy, rangeBy, limit])

  const content = (
    <>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '20%' }}>
          <Dropdown
            button={(
              <Button
                size="small"
                variant="outlined"
                startIcon={<FilterList />}
              >
                {type.label}
              </Button>
            )}
            items={types}
            onChange={setType}
          />
        </Box>
        <Box style={{ width: '45%', display: 'flex', gap: '5px' }}>
          {(type.filters || []).map(({ label, value: field, options }) => (
              <Dropdown
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={filterBy[field] ? <Tooltip title="Clear filter"><Clear onClick={() => setFilterBy({ ...filterBy, [field]: undefined })} /></Tooltip> : <FilterList />}
                  >
                    {`${label}${filterBy[field] ? `: ${filterBy[field]}` : ''}`}
                  </Button>
                )}
                items={options}
                onChange={({ value }) => setFilterBy({ ...filterBy, [field]: value })}
              />
          ))}
        </Box>
        <Box style={{ width: '35%', display: 'flex', gap: '5px' }}>
          {(type.ranges || []).map(({ label, value: args, options }) => (
              <Dropdown
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={rangeBy && isEqual(args, Object.keys(rangeBy)) ? <Tooltip title="Clear filter"><Clear onClick={() => setRangeBy()} /></Tooltip> : <FilterList />}
                  >
                    {label}
                  </Button>
                )}
                items={options}
                onChange={({ value }) => {
                  const rangeBy = {}
                  args.map((arg, index) => rangeBy[arg] = value[index])
                  setRangeBy(rangeBy)
                }}
              />
          ))}
        </Box>
      </Box>
      {results && (
        <MUIDataTable
          title={`${results.length} ${type.label} found`}
          data={results}
          columns={type.columns}
        />
      )}
    </>
  )

  return inline ? content : (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Export</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            {content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Export