import { useState, useEffect } from 'react'
import { TableRow, TableCell, TextField, IconButton, Typography } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import { positiveInt, positiveFloat } from '../../functions'
import MultiplierField from '../MultiplierField'

function PriceRow({ quantity, markupRate, index, basePrice, leadTime, leadTimeUnit, partQuote, readOnly, onChange, onRemove, changesClearedAt }) {
  const [updates, setUpdates] = useState()

  useEffect(() => {
    if (updates) onChange(updates)
  }, [updates])

  useEffect(() => {
    if (changesClearedAt) setUpdates()
  }, [changesClearedAt])

  return (
    <TableRow style={{ background: index%2 === 0 ? '#efefef' : 'none' }}>
      <TableCell>
        <IconButton size="small" onClick={onRemove}>
          <DeleteOutline fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell>Quote for</TableCell>
      <TableCell align="right">{readOnly ? quantity : (
        <TextField
          size="small"
          variant="outlined"
          value={get(updates, 'quantity', quantity)}
          onChange={({ target: { value } }) => setUpdates({ ...updates, quantity: positiveInt(value) })}
          style={{ maxWidth: '75px', transform: 'translateX(5px)' }}
        />
      )}</TableCell>
      <TableCell>unit</TableCell>
      <TableCell align="right">{`$${(basePrice*markupRate).toFixed(2)}`}</TableCell>
      <TableCell align="right">{readOnly ? `(${markupRate}x)` : (
        <TextField
          size="small"
          variant="outlined"
          value={get(updates, 'markupRate', markupRate)}
          onChange={({ target: { value } }) => setUpdates({ ...updates, markupRate: positiveFloat(value) })}
          style={{ maxWidth: '75px', transform: 'translateX(5px)' }}
          InputProps={{
            inputComponent: MultiplierField,
          }}
        />
      )}</TableCell>
      <TableCell align="right">
        <NumberFormat
          value={basePrice*markupRate*quantity}
          prefix="$"
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          displayType="text"
        />
      </TableCell>
      <TableCell align="right">{readOnly ? `${leadTime || partQuote.leadTime} ${leadTimeUnit || partQuote.leadTimeUnit}` : (
        <TextField
          size="small"
          variant="outlined"
          value={get(updates, 'leadTime', leadTime || partQuote.leadTime)}
          onChange={({ target: { value } }) => setUpdates({ ...updates, leadTime: positiveInt(value) })}
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="GrayText">{leadTimeUnit || partQuote.leadTimeUnit}</Typography>
            ),
          }}
          style={{ maxWidth: '120px' }}
        />
      )}</TableCell>
    </TableRow>
  )
}

export default PriceRow