import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getPartOrder, getOrder } from '../Orders/functions'
import { getShipment } from './functions'

function ShipmentBound({ companyId, shipmentId, partOrderId, inbound, outbound }) {
  const [shipment, setShipment] = useState()
  const [order, setOrder] = useState()

  useAsyncFunc(async shipmentId => {
    if (shipmentId) return getShipment(shipmentId)
  }, shipmentId, setShipment, [shipmentId])

  useAsyncFunc(async partOrderId => {
    if (partOrderId) {
      const { orderId } = await getPartOrder(partOrderId)
      return getOrder(orderId)
    }
  }, partOrderId, setOrder, [partOrderId])

  return (shipment || order) ?
    get(shipment, 'shipFromContactId', get(order, 'shipFromContactId')) === companyId ? inbound : outbound
    : <Skeleton variant="rectangular" animation="wave" />
}

export default ShipmentBound