import _ from 'lodash'
import { useEffect } from 'react'
import { collection, query, where, orderBy, limit, doc, getDoc, addDoc, updateDoc, startAt, startAfter, endBefore, limitToLast, getDocs } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { db, storage, Timestamp } from './Firebase'
import { findJobs } from './components/Jobs/functions'

const process = data => _.mapValues(data, v => v instanceof Timestamp ? v.toDate() : v)
const unsnap = snap => ({
  ...process(snap.data()),
  id: snap.id,
  _snap: snap,
  _collection: snap._key.path.segments.at(-2),
})
export const collect = snap => snap.docs ? snap.docs.map(unsnap) : unsnap(snap)
export const get = (col, id) => getDoc(doc(db, col, id)).then(collect)
export const add = (col, data) => addDoc(collection(db, col), { ...data, createdByUserId: getCurrentUserId(), createdAt: new Date(), updatedAt: new Date() }).then(getDoc).then(collect).then(data => updateCount(col, data))
export const update = async (col, id, updates, logCol) => {
  const createdByUserId = getCurrentUserId()
  const { _snap, ...before } = await get(col, id)
  await updateDoc(doc(db, col, id), { ...updates, updatedAt: new Date() })
  const { _snap: snapAfter, ...after } = await get(col, id)
  if (logCol) await add(logCol, { before, after, createdByUserId })
  return { _snap: snapAfter, ...after }
}

export const getCurrentUserId = () => getAuth().currentUser.uid

export const findCount = (col, companyId) => getDocs(query(collection(db, 'counts'),
  where('companyId', '==', companyId),
  where('col', '==', col),
  orderBy('updatedAt', 'desc'),
  limit(1),
)).then(collect).then(([ count ]) => count)

export const getCount = (col, companyId) => findCount(col, companyId).then(count => _.get(count, 'value', 0))

export const updateCount = async (col, data) => {
  if (data.companyId && col !== 'counts') {
    const count = await findCount(col, data.companyId)
    if (count) await update('counts', count.id, { value: count.value + 1 })
    else await add('counts', {
      companyId: data.companyId,
      col,
      value: 1,
    })
  }
  return data
}

export const getGlobal = name => get('globals', name).then(({ value }) => value)

export const setLocal = (name, value) => window.localStorage.setItem(name, JSON.stringify(value))

export const getLocal = (name, defaultValue) => {
  const value = window.localStorage.getItem(name)
  if (value) return JSON.parse(value, (name, value) => {
    if (typeof value === 'string' && /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ$/.test(value)) return new Date(value)
    return value
  })
  setLocal(name, defaultValue)
  return defaultValue
}

export const beginsWith = (field, value) => [where(field, '>=', value), where(field, '<=', value + '\uf8ff'), orderBy(field, 'asc')]

export const positiveInt = value => !value || parseInt(value) < 0 ? 0 : parseInt(value)

export const positiveFloat = value => !value || parseFloat(value) < 0 ? 0 : parseFloat(value)

export const uploadFile = async file => {
  const { id } = await add('files', {
    companyId: await getCompanyId(),
    name: file.name,
    size: file.size,
    type: file.type,
    lastModifiedAt: file.lastModifiedDate,
  })
  const fileRef = ref(storage, `files/${id}`)
  const upload = await uploadBytes(fileRef, file)
  const url = await getDownloadURL(upload.ref)
  return update('files', id, { url })
}

export const addErrorProps = (errors, fieldName) => ({ error: !!_.get(errors, fieldName), helperText: _.get(errors, fieldName) })

export const getDateDiffStr = (fromDate, toDate, nominalUnit) => {
  const diff = getDateDiff(fromDate, toDate)
  const unit = nominalUnit ||
    (diff.years > 0 ? 'years' :
    diff.months > 0 ? 'months' :
    diff.weeks > 0 ? 'weeks' :
    diff.days > 0 ? 'days' :
    diff.hours > 0 ? 'hours' :
    diff.minutes > 0 ? 'minutes' :
    diff.seconds > 0 ? 'seconds' :
    null)
  const getStr = (d, u) => `${d[u]} ${d[u] > 1 ? u : u.slice(0, -1)}`
  if (diff.isPassed) {
    return unit ? `${getStr(diff, unit)} ago` : 'just now'
  }
  return unit ? `in ${getStr(diff, unit)}` : 'shortly'
}

export const getDateDiff = (fromDate, toDate) => {
  const date = fromDate || new Date()
  const diff = {
    isPassed: toDate - date < 0,
    miliseconds: Math.abs(toDate - date),
  }
  diff.years = Math.floor(diff.miliseconds/1000/60/60/24/365)
  diff.months = Math.floor(diff.miliseconds/1000/60/60/24/31)
  diff.weeks = Math.floor(diff.miliseconds/1000/60/60/24/7)
  diff.days = Math.floor(diff.miliseconds/1000/60/60/24)
  diff.hours = Math.floor(diff.miliseconds/1000/60/60)
  diff.minutes = Math.floor(diff.miliseconds/1000/60)
  diff.seconds = Math.floor(diff.miliseconds/1000)
  return diff
}

export const getDate = (fromDate, operation, delta, unit) => {
  const date = fromDate ? new Date(fromDate.getTime()) : new Date()
  let multiplier = 1000
  if (unit === 'minute') multiplier*=60
  if (unit === 'hour') multiplier*=60*60
  if (unit === 'day') multiplier*=24*60*60
  if (unit === 'week') multiplier*=7*24*60*60
  if (unit === 'month') multiplier*=31*24*60*60
  if (unit === 'year') multiplier*=365*24*60*60
  if (operation === '-') {
    date.setTime(date.getTime() - delta * multiplier)
  }
  if (operation === '+') {
    date.setTime(date.getTime() + delta * multiplier)
  }
  if (operation === 'soy') {
    if (delta) date.setTime(date.getTime() + delta * 365*24*60*60 * multiplier)
    date.setMonth(1)
    date.setDate(1)
    date.setHours(0,0,0,0)
  }
  if (operation === 'som') {
    if (delta) date.setTime(date.getTime() + delta * 31*24*60*60 * multiplier)
    date.setDate(1)
    date.setHours(0,0,0,0)
  }
  if (operation === 'sow') {
    if (delta) date.setTime(date.getTime() + delta * 7*24*60*60 * multiplier)
    date.setDate(date.getDate() - date.getDay())
    date.setHours(0,0,0,0)
  }
  if (operation === 'sod') {
    date.setHours(0,0,0,0)
  }
  if (operation === 'eod') {
    date.setHours(23,59,59,999)
  }
  if (operation === 'eow') {
    if (delta) date.setTime(date.getTime() + delta * 7*24*60*60 * multiplier)
    date.setDate(date.getDate() + 6 - date.getDay())
    date.setHours(23,59,59,999)
  }
  if (operation === 'eom') {
    if (delta) date.setTime(date.getTime() + delta * 31*24*60*60 * multiplier)
    date.setMonth(date.getMonth() + 1)
    date.setDate(1)
    date.setHours(23,59,59,999)
    date.setTime(date.getTime() - 24*60*60 * multiplier)
  }
  if (operation === 'eoy') {
    if (delta) date.setTime(date.getTime() + delta * 365*24*60*60 * multiplier)
    date.setMonth(12)
    date.setDate(31)
    date.setHours(23,59,59,999)
  }
  return date
}

export const includeSearchIndices = (data, fields) => ({
  ...data,
  searchIndices: _.uniq(
    fields.map(field =>
      [...data[field]].map((_c, index) =>
        data[field].substring(0, index + 1).toLowerCase()
      ),
    ).flat(),
  ),
})

export const jobRanges = [
  { label: 'Last month', value: [getDate(null, 'som', -1), getDate(null, 'eom', -1)] },
  { label: 'Last week', value: [getDate(null, 'sow', -1), getDate(null, 'eow', -1)] },
  { label: 'Today', value: [getDate(null, 'sod'), getDate(null, 'eod')] },
  { label: 'This week', value: [getDate(null, 'sow'), getDate(null, 'eow')] },
  { label: 'This month', value: [getDate(null, 'som'), getDate(null, 'eom')] },
  { label: 'Next week', value: [getDate(null, 'sow', 1), getDate(null, 'eow', 1)] },
  { label: 'Next month', value: [getDate(null, 'som', 1), getDate(null, 'eom', 1)] },
]

export const jobStatuses = [
  { label: 'Not Started', value: 'NOT_STARTED' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Shipped', value: 'SHIPPED', dividedBefore: true },
  { label: 'On Hold', value: 'ON_HOLD', dividedBefore: true },
  { label: 'Cancelled', value: 'CANCELLED' },
]

export const partTypes = [
  { label: 'Assembly', value: 'ASSY' },
  { label: 'Part', value: 'PART' },
  { label: 'COTS', value: 'COTS' },
  { label: 'Material', value: 'MATL' },
  { label: 'Service', value: 'SERV' },
]

export const deviceTypes = [
  { label: 'Test Gages', value: 'GAGE' },
]

export const gageTypes = [
  { label: 'Thread Ring Gage', value: 'Thread Ring Gage' },
  { label: 'Thread Plug Gage', value: 'Thread Plug Gage' },
]

export const apiHost = 'https://us-central1-smartio-cloud.cloudfunctions.net/'

export const sendEmail = ({ to, subject, text, html }) => fetch(`${apiHost}sendEmail`, { method: 'POST', body: JSON.stringify({ to, subject, text, html }), headers: { 'Content-Type': 'application/json' } }).then(res => res.json())

export const getCompanyId = async () => await getGlobal('companyIdDefault')

export const findFileRefs = async ({ refFromType, refFromId, refFromIds, fileId, nameBeginsWith, refNumbersContain, categoriesIn, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'fileRefs'), ...[
    ...refFromType ? [where('refFromType', '==', refFromType)] : [],
    ...refFromId ? [where('refFromId', '==', refFromId)] : [],
    ...refFromIds ?
      refFromIds.length === 1
        ? [where('refFromId', '==', refFromIds[0])]
        : [where('refFromId', 'in', refFromIds)] : [],
    ...fileId ? [where('fileId', '==', fileId)] : [],
    ...nameBeginsWith ? beginsWith('name', nameBeginsWith) : [],
    ...refNumbersContain ? [where('refNumbers', 'array-contains-any', refNumbersContain)] : [],
    ...categoriesIn ? [where('categories', 'array-contains-any', categoriesIn)] : [],
    where('status', '==', 'ACTIVE'),
    orderBy(...order),
    limit(max || await getGlobal('fileRefsQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)

export const addFileRef = data => add('fileRefs', data)

export const addUniqueFileRef = async data => {
  if (await findFileRefs({ refFromId: data.refFromId, fileId: data.fileId, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length > 0)) return
  return add('fileRefs', data)
}

export const updateFileRef = (id, updates) => update('fileRefs', id, updates)

export const getFileRefCategories = () => getGlobal('fileRefCategories')

export const findFiles = async ({ nameBeginsWith, refNumbersContain, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'files'), ...[
    ...nameBeginsWith ? beginsWith('name', nameBeginsWith) : [],
    ...refNumbersContain ? [where('refNumbers', 'array-contains-any', refNumbersContain)] : [],
    where('status', '==', 'ACTIVE'),
    orderBy(...order),
    limit(max || await getGlobal('filesQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before)] : [],
  ]))
  .then(collect)

export const getFile = id => get('files', id)

export const updateFile = (id, updates) => update('files', id, updates, 'fileLogs')

export const findCompanies = async ({ nameBeginsWith, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'companies'), ...[
    ...nameBeginsWith ? beginsWith('name', nameBeginsWith) : [],
    where('status', '==', 'ACTIVE'),
    orderBy(...order),
    limit(max || await getGlobal('companiesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getCompany = id => get('companies', id)

export const addCompany = data => add('companies', data)

export const updateCompany = (id, updates) => update('companies', id, updates)

export const findCompanyRefs = async ({ companyId, nameBeginsWith, role, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'companyRefs'), ...[
    where('companyId', '==', companyId),
    ...nameBeginsWith ? beginsWith('name', nameBeginsWith) : [],
    ...role ? [where('role', '==', role)] : [],
    where('status', '==', 'ACTIVE'),
    orderBy(...order),
    limit(max || await getGlobal('companyRefsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getCompanyRef = id => get('companyRefs', id)

export const addCompanyRef = data => add('companyRefs', data)

export const updateCompanyRef = (id, updates) => update('companyRefs', id, updates)

export const findLogs = async ({ col, id, field, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, col), ...[
    where(field || 'before.id', '==', id),
    orderBy(...order),
    limit(max || await getGlobal('logsQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)
  .then(logs => logs.map(({ before, after, ...rest }) => ({
    ...rest,
    before: process(before),
    after: process(after),
  })))

export const getUser = id => get('users', id)

export const updateUser = (id, updates) => update('users', id, updates, 'userLogs')

export const findInternalMemos = async ({ refFromId, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'internalMemos'), ...[
    where('refFromId', '==', refFromId),
    orderBy(...order),
    limit(max || await getGlobal('internalMemosQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getInternalMemo = id => get('internalMemos', id)

export const addInternalMemo = data => add('internalMemos', data)

export const findTermTemplates = async ({ companyId, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'termTemplates'), ...[
    where('companyId', '==', companyId),
    orderBy(...order),
    limit(max || await getGlobal('termTemplatesQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const getTermTemplate = id => get('termTemplates', id)

export const addTermTemplate = data => add('termTemplates', data)

export const updateTermTemplate = (id, updates) => update('termTemplates', id, updates, 'termTemplateLogs')

export const validUpsTracking = new RegExp(/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/)

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

/**
 * @param {Function} asyncFunc
 * @param {Object} variables
 * @param {Function} onSuccess
 * @param {Array} states
 */

export const useAsyncFunc = (asyncFunc, variables, onSuccess, states) => {
    useEffect(() => {
      let isMounted = true
      asyncFunc(variables).then(data => {
        if (isMounted) onSuccess(data)
      })
      return () => { isMounted = false }
    }, states)
  }

export const sequence = (array, process) => array.reduce(
  (p, x, i) =>
    p.then(() => process(x, i)),
  Promise.resolve()
)