import { useState, useEffect, createContext, useContext } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged, sendSignInLinkToEmail } from 'firebase/auth'

const AuthContext = createContext()

export function AuthProvider(props) {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      setUser(user)
      navigate(location.pathname, { replace: true })
    }, setError)
    return () => unsubscribe()
  }, [])

  return <AuthContext.Provider value={{ user, error }} {...props} />
}

export function useAuth() {
  return useContext(AuthContext)
}

export function RequireAuth({ children }) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth?.user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export const sendSignInEmail = (email, path) => sendSignInLinkToEmail(getAuth(), email, { url: `https://smartio-cloud.web.app/login`, handleCodeInApp: true })
  .then(() => window.localStorage.setItem('email', email))