import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Button, Stack, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip } from '@mui/material'
import { Download, Check, Clear, Search, Add } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt, positiveFloat, addUniqueFileRef } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import FileRefs from '../FileRefs'
import Popover from '../Popover'
import PriceField from '../PriceField'
import UpdateFileRef from '../UpdateFileRef'
import { findJobs, findJobLines, addJobLine } from '../Jobs/functions'
import { findPartQuotes, findPartQuoteLines } from '../Quotes/functions'
import SelectPartOrder from '../Orders/SelectPartOrder'
import SelectContact from '../Contacts/SelectContact'
import SelectPart from './SelectPart'

const getLatestJobWithLines = async (resolve, reject, args, excludeIds) => {
  const [job] = await findJobs({ orderBy: ['createdAt','desc'], ...args })
  if (!job) return resolve()
  const lines = await findJobLines({ jobId: job.id, orderBy: ['number','asc'], limit: 99 })
  if (lines.length === 0 || excludeIds.includes(job.id)) getLatestJobWithLines(resolve, reject, { ...args, startAfter: job._snap })
  else resolve({ ...job, lines })
}

const getLatestQuoteWithLines = async (resolve, reject, args, excludeIds) => {
  const [quote] = await findPartQuotes({ orderBy: ['createdAt','desc'], ...args })
  if (!quote) return resolve()
  const lines = await findPartQuoteLines({ quoteId: quote.id, status: 'ACTIVE', orderBy: ['number','asc'], limit: 99 })
  if (lines.length === 0 || excludeIds.includes(quote.id)) getLatestQuoteWithLines(resolve, reject, { ...args, startAfter: quote._snap })
  else resolve({ ...quote, lines })
}

function ImportPartTraveler({ companyId, partId, jobId, forceOpen, setForceOpen, onImported }) {
  const [isOpen, setIsOpen] = useState(false)
  const [partJob, setPartJob] = useState()
  const [partQuote, setPartQuote] = useState()
  const [items, setItems] = useState([])
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const source = jobId ? (partJob || partQuote) : partQuote

  useEffect(() => {
    setItems(source?.lines)
  }, [source])

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  const confirm = async () => {
    await Promise.all(items.map(async item => {
      if (item.selected) {
        if (jobId) {
          const { id: refFromId } = await addJobLine({
            jobId,
            number: item.number,
            description: item.description,
            quantity: item.quantity,
            unit: item.unit,
            status: 'ACTIVE',
          })
          await Promise.all((item.fileRefs || []).map(async ({ name, fileId }) => addUniqueFileRef({ name, fileId, refFromId, refFromType: 'jobLine', status: 'ACTIVE' })))
        }
      }
    }))
    enqueueSnackbar('Imported successfully.', { variant: 'success' })
    handleClose()
    if (onImported) onImported()
  }

  const cancel = () => {
    handleClose()
  }

  const updateItem = (id, updates) => setItems(items.map(item => {
    if (item.id !== id) return item
    return { ...item, ...updates }
  }))

  useAsyncFunc(async ({ companyId, partId }) => {
    if (companyId && partId) {
      setPartJob(await new Promise((resolve, reject) => getLatestJobWithLines(resolve, reject, { companyId, partId }, [jobId])))
      setPartQuote(await new Promise((resolve, reject) => getLatestQuoteWithLines(resolve, reject, { partId, statusIn: ['APPROVED','COMPLETED'] })))
    }
  }, { companyId, partId }, () => {}, [companyId, partId])

  const content = (
    <>
      <Box sx={{ marginBottom: '10px' }}>
        <Typography variant="body2" sx={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setItems(items.map(item => ({ ...item, selected: true })))}>Select All</Typography>
        {` · `}
        <Typography variant="body2" sx={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setItems(items.map(item => ({ ...item, selected: false })))}>None</Typography>
      </Box>
      <List>
        {(items || []).map(item => {
          const { id, selected, number, vendorContactId, poNumberRef, description, quantity, unit, unitPrice, fileRefs } = item
          return (
            <ListItem key={id} disablePadding>
              <ListItemIcon>
                <Checkbox
                  checked={!!selected}
                  onClick={({ target: { checked } }) => updateItem(id, { selected: checked })}
                />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Material / Process"
                    placeholder="Material, process, labor..."
                    value={description}
                    onChange={({ target: { value } }) => updateItem(id, { description: value })}
                    style={{ minWidth: '500px' }}
                    InputProps={{
                      endAdornment: (
                        <Popover
                          button={(
                            <Tooltip title="Search materials or subparts">
                              <IconButton edge="end">
                                <Search fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          content={(
                            <Box style={{ minWidth: '700px', padding: '15px' }}>
                              <SelectPart
                                companyId={companyId}
                                assyPartId={partId}
                                onChange={part => updateItem(id, { description: part ? `${part.number} · ${part.name} · Rev.${part.revision || '-'}` : '' })}
                              />
                            </Box>
                          )}
                          forceClosingOnClick
                        />
                      ),
                    }}
                  />
                )}
                secondary={(
                  <>
                    <Box style={{ display: 'flex', gap: '5px', paddingTop: '10px' }}>
                      <SelectContact
                        label="Vendor"
                        companyId={companyId}
                        contactId={vendorContactId}
                        onChange={contact => updateItem(id, { vendorContactId: contact?.id })}
                        style={{ minWidth: '300px' }}
                        findType="VEND"
                      />
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="PO number"
                        placeholder="PO number"
                        value={poNumberRef}
                        onChange={({ target: { value } }) => updateItem(id, { poNumberRef: value })}
                        style={{ maxWidth: '170px' }}
                        InputProps={{
                          endAdornment: (
                            <SelectPartOrder
                              companyId={companyId}
                              button={(
                                <Tooltip title="Search purchases">
                                  <IconButton edge="end">
                                    <Search fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              partId={partId}
                              query={poNumberRef}
                              isPurchase
                              onChange={(partOrderId, { lineNumber, quantity, notes }, { numberRef, number }) => updateItem(id, { poNumberRef: numberRef })}
                            />
                          ),
                        }}
                      />
                      <TextField
                        size="small"
                        variant="outlined"
                        name="quantity"
                        label="Quantity"
                        value={quantity}
                        onChange={({ target: { value } }) => updateItem(id, { quantity: positiveInt(value) })}
                        style={{ maxWidth: '180px' }}
                        InputProps={{
                          endAdornment: (
                            <Dropdown
                              button={(
                                <Button
                                  size="small"
                                  variant="outlined"
                                >
                                  {unit}
                                </Button>
                              )}
                              buttonStyle={{ transform: 'translateX(9px)' }}
                              items={[
                                { label: 'piece', value: 'PIECE' },
                                { label: 'box', value: 'BOX' },
                                { label: 'pallet', value: 'PALLET' },
                              ]}
                              onChange={({ value }) => updateItem(id, { unit: value })}
                            />
                          ),
                        }}
                        {...addErrorProps(errors, `quantity-${id}`)}
                      />
                      {!jobId && (
                        <TextField
                          size="small"
                          variant="outlined"
                          name="unitPrice"
                          label="Unit price"
                          placeholder="$"
                          value={unitPrice || ''}
                          onChange={({ target: { value } }) => updateItem(id, { unitPrice: positiveFloat(value) })}
                          InputProps={{
                            inputComponent: PriceField,
                          }}
                          {...addErrorProps(errors, `unitPrice-${id}`)}
                        />
                      )}
                    </Box>
                    <Box sx={{ paddingTop: '10px' }}>
                      <FileRefs
                        companyId={source.companyId}
                        refFroms={[
                          { id, type: 'jobLine' },
                        ]}
                        inline
                        inlineItemRender={fileRef => {
                          const included = (fileRefs || []).find(({ id: fileRefId }) => fileRefId === fileRef.id)
                          return (
                            <UpdateFileRef
                              key={fileRef.id}
                              button={(
                                <Button
                                  size="small"
                                  variant="outlined"
                                  startIcon={(
                                    <Tooltip title="Incude file">
                                      <IconButton size="small" onClick={e => {
                                        e.stopPropagation()
                                        if (!included) return updateItem(id, { fileRefs: [ ...(fileRefs || []), fileRef ] })
                                        return updateItem(id, { fileRefs: (fileRefs || []).filter(({ id: fileRefId }) => fileRefId !== fileRef.id) })
                                      }}>{included ? <Check fontSize="small" /> : <Add fontSize="small" />}</IconButton>
                                    </Tooltip>
                                  )}
                                  style={{ marginLeft: '5px', marginBottom: '5px', ...!included && { borderStyle: 'dashed' } }}
                                >
                                  {fileRef.name}
                                </Button>
                              )}
                              buttonStyle={{ display: 'inline-block' }}
                              fileRef={fileRef}
                              onUpdated={fetch}
                            />
                          )
                        }}
                      />
                    </Box>
                  </>
                )}
              />
            </ListItem>
          )
        })}
      </List>
    </>
  )

  return source && (
    <Stack>
      <Alert
        severity="info"
        action={(
          <>
            <Button
              size="small"
              variant="contained"
              startIcon={<Download />}
              onClick={() => setIsOpen(true)}
              disableElevation
            >
              Import
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={isOpen}
              onClose={handleClose}
            >
              <DialogTitle>Confirm Import</DialogTitle>
              <DialogContent>
                <DialogContentText style={{ paddingTop: '5px' }}>
                  {content}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  startIcon={<Check />}
                  disableElevation
                  onClick={confirm}
                  disabled={!(items || []).find(({ selected }) => selected)}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Clear />}
                  disableElevation
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      >
        {`Traveler found on ${jobId ? 'other ' : ''}${source._collection}`}
      </Alert>
    </Stack>
  )
}

export default ImportPartTraveler