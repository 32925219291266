import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAfter, endBefore, limitToLast, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith } from '../../functions'

export const getPart = id => get('parts', id)

export const addPart = data => add('parts', data)

export const updatePart = (id, updates) => update('parts', id, updates, 'partLogs')

export const findParts = async ({ companyId, number, numberBeginsWith, revision, name, nameBeginsWith, type, typeIn, createdAtFrom, createdAtTo, updatedAtFrom, updatedAtTo, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'parts'), ...[
    where('companyId', '==', companyId),
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    ...revision ? [where('revision', '==', revision)] : [],
    ...name ? [where('name', '==', name)]
    : nameBeginsWith ? beginsWith('name', nameBeginsWith)
    : [],
    ...type ? [where('type', '==', type)]
    : typeIn ? [where('type', 'in', typeIn)]
    : [],
    ...createdAtFrom ? [where('createdAt', '>=', createdAtFrom)] : [],
    ...createdAtTo ? [where('createdAt', '<', createdAtTo)] : [],
    ...updatedAtFrom ? [where('updatedAt', '>=', updatedAtFrom)] : [],
    ...updatedAtTo ? [where('updatedAt', '<', updatedAtTo)] : [],
    ...(!createdAtFrom && !createdAtTo && !updatedAtFrom && !updatedAtTo) ? [orderBy(...order)] : [],
    limit(max || await getGlobal('partsQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)

export const validatePartNumber = (number, revision, companyId) => findParts({ companyId, number, revision, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const getLatestRevision = (number, companyId) => findParts({ companyId, number, orderBy: ['revision','desc'], limit: 1 }).then(([latest]) => _.get(latest, 'revision'))

export const getPartDetail = id => get('partDetails', id)

export const addPartDetail = data => add('partDetails', data)

export const updatePartDetail = (id, updates) => update('partDetails', id, updates, 'partDetailLogs')

export const findPartDetails = async ({ partId, detailPartId, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'partDetails'), ...[
    where('status', '==', 'ACTIVE'),
    ...partId ? [where('partId', '==', partId)] : [],
    ...detailPartId ? [where('detailPartId', '==', detailPartId)] : [],
    orderBy(...order),
    limit(max || await getGlobal('partsQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)