import { useState } from 'react'
import { Badge, Tooltip } from '@mui/material'
import { SettingsApplications, ManageAccounts, Settings } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getPart, getLatestRevision } from './functions'

function PartIcon({ partId }) {
  const [part, setPart] = useState()
  const [latestRevision, setLatestRevision] = useState()

  useAsyncFunc(async ({ partId }) => {
    if (partId) return getPart(partId)
  }, { partId }, setPart, [partId])

  useAsyncFunc(async ({ part }) => {
    if (part?.number) return getLatestRevision(part.number, part.companyId)
  }, { part }, setLatestRevision, [part])

  return (
    <Tooltip title={`Rev.${get(part, 'revision', '')} - Latest${latestRevision !== part?.revision ? ` Rev.${latestRevision}` : ''}`}>
      <Badge
        variant="dot"
        {...part && { color: latestRevision === part.revision ? 'success' : 'secondary' }}
      >
        {get(part, 'type') === 'ASSY' ? <SettingsApplications /> : get(part, 'type') === 'SERV' ? <ManageAccounts /> : <Settings />}
      </Badge>
    </Tooltip>
  )
}

export default PartIcon