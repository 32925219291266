import { FormGroup, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material'

function Checkbox({ style, ...props }) {
  return (
    <FormGroup style={{ ...style }}>
      <FormControlLabel
        control={<MuiCheckbox {...props} />}
        {...props}
        {...!props.label && { sx: { marginRight: 0 } }}
      />
    </FormGroup>
  )
}

export default Checkbox