import { useState, useEffect } from 'react'
import { TextField, Button } from '@mui/material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps } from '../../functions'
import { updateQuote } from './functions'

function EditCustomerNote({ quote, onUpdate }) {
  const [updatedQuote, setUpdatedQuote] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return
    return updateQuote(quote.id, {
      customerNote: updatedQuote.customerNote,
    })
    .then(onUpdate)
    .then(() => enqueueSnackbar('Note updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedQuote(quote)
  }, [quote])

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        value={get(updatedQuote, 'customerNote', '')}
        onChange={({ target: { value } }) => setUpdatedQuote({ ...updatedQuote, customerNote: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'customerNote')}
      />
      {!isEqual(get(quote, 'customerNote'), get(updatedQuote, 'customerNote')) && (
        <Button
          variant="outlined"
          onClick={() => setUpdatedQuote(quote)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        disabled={isEqual(quote, updatedQuote)}
        disableElevation
      >
        Save Notes
      </Button>
    </>
  )
}

export default EditCustomerNote