import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, IconButton, Button, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert } from '@mui/material'
import { ArrowRight, Inbox, Print, ContentCopy, DeleteOutline, Launch } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import Accordion from '../Accordion'
import Confirm from '../Confirm'
import Documents from '../Documents'
import EditInternalMemo from '../EditInternalMemo'
import { findJobAllocations } from '../Jobs/functions'
import PartNumber from '../Parts/PartNumber'
import PartName from '../Parts/PartName'
import OrderNumber from '../Orders/OrderNumber'
import PartOrderProp from '../Orders/PartOrderProp'
import { getShipment, findShipmentLines, updateShipment } from './functions'
import ShipList from './ShipList'
import SearchShipments from './Search'
import EditShipmentDetails from './EditDetails'

function Shipments({ companyId }) {
  const [selected, setSelected] = useState()
  const [lineItems, setLineItems] = useState()
  const [jobAllocations, setJobAllocations] = useState()
  const [shipmentsFetchedAt, setShipmentsFetchedAt] = useState(new Date())

  const navigate = useNavigate()
  const { shipmentId } = useParams()

  useAsyncFunc(async args => {
    if (shipmentId) return getShipment(args)
    return () => null
  }, shipmentId, setSelected, [shipmentId])

  useAsyncFunc(async shipmentId => {
    if (shipmentId) return findShipmentLines({ shipmentId, orderBy: ['createdAt','asc'] })
    return () => null
  }, shipmentId, setLineItems, [shipmentId])

  useEffect(() => {
    if (lineItems && lineItems.length > 0) findJobAllocations({ partOrderIdIn: lineItems.map(({ partOrderId }) => partOrderId), orderBy: ['createdAt','desc'] }).then(setJobAllocations)
  }, [lineItems])

  return (
    <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
      <Box style={{ minHeight: '100vh', border: '1px solid #d9d9d9', borderRadius: '5px', ...!selected && { flexGrow: 1 } }}>
        {selected ? (
          <IconButton onClick={() => navigate(`/shipments`)}>
            <ArrowRight />
          </IconButton>
        ) : <ShipList companyId={companyId} onShipped={() => setShipmentsFetchedAt(new Date())} />}
      </Box>
      <Box style={{ width: '23vw' }}>
        <SearchShipments
          key={shipmentsFetchedAt}
          companyId={companyId}
          selected={selected}
          onSelect={({ id }) => navigate(`/shipments/${id}`)}
        />
      </Box>
      {selected && (
        <Box style={{ flexGrow: 1, minHeight: '100vh', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
          <Box style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: 0, padding: '10px', background: '#efefef', zIndex: 2 }}>
            <Button style={{ flexGrow: 1 }}>
              {`Shipment Number ${selected.number}`}
            </Button>
            <Box>
              <Button
                startIcon={<Launch />}
              >
                Share
              </Button>
              <Documents
                companyId={companyId}
                button={(
                  <Button
                    startIcon={<Print />}
                  >
                    Print
                  </Button>
                )}
                buttonStyle={{ display: 'inline-block' }}
                jobAllocations={jobAllocations}
              />
              <Button
                startIcon={<ContentCopy />}
              >
                Copy
              </Button>
              <Button
                startIcon={<DeleteOutline />}
              >
                Delete
              </Button>
            </Box>
          </Box>
          <Stack>
            {get(selected, 'status') === 'SHIPPED' && (
              <Alert
                severity="success"
              >
                Shipment is <Typography color="green" style={{ display: 'inline', fontWeight: 'bold' }}>{selected.status}.</Typography>
              </Alert>
            )}
            {get(selected, 'status') === 'NOT_SHIPPED' && (
              <Alert
                severity="primary"
                action={(
                  <Confirm
                    button={(
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<Inbox />}
                        disableElevation
                      >
                        Confirm Shipment
                      </Button>
                    )}
                    buttonStyle={{ display: 'inline-block' }}
                    title="Confirm shipment?"
                    content="Are you sure you want to confirm this shipment?"
                    onConfirm={() => updateShipment(selected.id, { status: 'SHIPPED' }).then(() => window.location.reload())}
                  />
                )}
              >
                Shipment is <Typography color="red" style={{ display: 'inline', fontWeight: 'bold' }}>{selected.status}.</Typography>
              </Alert>
            )}
          </Stack>
          <Box style={{ padding: '10px' }}>
            <Accordion
              title="Shipment Details"
              content={(
                <EditShipmentDetails
                  shipment={selected}
                  onUpdate={setSelected}
                />
              )}
              defaultExpanded
            />
            <Accordion
              title="Internal Memo"
              content={(
                <>
                  <Stack>
                    <Alert
                      severity="info"
                    >
                      For internal use only. Not visible to customer.
                    </Alert>
                  </Stack>
                  <EditInternalMemo
                    refFromId={selected.id}
                  />
                </>
              )}
            />
            <Accordion
              title={(
                <>
                  Shipment Items
                </>
              )}
              content={(
                <Box style={{ minHeight: '250px', margin: '0 5px 15px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>PO</TableCell>
                          <TableCell>Line</TableCell>
                          <TableCell>Part</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Due</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(lineItems || []).map(({ id, partOrderId, partId, quantity }) => (
                          <TableRow key={id}>
                            <TableCell>
                              <OrderNumber partOrderId={partOrderId} />
                            </TableCell>
                            <TableCell>
                              <PartOrderProp partOrderId={partOrderId} prop="lineNumber" />
                            </TableCell>
                            <TableCell>
                              <PartNumber partId={partId} />
                            </TableCell>
                            <TableCell>
                              <Tooltip title={<PartName partId={partId} />}>
                                <Typography variant="body2" style={{ width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                  <PartName partId={partId} />
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {quantity}
                            </TableCell>
                            <TableCell>
                              <PartOrderProp partOrderId={partOrderId} prop="dueAt" />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              defaultExpanded
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Shipments
