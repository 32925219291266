import { Box, Stack, Chip } from '@mui/material'

function Toggles({ options, selected, setSelected, before, after, disabled }) {
  return (
    <Box style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
      {before}
      <Box style={{ flexGrow: 1, overflow: 'hidden' }}>
        <Stack direction="row" spacing={.5} style={{ overflowX: 'scroll' }}>
          {options.map(({ label, value }) => (
            <Chip
              key={value}
              color="primary"
              variant={selected.includes(value) ? 'contained' : 'outlined'}
              label={label}
              onClick={() => !disabled && setSelected([value])}
              disabled={disabled}
            />
          ))}
        </Stack>
      </Box>
      {after}
    </Box>
  )
}

export default Toggles