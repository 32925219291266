import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Launch, ListAlt, Undo } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt, jobStatuses } from '../../functions'
import ChangeLogs from '../ChangeLogs'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import Radio from '../Radio'
import OrderLookup from '../Orders/Lookup'
import { updateJob } from './functions'

function EditJobDetails({ job, onUpdate }) {
  const [updatedJob, setUpdatedJob] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    if (!get(updatedJob, 'number')) errors.number = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateJob(job.id, {
      number: updatedJob.number,
      numberRef: updatedJob.numberRef,
      quantity: updatedJob.quantity,
      balance: updatedJob.balance,
      dueAt: updatedJob.dueAt,
      shipAt: updatedJob.shipAt,
      status: updatedJob.status,
      poNumber: updatedJob.poNumber,
      lineNumber: updatedJob.lineNumber,
      ...updatedJob.description && { description: updatedJob.description },
    }).then(onUpdate)
      .then(() => enqueueSnackbar('Job updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedJob(job)
  }, [job])

  return (
    <>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Job number"
            fullWidth
            value={get(updatedJob, 'number', 0)}
            onChange={({ target: { value } }) => setUpdatedJob({ ...updatedJob, number: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'number')}
            disabled
          />
        </Box>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Job reference number"
            fullWidth
            value={get(updatedJob, 'numberRef', '')}
            onChange={({ target: { value } }) => setUpdatedJob({ ...updatedJob, numberRef: value })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'numberRef')}
          />
        </Box>
      </Box>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Quantity"
            fullWidth
            value={get(updatedJob, 'quantity', 1)}
            onChange={({ target: { value } }) => setUpdatedJob({ ...updatedJob, quantity: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'quantity')}
          />
        </Box>
        <Box style={{ width: '50%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Available"
            fullWidth
            value={get(updatedJob, 'quantityAvailable', 0)}
            style={{ marginBottom: '10px' }}
            disabled
          />
        </Box>
      </Box>
      <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '50%' }}>
          <DateField
            label="Due Date"
            value={get(updatedJob, 'dueAt')}
            onChange={dueAt => setUpdatedJob({ ...updatedJob, dueAt })}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                style={{ marginBottom: '10px' }}
              />
            )}
          />
        </Box>
        <Box style={{ width: '50%' }}>
          <DateField
            label="Ship Date"
            value={get(updatedJob, 'shipAt')}
            onChange={shipAt => setUpdatedJob({ ...updatedJob, shipAt })}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                style={{ marginBottom: '10px' }}
              />
            )}
          />
        </Box>
      </Box>
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
          >
            {get(updatedJob, 'status', 'NOT_STARTED').replace('_',' ')}
          </Button>
        )}
        buttonStyle={{ marginBottom: '10px' }}
        items={jobStatuses}
        onChange={({ value }) => setUpdatedJob({ ...updatedJob, status: value })}
      />
      <TextField
        size="small"
        variant="outlined"
        label="Job description"
        rows={3}
        multiline
        fullWidth
        value={get(updatedJob, 'description', '')}
        onChange={({ target: { value } }) => setUpdatedJob({ ...updatedJob, description: value })}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'description')}
      />
      {/* <Box style={{ display: 'flex', gap: '10px' }}>
        <Box style={{ width: '70%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="PO number"
            fullWidth
            value={get(updatedJob, 'poNumber', '')}
            onChange={({ target: { value } }) => setUpdatedJob({ ...updatedJob, poNumber: value })}
            style={{ marginBottom: '10px' }}
            InputProps={{
              endAdornment: get(updatedJob, 'poNumber') && (
                <OrderLookup
                  companyId={job.companyId}
                  button={(
                    <IconButton edge="end"><Launch fontSize="small" /></IconButton>
                  )}
                  query={get(updatedJob, 'poNumber', '')}
                />
              ),
            }}
            {...addErrorProps(errors, 'poNumber')}
          />
        </Box>
        <Box style={{ width: '30%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Line number"
            fullWidth
            value={get(updatedJob, 'lineNumber', '')}
            onChange={({ target: { value } }) => setUpdatedJob({ ...updatedJob, lineNumber: positiveInt(value) })}
            style={{ marginBottom: '10px' }}
            {...addErrorProps(errors, 'lineNumber')}
          />
        </Box>
      </Box> */}
      <Box style={{
        ...!isEqual(job, updatedJob) && { position: 'sticky', bottom: 0, padding: '5px 0', background: '#fff', zIndex: 1 },
      }}>
        {!isEqual(job, updatedJob) && (
          <Button
            variant="outlined"
            startIcon={<Undo />}
            onClick={() => setUpdatedJob(job)}
            style={{ marginRight: '5px' }}
            disableElevation
          >
            Revert
          </Button>
        )}
        <Button
          variant="contained"
          onClick={submit}
          disabled={isEqual(job, updatedJob)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Save Details
        </Button>
        <ChangeLogs
          col="jobLogs"
          id={job.id}
          button={(
            <Button
              variant="outlined"
              disableElevation
              startIcon={<ListAlt />}
            >
              Changelog
            </Button>
          )}
          buttonStyle={{ display: 'inline-block' }}
        />
      </Box>
    </>
  )
}

export default EditJobDetails