import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Chip, IconButton } from '@mui/material'
import { Check, Clear, South, North, Visibility, CopyAll, Sort, ArrowDropDown, History, ContentCopy } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get, isEqual, sumBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt, positiveFloat, getDateDiffStr, getDate } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import OrderBy from '../OrderBy'
import Popover from '../Popover'
import PriceField from '../PriceField'
import { getPart } from '../Parts/functions'
import SelectPart from '../Parts/SelectPart'
import { getPartOrder, getOrder } from '../Orders/functions'
import SelectPartOrder from '../Orders/SelectPartOrder'
import { addLineItem, updateLineItem, findLineItems, getNewLineNumber } from './functions'
import PartOrderInvoiceLineItems from './PartOrderInvoiceLineItems'

function CreateEditLineItem({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate, onUpdate }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newLineItem, setNewLineItem] = useState()
  const [part, setPart] = useState()
  const [partOrder, setPartOrder] = useState()
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])
  const [matches, setMatches] = useState([])
  const [isBusinessDays, setIsBusinessDays] = useState(true)
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    if (!get(newLineItem, 'partId')) errors.partId = 'Required'
    if (!get(newLineItem, 'quantity')) errors.quantity = 'Required'
    if (!get(newLineItem, 'unit')) errors.unit = 'Required'
    if (!get(newLineItem, 'unitPrice')) errors.unitPrice = 'Required'
    // if (!get(newLineItem, 'shipAt')) errors.shipAt = 'Required'
    // if (!get(newLineItem, 'dueAt')) errors.dueAt = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    if (data.id) {
      return updateLineItem(data.id, {
        partId: newLineItem.partId,
        quantity: newLineItem.quantity,
        unit: newLineItem.unit,
        unitPrice: newLineItem.unitPrice,
        ...newLineItem.name !== undefined && { name: newLineItem.name },
        ...newLineItem.shipAt && { shipAt: newLineItem.shipAt },
        ...newLineItem.dueAt && { dueAt: newLineItem.dueAt },
        ...newLineItem.notes && { notes: newLineItem.notes },
        ...newLineItem.refPoNumber && { refPoNumber: newLineItem.refPoNumber },
        ...newLineItem.refLineNumber && { refLineNumber: newLineItem.refLineNumber },
      }).then(lineItem => {
        handleClose()
        enqueueSnackbar('Line item updated successfully.', { variant: 'success' })
        if (onUpdate) onUpdate(lineItem)
      })
    }
    return addLineItem({ ...newLineItem, lineNumber: await getNewLineNumber(data.invoiceId), companyId })
      .then(lineItem => {
        handleClose()
        enqueueSnackbar('Line item created successfully.', { variant: 'success' })
        if (onCreate) onCreate(lineItem)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewLineItem(data)
  }, [data])

  useAsyncFunc(async newLineItem => {
    if (newLineItem?.partId) await getPart(newLineItem.partId).then(setPart)
    if (newLineItem?.partOrderId) {
      const partOrder = await getPartOrder(newLineItem.partOrderId)
      setPartOrder(partOrder)
      return getOrder(partOrder.orderId)
    }
    return () => null
  }, newLineItem, setOrder, [newLineItem])

  useAsyncFunc(async args => {
    if (get(newLineItem, 'partId')) return findLineItems(args)
    return () => null
  }, { partId: get(newLineItem, 'partId'), ...get(data, 'invoiceId') ? { excludeOrderIds: [], orderBy: ['invoiceId','desc'] } : { orderBy } }, setMatches, [orderBy, newLineItem, data])

  return (
    <>
      {button && (
        <Box style={{ cursor: 'pointer', ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>{`${data.id ? 'Edit' : 'Create new'} line item`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ flexGrow: 1 }}>
                <Box style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
                  <SelectPartOrder
                    companyId={companyId}
                    partOrderLabelPrefix={({ id }) => (
                      <PartOrderInvoiceLineItems
                        partOrderId={id}
                        renderLineItems={(content, lineItems, partOrder) => {
                          const invoicedSum = sumBy(lineItems, ({ quantity, unitPrice }) => quantity * unitPrice)
                          const invoicedPercent = partOrder ? invoicedSum / (partOrder.quantity * partOrder.unitPrice) * 100 : 0
                          const chip = (
                            <Chip
                              color="primary"
                              {...lineItems.length === 0 && { variant: 'outlined' }}
                              label={`$${invoicedSum.toFixed(2)} (${Math.round(invoicedPercent)}%) invoiced`}
                              style={{ marginRight: '10px' }}
                            />
                          )
                          return lineItems.length > 0 ? (
                            <Popover
                              button={chip}
                              content={content}
                              trigger="hover"
                              clickableInside
                            />
                          ) : chip
                        }}
                      />
                    )}
                    onChange={async (partOrderId, { lineNumber: refLineNumber, partId, quantity, unit, unitPrice }, { numberRef: refPoNumber }) => setNewLineItem({
                      ...newLineItem, partOrderId, refPoNumber, refLineNumber, partId, quantity, unit, unitPrice, name: `P/N ${await getPart(partId).then(({ number }) => number)}`, notes: await getPart(partId).then(({ name }) => name) })}
                  />
                  or
                  <Box style={{ flexGrow: 1 }}>
                    <SelectPart
                      companyId={companyId}
                      partId={get(newLineItem, 'partId')}
                      onChange={part => setNewLineItem({ ...newLineItem, partId: get(part, 'id') })}
                      inputProps={{
                        label: 'Select a part',
                        ...addErrorProps(errors, 'partId')
                      }}
                    />
                  </Box>
                </Box>
                {get(newLineItem, 'partId') && (
                  <>
                    <Box style={{ display: 'flex', gap: '10px' }}>
                      <Box style={{ width: '50%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="refPoNumber"
                          label="PO Number"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newLineItem, 'refPoNumber', get(order, 'numberRef', ''))}
                          onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, refPoNumber: value })}
                          {...addErrorProps(errors, 'refPoNumber')}
                          disabled={order}
                        />
                      </Box>
                      <Box style={{ width: '50%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="refLineNumber"
                          label="PO Line Number"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newLineItem, 'refLineNumber', get(partOrder, 'lineNumber', ''))}
                          onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, refLineNumber: positiveInt(value) })}
                          {...addErrorProps(errors, 'refLineNumber')}
                          disabled={partOrder}
                        />
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', gap: '10px' }}>
                      <Box style={{ width: '30%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="lineNumber"
                          label="Line Number"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newLineItem, 'lineNumber', '')}
                          onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, lineNumber: positiveInt(value) })}
                          {...addErrorProps(errors, 'lineNumber')}
                        />
                      </Box>
                      <Box style={{ width: '70%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="name"
                          label="Name"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newLineItem, 'name', get(part, 'id') ? `${get(part, 'number')} Rev.${get(part, 'revision')}` : '')}
                          onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, name: value })}
                          {...addErrorProps(errors, 'name')}
                        />
                      </Box>
                    </Box>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Description"
                      multiline
                      rows={5}
                      fullWidth
                      style={{ marginBottom: '10px' }}
                      value={get(newLineItem, 'notes', '')}
                      onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, notes: value })}
                      InputProps={{
                        endAdornment: (
                          <Dropdown
                            button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                            beforeItems={<Typography variant="overline" display="block" color="GrayText">Notes history</Typography>}
                            items={(matches || []).map(({ notes, updatedAt }) => ({
                              label: (
                                <>
                                  {get(newLineItem, 'notes', '') === notes && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                                  {updatedAt.toLocaleString()}
                                </>
                              ),
                              value: notes,
                              tooltip: notes,
                            }))}
                            onChange={({ value }) => setNewLineItem({ ...newLineItem, notes: value })}
                            disableCloseOnSelect
                          />
                        ),
                      }}
                      {...addErrorProps(errors, 'notes')}
                    />
                    <Box style={{ display: 'flex', gap: '10px' }}>
                      <Box style={{ width: '50%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="quantity"
                          label="Quantity"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newLineItem, 'quantity', '')}
                          onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, quantity: positiveInt(value) })}
                          {...addErrorProps(errors, 'quantity')}
                          required
                        />
                      </Box>
                      <Box>
                        <Dropdown
                          button={(
                            <TextField
                              size="small"
                              variant="outlined"
                              value={get(newLineItem, 'unit', '')}
                              InputProps={{
                                endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                              }}
                              style={{ width: '120px' }}
                            />
                          )}
                          items={[
                            { label: 'piece', value: 'PIECE' },
                            { label: 'hour', value: 'HOUR' },
                          ]}
                          onChange={({ value }) => setNewLineItem({ ...newLineItem, unit: value })}
                        />
                      </Box>
                      <Box style={{ flexGrow: 1 }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="unitPrice"
                          label="Unit price"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newLineItem, 'unitPrice', '')}
                          onChange={({ target: { value } }) => setNewLineItem({ ...newLineItem, unitPrice: positiveFloat(value) })}
                          InputProps={{
                            inputComponent: PriceField,
                            endAdornment: (
                              <Dropdown
                                button={<IconButton edge="end"><History fontSize="small" /></IconButton>}
                                beforeItems={<Typography variant="overline" display="block" color="GrayText">Pricing history</Typography>}
                                items={(matches || []).map(({ quantity, unitPrice, unit, updatedAt }) => ({
                                  label: (
                                    <>
                                      {get(newLineItem, 'quantity', 0) === quantity
                                      && get(newLineItem, 'unitPrice', 0) === unitPrice
                                      && get(newLineItem, 'unit', '') === unit
                                      && <Check fontSize="small" color="success" style={{ marginRight: '5px' }} />}
                                      {updatedAt.toLocaleString()}
                                    </>
                                  ),
                                  value: { quantity, unitPrice, unit },
                                  tooltip: `${quantity} ${unit} at $${unitPrice}/${unit}`,
                                }))}
                                onChange={({ value }) => setNewLineItem({ ...newLineItem, ...value })}
                                disableCloseOnSelect
                              />
                            ),
                          }}
                          {...addErrorProps(errors, 'unitPrice')}
                          required
                        />
                      </Box>
                    </Box>
                    {/* <Box style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
                      <Box style={{ width: '30%' }}>
                        <DateField
                          label="Ship date"
                          value={get(newLineItem, 'shipAt')}
                          onChange={date => setNewLineItem({ ...newLineItem, shipAt: date })}
                          renderInput={params => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              style={{ marginBottom: '10px' }}
                              {...addErrorProps(errors, 'shipAt')}
                            />
                          )}
                        />
                      </Box>
                      <Box style={{ width: '30%' }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="terms"
                          label="Terms"
                          fullWidth
                          style={{ marginBottom: '10px' }}
                          value={get(newLineItem, 'invoice.terms', '')}
                          disabled
                        />
                      </Box>
                      <Box style={{ width: '40%' }}>
                        <DateField
                          label="Due date"
                          value={get(newLineItem, 'dueAt')}
                          onChange={date => setNewLineItem({ ...newLineItem, dueAt: date })}
                          renderInput={params => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              style={{ marginBottom: '10px' }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Dropdown
                                    button={(
                                      <Typography variant="overline" color="GrayText" style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>
                                        {getDateDiffStr(get(newLineItem, 'shipAt'), get(newLineItem, 'dueAt'), 'days')}
                                      </Typography>
                                    )}
                                    // beforeItems={(
                                    //   <Checkbox
                                    //     checked={isBusinessDays}
                                    //     onChange={({ target: { checked } }) => setIsBusinessDays(checked)}
                                    //     label="Only business days"
                                    //   />
                                    // )}
                                    items={[
                                      { label: 'Upon Receipt', value: 1 },
                                      { label: 'In 7 days', value: 7 },
                                      { label: 'In 10 days', value: 10 },
                                      { label: 'In 30 days', value: 30 },
                                      { label: 'In 45 days', value: 45 },
                                      { label: 'In 60 days', value: 60 },
                                      { label: 'In 90 days', value: 90 },
                                    ]}
                                    onChange={({ value }) => setNewLineItem({ ...newLineItem, dueAt: getDate(get(newLineItem, 'shipAt'), '+', value, 'day') })}
                                  />
                                ),
                              }}
                              {...addErrorProps(errors, 'dueAt')}
                            />
                          )}
                        />
                      </Box>
                    </Box> */}
                  </>
                )}
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
            disabled={isEqual(data, newLineItem)}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateEditLineItem