import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Button, Tooltip, Alert, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tabs, Tab, IconButton } from '@mui/material'
import { Info, Add, ContentCopy, DeleteOutline, Undo, ArrowLeft, ArrowRight } from '@mui/icons-material'
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip as Charttip } from 'recharts'
import NumberFormat from 'react-number-format'
import { get, isEqual, sumBy, sortBy, omitBy, isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt, positiveFloat, getDate } from '../../functions'
import { findPartQuotes, addPartQuote, updatePartQuote, findPartQuoteLines, addPartQuoteLine, updatePartQuoteLine, getPartQuoteCombos, getPartQuote } from './functions'
import Accordion from '../Accordion'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import PriceField from '../PriceField'
import Radio from '../Radio'
import SelectPart from '../Parts/SelectPart'
import { findPartOrders } from '../Orders/functions'
import OrderNumber from '../Orders/OrderNumber'
import SelectPartQuote from './SelectPartQuote'
import PartQuoteLine from './PartQuoteLine'
import MaterialsRow from './MaterialsRow'
import LaborRow from './LaborRow'
import PriceRow from './PriceRow'
import QuoteProp from './QuoteProp'

function CalculateQuote({ companyId, quoteId, partQuoteId, onUpdated, isDetailed }) {
  const [part, setPart] = useState()
  const [partQuotes, setPartQuotes] = useState([])
  const [partQuote, setPartQuote] = useState()
  const [updatedPartQuote, setUpdatedPartQuote] = useState()
  const [partQuoteLines, setPartQuoteLines] = useState([])
  const [partOrders, setPartOrders] = useState()
  const [view, setView] = useState('QUOTES')
  const [rowView, setRowView] = useState('VENDOR')
  const [quoteHistory, setQuoteHistory] = useState({})
  const [updates, setUpdates] = useState([])
  const [changesClearedAt, setChangesClearedAt] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const updateTier = (index, updates) => setUpdatedPartQuote({
    ...updatedPartQuote,
    combos: partQuote.combos.map((combo, i) => ({
      ...combo,
      ...i === index && {
        ...updates,
        ...updatedPartQuote.unitPriceType === 'FINAL' && updates.unitPrice && { unitPriceBase: updates.unitPrice / (combo.markupRate || 1) },
        ...updatedPartQuote.unitPriceType !== 'FINAL' && (updates.unitPriceBase || updates.markupRate) && { unitPrice: (updates.unitPriceBase || combo.unitPriceBase || 0) * (updates.markupRate || combo.markupRate || 1) },
      },
    })),
  })

  const removeTier = index => setUpdatedPartQuote({ ...updatedPartQuote, combos: partQuote.combos.filter((_, i) => i !== index) })

  const partQuotesExist = partQuotes.length > 0
  const partQuoteSelected = get(partQuote, 'id')

  const updatedPartQuoteLines = partQuoteLines.map(line => {
    const match = updates.find(({ id }) => id === line.id)
    if (match) {
      return { ...line, ...match }
    }
    return line
  })

  const moveLine = (id, delta) => {
    const { number } = updatedPartQuoteLines.find(l => l.id === id)
    if ((number === 1 && delta < 0) || (number === partQuoteLines.length && delta > 0)) return
    const newNumber = number + delta
    const conflict = updatedPartQuoteLines.find(l => l.number === newNumber)
    return setUpdates([
      ...updates.map(u => {
        if (u.id === id) return { ...u, number: newNumber }
        if (conflict && u.id === conflict.id) return { ...u, number }
        return u
      }),
      ...!updates.find(u => u.id === id) ? [{ id, number: newNumber }] : [],
      ...conflict && !updates.find(u => u.id === conflict.id) ? [{ id: conflict.id, number }] : [],
    ])
  }

  const updatePartQuoteLines = async () => await Promise.all(updates.map(async ({ id, ...u }) => updatePartQuoteLine(id, omitBy(u, isNil)))).then(fetch).then(() => setUpdates([]))

  const fetch = () => findPartQuoteLines({ partQuoteId: partQuote.id, status: 'ACTIVE', orderBy: ['number','asc'] }).then(setPartQuoteLines)

  const fetchHistory = async () => {
    const query = async (args, prop) => await findPartQuotes({ partId: part.id, limit: 1, ...args }).then(([ result ]) => get(result, prop))
    setQuoteHistory({
      quantityBaseMin: await query({ orderBy: ['quantityBase','asc'] }, 'quantityBase'),
      quantityBaseMax: await query({ orderBy: ['quantityBase','desc'] }, 'quantityBase'),
      leadTimeMin: await query({ orderBy: ['leadTime','asc'] }, 'leadTime'),
      leadTimeMax: await query({ orderBy: ['leadTime','desc'] }, 'leadTime'),
    })
  }

  const getAvg = (min, max) => (min && max) ? (min + max) /2 : '—'

  const add = () => addPartQuoteLine({ partQuoteId: partQuote.id, number: partQuoteLines.length + 1, quantity: partQuote.quantityBase, unit: 'PIECE', unitPrice: 0, markupRate: 1 }).then(fetch)

  const update = () => updatePartQuote(partQuote.id, {
    status: updatedPartQuote.status,
    partId: updatedPartQuote.partId,
    quoteId: updatedPartQuote.quoteId,
    quantityBase: updatedPartQuote.quantityBase,
    leadTime: updatedPartQuote.leadTime,
    leadTimeUnit: updatedPartQuote.leadTimeUnit,
    dateExpiration: updatedPartQuote.dateExpiration,
    combos: updatedPartQuote.combos,
    ...!isDetailed && { unitPrice: updatedPartQuote.unitPrice },
  }).then(async partQuote => {
    enqueueSnackbar('Quote updated successfully.', { variant: 'success' })
    if (onUpdated) onUpdated(partQuote)
    if (updates.length > 0) await updatePartQuoteLines()
    return setPartQuote(partQuote)
  })

  useEffect(() => {
    if (partQuoteId) getPartQuote(partQuoteId).then(setPartQuote)
  }, [partQuoteId])

  useEffect(() => {
    if (part) {
      findPartQuotes({ partId: part.id, orderBy: ['updatedAt','desc'] }).then(setPartQuotes)
      findPartOrders({ partId: part.id, orderBy: ['updatedAt','desc'], limit: 3 }).then(setPartOrders)
      fetchHistory()
    }
    else {
      setPartQuotes([])
      setPartOrders([])
    }
  }, [part])

  useEffect(() => {
    if (partQuote) fetch()
    setUpdatedPartQuote(partQuote)
  }, [partQuote])

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '5px 0 10px' }}>
        <Box style={{ flexGrow: 1 }}>
          <SelectPart
            companyId={companyId}
            partId={get(partQuote, 'partId')}
            onChange={setPart}
          />
        </Box>
        {(!isEqual(partQuote, updatedPartQuote) || updates.length > 0) && (
          <>
            <Button
              variant="outlined"
              startIcon={<Undo />}
              onClick={() => {
                setUpdatedPartQuote(partQuote)
                setUpdates([])
                setChangesClearedAt(new Date())
              }}
              style={{ marginLeft: '10px' }}
              disableElevation
            >
              Revert
            </Button>
            <Button
              variant="contained"
              onClick={update}
              disableElevation
            >
              Save Quote
            </Button>
          </>
        )}
        {updates.length === 0 && (
          <>
            {get(partQuote, 'id') && partQuote.quoteId !== quoteId && (
              <Button
                variant="contained"
                onClick={() => addPartQuote({
                    status: 'ACTIVE',
                    partId: part.id,
                    quoteId,
                    quantityBase: partQuote.quantityBase,
                    leadTime: partQuote.leadTime,
                    leadTimeUnit: partQuote.leadTimeUnit,
                    dateExpiration: getDate(null, '+', 6, 'month'),
                    combos: partQuote.combos,
                  })
                  .then(async partQuote => {
                    const { id: partQuoteId } = partQuote
                    await Promise.all(
                      partQuoteLines.map(
                        ({ number, description = '', quantity, unit, unitPrice, markupRate }) =>
                          addPartQuoteLine({
                            number,
                            description,
                            quantity,
                            unit,
                            unitPrice,
                            markupRate,
                            partQuoteId,
                          })
                      )
                    )
                    return partQuote
                  })
                  .then(setPartQuote)}
                style={{ marginLeft: '10px' }}
                disableElevation
              >
                Copy & Edit
              </Button>
            )}
            {partQuotesExist && (
              <Typography variant="subtitle2" color="GrayText" style={{ marginLeft: '10px' }}>
                OR
              </Typography>
            )}
            <Button
              variant={partQuotesExist ? 'outlined' : 'contained'}
              onClick={async () => addPartQuote({
                status: 'ACTIVE',
                partId: part.id,
                quoteId,
                quantityBase: 1,
                leadTime: 16,
                leadTimeUnit: 'week',
                dateExpiration: getDate(null, '+', 6, 'month'),
                combos: await getPartQuoteCombos(),
              }).then(setPartQuote)}
              style={{ marginLeft: '10px' }}
              disabled={!get(part, 'id')}
              disableElevation
            >
              {partQuotesExist ? 'Start Fresh' : 'New Quote'}
            </Button>
          </>
        )}
      </Box>
      {part && (
        <Box sx={{ position: 'relative' }}>
          {get(partQuote, 'id') && isDetailed ? (
            <TableContainer style={{ maxHeight: '75vh' }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Line #</TableCell>
                    {rowView === 'VENDOR' && (
                      <>
                        <TableCell>Vendor</TableCell>
                        <TableCell>PO</TableCell>
                      </>
                    )}
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button
                          className="tiny"
                          size="small"
                          variant="outlined"
                          onClick={() => setRowView('VENDOR')}
                          style={{ padding: '0 10px', fontSize: '12px' }}
                          startIcon={<ArrowLeft />}
                          disableElevation
                          disabled={rowView === 'VENDOR'}
                        >
                          Vendor
                        </Button>
                        <Box sx={{ flexGrow: 1 }}>
                          Description
                        </Box>
                        <Button
                          className="tiny"
                          size="small"
                          variant="outlined"
                          onClick={() => setRowView('PRICING')}
                          style={{ padding: '0 10px', fontSize: '12px' }}
                          endIcon={<ArrowRight />}
                          disableElevation
                          disabled={rowView === 'PRICING'}
                        >
                          Pricing
                        </Button>
                      </Box>
                    </TableCell>
                    {/* <TableCell>Reference</TableCell> */}
                    <TableCell>Qty Needed</TableCell>
                    <TableCell>Unit</TableCell>
                    {rowView === 'PRICING' && (
                      <>
                        <TableCell>Qty Made</TableCell>
                        <TableCell>Rate</TableCell>
                        {/* <TableCell>Margin</TableCell> */}
                        <TableCell align="right">Amount</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Alert
                        severity="info"
                      >
                        For internal use only. Not visible to customer.
                      </Alert>
                    </TableCell>
                  </TableRow>
                  {sortBy(updatedPartQuoteLines, ['number']).filter(({ status }) => status !== 'ARCHIVED').map(data => (
                    <PartQuoteLine
                      key={data.id}
                      {...data}
                      companyId={companyId}
                      partId={partQuote.partId}
                      readOnly={get(partQuote, 'quoteId') !== quoteId}
                      onUpdate={changes => {
                        if (!updates.find(u => u.id === data.id)) {
                          return setUpdates([ ...updates, { id: data.id, ...changes } ])
                        }
                        return setUpdates(
                          updates.map(u => u.id === data.id ? { ...u, ...changes } : u)
                        )
                      }}
                      onMove={moveLine}
                      changesClearedAt={changesClearedAt}
                      view={rowView}
                    />
                  ))}
                  {get(partQuote, 'quoteId') === quoteId && (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={add}
                          disableElevation
                        >
                          Add New Line
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={9}>
                      <div style={{ display: 'flex', gap: '15px' }}>
                        <LineChart
                          width={400}
                          height={300}
                          data={get(updatedPartQuote, 'combos', []).map(combo => {
                            const basePrice = sumBy(updatedPartQuoteLines, ({ quantity, quantityMade, unitPrice, isFlatFee, markupRate }) => (quantity*unitPrice*markupRate) / (isFlatFee ? 1 : (quantityMade || 1))) / updatedPartQuote.quantityBase
                            return { ...combo, unitPrice: Math.round(basePrice * combo.markupRate * 100) / 100 }
                          })}
                        >
                          <XAxis dataKey="quantity"/>
                          <YAxis dataKey="unitPrice"/>
                          <Charttip />
                          <CartesianGrid stroke="#ccc" strokeDasharray="1 1"/>
                          <Line type="monotone" dataKey="unitPrice" stroke="#8884d8" />
                        </LineChart>
                        <Table size="small">
                          <TableBody>
                            <MaterialsRow
                              partQuote={updatedPartQuote}
                              partQuoteLines={updatedPartQuoteLines}
                            />
                            <LaborRow
                              partQuote={updatedPartQuote}
                              partQuoteLines={updatedPartQuoteLines}
                            />
                            {get(updatedPartQuote, 'combos', []).map((data, index) => (
                              <PriceRow
                                key={index}
                                {...data}
                                partQuote={updatedPartQuote}
                                index={index}
                                basePrice={sumBy(updatedPartQuoteLines, ({ quantity, quantityMade, unitPrice, isFlatFee, markupRate }) => (quantity*unitPrice*markupRate) / (isFlatFee ? 1 : (quantityMade || 1))) / updatedPartQuote.quantityBase}
                                readOnly={get(partQuote, 'quoteId') !== quoteId}
                                onChange={updates => setUpdatedPartQuote({ ...updatedPartQuote, combos: updatedPartQuote.combos.map((c, i) => i === index ? { ...c, ...updates } : c) })}
                                onRemove={() => setUpdatedPartQuote({ ...updatedPartQuote, combos: updatedPartQuote.combos.filter((_c, i) => i !== index) })}
                                changesClearedAt={changesClearedAt}
                              />
                            ))}
                            <TableRow>
                              <TableCell colSpan={8}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  startIcon={<Add />}
                                  onClick={() => setUpdatedPartQuote({ ...updatedPartQuote, combos: [...updatedPartQuote.combos,{ quantity: 0, markupRate: 1 }] })}
                                  style={{ marginTop: '5px', whiteSpace: 'nowrap' }}
                                  disableElevation
                                >
                                  Add New Price Point
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ flexGrow: 1 }}>
                <Tabs value={view} onChange={(event, newValue) => setView(newValue)}>
                  <Tab label="Past Quotes" value="QUOTES" />
                  <Tab label="Past Orders" value="ORDERS" />
                </Tabs>
                {view === 'QUOTES' && (
                  <TableContainer style={{ background: '#efefef', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Quote Date</TableCell>
                          <TableCell>Quote Number</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Unit</TableCell>
                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Extended Price</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {partQuotes.filter(({ id }) => id !== get(partQuote, 'id')).length === 0 && (
                          <TableRow>
                            <TableCell colSpan="100%">
                              <Typography variant="body2">No quotes found.</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {partQuotes.filter(({ id }) => id !== get(partQuote, 'id')).map((partQuote) => {
                          const { id, quoteId, partId, quantityBase, leadTime, leadTimeUnit, createdAt } = partQuote
                          return (
                            <TableRow key={id}>
                              <TableCell>{createdAt.toLocaleDateString()}</TableCell>
                              <TableCell><QuoteProp quoteId={quoteId} prop="number" /></TableCell>
                              <TableCell>{quantityBase}</TableCell>
                              <TableCell>piece</TableCell>
                              <TableCell align="right">
                              </TableCell>
                              <TableCell align="right">
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  size="small"
                                  variant="outlined"
                                  startIcon={<ContentCopy />}
                                  onClick={() => setPartQuote(partQuote)}
                                  style={{ whiteSpace: 'nowrap' }}
                                  disableElevation
                                >
                                  Copy Quote
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {view === 'ORDERS' && (
                  <TableContainer style={{ position: 'sticky', top: 0, background: '#efefef', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Order Date</TableCell>
                          <TableCell>PO Number</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Unit</TableCell>
                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Extended Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {partOrders.length === 0 && (
                          <TableRow>
                            <TableCell colSpan="100%">
                              <Typography variant="body2">No orders found.</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {partOrders.map(({ id, orderId, quantity, unitPrice, createdAt }) => (
                          <TableRow key={id}>
                            <TableCell>{createdAt.toLocaleDateString()}</TableCell>
                            <TableCell><OrderNumber orderId={orderId} /></TableCell>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>piece</TableCell>
                            <TableCell align="right">
                              <NumberFormat
                                value={unitPrice}
                                prefix="$"
                                thousandSeparator
                                fixedDecimalScale
                                decimalScale={2}
                                displayType="text"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <NumberFormat
                                value={unitPrice*quantity}
                                prefix="$"
                                thousandSeparator
                                fixedDecimalScale
                                decimalScale={2}
                                displayType="text"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
              <Box>
                {partQuoteSelected && (
                  <>
                    <Tabs value="CURRENT">
                      <Tab label="Current Quote" value="CURRENT" />
                    </Tabs>
                    <TableContainer style={{ position: 'sticky', top: 0, background: '#efefef', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell>Min</TableCell>
                            <TableCell>Max</TableCell>
                            <TableCell>Avg</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={2}>MOQ</TableCell>
                            <TableCell><Typography style={{ minWidth: '100px' }}>{get(quoteHistory, 'quantityBaseMin', '—')}</Typography></TableCell>
                            <TableCell><Typography style={{ minWidth: '100px' }}>{get(quoteHistory, 'quantityBaseMax', '—')}</Typography></TableCell>
                            <TableCell><Typography style={{ minWidth: '100px' }}>{getAvg(get(quoteHistory, 'quantityBaseMin'), get(quoteHistory, 'quantityBaseMax'))}</Typography></TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                type="number"
                                size="small"
                                variant="outlined"
                                label="Base quantity"
                                value={get(updatedPartQuote, 'quantityBase', 0)}
                                onChange={({ target: { value } }) => setUpdatedPartQuote({ ...updatedPartQuote, quantityBase: positiveInt(value) })}
                                {...addErrorProps(errors, 'quantityBase')}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2}>Lead time</TableCell>
                            <TableCell><Typography style={{ minWidth: '100px' }}>{get(quoteHistory, 'leadTimeMin', '—')}</Typography></TableCell>
                            <TableCell><Typography style={{ minWidth: '100px' }}>{get(quoteHistory, 'leadTimeMax', '—')}</Typography></TableCell>
                            <TableCell><Typography style={{ minWidth: '100px' }}>{getAvg(get(quoteHistory, 'leadTimeMin'), get(quoteHistory, 'leadTimeMax'))}</Typography></TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                type="number"
                                size="small"
                                variant="outlined"
                                label="Lead time"
                                value={get(updatedPartQuote, 'leadTime', 0)}
                                onChange={({ target: { value } }) => setUpdatedPartQuote({ ...updatedPartQuote, leadTime: positiveInt(value) })}
                                {...addErrorProps(errors, 'leadTime')}
                                InputProps={{
                                  endAdornment: (
                                    <Typography variant="subtitle2" color="GrayText">
                                      weeks
                                    </Typography>
                                  )
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={5}><Typography>Expiration date</Typography></TableCell>
                            <TableCell>
                              <DateField
                                label="Expiration date"
                                value={get(updatedPartQuote, 'dateExpiration')}
                                onChange={dateExpiration => setUpdatedPartQuote({ ...updatedPartQuote, dateExpiration })}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: <Tooltip title="Suggested expiration date"><Info fontSize="small" color="info" style={{ marginRight: '5px' }} /></Tooltip>,
                                    }}
                                    {...addErrorProps(errors, 'dateExpiration')}
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Radio
                                options={[
                                  { label: 'Control base price & margin', value: 'BASE' },
                                  { label: 'Control final price', value: 'FINAL' },
                                ]}
                                value={get(updatedPartQuote, 'unitPriceType', 'BASE')}
                                onChange={({ target: { value } }) => setUpdatedPartQuote({ ...updatedPartQuote, unitPriceType: value })}
                                optionCtnStyle={{ flexDirection: 'row', justifyContent: 'end' }}
                                style={{ width: '100%' }}
                              />
                            </TableCell>
                          </TableRow>
                          {get(updatedPartQuote, 'combos', []).map(({ quantity, markupRate, unitPrice, unitPriceBase, leadTime, leadTimeUnit }, index) => {
                            const isFinal = get(updatedPartQuote, 'unitPriceType') === 'FINAL'
                            return (
                              <TableRow sx={{ background: '#fff' }}>
                                <TableCell colSpan={2}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    name="quantity"
                                    label="Quantity"
                                    fullWidth
                                    value={quantity}
                                    onChange={({ target: { value } }) => updateTier(index, { quantity: positiveInt(value) })}
                                    style={{ transform: 'translateX(-5px)' }}
                                    InputProps={{
                                      endAdornment: (
                                        <IconButton edge="end" onClick={() => removeTier(index)}>
                                          <DeleteOutline fontSize="small" />
                                        </IconButton>
                                      ),
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    name="unitPriceBase"
                                    label="Base Price"
                                    placeholder="$"
                                    fullWidth
                                    value={unitPriceBase}
                                    onChange={({ target: { value } }) => updateTier(index, { unitPriceBase: positiveFloat(value) })}
                                    style={{ transform: 'translateX(-5px)' }}
                                    InputProps={{
                                      inputComponent: PriceField,
                                    }}
                                    disabled={isFinal}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    name="margin"
                                    label="Margin"
                                    fullWidth
                                    value={markupRate}
                                    onChange={({ target: { value } }) => updateTier(index, { markupRate: positiveFloat(value) })}
                                    style={{ transform: 'translateX(-5px)' }}
                                    InputProps={{
                                      endAdornment: 'x',
                                    }}
                                    disabled={isFinal}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    name="unitPrice"
                                    label="Final Unit Price"
                                    placeholder="$"
                                    fullWidth
                                    value={unitPrice}
                                    onChange={({ target: { value } }) => updateTier(index, { unitPrice: positiveFloat(value) })}
                                    InputProps={{
                                      inputComponent: PriceField,
                                    }}
                                    disabled={!isFinal}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    value={leadTime || get(updatedPartQuote, 'leadTime')}
                                    onChange={({ target: { value } }) => updateTier(index, { leadTime: positiveInt(value) })}
                                    InputProps={{
                                      endAdornment: (
                                        <Typography variant="body2" color="GrayText">{leadTimeUnit || get(updatedPartQuote, 'leadTimeUnit')}</Typography>
                                      ),
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

export default CalculateQuote