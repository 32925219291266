import { useState } from 'react'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { EditOutlined, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, RequestQuoteOutlined, LocalShippingOutlined, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { Sankey, Layer, Rectangle, Tooltip as Charttip } from 'recharts'
import NumberFormat from 'react-number-format'
import { get, lowerCase, startCase } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc } from '../../functions'
import { getPart } from '../Parts/functions'
import PartName from '../Parts/PartName'
import PartNumber from '../Parts/PartNumber'
import OrderNumber from '../Orders/OrderNumber'
import PartOrderProp from '../Orders/PartOrderProp'
import LookupPrice from '../Orders/LookupPrice'
import { findShipmentLines } from '../Shipments/functions'
import { updateLineItem } from './functions'
import CreateEditLineItem from './CreateEditLineItem'

function LineItemRow({ id, companyId, partId, partOrderId, invoiceId, refPoNumber, refLineNumber, lineNumber, name, notes, quantity, unit, unitPrice, invoiceAt, invoice, onUpdated, readOnly, isShowingJobs, isShowingInvoices, isShowingShipments, isLineIncluded, view }) {
  const [part, setPart] = useState()
  const [shipmentLines, setShipmentLines] = useState()

  useAsyncFunc(getPart, partId, setPart, [partId])

  useAsyncFunc(findShipmentLines, { partOrderId, orderBy: ['createdAt','asc'] }, setShipmentLines, [partOrderId])

  return (
    <TableRow>
      {isLineIncluded && (
        <>
          <TableCell><OrderNumber partOrderId={partOrderId} /></TableCell>
          <TableCell>{lineNumber}</TableCell>
        </>
      )}
      {view !== 'PRINT' && <TableCell>{unit}</TableCell>}
      {/* <TableCell>{startCase(lowerCase(unit))}{quantity > 1 ? 's' : ''}</TableCell> */}
      <TableCell>
        {/* {refPoNumber && (
          <Typography variant="body2">
            {`PO ${refPoNumber} · Line ${refLineNumber}`}
          </Typography>
        )} */}
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {name || `P/N ${get(part, 'number')} Rev.${get(part, 'revision')}`}
        </Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {get(part, 'name')}
        </Typography>
        <Typography variant="body2" style={{ paddingLeft: '15px' }}>
          {notes}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {quantity}
        {view === 'PRINT' && ` ${unit}`}
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={unitPrice}
          prefix="$"
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          displayType="text"
        />
        <LookupPrice
          partId={partId}
          quantity={quantity}
          unitPrice={unitPrice}
          onUpdate={unitPrice => updateLineItem(id, { unitPrice }).then(onUpdated)}
        />
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={unitPrice*quantity}
          prefix="$"
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          displayType="text"
        />
      </TableCell>
      {/* <TableCell align="right">{(shipmentLines || []).length}</TableCell> */}
      <TableCell align="right">
        {invoiceAt ? invoiceAt.toLocaleDateString() : ''}
      </TableCell>
      <TableCell align="right">
        <PartOrderProp partOrderId={partOrderId} prop="dueAt" />
      </TableCell>
      {!readOnly && (
        <TableCell align="right">
          <CreateEditLineItem
            companyId={companyId}
            data={{
              id,
              partId,
              partOrderId,
              invoiceId,
              refPoNumber,
              refLineNumber,
              lineNumber,
              name,
              notes,
              quantity,
              unit,
              unitPrice,
              invoiceAt,
              invoice,
            }}
            button={(
              <IconButton size="small">
                <EditOutlined fontSize="small" />
              </IconButton>
            )}
            buttonStyle={{ display: 'inline-block' }}
            onUpdate={onUpdated}
          />
          <CreateEditLineItem
            companyId={companyId}
            data={{
              partId,
              invoiceId,
              refPoNumber,
              refLineNumber,
              lineNumber,
              name,
              notes,
              quantity,
              unit,
              unitPrice,
              invoiceAt,
              invoice,
            }}
            button={(
              <IconButton size="small">
                <ContentCopy fontSize="small" />
              </IconButton>
            )}
            buttonStyle={{ display: 'inline-block' }}
            onUpdate={onUpdated}
          />
          <IconButton size="small">
            <DeleteOutline fontSize="small" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  )
}

export default LineItemRow