import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getInvoice } from './functions'

function InvoiceProp({ invoiceId, prop }) {
  const [invoice, setInvoice] = useState()

  useAsyncFunc(async invoiceId => {
    if (invoiceId) return getInvoice(invoiceId)
  }, invoiceId, setInvoice, [invoiceId])

  return get(invoice, prop, <Skeleton variant="rectangular" animation="wave" />)
}

export default InvoiceProp