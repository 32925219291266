import { Box, Typography, TextField, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { CalendarMonthOutlined } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get, isEqual } from 'lodash'
import DateField from './DateField'
import Dropdown from './Dropdown'

function DateRange({ value, ranges, inputStyle, onChange }) {
  return (
    <>
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<CalendarMonthOutlined />}
          >
            {get(ranges.find(({ value: [from, to] }) => isEqual(value[0], from) && isEqual(value[1], to)), 'label', 'Custom')}
          </Button>
        )}
        buttonStyle={{ display: 'inline-block' }}
        items={ranges}
        onChange={({ value }) => onChange(value)}
      />
      <DateField
        label="Start"
        value={value[0]}
        onChange={from => onChange([from, value[1]])}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            inputProps={{
              ...params.inputProps,
              style: { ...params.inputProps.style, ...inputStyle },
            }}
          />
        )}
      />
      <DateField
        label="End"
        value={value[1]}
        onChange={to => onChange([value[0], to])}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            inputProps={{
              ...params.inputProps,
              style: { ...params.inputProps.style, ...inputStyle },
            }}
          />
        )}
      />
    </>
  )
}

export default DateRange