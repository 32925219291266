import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Badge } from '@mui/material'
import { DonutLarge, DeleteOutline, Check, Clear, Add } from '@mui/icons-material'
import { get, groupBy, sortBy, reduce } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, useAsyncFunc, jobStatuses, positiveInt, sequence, getDateDiff } from '../../functions'
import Accordion from '../Accordion'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'
import { getPart } from '../Parts/functions'
import PartNumber from '../Parts/PartNumber'
import PartName from '../Parts/PartName'
import EditPartDetails from '../Parts/EditDetails'
import { findPartOrders, getPartOrder, updatePartOrder } from '../Orders/functions'
import OrderContactProp from '../Orders/OrderContactProp'
import OrderNumber from '../Orders/OrderNumber'
import { getJob, findJobs, upsertJobAllocation, updateJob } from './functions'
import CreateJob from './Create'

function AllocateJobs({ jobId, partId, forPartOrderId, button, buttonStyle, forceOpen, setForceOpen, onAllocated }) {
  const [isAllocating, setIsAllocating] = useState(false)
  const [job, setJob] = useState()
  const [jobs, setJobs] = useState()
  const [part, setPart] = useState()
  const [partOrders, setPartOrders] = useState()
  const [allocations, setAllocations] = useState([])
  const [isOverAllocating, setIsOverAllocating] = useState(false)
  const [isShowingRelatedPartOrders, setIsShowingRelatedPartOrders] = useState(false)
  const [jobUpdates, setJobUpdates] = useState([])
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsAllocating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isAllocating !== forceOpen) setIsAllocating(forceOpen)
  }, [forceOpen, isAllocating])

  useAsyncFunc(async jobId => {
    if (jobId) return getJob(jobId)
  }, jobId, setJob, [jobId])

  useAsyncFunc(async job => {
    if (job?.partId) return getPart(job.partId)
  }, job, setPart, [job])

  useAsyncFunc(async partId => {
    if (partId) return getPart(partId)
  }, partId, setPart, [partId])

  useAsyncFunc(async part => {
    if (part?.id) return findJobs({ companyId: part.companyId, partId: part.id, quantityAvailableMin: 1, orderBy: ['createdAt','desc'] })
  }, part, setJobs, [part])

  const fetchJobs = () => findJobs({ companyId: part.companyId, partId: part.id, quantityAvailableMin: 1, orderBy: ['createdAt','desc'] }).then(setJobs)

  useAsyncFunc(async part => {
    if (part?.id) return findPartOrders({ partId: part.id, status: 'OPEN', orderBy: ['dueAt','asc'] })
  }, part, setPartOrders, [part])

  const getAllocatingQuantity = (allocations, jobId) => reduce(allocations, (sum, a) => sum + (jobId === a.jobId ? a.quantity : 0), 0)
  const getEarliestDueAt = (allocations, jobId) => get(sortBy(allocations.filter(a => a.jobId === jobId), ({ dueAt }) => dueAt), '[0].dueAt')
  const updateAllocation = (jobId, partOrderId, updates) => setAllocations(allocations.map(a => {
    if (a.jobId === jobId && a.partOrderId === partOrderId) {
      return { ...a, ...updates }
    }
    return a
  }))

  const upsertJobUpdates = (jobId, updates) => setJobUpdates(jobUpdates.find(u => u.jobId === jobId) ? jobUpdates.map(u => {
    if (u.jobId === jobId) {
      return { ...u, updates: { ...u.updates, ...updates } }
    }
    return u
  }) : [ ...jobUpdates, { jobId, updates } ])

  useEffect(() => {
    const validate = () => {
      const errors = {}
      jobs.map(({ id, quantityAvailable }) => {
        if (!isOverAllocating && getAllocatingQuantity(allocations, id) > quantityAvailable) {
          groupBy(allocations, ({ jobId }) => jobId)[id].map(({ jobId, partOrderId }) => {
            errors[`quantity-${jobId}-${partOrderId}`] = 'Job overallocated'
          })
        }
      })
      setErrors(errors)
      return Object.keys(errors).length === 0
    }
    jobs && validate()
  }, [jobs, allocations, isOverAllocating])

  const submit = async () => {
    const process = async (allocation, index) => {
      await upsertJobAllocation(allocation)
      const { dueAt } = await getPartOrder(allocation.partOrderId).then(({ id, quantityBalance }) => updatePartOrder(id, { quantityBalance: quantityBalance - allocation.quantity }))
      await getJob(allocation.jobId).then(({ id, quantityAvailable, dueAt: jobDueAt }) => updateJob(id, { ...get(jobUpdates.find(({ jobId }) => jobId === id), 'updates', {}), quantityAvailable: quantityAvailable - allocation.quantity, ...jobDueAt > dueAt && { dueAt } }))
    }
    await sequence(allocations, process)
    enqueueSnackbar('Jobs allocated successfully.', { variant: 'success' })
    handleClose()
    setAllocations([])
    if (onAllocated) onAllocated()
  }

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsAllocating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        open={isAllocating}
        onClose={handleClose}
      >
        <DialogTitle>
          Allocate jobs
          {part?.companyId && (
            <CreateJob
              companyId={part.companyId}
              button={(
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Add />}
                  disableElevation
                >
                  Add Job
                </Button>
              )}
              buttonStyle={{ display: 'inline-block', marginLeft: '10px' }}
              data={{
                isNumberAuto: true,
                status: 'IN_PROGRESS',
                partId,
                ...forPartOrderId && (partOrders || []).find(({ id }) => id === forPartOrderId) && {
                  quantity: partOrders.find(({ id }) => id === forPartOrderId).quantity,
                  dueAt: partOrders.find(({ id }) => id === forPartOrderId).dueAt,
                },
              }}
              onCreate={fetchJobs}
            />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {part && (
              <Accordion
                title={(
                  <>
                    {`P/N `}
                    <PartNumber partId={part.id} />
                    {` · `}
                    <PartName partId={part.id} />
                  </>
                )}
                content={(
                  <EditPartDetails
                    part={part}
                    onUpdate={setPart}
                  />
                )}
              />
            )}
            {(jobs || []).map(({ id, number, numberRef, quantity, quantityAvailable, status, dueAt, createdAt }) => {
              const allocatingQuantity = getAllocatingQuantity(allocations, id)
              const earliestDueAt = getEarliestDueAt(allocations, id)
              return (
                <Accordion
                  key={id}
                  title={(
                    <Badge
                      {...getDateDiff(createdAt, new Date()).minutes < 30 && { color: 'primary', badgeContent: 'New' }}
                    >
                      {`J/N ${number}`}
                    </Badge>
                  )}
                  afterTitle={(
                    <Box onClick={e => e.stopPropagation()} style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '30px' }}>
                      <Box>
                        <Typography variant="overline">Available</Typography>
                        <Typography {...allocatingQuantity && { color: quantityAvailable - allocatingQuantity < 0 ? 'red' : 'green' }}>
                          {quantityAvailable}{allocatingQuantity ? ` → ${quantityAvailable - allocatingQuantity}` : ''}
                          {` / ${quantity}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="overline">Due</Typography>
                        <Typography {...earliestDueAt < dueAt && { color: 'green' }}>
                          {dueAt.toLocaleDateString()}
                          {earliestDueAt < dueAt && ` → ${earliestDueAt.toLocaleDateString()}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="overline">Status</Typography>
                        <Dropdown
                          button={(
                            <Button
                              size="small"
                              variant="outlined"
                              {...get(jobUpdates.find(({ jobId }) => jobId === id), 'updates.status', status) !== status && { color: 'success' }}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              {get(jobUpdates.find(({ jobId }) => jobId === id), 'updates.status', status).replace('_',' ')}
                            </Button>
                          )}
                          items={jobStatuses}
                          onChange={({ value }) => upsertJobUpdates(id, { status: value })}
                        />
                      </Box>
                    </Box>
                  )}
                  content={(
                    <TableContainer style={{ marginBottom: '5px', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Due
                            </TableCell>
                            <TableCell>
                              PO
                            </TableCell>
                            <TableCell>
                              Line
                            </TableCell>
                            <TableCell>
                              Ship To
                            </TableCell>
                            <TableCell>
                              Quantity
                            </TableCell>
                            <TableCell>
                              Balance
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                              Last Updated
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(partOrders || []).map(({ id: partOrderId, orderId, lineNumber, quantity, quantityBalance, dueAt, updatedAt }) => {
                            const allocation = allocations.find(allocation => allocation.jobId === id && allocation.partOrderId === partOrderId)
                            const totalAllocationQuantity = reduce(allocations, (sum, a) => sum + (partOrderId === a.partOrderId ? a.quantity : 0), 0)
                            return (isShowingRelatedPartOrders || partOrderId === forPartOrderId || !forPartOrderId) && (
                              <TableRow key={partOrderId} {...partOrderId === forPartOrderId && { style: { background: '#e3f2fd' } }}>
                                <TableCell>
                                  {dueAt.toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                  <OrderNumber orderId={orderId} />
                                </TableCell>
                                <TableCell>
                                  {lineNumber}
                                </TableCell>
                                <TableCell>
                                  <OrderContactProp partOrderId={partOrderId} contactIdField="shipToContactId" prop="name" />
                                </TableCell>
                                <TableCell>
                                  {quantity}
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2" {...totalAllocationQuantity > 0 && { color: 'green' }}>
                                    {quantityBalance}
                                    {totalAllocationQuantity > 0 ? ` → ${quantityBalance - totalAllocationQuantity}` : ''}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {allocation ? (
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      label="Quantity"
                                      value={allocation.quantity}
                                      onChange={({ target: { value } }) => updateAllocation(id, partOrderId, { quantity: positiveInt(value) })}
                                      onBlur={({ target: { value } }) => positiveInt(value) === 0 && setAllocations(allocations.filter(a => a !== allocation))}
                                      InputProps={{
                                        endAdornment: (
                                          <>
                                            <Typography color="GrayText" style={{ cursor: 'pointer' }} onClick={() => updateAllocation(id, partOrderId, { quantity: quantityBalance })}>Max</Typography>
                                            <Tooltip title="Delete allocation">
                                              <IconButton edge="end" onClick={() => setAllocations(allocations.filter(a => a !== allocation))}>
                                                <DeleteOutline fontSize="small" />
                                              </IconButton>
                                            </Tooltip>
                                          </>
                                        ),
                                      }}
                                      {...addErrorProps(errors, `quantity-${jobId}-${partOrderId}`)}
                                    />
                                  ) : (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      startIcon={<DonutLarge />}
                                      onClick={() => setAllocations([ ...allocations, { jobId: id, partOrderId, quantity: 1, dueAt } ])}
                                    >
                                      Allocate
                                    </Button>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {updatedAt.toLocaleDateString()}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Checkbox
                                checked={isOverAllocating}
                                onChange={({ target: { checked } }) => setIsOverAllocating(checked)}
                                label="Allow overallocation"
                              />
                              {forPartOrderId && (
                                <Checkbox
                                  checked={isShowingRelatedPartOrders}
                                  onChange={({ target: { checked } }) => setIsShowingRelatedPartOrders(checked)}
                                  label="Show related line items"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  defaultExpanded={id === jobId || getDateDiff(createdAt, new Date()).minutes < 5}
                />
              )
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
            disabled={Object.keys(errors).length > 0}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AllocateJobs