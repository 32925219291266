import { Layer, Rectangle } from 'recharts'
import { get } from 'lodash'
import OrderNumber from './OrderNumber'
import PartOrderProp from './PartOrderProp'

function OrderConnectionNode({ x, y, width, height, index, payload, containerWidth }) {
  const isOut = x + width + 6 > containerWidth

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill="#7e57c2"
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="13"
      >
        {(payload.type === 'TOTAL' || payload.type === 'UNALLOCATED') ? payload.type : (
          <>
            {`PO `}
            <OrderNumber partOrderId={get(payload, 'connection.partOrderId')} />
            {` · Line #`}
            <PartOrderProp partOrderId={get(payload, 'connection.partOrderId')} prop="lineNumber" />
          </>
        )}
      </text>
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="11"
      >
        {`${(payload.type === 'TOTAL' || payload.type === 'UNALLOCATED') ? payload.value : get(payload, 'connection.quantity')} units`}
      </text>
    </Layer>
  )
}

export default OrderConnectionNode