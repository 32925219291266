import { useState, useEffect } from 'react'
import { Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Sync, RocketLaunchOutlined, DownloadForOfflineOutlined } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc, getLocal, setLocal, jobRanges as ranges, getDateDiffStr } from '../../functions'
import Checkbox from '../Checkbox'
import Paginate from '../Paginate'
import { findPartOrders } from '../Orders/functions'
import PartOrderFilters from '../Orders/PartOrderFilters'
import Ship from './Ship'
import ShipListRow from './ShipListRow'

function ShipList({ companyId, onShipped }) {
  const [partOrders, setPartOrders] = useState()
  const [shipping, setShipping] = useState([])
  const [limit, setLimit] = useState(10)
  const [fetchedAt, setFetchedAt] = useState()
  const [isOnlyCompleted, setIsOnlyCompleted] = useState(true)

  const [todayFrom, todayTo] = ranges.find(({ label }) => label === 'Today').value

  const [filterBy, setFilterBy] = useState(
    getLocal('shipmentsPartOrdersFilterBy', {
      label: 'Due Date',
      value: 'dueAt',
    }))

  const [filterByValue, setFilterByValue] = useState(
    getLocal('shipmentsPartOrdersFilterByValue', {
      dueAtFrom: todayFrom,
      dueAtTo: todayTo,
    }))

  const getFilterArgs = (field, value) => {
    const { dueAtFrom, dueAtTo } = value
    return {
      ...field === 'dueAt' && { dueAtFrom, dueAtTo },
    }
  }

  useAsyncFunc(async args => {
    setLocal('shipmentsPartOrdersFilterBy', filterBy)
    setLocal('shipmentsPartOrdersFilterByValue', filterByValue)
    return findPartOrders(args)
  }, {
    ...isOnlyCompleted && { status: 'COMPLETED' },
    ...getFilterArgs(filterBy.value, filterByValue),
    orderBy: ['dueAt','asc'],
    limit,
  }, setPartOrders, [filterBy, filterByValue, limit, isOnlyCompleted])

  const fetch = () => findPartOrders({
    ...isOnlyCompleted && { status: 'COMPLETED' },
    ...getFilterArgs(filterBy.value, filterByValue),
    orderBy: ['dueAt','asc'],
    limit,
  }).then(setPartOrders)

  useEffect(() => {
    setFetchedAt(new Date())
    setShipping([])
  }, [partOrders])

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={7}>
              <PartOrderFilters
                beforeFilters={(
                  <Ship
                    companyId={companyId}
                    partOrders={shipping}
                    button={(
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<RocketLaunchOutlined />}
                        disableElevation
                        disabled={shipping.length === 0}
                      >
                        Ship
                      </Button>
                    )}
                    onSubmit={() => {
                      fetch()
                      if (onShipped) onShipped()
                    }}
                  />
                )}
                {...fetchedAt && {
                  afterFilters: (
                    <>
                      <Checkbox
                        checked={isOnlyCompleted}
                        onChange={({ target: { checked } }) => setIsOnlyCompleted(checked)}
                        label="Ready to ship"
                      />
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<Sync />}
                        onClick={fetch}
                      >
                        {getDateDiffStr(null, fetchedAt)}
                      </Button>
                    </>
                  ),
                }}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                filterByValue={filterByValue}
                setFilterByValue={setFilterByValue}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Due
            </TableCell>
            <TableCell>
              PO
            </TableCell>
            <TableCell>
              Line
            </TableCell>
            <TableCell>
              Ship To
            </TableCell>
            <TableCell>
              Part
            </TableCell>
            <TableCell>
              Quantity
            </TableCell>
            <TableCell>
              Last Updated
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partOrders && partOrders.length === 0 && <TableRow><TableCell colSpan={6}>No more orders ready to ship.</TableCell></TableRow>}
          {(partOrders || []).map(partOrder => (
            <ShipListRow
              key={partOrder.id}
              companyId={companyId}
              shipping={shipping}
              setShipping={setShipping}
              partOrder={partOrder}
            />
          ))}
          {partOrders && (
            <TableRow>
              <TableCell colSpan={11} style={{ textAlign: 'center' }}>
                <Paginate
                  label="Load more"
                  size="small"
                  variant="outlined"
                  startIcon={<DownloadForOfflineOutlined />}
                  disableElevation
                  onClick={() => setLimit(limit + 10)}
                  query={() => findPartOrders({
                    ...isOnlyCompleted && { status: 'COMPLETED' },
                    ...getFilterArgs(filterBy.value, filterByValue),
                    orderBy: ['dueAt','asc'],
                    startAfter: get(partOrders[partOrders.length - 1], '_snap'),
                    limit: 1,
                  })}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ShipList
