import { useState, useEffect } from 'react'
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, Stack, Alert } from '@mui/material'
import { Check, AddBoxOutlined, Clear, HighlightOff } from '@mui/icons-material'
import { get, intersectionBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, addUniqueFileRef, updateFileRef, findFileRefs } from '../functions'
import Checkbox from './Checkbox'
import FileRefResult from './FileRefResult'
import RefFromId from './RefFromId'

function AddRemoveFileRefs({ companyId, button, buttonStyle, forceOpen, setForceOpen, categories, adding, removing, refFroms, refFromIds, onUpdated }) {
  const [isAddingRemoving, setIsAddingRemoving] = useState(false)
  const [errors, setErrors] = useState({})

  const [updatedAdding, setUpdatedAdding] = useState([])
  const [updatedRemoving, setUpdatedRemoving] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!validate()) return
    await Promise.all(
      updatedAdding.map(async ({ checked, id, name, refFromId }) =>
        checked && addUniqueFileRef({
          name, fileId: id, refFromId, refFromType: refFroms.find(({ id }) => id === refFromId).type, status: 'ACTIVE', ...categories && { categories },
        })
      )
    )
    await Promise.all(
      updatedRemoving.map(({ checked, id, refFromId }) =>
        checked && findFileRefs({ refFromId, fileId: id, orderBy: ['updatedAt','desc'] })
          .then(async fileRefs => await Promise.all(fileRefs.map(({ id }) => updateFileRef(id, { status: 'REMOVED', removedAt: new Date() }))))
      )
    )
    if (onUpdated) onUpdated()
    enqueueSnackbar('References updated successfully.', { variant: 'success' })
    handleClose()
  }

  const handleClose = () => {
    setIsAddingRemoving(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isAddingRemoving !== forceOpen) setIsAddingRemoving(forceOpen)
  }, [forceOpen, isAddingRemoving])

  useEffect(() => {
    setUpdatedAdding(adding.map(item => refFromIds.map(refFromId => ({ ...item, refFromId, checked: true }))).flat())
  }, [adding, refFromIds])

  useEffect(() => {
    setUpdatedRemoving(removing.map(item => refFromIds.map(refFromId => ({ ...item, refFromId, checked: true }))).flat())
  }, [removing, refFromIds])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsAddingRemoving(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isAddingRemoving}
        onClose={handleClose}
        style={{ zIndex: 1400 }}
      >
        <DialogTitle>Add or remove references</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            {refFroms.map(({ id: refFromId, type: refFromIdType }) => (
                <List key={refFromId}>
                  <Checkbox
                    checked={updatedAdding.find(i => i.refFromId === refFromId && i.checked) || updatedRemoving.find(i => i.refFromId === refFromId && i.checked) || false}
                    onChange={({ target: { checked } }) => {
                      setUpdatedAdding(updatedAdding.map(i => {
                        if (i.refFromId === refFromId) return { ...i, checked }
                        return i
                      }))
                      setUpdatedRemoving(updatedRemoving.map(i => {
                        if (i.refFromId === refFromId) return { ...i, checked }
                        return i
                      }))
                    }}
                    label={(
                      <RefFromId
                        id={refFromId}
                        type={refFromIdType}
                      />
                    )}
                  />
                  {updatedAdding.length > 0 && (
                    <Stack>
                      <Alert
                        severity="info"
                      >
                        {`Selected files will be added to the ${refFromIdType}`}
                      </Alert>
                    </Stack>
                  )}
                  {adding.map(item => (
                    <Checkbox
                      key={item.id}
                      checked={get(updatedAdding.find(i => i.id === item.id && i.refFromId === refFromId), 'checked')}
                      onChange={({ target: { checked } }) => setUpdatedAdding(updatedAdding.map(i => {
                        if (i.id === item.id && i.refFromId === refFromId) return { ...i, checked }
                        return i
                      }))}
                      label={(
                        <FileRefResult
                          {...item}
                          readOnly
                          isFile
                          icon={<AddBoxOutlined color="success" />}
                        />
                      )}
                      style={{ marginLeft: '15px' }}
                    />
                  ))}
                  {updatedRemoving.length > 0 && (
                    <Stack>
                      <Alert
                        severity="warning"
                      >
                        {`Selected files will be removed from the ${refFromIdType}`}
                      </Alert>
                    </Stack>
                  )}
                  {removing.map(item => (
                    <Checkbox
                      key={item.id}
                      checked={get(updatedRemoving.find(i => i.id === item.id && i.refFromId === refFromId), 'checked')}
                      onChange={({ target: { checked } }) => setUpdatedRemoving(updatedRemoving.map(i => {
                        if (i.id === item.id && i.refFromId === refFromId) return { ...i, checked }
                        return i
                      }))}
                      label={(
                        <FileRefResult
                          {...item}
                          readOnly
                          isFile
                          icon={<HighlightOff color="error" />}
                        />
                      )}
                      style={{ marginLeft: '15px' }}
                    />
                  ))}
                </List>
              )
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
            disabled={!updatedAdding.find(({ checked }) => checked) && !updatedRemoving.find(({ checked }) => checked)}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddRemoveFileRefs