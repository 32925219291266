import { useState, useEffect, useRef } from 'react'
import { Box, Typography, Card, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, Tabs, Tab, Stack, Chip, Alert, Tooltip } from '@mui/material'
import { Print, AddLink, Clear, AspectRatio, Sync, LocalShippingOutlined, Sort, FilterList, LocalOfferOutlined, LayersOutlined, ExpandMore, ChevronRight } from '@mui/icons-material'
import { TreeView, TreeItem } from '@mui/lab'
import { useReactToPrint } from 'react-to-print'
import { get, keyBy, uniqBy, sortBy, reduce } from 'lodash'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { useAsyncFunc, findFileRefs, addUniqueFileRef, sequence } from '../../functions'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'
import OrderBy from '../OrderBy'
import File from '../File'
import SearchFiles from '../SearchFiles'
import UpdateFileRef from '../UpdateFileRef'
import { getJob, getJobLine, findJobLines, addJobLine } from './functions'
import JobNumber from './JobNumber'

const sections = [
  { label: "Materials", value: "MATERIALS", description: "Purchase Orders" },
  { label: "External Processes", value: "EXTERNAL_PROCESSES", description: "Certificates" },
  { label: "FAI Form 1", value: "FAI_FORM_1", description: "Part Number Accountability" },
  { label: "FAI Form 2", value: "FAI_FORM_2", description: "Product Accountability — Raw Material, Specifications, Special Processes, and Functional Testing" },
  { label: "FAI Form 3", value: "FAI_FORM_3", description: "Characteristic Accountability, Verification, and Compatibility Evaluation" },
  { label: "Technical Drawings", value: "DRAWINGS" },
]

const FileRef = ({ index, fileRef, filteredFileRefs, prints, setPrints, isDuplicate }) => {
  const [jobLines, setJobLines] = useState()

  const { id, name, categories, fileId, refFromType } = fileRef
  const isPrinting = !!prints.find(p => p.id === id)
  const ext = 'pdf'

  useAsyncFunc(async () => {
    const fileRefs = await findFileRefs({ fileId, refFromType: 'jobLine', orderBy: ['createdAt','desc'] })
    return Promise.all(
      fileRefs.map(async ({ refFromId }) =>
        getJobLine(refFromId)
      )
    )
  }, null, setJobLines, [fileId])

  return (
    <Box className={`doc-ctn ${isPrinting ? 'printing' : ''}`}>
      <Box className="actions-ctn">
        <Box style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
          <Box style={{ width: '32px' }}>
            <FileIcon extension={ext} {...defaultStyles[ext]} />
          </Box>
          <Box style={{ flexGrow: 1 }}>
            {name}
            {isDuplicate && <Chip variant="outlined" color="warning" size="small" label="Duplicate" sx={{ marginLeft: '8px' }} />}
            {isPrinting && (
              <Stack direction="row" sx={{ marginTop: '5px', flexWrap: 'wrap', gap: .5 }}>
                <Chip icon={<LocalOfferOutlined fontSize="small" />} size="small" label={refFromType} />
                {jobLines && <Chip icon={<LayersOutlined fontSize="small" />} size="small" label={`Qty used: ${reduce(jobLines, (sum, { quantity }) => sum + quantity, 0)} pcs`} />}
                {categories.map(c => <Chip size="small" label={c} />)}
              </Stack>
            )}
          </Box>
          <UpdateFileRef
            button={(
              <Button
                size="small"
                variant="outlined"
                startIcon={<AspectRatio />}
              >
                View
              </Button>
            )}
            buttonStyle={{ cursor: 'zoom-in' }}
            fileRef={fileRef}
            fileRefs={filteredFileRefs}
            startingIndex={index}
          />
          <UpdateFileRef
            button={(
              <Button
                size="small"
                variant="outlined"
                startIcon={<Print />}
              >
                Print
              </Button>
            )}
            buttonStyle={{ cursor: 'zoom-in' }}
            fileRef={fileRef}
            fileRefs={filteredFileRefs}
            startingIndex={index}
            forcePrint
          />
          <Checkbox
            checked={isPrinting}
            onChange={({ target: { checked } }) => {
              if (checked) return setPrints(uniqBy([ ...prints, fileRef ], ({ id }) => id))
              return setPrints(prints.filter(p => id !== p.id))
            }}
            label="Print"
          />
        </Box>
      </Box>
      <Box className="doc">
        <File fileId={fileId} />
      </Box>
    </Box>
  )
}

const JobDocuments = ({ jobId, jobIds, contentRef, isCreating, viewOptions, orderBy, prints, setPrints, jobLine, setJobLine }) => {
  const [view, setView] = useState(sections[0].value)
  const [job, setJob] = useState()
  const [jobs, setJobs] = useState()
  const [fileRefs, setFileRefs] = useState([])

  const sectionRefs = useRef(keyBy(sections, 'value'))

  useAsyncFunc(async args => {
    if (jobId && isCreating) {
      const job = await getJob(args)
      const [jobLine] = await findJobLines({ jobId, descriptionBeginsWith: 'Shipment Inspection', orderBy: ['number','desc'], limit: 1 })
      const fileRefs = await findFileRefs({ refFromIds: [jobLine?.id || jobId, job.partId], orderBy })
      setFileRefs(fileRefs)
      setPrints(viewOptions.hideDuplicates ? uniqBy(fileRefs, ({ fileId }) => fileId) : fileRefs)
      setJobLine(jobLine)
      return job
    }
    return () => null
  }, jobId, setJob, [jobId, isCreating, viewOptions, orderBy])

  useAsyncFunc(async args => {
    if (jobIds && isCreating) {
      const jobs = await Promise.all(jobIds.map(async jobId => await getJob(jobId)))
      const fileRefs = await findFileRefs({ refFromIds: jobIds, orderBy })
      setFileRefs(fileRefs)
      setPrints(viewOptions.hideDuplicates ? uniqBy(fileRefs, ({ fileId }) => fileId) : fileRefs)
      return jobs
    }
    return () => null
  }, jobIds, setJobs, [jobIds, isCreating, viewOptions, orderBy])

  return (
    <Box style={{ display: 'flex', borderTop: '1px solid #efefef', borderBottom: '1px solid #efefef' }}>
      <Box style={{ minWidth: '230px', minHeight: '75vh', borderRight: '1px solid #efefef' }}>
        <Box style={{ position: 'sticky', top: '2px', overflowX: 'hidden' }}>
          <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />}>
            {sections.map(({ label, value }) => {
              const filteredFileRefs = fileRefs.filter(({ categories }) => (categories || []).includes(value))
              return (
                <TreeItem nodeId={value} label={(
                  <Checkbox
                    checked={filteredFileRefs.every(({ id }) => prints.find(p => p.id === id))}
                    indeterminate={!filteredFileRefs.every(({ id }) => prints.find(p => p.id === id)) && prints.find(p => filteredFileRefs.find(({ id }) => id === p.id))}
                    onChange={({ target: { checked } }) => {
                      if (checked) return setPrints(uniqBy([ ...prints, ...filteredFileRefs ], ({ id }) => id))
                      return setPrints(prints.filter(p => !filteredFileRefs.find(({ id }) => id === p.id)))
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      sectionRefs.current[value].current.scrollIntoView({ behavior: 'smooth' })
                    }}
                    label={`${value} (${filteredFileRefs.length})`}
                    disabled={filteredFileRefs.length === 0}
                  />
                )}>
                  {filteredFileRefs.map(fileRef => {
                    const { id, name } = fileRef
                    return (
                      <TreeItem nodeId={id} label={(
                        <Checkbox
                          checked={!!prints.find(p => p.id === id)}
                          onChange={({ target: { checked } }) => {
                            if (checked) return setPrints(uniqBy([ ...prints, fileRef ], ({ id }) => id))
                            return setPrints(prints.filter(p => id !== p.id))
                          }}
                          label={name}
                        />
                      )} />
                    )
                  })}
                </TreeItem>
              )
            })}
          </TreeView>
        </Box>
      </Box>
      <Box ref={contentRef} style={{ flexGrow: 1, background: '#fafafa' }}>
        {jobLine && (
          <Stack>
            <Alert
              severity="info"
              action={(
                <>
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<LocalShippingOutlined />}
                    disableElevation
                  >
                    View Shipment
                  </Button>
                </>
              )}
            >
              {`Packing list from shipment on ${jobLine.createdAt.toLocaleDateString()}`}
            </Alert>
          </Stack>
        )}
        {sections.map(({ label, value, description }) => {
          const filteredFileRefs = fileRefs.filter(({ categories }) => (categories || []).includes(value))
          const ext = 'pdf'
          return (
            <Box ref={sectionRefs.current[value]} className="cert-ctn">
              <Card className="cert-card" elevation={2} style={{ position: 'sticky', top: '10px', display: 'flex', alignItems: 'center', padding: '20px', zIndex: 3 }}>
                <Box style={{ width: '32px', marginRight: '25px' }}>
                  <FileIcon extension={ext} {...defaultStyles[ext]} />
                </Box>
                <Box style={{ flexGrow: 1 }}>
                  {label}
                  {description && (
                    <Typography variant="body2" color="GrayText">
                      {description}
                    </Typography>
                  )}
                </Box>
                <Box>
                  {job && (
                    <SearchFiles
                      companyId={job.companyId}
                      refFroms={[
                        ...jobLine ? [{ id: jobLine.id, type: 'jobLine' }] : [],
                        { id: jobId, type: 'job' },
                        { id: job.partId, type: 'part' },
                      ]}
                      refFromIds={[...jobLine ? [jobLine.id] : [], jobId, job.partId]}
                      categories={[value]}
                      button={(
                        <Tooltip title="Attach file">
                          <IconButton>
                            <AddLink />
                          </IconButton>
                        </Tooltip>
                      )}
                    />
                  )}
                </Box>
                <Box>
                  <Checkbox
                    checked={filteredFileRefs.every(({ id }) => prints.find(p => p.id === id))}
                    indeterminate={!filteredFileRefs.every(({ id }) => prints.find(p => p.id === id)) && prints.find(p => filteredFileRefs.find(({ id }) => id === p.id))}
                    onChange={({ target: { checked } }) => {
                      if (checked) return setPrints(uniqBy([ ...prints, ...filteredFileRefs ], ({ id }) => id))
                      return setPrints(prints.filter(p => !filteredFileRefs.find(({ id }) => id === p.id)))
                    }}
                    label="Print"
                    disabled={filteredFileRefs.length === 0}
                  />
                </Box>
              </Card>
              {filteredFileRefs.length === 0 && <Typography variant="body2" color="GrayText" style={{ margin: '15px' }}>No documents found.</Typography>}
              {filteredFileRefs.map((fileRef, index) => (
                <FileRef
                  fileRef={fileRef}
                  index={index}
                  filteredFileRefs={filteredFileRefs}
                  prints={prints}
                  setPrints={setPrints}
                  isDuplicate={!uniqBy(fileRefs, ({ fileId }) => fileId).find(({ id }) => id === fileRef.id)}
                />
              ))}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

function InspectJob({ jobId, jobIds, button, buttonStyle, forceOpen, setForceOpen }) {
  const [isCreating, setIsCreating] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [viewOptions, setViewOptions] = useState({ hideDuplicates: true })
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])
  const [selected, setSelected] = useState('ALL')
  const [fileRefs, setFileRefs] = useState([])
  const [jobLine, setJobLine] = useState()
  const [errors, setErrors] = useState({})

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  // useEffect(() => {
  //   if (jobIds && jobIds.length > 0) setSelected(jobIds[0])
  // }, [jobIds])

  const validate = async () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return
    setIsProcessing(true)
    if (!jobLine) {
      await sequence(jobIds, async jobId =>
        addJobLine({
          jobId,
          description: 'Shipment Inspection',
          number: (await findJobLines({ jobId, orderBy: ['number','asc'], limit: 9999 })).length + 1,
          quantity: (await getJob(jobId)).quantity,
          unit: 'piece',
          status: 'ACTIVE',
        }).then(async jobLine => {
          if (jobId === selected) setJobLine(jobLine)
          await sequence(fileRefs, async ({ name, fileId, categories }) =>
            addUniqueFileRef({ name, fileId, ...categories && { categories }, refFromId: jobLine.id, refFromType: 'jobLine', status: 'ACTIVE' }))
        }
        ))
    }
    setIsProcessing(false)
    return print()
  }

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsCreating(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Box style={{ flexGrow: 1 }}>
              FAI Report
            </Box>
            <Box>
              <Tabs value={selected} onChange={(event, newValue) => setSelected(newValue)}>
                <Tab label="All" value="ALL" />
                {(jobIds || []).map(jobId => <Tab label={(
                  <>
                    {`Job #`}
                    <JobNumber jobId={jobId} />
                  </>
                )} value={jobId} />)}
              </Tabs>
            </Box>
            <OrderBy
              button={(
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Sort />}
                >
                  {`Sort by: ${orderBy.join(', ')}`}
                </Button>
              )}
              onChange={({ value }) => setOrderBy(value)}
            />
            <Dropdown
              button={(
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<FilterList />}
                >
                  View Options
                </Button>
              )}
              items={[
                { label: <Checkbox checked={viewOptions.hideDuplicates} label="Hide Duplicates" />, value: 'hideDuplicates' },
              ]}
              onChange={({ value: opt }) => setViewOptions({ ...viewOptions, [opt]: !viewOptions[opt] })}
              disableCloseOnSelect
            />
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <DialogContentText>
            {selected && (
              <JobDocuments
                {...selected === 'ALL' ? { jobIds } : { jobId: selected }}
                contentRef={contentRef}
                isCreating={isCreating}
                viewOptions={viewOptions}
                orderBy={orderBy}
                prints={fileRefs}
                setPrints={setFileRefs}
                jobLine={jobLine}
                setJobLine={setJobLine}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isProcessing ? (
            <Button
              variant="contained"
              startIcon={<Sync />}
              disableElevation
              disabled
            >
              Processing...
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<Print />}
              disableElevation
              onClick={submit}
              disabled={fileRefs.length === 0}
            >
              {`${!jobLine ? `Save & ` : ''}Print`}
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InspectJob