import { useState, useEffect } from 'react'
import { Box, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { Check, Edit, Clear } from '@mui/icons-material'
import { get, xorBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, getCurrentUserId } from '../../functions'
import ApprovedByOn from '../ApprovedByOn'
import Checkbox from '../Checkbox'
import Confirm from '../Confirm'
import Radio from '../Radio'
import { findQuestions, addQuestion, updateQuestion, findSubmissions, updateContact } from './functions'
import SelectContact from './SelectContact'
import NewQuestionRow from './NewQuestionRow'

function ContactQuestionnaire({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate, onUpdate }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [contact, setContact] = useState()
  const [rows, setRows] = useState()
  const [submissions, setSubmissions] = useState()
  const [updates, setUpdates] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  useAsyncFunc(async companyId => {
    if (companyId) return findQuestions({ companyId, status: 'ACTIVE', orderBy: ['order','asc'] })
    return () => null
  }, companyId, setRows, [companyId])

  useAsyncFunc(async ({ companyId, contact }) => {
    if (companyId && contact?.id) return findSubmissions({ companyId, forContactId: contact.id, status: 'ACTIVE', orderBy: ['order','asc'] })
    return () => null
  }, { companyId, contact }, setSubmissions, [companyId, contact])

  const fetch = () => findQuestions({ companyId, status: 'ACTIVE', orderBy: ['order','asc'] }).then(setRows)

  const approveVendor = () => updateContact(contact?.id, { approvedByUserId: getCurrentUserId(), approvedAt: new Date() }).then(setContact)

  const updateSubmission = (questionId, content) => setUpdates([
    ...(updates || []).map(u => ({ ...u, ...u.questionId === questionId ? { content } : {} })),
    ...!(updates || []).find(u => u.questionId === questionId) ? [{ questionId, content }] : [],
  ])

  const updatedSubmissions = [
    ...(submissions || []).map(s => ({ ...s, ...(updates.find(u => u.questionId === s.questionId) || {}) })),
    ...xorBy((submissions || []), updates, 'questionId'),
  ]

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return
    return
  }

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          Questionnaire
          {isEditing ? (
            <Button
              size="small"
              variant="outlined"
              color="error"
              startIcon={<Clear />}
              disableElevation
              onClick={() => setIsEditing(false)}
              style={{ marginLeft: '10px' }}
            >
              Stop Editing
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              startIcon={<Edit />}
              disableElevation
              onClick={() => setIsEditing(true)}
              style={{ marginLeft: '10px' }}
            >
              Edit Questionnaire
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <SelectContact
              companyId={companyId}
              contactId=""
              onChange={setContact}
              style={{ marginBottom: '20px' }}
              findType="VEND"
            />
            {contact?.id && (
              <ApprovedByOn
                approvedByUserId={get(contact, 'approvedByUserId', '')}
                approvedAt={get(contact, 'approvedAt')}
                onApprove={approveVendor}
                style={{ marginBottom: '20px' }}
              />
            )}
            {(rows || []).map(({ id, label, instructions, type, options, order }) => {
              const content = (updatedSubmissions || []).find(({ questionId }) => questionId === id)?.content || ((type === 'SELECT_MULTIPLE' || type === 'CAPABILITIES') ? [] : '')
              return (
                <Box key={id}>
                  <Typography variant="h6">
                    {`${order + 1}. ${label}`}
                  </Typography>
                  <Typography variant="subtitle2" color="GrayText" sx={{ margin: '0 0 10px 20px' }}>
                    {instructions}
                  </Typography>
                  {contact?.id && (
                    <>
                      {type === 'BOOLEAN' && (
                        <Radio
                          options={[
                            { label: 'Yes', value: 'YES' },
                            { label: 'No', value: 'NO' },
                          ]}
                          value={content}
                          onChange={({ target: { value } }) => updateSubmission(id, value)}
                          style={{ marginBottom: '10px' }}
                        />
                      )}
                      {type === 'SELECT_ONE' && (
                        <Radio
                          options={options.map(value => ({ label: value, value }))}
                          value={content}
                          onChange={({ target: { value } }) => updateSubmission(id, value)}
                          style={{ marginBottom: '10px' }}
                        />
                      )}
                      {(type === 'SELECT_MULTIPLE' || type === 'CAPABILITIES') && (
                        <>
                          {options.map(option => (
                            <Checkbox
                              checked={content.includes(option)}
                              onChange={({ target: { checked } }) => checked ? updateSubmission(id, [ ...content, option ]) : updateSubmission(id, content.filter(c => c !== option))}
                              label={option}
                            />
                          ))}
                        </>
                      )}
                      {type === 'TEXT' && (
                        <TextField
                          size="small"
                          variant="outlined"
                          name="submission"
                          label="Submission"
                          rows={3}
                          multiline
                          fullWidth
                          value={content}
                          onChange={({ target: { value } }) => updateSubmission(id, value)}
                          style={{ marginBottom: '10px' }}
                          disabled={getCurrentUserId() !== get(contact, 'approvedByUserId')}
                        />
                      )}
                    </>
                  )}
                </Box>
              )
            })}
            {contact && (
              <Box style={{ marginTop: '15px' }}>
                <Button
                  variant="contained"
                  startIcon={<Check />}
                  disableElevation
                  onClick={submit}
                  style={{ marginRight: '10px' }}
                  disabled={(updates || []).length === 0}
                >
                  {(updates || []).length === 0 ? 'All Changes Saved' : 'Save'}
                </Button>
                {(updates || []).length > 0 && (
                  <Confirm
                    button={(
                      <Button
                        variant="outlined"
                        startIcon={<Clear />}
                        disableElevation
                        style={{ marginRight: '10px' }}
                      >
                        Discard Changes
                      </Button>
                    )}
                    buttonStyle={{ display: 'inline-block' }}
                    title="Confirm discard?"
                    content="Are you sure you want to discard all changes?"
                    onConfirm={() => setUpdates()}
                  />
                )}
              </Box>
            )}
            {isEditing && (
              <Box style={{ marginTop: '15px' }}>
                <NewQuestionRow
                  companyId={companyId}
                  order={(rows || []).length}
                  onAdded={fetch}
                  style={{ marginRight: '10px' }}
                />
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ContactQuestionnaire