import { useState } from 'react'
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import Confirm from '../Confirm'
import { updatePartQuote } from './functions'
import CalculateQuote from './Calculate'

function EditPartQuote({ companyId, button, buttonStyle, quoteId, partQuoteId, onUpdated }) {
  const [isOpen, setIsOpen] = useState(false)
  const [view, setView] = useState('DETAILED')

  const remove = () => partQuoteId ? updatePartQuote(partQuoteId, { status: 'ARCHIVED' }).then(onUpdated) : setIsOpen(false)

  const content = <CalculateQuote companyId={companyId} quoteId={quoteId} partQuoteId={partQuoteId} onUpdated={onUpdated} isDetailed={view === 'DETAILED'} />

  return button ? (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Tabs value={view} onChange={(event, newValue) => setView(newValue)}>
                <Tab label="Simple Pricing" value="SIMPLE" />
                <Tab label="Detailed Costing" value="DETAILED" />
              </Tabs>
            </Box>
            <Confirm
              button={(
                <Button
                  color="error"
                  variant="outlined"
                  startIcon={<DeleteOutline />}
                  disableElevation
                >
                  Delete
                </Button>
              )}
              title="Confirm delete?"
              content="Are you sure you want to delete this line item?"
              onConfirm={remove}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
    </>
  ) : content
}

export default EditPartQuote