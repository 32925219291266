import { Box, Button, TextField, IconButton } from '@mui/material'
import { FilterList, Search } from '@mui/icons-material'
import { get } from 'lodash'
import { jobRanges as ranges } from '../../functions'
import Checkbox from '../Checkbox'
import DateRange from '../DateRange'
import Dropdown from '../Dropdown'
import SelectPart from '../Parts/SelectPart'

function PartOrderFilters({ beforeFilters, afterFilters, filterBy, setFilterBy, filterByValue, setFilterByValue }) {
  return (
    <Box style={{ display: 'flex', gap: '10px' }}>
      {beforeFilters}
      <Dropdown
        button={(
          <Button
            size="small"
            variant="outlined"
            startIcon={<FilterList />}
          >
            {filterBy.label}
          </Button>
        )}
        items={[
          { label: 'Due Date', value: 'dueAt' },
        ]}
        onChange={setFilterBy}
      />
      {filterBy.value === 'dueAt' && (
        <DateRange
          value={[filterByValue.dueAtFrom, filterByValue.dueAtTo]}
          ranges={ranges}
          inputStyle={{ padding: '6px 12px', fontSize: '14px' }}
          onChange={([dueAtFrom, dueAtTo]) => setFilterByValue({ ...filterByValue, dueAtFrom, dueAtTo })}
        />
      )}
      {afterFilters}
    </Box>
  )
}

export default PartOrderFilters