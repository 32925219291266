import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getPartOrder } from './functions'

function PartOrderProp({ partOrderId, prop }) {
  const [partOrder, setPartOrder] = useState()

  useAsyncFunc(async partOrderId => {
    if (partOrderId) return getPartOrder(partOrderId)
  }, partOrderId, setPartOrder, [partOrderId])

  return get(partOrder, prop) ? partOrder[prop] instanceof Date ? partOrder[prop].toLocaleDateString() : partOrder[prop] : <Skeleton variant="rectangular" animation="wave" />
}

export default PartOrderProp