import { useState, useEffect } from 'react'
import { TextField, Button } from '@mui/material'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps } from '../functions'

function EditFootnote({ note, onSubmit }) {
  const [updatedNote, setUpdatedNote] = useState()
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return
    return onSubmit(updatedNote)
    .then(() => enqueueSnackbar('Note updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedNote(note)
  }, [note])

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        value={updatedNote}
        onChange={({ target: { value } }) => setUpdatedNote(value)}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'footnote')}
      />
      {!isEqual(note, updatedNote) && (
        <Button
          variant="outlined"
          onClick={() => setUpdatedNote(note)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        disabled={isEqual(note, updatedNote)}
        disableElevation
      >
        Save Notes
      </Button>
    </>
  )
}

export default EditFootnote