import { useState } from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, IconButton } from '@mui/material'
import { Settings, Architecture } from '@mui/icons-material'
import PartNumber from './Parts/PartNumber'
import PartName from './Parts/PartName'
import JobNumber from './Jobs/JobNumber'
import JobLineNumber from './Jobs/JobLineNumber'

function RefFromId({ id, type }) {

  return (
    <ListItem
      disablePadding
    >
      <ListItemButton>
        {type === 'part' && (
          <>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText
              primary={(
                <>
                  {`P/N `}
                  <PartNumber partId={id} />
                </>
              )}
              secondary={<PartName partId={id} />}
            />
          </>
        )}
        {type === 'job' && (
          <>
            <ListItemIcon>
              <Architecture />
            </ListItemIcon>
            <ListItemText
              primary={(
                <>
                  {`J/N `}
                  <JobNumber jobId={id} />
                </>
              )}
            />
          </>
        )}
        {type === 'jobLine' && (
          <>
            <ListItemIcon>
              <Architecture />
            </ListItemIcon>
            <ListItemText
              primary={(
                <>
                  {`J/N `}
                  <JobNumber jobLineId={id} />
                </>
              )}
              secondary={(
                <>
                  {`Line `}
                  <JobLineNumber jobLineId={id} />
                  {` · P/N `}
                  <PartNumber jobLineId={id} />
                  {` · `}
                  <PartName jobLineId={id} />
                </>
              )}
            />
          </>
        )}
      </ListItemButton>
    </ListItem>
  )
}

export default RefFromId