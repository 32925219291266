import { useState, createContext, useContext } from 'react'
import { Routes, Route, Link, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Search, Settings, Add, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { SnackbarProvider } from 'notistack'
import './App.css'
import { useAsyncFunc, getCompanyId } from './functions'
import Nav from './Nav'
import { AuthProvider, RequireAuth } from './components/Auth'
import Login from './components/Auth/Login'
import Parts from './components/Parts'
import Jobs from './components/Jobs'
import Quotes from './components/Quotes'
import Orders from './components/Orders'
import Shipments from './components/Shipments'
import Invoices from './components/Invoices'
import Contacts from './components/Contacts'
import Journal from './components/Journal'
import Manual from './components/Manual'
import Testing from './components/Testing'

function App() {
  const [companyId, setCompanyId] = useState()

  useAsyncFunc(getCompanyId, null, setCompanyId, [])

  return (
    <AuthProvider>
      <SnackbarProvider maxSnack={3}>
        <div className="App">
          <Nav />

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={(
                <RequireAuth>
                  <Jobs companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":jobId"
                element={(
                  <RequireAuth>
                    <Jobs companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/parts"
              element={(
                <RequireAuth>
                  <Parts companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":partId"
                element={(
                  <RequireAuth>
                    <Parts companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/quotes"
              element={(
                <RequireAuth>
                  <Quotes companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":quoteId"
                element={(
                  <RequireAuth>
                    <Quotes companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/orders"
              element={(
                <RequireAuth>
                  <Orders companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":orderId"
                element={(
                  <RequireAuth>
                    <Orders companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/shipments"
              element={(
                <RequireAuth>
                  <Shipments companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":shipmentId"
                element={(
                  <RequireAuth>
                    <Shipments companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/invoices"
              element={(
                <RequireAuth>
                  <Invoices companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":invoiceId"
                element={(
                  <RequireAuth>
                    <Invoices companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/contacts"
              element={(
                <RequireAuth>
                  <Contacts companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":contactId"
                element={(
                  <RequireAuth>
                    <Contacts companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/journal"
              element={(
                <RequireAuth>
                  <Journal companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":journalEntryId"
                element={(
                  <RequireAuth>
                    <Journal companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/manual"
              element={(
                <RequireAuth>
                  <Manual companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":manualEntryId"
                element={(
                  <RequireAuth>
                    <Manual companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
            <Route
              path="/devices"
              element={(
                <RequireAuth>
                  <Testing companyId={companyId} />
                </RequireAuth>
              )}
            >
              <Route
                path=":deviceId"
                element={(
                  <RequireAuth>
                    <Testing companyId={companyId} />
                  </RequireAuth>
                )}
              />
            </Route>
          </Routes>
          
        </div>
      </SnackbarProvider>
    </AuthProvider>
  )
}

export default App
