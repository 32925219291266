import { useState, useEffect } from 'react'
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, positiveInt, jobStatuses } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Radio from '../Radio'
import SelectPart from '../Parts/SelectPart'
import { addJob, getNewJobNumber, validateJobNumber, findJobLines, addJobLine } from './functions'

function CreateJob({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, onCreate, noBubble }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newJob, setNewJob] = useState()
  const [jobLines, setJobLines] = useState([])
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const validate = async () => {
    const errors = {}
    if (!get(newJob, 'number')) errors.number = 'Required'
    if (!get(newJob, 'quantity')) errors.quantity = 'Required'
    if (!get(newJob, 'dueAt')) errors.dueAt = 'Required'
    if (!get(newJob, 'shipAt')) errors.shipAt = 'Required'
    if (get(newJob, 'number') && !await validateJobNumber(newJob.number, companyId)) errors.number = 'Conflicted'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return addJob({ ...newJob, companyId, quantityAvailable: newJob.quantity })
      .then(async newJob => {
        enqueueSnackbar('Job created successfully.', { variant: 'success' })
        handleClose()
        await Promise.all(jobLines
          .map(async ({ number, description, quantity, unit }) =>
            addJobLine({
              jobId: newJob.id,
              number,
              description,
              quantity,
              unit,
              status: 'ACTIVE',
            })
          ))
        if (onCreate) onCreate(newJob)
      })
  }

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    if (get(data, 'copyFromId')) findJobLines({ jobId: data.copyFromId, orderBy: ['createdAt','asc'] }).then(setJobLines)
  }, [data])

  useEffect(() => {
    setNewJob(data)
  }, [data])

  useEffect(() => {
    if (newJob) {
      const { number, isNumberAuto } = newJob
      if (isNumberAuto && !number) {
        getNewJobNumber(companyId).then(number => setNewJob({ ...newJob, number }))
      }
    }
  }, [newJob])

  return (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={e => {
          if (noBubble) e.stopPropagation()
          setIsCreating(true)
        }}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>Create new job</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <SelectPart
              companyId={companyId}
              partId={get(newJob, 'partId')}
              onChange={part => setNewJob({ ...newJob, partId: get(part, 'id') })}
              style={{ marginBottom: '10px' }}
            />
            {get(newJob, 'partId') && (
              <>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Job number"
                  fullWidth
                  style={{ marginBottom: '10px' }}
                  value={get(newJob, 'number', '')}
                  onChange={({ target: { value } }) => setNewJob({ ...newJob, number: value, isNumberAuto: false })}
                  InputProps={{
                    endAdornment: (
                      <Checkbox
                        checked={get(newJob, 'isNumberAuto', false)}
                        onChange={async ({ target: { checked } }) => setNewJob({ ...newJob, isNumberAuto: checked, ...checked && { number: await getNewJobNumber(companyId) } })}
                        style={{ marginRight: '-15px', whiteSpace: 'nowrap' }}
                        label="Auto-generate"
                      />
                    ),
                  }}
                  {...addErrorProps(errors, 'number')}
                  required
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label="Quantity"
                  fullWidth
                  style={{ marginBottom: '10px' }}
                  value={get(newJob, 'quantity', '')}
                  onChange={({ target: { value } }) => setNewJob({ ...newJob, quantity: positiveInt(value) })}
                  {...addErrorProps(errors, 'quantity')}
                  required
                />
                <DateField
                  label="Due date"
                  value={get(newJob, 'dueAt')}
                  onChange={dueAt => setNewJob({ ...newJob, dueAt })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      style={{ marginBottom: '10px' }}
                      {...addErrorProps(errors, 'dueAt')}
                    />
                  )}
                />
                <DateField
                  label="Ship date"
                  value={get(newJob, 'shipAt')}
                  onChange={shipAt => setNewJob({ ...newJob, shipAt })}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      style={{ marginBottom: '10px' }}
                      {...addErrorProps(errors, 'shipAt')}
                    />
                  )}
                />
                <Radio
                  options={jobStatuses}
                  value={get(newJob, 'status', '')}
                  onChange={({ target: { value } }) => setNewJob({ ...newJob, status: value })}
                  style={{ marginBottom: '10px' }}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label="Description"
                  multiline
                  rows={3}
                  fullWidth
                  style={{ marginBottom: '10px' }}
                  value={get(newJob, 'description', '')}
                  onChange={({ target: { value } }) => setNewJob({ ...newJob, description: value })}
                  {...addErrorProps(errors, 'description')}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
            disabled={!get(newJob, 'partId')}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateJob