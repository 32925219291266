import { useState, useEffect } from 'react'
import { TextField, Button, IconButton, Stack, Alert, Tooltip } from '@mui/material'
import { Add, EditOutlined, Check } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { findTermTemplates, addTermTemplate, updateTermTemplate, useAsyncFunc, addErrorProps } from '../functions'
import Confirm from './Confirm'

function EditDisclaimer({ companyId, note, onSubmit }) {
  const [isAddEditing, setIsAddEditing] = useState(false)
  const [templates, setTemplates] = useState()
  const [newDisclaimer, setNewDisclaimer] = useState()
  const [updatedNote, setUpdatedNote] = useState()
  const [errors, setErrors] = useState({})
  const [removing, setRemoving] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const validate = () => {
    const errors = {}
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return
    return onSubmit(updatedNote)
    .then(() => enqueueSnackbar('Note updated successfully.', { variant: 'success' }))
  }

  useEffect(() => {
    setUpdatedNote(note)
  }, [note])

  useAsyncFunc(async companyId => {
    if (companyId) return findTermTemplates({ companyId, orderBy: ['createdAt','desc'] })
    return () => null
  }, companyId, setTemplates, [companyId])

  const fetch = () => findTermTemplates({ companyId, orderBy: ['createdAt','desc'] }).then(setTemplates)

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        rows={4}
        multiline
        fullWidth
        value={updatedNote}
        onChange={({ target: { value } }) => setUpdatedNote(value)}
        style={{ marginBottom: '10px' }}
        {...addErrorProps(errors, 'footnote')}
      />
      <Confirm
        forceOpen={isAddEditing}
        setForceOpen={setIsAddEditing}
        title="Add disclaimer"
        content={(
          <>
            <TextField
              size="small"
              variant="outlined"
              label="Title"
              fullWidth
              value={get(newDisclaimer, 'title', '')}
              onChange={({ target: { value } }) => setNewDisclaimer({ ...newDisclaimer, title: value, companyId })}
              style={{ marginBottom: '10px' }}
              {...addErrorProps(errors, 'title')}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Content"
              rows={4}
              multiline
              fullWidth
              value={get(newDisclaimer, 'content', '')}
              onChange={({ target: { value } }) => setNewDisclaimer({ ...newDisclaimer, content: value })}
              style={{ marginBottom: '10px' }}
              {...addErrorProps(errors, 'content')}
            />
          </>
        )}
        onConfirm={() => (newDisclaimer.id ? updateTermTemplate(newDisclaimer.id, { title: newDisclaimer.title, content: newDisclaimer.content }) : addTermTemplate(newDisclaimer)).then(fetch)}
        onCancel={() => setIsAddEditing(false)}
      />
      <Confirm
        forceOpen={!!removing}
        setForceOpen={() => setRemoving()}
        title="Confirm remove?"
        content="Are you sure you want to remove this disclaimer?"
        onConfirm={() => setUpdatedNote(updatedNote.replace(removing.content, ''))}
        onCancel={() => setRemoving()}
      />
      <Stack sx={{ margin: '-10px 0 10px' }}>
        <Alert
          severity="info"
          sx={{ alignItems: 'center' }}
          action={(
            <Tooltip title="Add New">
              <IconButton size="small" onClick={e => {
                e.stopPropagation()
                setNewDisclaimer()
                setIsAddEditing(true)
              }}>
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        >
          {(templates || []).map(({ id, title, content }) => {
            const isIncluded = (updatedNote || '').includes(content)
            return (
              <Button
                key={id}
                size="small"
                variant="contained"
                startIcon={isIncluded ? <Check /> : <Add />}
                endIcon={(
                  <Tooltip title="Edit">
                    <IconButton size="small" edge="end" onClick={e => {
                      e.stopPropagation()
                      setNewDisclaimer({ id, title, content })
                      setIsAddEditing(true)
                    }}>
                      <EditOutlined fontSize="small" sx={{ fontSize: '1.125rem', color: '#fff' }} />
                    </IconButton>
                  </Tooltip>
                )}
                onClick={() => isIncluded ? setRemoving({ title, content }) : setUpdatedNote(`${updatedNote ? `${updatedNote}\n` : ''}${content}`)}
                style={{ marginRight: '5px' }}
                disableElevation
                {...isIncluded && { color: 'success' }}
              >
                {title}
              </Button>
            )
          })}
        </Alert>
      </Stack>
      {!isEqual(note, updatedNote) && (
        <Button
          variant="outlined"
          onClick={() => setUpdatedNote(note)}
          style={{ marginRight: '5px' }}
          disableElevation
        >
          Revert
        </Button>
      )}
      <Button
        variant="contained"
        onClick={submit}
        disabled={isEqual(note, updatedNote)}
        disableElevation
      >
        Save Notes
      </Button>
    </>
  )
}

export default EditDisclaimer