import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getPart } from './functions'

function PartProp({ partId, prop }) {
  const [part, setPart] = useState()

  useAsyncFunc(async partId => {
    if (partId) return getPart(partId)
  }, partId, setPart, [partId])

  return get(part, prop) ? part[prop] instanceof Date ? part[prop].toLocaleDateString() : part[prop] : <Skeleton variant="rectangular" animation="wave" />
}

export default PartProp