import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Stack, Alert, Tabs, Tab, Chip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { Add, Remove, Inbox, OpenInNew, Upload as UploadIcon } from '@mui/icons-material'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { getDate, useAsyncFunc, addFileRef, getDateDiffStr } from '../../functions'
import Accordion from '../Accordion'
import FileRefs from '../FileRefs'
import UpdateFileRef from '../UpdateFileRef'
import Upload from '../Upload'
import CreateJob from '../Jobs/Create'
import CreateOrder from '../Orders/Create'
import CreateQuote from '../Quotes/Create'
import JournalEntriesCount from '../Journal/JournalEntriesCount'
import Actions from './Actions'
import { getPart } from './functions'
import SearchParts from './Search'
import EditPartDetails from './EditDetails'
import PartInventory from './PartInventory'
import PartVersions from './PartVersions'
import PartReferences from './PartReferences'
import PartJobs from './PartJobs'
import PartOrders from './PartOrders'
import PartQuotes from './PartQuotes'
import PartDetails from './PartDetails'
import PartTraveler from './PartTraveler'
import PartIncidents from './PartIncidents'

function Parts({ companyId }) {
  const [part, setPart] = useState()
  const [view, setView] = useState('ALL')
  const [isUploading, setIsUploading] = useState(false)
  const [filesFetchedAt, setFilesFetchedAt] = useState(new Date())
  const [isCreatingJob, setIsCreatingJob] = useState(false)
  const [isCreatingOrder, setIsCreatingOrder] = useState(false)
  const [isCreatingQuote, setIsCreatingQuote] = useState(false)

  const contentRef = useRef()

  const navigate = useNavigate()
  const { partId } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  useAsyncFunc(async args => {
    if (partId) {
      contentRef.current.scrollIntoView()
      return getPart(args)
    }
    return () => null
  }, partId, setPart, [partId])

  return (
    <>
      {part && (
        <Upload
          companyId={companyId}
          forceOpen={isUploading}
          setForceOpen={setIsUploading}
          onUploaded={async files => Promise.all(
            files.map(({ id: fileId, name }) => addFileRef({ companyId, name, fileId, refFromId: get(part, 'id'), refFromType: 'part', status: 'ACTIVE' }))
          ).then(fileRefs => {
            setFilesFetchedAt(new Date())
            enqueueSnackbar('Files uploaded successfully.', {
              variant: 'success',
              action: (
                <UpdateFileRef
                  button={(
                    <Button
                      size="small"
                      color="success"
                      variant="contained"
                      disableElevation
                    >
                      View Files
                    </Button>
                  )}
                  fileRef={fileRefs[0]}
                  fileRefs={fileRefs}
                  startingIndex={0}
                  onUpdated={() => setFilesFetchedAt(new Date())}
                />
              ),
            })
          })}
        />
      )}
      <Box style={{ display: 'flex', padding: '10px', gap: '10px' }}>
        <Box style={{  width: '23vw', paddingTop: '10px' }}>
          <Box style={{ position: 'sticky', top: '10px', height: '90vh', overflowY: 'scroll' }}>
            <SearchParts
              companyId={companyId}
              selected={part}
              onSelect={({ id }) => navigate(`/parts/${id}`)}
            />
          </Box>
        </Box>
        <Box ref={contentRef} style={{ flexGrow: 1, minHeight: '100vh', border: '1px solid #d9d9d9', borderRadius: '5px' }}>
          <Box style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: 0, padding: '10px', background: '#efefef', zIndex: 2 }}>
            <Actions item={part} />
          </Box>
          {/* <Stack>
            <Alert
              severity="info"
              action={(
                <>
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<Inbox />}
                    style={{ marginLeft: '5px' }}
                    disableElevation
                  >
                    Move To Order
                  </Button>
                </>
              )}
            >
              Part is active.
            </Alert>
          </Stack> */}
          {part && (
            <Box style={{ padding: '10px' }}>
              <Tabs value={view} onChange={(event, newValue) => setView(newValue)}>
                <Tab label="All" value="ALL" />
                <Tab label="Sales" value="SALES" />
                <Tab label="Engineering" value="ENGINEERING" />
              </Tabs>
              <JournalEntriesCount
                companyId={companyId}
                partId={partId}
                type="INCIDENT_REPORT"
                renderItems={journalEntries => (
                  <Stack>
                    {journalEntries.map(({ id, number, createdAt }) => (
                      <Alert
                        key={id}
                        severity="error"
                        action={(
                          <>
                            <Button
                              size="small"
                              variant="contained"
                              startIcon={<Inbox />}
                              style={{ marginLeft: '5px' }}
                              disableElevation
                              disabled
                            >
                              Pending Approval
                            </Button>
                          </>
                        )}
                      >
                      <Tooltip title="Open In New Window">
                        <Link to={`/journal/${id}`} target="_blank" rel="noopener noreferrer">
                          {`Incident ${number}`}
                        </Link>
                      </Tooltip>
                        {` filed at ${createdAt.toLocaleDateString()}.`}
                      </Alert>
                    ))}
                  </Stack>
                )}
                limit={1}
              />
              <Box style={{ display: 'flex' }}>
                <Box style={{ width: '50%' }}>
                  <Accordion
                    title="Part Details"
                    content={(
                      <EditPartDetails
                        part={part}
                        onUpdate={setPart}
                      />
                    )}
                    defaultExpanded
                  />
                  {view !== 'SALES' && (
                    <Accordion
                      title={(
                        <>
                          Files
                          <Button
                            size="small"
                            variant="outlined"
                            style={{ marginLeft: '10px' }}
                            startIcon={<UploadIcon />}
                            onClick={e => {
                              e.stopPropagation()
                              setIsUploading(true)
                            }}
                          >
                            Upload
                          </Button>
                        </>
                      )}
                      content={get(part, 'id') && (
                        <FileRefs
                          key={filesFetchedAt}
                          companyId={companyId}
                          refFroms={[
                            { id: part.id, type: 'part' },
                          ]}
                        />
                      )}
                      defaultExpanded
                    />
                  )}
                </Box>
                <Box style={{ width: '50%' }}>
                  {view !== 'ENGINEERING' && (
                    <Accordion
                      title="Inventory"
                      content={<PartInventory companyId={companyId} partId={partId} />}
                      defaultExpanded
                    />
                  )}
                  {view !== 'SALES' && (
                    <>
                      <Accordion
                        title="History"
                        content={<PartVersions partId={partId} />}
                        defaultExpanded
                      />
                      <Accordion
                        title="Assemblies"
                        content={<PartDetails partId={partId} />}
                        defaultExpanded
                      />
                    </>
                  )}
                </Box>
              </Box>
              <Accordion
                title="Traveler"
                content={<PartTraveler companyId={companyId} partId={partId} />}
                defaultExpanded
              />
              <CreateJob
                companyId={part.companyId}
                forceOpen={isCreatingJob}
                setForceOpen={setIsCreatingJob}
                data={{
                  isNumberAuto: true,
                  status: 'IN_PROGRESS',
                  partId,
                }}
              />
              <Accordion
                title={(
                  <>
                    Related Jobs
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={e => {
                        e.stopPropagation()
                        setIsCreatingJob(true)
                      }}
                      style={{ marginLeft: '10px' }}
                    >
                      Add Job
                    </Button>
                  </>
                )}
                content={(
                  <PartJobs
                    companyId={companyId}
                    partId={part.id}
                  />
                )}
              />
              <CreateOrder
                companyId={companyId}
                forceOpen={isCreatingOrder}
                setForceOpen={setIsCreatingOrder}
                data={{ companyId, status: 'OPEN', isNumberAuto: true, orderAt: new Date() }}
              />
              {view !== 'ENGINEERING' && (
                <>
                  <Accordion
                    title={(
                      <>
                        Related Orders
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={e => {
                            e.stopPropagation()
                            setIsCreatingOrder(true)
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          Add Order
                        </Button>
                      </>
                    )}
                    content={(
                      <PartOrders
                        partId={part.id}
                      />
                    )}
                  />
                  <CreateQuote
                    companyId={companyId}
                    forceOpen={isCreatingQuote}
                    setForceOpen={setIsCreatingQuote}
                    data={{ companyId, status: 'ACTIVE', isNumberAuto: true, date: new Date(), dateExp: getDate(null, '+', 180, 'day') }}
                  />
                  <Accordion
                    title={(
                      <>
                        Related Quotes
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={e => {
                            e.stopPropagation()
                            setIsCreatingQuote(true)
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          Add Quote
                        </Button>
                      </>
                    )}
                    content={(
                      <PartQuotes
                        partId={part.id}
                      />
                    )}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Parts