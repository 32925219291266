import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, List } from '@mui/material'
import { Search, Add, Sort } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { findContacts } from '../Contacts/functions'
import OrderBy from '../OrderBy'
import { findOrders } from './functions'
import CreateOrder from './Create'
import OrderResult from './OrderResult'

function SearchOrders({ companyId, selected, onSelect, onResult, newOrderProps, inline, isPurchase, query: search }) {
  const [orders, setOrders] = useState()
  const [query, setQuery] = useState()
  const [orderBy, setOrderBy] = useState(['createdAt','desc'])

  useAsyncFunc(async args => {
    if (companyId) return findOrders({ ...args, ...isPurchase && { shipToContactIdIn: await findContacts({ companyId, type: 'CUST', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)) } })
    return () => []
  }, { companyId, orderBy, ...query && { numberRefBeginsWith: query } }, setOrders, [companyId, query, orderBy])

  const fetch = () => findOrders({ companyId, orderBy }).then(setOrders)

  useEffect(() => {
    const match = (orders || []).find(({ id }) => id === get(selected, 'id'))
    if (match && match.updatedAt < selected.updatedAt) {
      setOrders(orders.map(q => q.id !== match.id ? q : selected))
    }
    if (onResult) onResult(orders)
  }, [orders, selected])

  useEffect(() => {
    setQuery(search)
  }, [search])

  return (
    <Box style={{ display: 'flex', flexDirection: inline ? 'row' : 'column', gap: '5px' }}>
      <Box style={{ flexGrow: 1 }}>
        <TextField
          type="number"
          size="small"
          variant="outlined"
          placeholder="Search orders..."
          fullWidth
          value={query || ''}
          onChange={({ target: { value } }) => setQuery(value)}
          InputProps={{
            startAdornment: <IconButton edge="start"><Search /></IconButton>,
          }}
        />
      </Box>
      <Box style={{ display: 'flex', gap: '5px' }}>
        <OrderBy
          button={(
            <Button
              size="small"
              variant="outlined"
              startIcon={<Sort />}
            >
              {`Sort by: ${orderBy[0]}`}
            </Button>
          )}
          onChange={({ value }) => setOrderBy(value)}
        />
        <CreateOrder
          companyId={companyId}
          button={(
            <Button
              size="small"
              variant="outlined"
              startIcon={<Add />}
            >
              New Order
            </Button>
          )}
          data={{ companyId, status: 'OPEN', isNumberAuto: true, date: new Date(), ...newOrderProps }}
          onCreate={fetch}
        />
      </Box>
      {!onResult && (
        <List>
          {(orders || []).map(item => (
            <OrderResult
              key={item.id}
              item={item}
              selected={selected}
              onSelect={onSelect}
            />
          ))}
        </List>
      )}
    </Box>
  )
}

export default SearchOrders