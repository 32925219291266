import { IconButton } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { getDate } from '../functions'
import Dropdown from './Dropdown'

function DateField(props) {
  const { value, renderInput, withDropdown, onChange } = props
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...props}
        {...!value && { value: null }}
        {...withDropdown && {
          renderInput: params => renderInput({
            ...params,
            InputProps: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <Dropdown
                    button={(
                      <IconButton size="small" edge="end">
                        <ArrowDropDown fontSize="small" />
                      </IconButton>
                    )}
                    buttonStyle={{ marginRight: '-10px' }}
                    items={[
                      { label: 'Today', value: 0 },
                      { label: 'In 7 days', value: 7 },
                      { label: 'In 10 days', value: 10 },
                      { label: 'In 30 days', value: 30 },
                      { label: 'In 45 days', value: 45 },
                      { label: 'In 60 days', value: 60 },
                      { label: 'In 90 days', value: 90 },
                    ]}
                    onChange={({ value }) => onChange(getDate(null, '+', value, 'day'))}
                    disableCloseOnSelect
                  />
                </>
              ),
            }
          })
        }}
      />
    </LocalizationProvider>
  )
}

export default DateField