import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@mui/material'
import { DeleteOutline, PrintOutlined, DragIndicator, ArrowDropDown, OutboundOutlined, OpenInNew, ReportProblemOutlined } from '@mui/icons-material'
import { get } from 'lodash'
import Accordion from '../Accordion'
import Confirm from '../Confirm'
import Documents from '../Documents'
import Dropdown from '../Dropdown'
import PartName from '../Parts/PartName'
import PartNumber from '../Parts/PartNumber'
import { findJobAllocations } from '../Jobs/functions'
import { getNewShipmentNumber } from './functions'
import OrderContactProp from '../Orders/OrderContactProp'
import OrderNumber from '../Orders/OrderNumber'
import PartOrderAction from '../Orders/PartOrderAction'
import ShipmentBound from './ShipmentBound'

function ShipmentRow({ companyId, index, id, partOrders, status, shipVia, trackingNumber, isDroppable, isDragover, onDragStart, onDragEnter, onDragEnd, onUpdate, onRemove, errors }) {
  const [number, setNumber] = useState()
  const [jobAllocations, setJobAllocations] = useState()

  useEffect(() => {
    if (companyId && id) getNewShipmentNumber(companyId).then(number => setNumber(number + index))
  }, [companyId, index, id])

  useEffect(() => {
    const fetch = async () => partOrders.length > 0 && setJobAllocations(await findJobAllocations({ partOrderIdIn: partOrders.map(({ id }) => id), orderBy: ['createdAt','desc'] }))
    fetch()
  }, [partOrders])

  return (
    <Accordion
      title={(
        <>
          {`Shipment #(${number})`}
          <ShipmentBound
            companyId={companyId}
            partOrderId={get(partOrders, '[0].id')}
            inbound={(
              <Tooltip title="Inbound">
                <Button
                  className="tiny"
                  size="small"
                  color="success"
                  variant="contained"
                  startIcon={<OutboundOutlined style={{ transform: 'rotateZ(90deg)' }} />}
                  style={{ minWidth: 'auto', marginLeft: '10px', padding: '0 8px 0 12px', fontSize: '12px' }}
                  disableElevation
                >
                  Inbound
                </Button>
              </Tooltip>
            )}
            outbound={(
              <Tooltip title="Outbound">
                <Button
                  className="tiny"
                  size="small"
                  color="warning"
                  variant="contained"
                  startIcon={<OutboundOutlined />}
                  style={{ minWidth: 'auto', marginLeft: '10px', padding: '0 8px 0 12px', fontSize: '12px' }}
                  disableElevation
                >
                  Outbound
                </Button>
              </Tooltip>
            )}
          />
        </>
      )}
      afterTitle={(
        <Box onClick={e => e.stopPropagation()} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center', gap: '10px' }}>
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Dropdown
              button={(
                <TextField
                  size="small"
                  variant="outlined"
                  name="shipVia"
                  label="Ship Via"
                  value={shipVia || ''}
                  onChange={({ target: { value } }) => onUpdate(id, { shipVia: value })}
                  InputProps={{
                    endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                  }}
                  style={{ width: '170px' }}
                />
              )}
              items={['UPS','FedEx','Our Truck'].map(value => ({ label: value, value }))}
              onChange={({ value }) => onUpdate(id, { shipVia: value })}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Tracking number"
              value={trackingNumber || ''}
              onChange={({ target: { value } }) => onUpdate(id, { trackingNumber: value })}
            />
            <Documents
              companyId={companyId}
              button={(
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<PrintOutlined />}
                  style={{ whiteSpace: 'nowrap' }}
                  disabled={partOrders.length === 0}
                >
                  Print
                </Button>
              )}
              buttonStyle={{ display: 'flex' }}
              jobAllocations={jobAllocations}
              disabled={partOrders.length === 0}
            />
            {/* <Dropdown
              button={(
                <Button
                  size="small"
                  variant={status === 'NOT_SHIPPED' ? 'outlined' : 'contained'}
                  color={status === 'ON_HOLD' ? 'error' : 'primary'}
                  style={{ whiteSpace: 'nowrap' }}
                  disableElevation
                  disabled={partOrders.length === 0}
                >
                  {status.replace('_',' ')}
                </Button>
              )}
              items={[
                { label: 'Not Shipped', value: 'NOT_SHIPPED' },
                { label: 'Shipped', value: 'SHIPPED' },
                { label: 'On Hold', value: 'ON_HOLD', dividedBefore: true },
              ]}
              onChange={({ value }) => onUpdate(id, { status: value })}
              disabled={partOrders.length === 0}
            /> */}
            <Confirm
              button={(
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteOutline />}
                >
                  Discard
                </Button>
              )}
              buttonStyle={{ display: 'flex' }}
              title="Confirm discard?"
              content="Are you sure you want to discard this shipment?"
              onConfirm={() => onRemove({ id })}
            />
          </Box>
        </Box>
      )}
      content={(
        <TableContainer
          style={{ marginBottom: '5px', border: '1px solid #d9d9d9', borderRadius: '5px' }}
          {...(isDroppable || isDragover) && { className: `${isDroppable ? 'droppable' : ''} ${isDragover ? 'dragover' : ''}` }}
          onDragEnter={e => onDragEnter(e, { id })}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Due
                </TableCell>
                <TableCell>
                  Part
                </TableCell>
                <TableCell>
                  PO
                </TableCell>
                <TableCell>
                  Line
                </TableCell>
                <TableCell>
                  From
                </TableCell>
                <TableCell>
                  Ship To
                </TableCell>
                <TableCell>
                  Quantity
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                  Updated
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="droppableZone">
              {partOrders.map(partOrder => {
                const { id: partOrderId, orderId, lineNumber, partId, quantity, quantityBalance, dueAt, updatedAt } = partOrder
                return (
                  <TableRow
                    key={partOrderId}
                    {...onDragStart && onDragEnd && {
                      draggable: true,
                      onDragStart: e => onDragStart(e, partOrder),
                      onDragEnd: e => onDragEnd(e, partOrder),
                    }}
                  >
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <DragIndicator fontSize="small" style={{ marginRight: '10px', marginLeft: '-5px', verticalAlign: 'bottom', color: '#a6a6a6' }} />
                      {dueAt.toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={<PartName partId={partId} />}>
                        <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                          <PartNumber partId={partId} />
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <OrderNumber orderId={orderId} />
                      <Tooltip title="Open In New Window">
                        <OpenInNew style={{ marginLeft: '5px', verticalAlign: 'text-top', fontSize: '16px', color: '#a6a6a6' }} onClick={() => window.open(`/orders/${orderId}`,'_blank')} />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {lineNumber}
                    </TableCell>
                    <TableCell>
                      <OrderContactProp partOrderId={partOrderId} contactIdField="shipFromContactId" prop="name" />
                    </TableCell>
                    <TableCell>
                      <OrderContactProp partOrderId={partOrderId} contactIdField="shipToContactId" prop="name" />
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      {quantity - (quantityBalance !== undefined ? quantityBalance : quantity)}
                      {` / `}
                      {quantity}
                      <PartOrderAction companyId={companyId} partOrderId={partOrderId} shippingDisabled />
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                      {updatedAt.toLocaleDateString()}
                      {errors[partOrderId] && (
                        <Tooltip title={errors[partOrderId]}>
                          <ReportProblemOutlined style={{ marginLeft: '5px', verticalAlign: 'bottom', color: '#ff9800' }} onClick={() => window.open(`/orders/${orderId}`,'_blank')} />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell colSpan={7}>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      defaultExpanded
    />
  )
}

export default ShipmentRow