import { useState, useEffect } from 'react'
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { updateProfile } from 'firebase/auth'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, updateUser } from '../../functions'
import PhoneField from '../PhoneField'
import { useAuth } from './'

function EditProfile({ button, buttonStyle }) {
  const [isEditing, setIsEditing] = useState(false)
  const [updatedUser, setUpdatedUser] = useState()
  const [errors, setErrors] = useState({})

  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const update = updates => 
    setUpdatedUser({
      ...updatedUser,
      ...updates,
    })

  const validate = async () => {
    const errors = {}
    if (!get(updatedUser, 'displayName')) errors.displayName = 'Required'
    if (!get(updatedUser, 'email')) errors.email = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateProfile(user, updatedUser)
    .then(() => updateUser(user.uid, updatedUser))
    .then(() => {
      enqueueSnackbar('Profile updated successfully.', { variant: 'success' })
      setIsEditing(false)
    })
  }

  useEffect(() => {
    if (user) {
      const { displayName, email, phoneNumber } = user
      setUpdatedUser({
        displayName,
        email,
        phoneNumber,
      })
    }
  }, [user])

  return (
    <>
      <Box style={{ ...buttonStyle }} onClick={() => setIsEditing(true)}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isEditing}
        onClose={() => setIsEditing(false)}
      >
        <DialogTitle>Edit profile</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <TextField
              size="small"
              variant="outlined"
              name="displayName"
              label="Name"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(updatedUser, 'displayName', '')}
              onChange={({ target: { value } }) => update({ displayName: value })}
              {...addErrorProps(errors, 'displayName')}
            />
            <TextField
              size="small"
              variant="outlined"
              name="email"
              label="Email"
              fullWidth
              style={{ marginBottom: '10px' }}
              value={get(updatedUser, 'email', '')}
              onChange={({ target: { value } }) => update({ email: value })}
              {...addErrorProps(errors, 'email')}
              disabled
            />
            <TextField
              size="small"
              variant="outlined"
              name="phoneNumber"
              label="Phone"
              fullWidth
              value={get(updatedUser, 'phoneNumber', '')}
              onChange={({ target: { value } }) => update({ phoneNumber: value })}
              style={{ marginBottom: '10px' }}
              InputProps={{
                inputComponent: PhoneField,
              }}
              {...addErrorProps(errors, 'phoneNumber')}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditProfile