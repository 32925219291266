import _ from 'lodash'
import { collection, query, where, orderBy, limit, startAt, startAfter, endBefore, limitToLast, getDocs } from 'firebase/firestore'
import { db } from '../../Firebase'
import { collect, get, add, update, getGlobal, beginsWith, positiveInt, getCount } from '../../functions'
import { findContacts } from '../Contacts/functions'

export const findOrders = async ({ companyId, number, numberRef, numberBeginsWith, numberRefBeginsWith, shipFromContactId, shipFromContactIdIn, shipFromContactIdNotIn, billToContactIdIn, shipToContactId, shipToContactIdIn, status, statusIn, statusNotIn, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'orders'), ...[
    ...number ? [where('number', '==', number)]
    : numberBeginsWith ? beginsWith('number', numberBeginsWith)
    : [],
    ...numberRef ? [where('numberRef', '==', numberRef)]
    : numberRefBeginsWith ? beginsWith('numberRef', numberRefBeginsWith)
    : [],
    ...shipFromContactId ? [where('shipFromContactId', '==', shipFromContactId)] : [],
    ...shipToContactId ? [where('shipToContactId', '==', shipToContactId)] : [],
    ...shipFromContactIdIn && shipFromContactIdIn.length > 0 ? [where('shipFromContactId', 'in', shipFromContactIdIn)] : [],
    ...shipFromContactIdNotIn && shipFromContactIdNotIn.length > 0 ? [where('shipFromContactId', 'not-in', shipFromContactIdNotIn)] : [],
    ...billToContactIdIn && billToContactIdIn.length > 0 ? [where('billToContactId', 'in', billToContactIdIn)] : [],
    ...shipToContactIdIn && shipToContactIdIn.length > 0 ? [where('shipToContactId', 'in', shipToContactIdIn)] : [],
    ...status ? [where('status', '==', status)] : [],
    ...statusIn ? [where('status', 'in', statusIn)] : [],
    ...statusNotIn ? [where('status', 'not-in', statusNotIn)] : [],
    where('companyId', '==', companyId),
    ...!shipFromContactIdNotIn ? [orderBy(...order)] : [],
    limit(max || await getGlobal('ordersQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(async snap => {
    const purchasing = await findContacts({ companyId, type: 'CUST', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id))
    return collect(snap).map(order => ({
      ...order,
      isPurchase: purchasing.includes(order.shipToContactId),
    }))
  })

export const getOrder = id => get('orders', id).then(async order => {
  const purchasing = await findContacts({ companyId: order.companyId, type: 'CUST', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id))
  return ({
    ...order,
    isPurchase: purchasing.includes(order.shipToContactId),
  })
})

export const addOrder = data => add('orders', data)

export const updateOrder = (id, updates) => update('orders', id, updates, 'orderLogs')

export const getLastPurchaseOrder = async companyId => findOrders({ companyId, orderBy: ['numberRef','desc'], limit: 1, shipToContactIdIn: await findContacts({ companyId, type: 'CUST', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)) }).then(([lastPurchaseOrder]) => lastPurchaseOrder)

export const getLastSalesOrder = async companyId => findOrders({ companyId, orderBy: ['numberRef','desc'], limit: 1, shipFromContactIdIn: await findContacts({ companyId, type: 'VEND', isInternal: true, orderBy: ['createdAt','desc'] }).then(contacts => contacts.map(({ id }) => id)) }).then(([lastSalesOrder]) => lastSalesOrder)

export const getNewOrderNumber = companyId => getCount('orders', companyId).then(count => count + 1)

export const validateOrderNumber = (number, companyId) => findOrders({ companyId, number, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const validateOrderNumberRef = (numberRef, companyId) => findOrders({ companyId, numberRef, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0)

export const findPartOrders = async ({ partId, partIdIn, orderId, excludeOrderIds, lineNumber, isPurchase, quantity, quantityBalanceMin, status, statusIn, statusNotIn, dueAt, dueAtFrom, dueAtTo, orderBy: order, limit: max, startAfter: after, endBefore: before }) =>
  getDocs(query(collection(db, 'partOrders'), ...[
    ...orderId ? [where('orderId', '==', orderId)] : [],
    ...partId ? [where('partId', '==', partId)] : [],
    ...partIdIn && partIdIn.length > 0 ? [where('partId', 'in', partIdIn)] : [],
    ...excludeOrderIds && excludeOrderIds.length > 0 ? [where('orderId', 'not-in', excludeOrderIds)] : [],
    ...lineNumber ? [where('lineNumber', '==', lineNumber)] : [],
    ...isPurchase !== undefined ? [where('isPurchase', '==', isPurchase)] : [],
    ...quantity ? [where('quantity', '==', quantity)] : [],
    ...quantityBalanceMin ? [where('quantityBalance', '>=', quantityBalanceMin)] : [],
    ...status ? [where('status', '==', status)] : [],
    ...statusIn ? [where('status', 'in', statusIn)] : [],
    ...statusNotIn ? [where('status', 'not-in', statusNotIn)] : [],
    ...dueAt ? [where('dueAt', '==', dueAt)] : [],
    ...dueAtFrom ? [where('dueAt', '>=', dueAtFrom)] : [],
    ...dueAtTo ? [where('dueAt', '<', dueAtTo)] : [],
    ...!dueAtFrom && !dueAtTo && !quantityBalanceMin && !statusIn && !statusNotIn ? [orderBy(...order)] : [],
    limit(max || await getGlobal('partOrdersQueryLimit')),
    ...after ? [startAfter(after)] : [],
    ...before ? [endBefore(before), limitToLast(max + 1)] : [],
  ]))
  .then(collect)

export const getPartOrder = id => get('partOrders', id)

export const addPartOrder = data => add('partOrders', data)

export const getNewLineNumber = orderId => findPartOrders({ orderId, orderBy: ['lineNumber','desc'], limit: 1 }).then(([lastPartOrder]) => _.get(lastPartOrder, 'lineNumber', 0) + 1)

export const validatePartOrderLineNumber = (orderId, lineNumber, partOrderId) => findPartOrders({ orderId, lineNumber, orderBy: ['createdAt','desc'], limit: 1 }).then(conflicts => conflicts.length === 0 || conflicts[0].id === partOrderId)

export const updatePartOrder = (id, updates) => update('partOrders', id, { ...updates, ...updates.quantityBalance !== undefined && { status: updates.quantityBalance === 0 ? 'COMPLETED' : 'OPEN' } }, 'partOrderLogs')

export const findPartOrderLogs = async ({ partId, orderId, excludeOrderIds, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'partOrderLogs'), ...[
    ...orderId ? [where('before.orderId', '==', orderId)] : [],
    ...partId ? [where('before.partId', '==', partId)] : [],
    ...excludeOrderIds ? [where('before.orderId', 'not-in', excludeOrderIds)] : [],
    orderBy(...order),
    limit(max || await getGlobal('partOrderLogsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const findPartOrderJobConnections = async ({ partOrderId, jobId, status, orderBy: order, limit: max, startAt: snap }) =>
  getDocs(query(collection(db, 'partOrderJobConnections'), ...[
    ...partOrderId ? [where('partOrderId', '==', partOrderId)] : [],
    ...jobId ? [where('jobId', '==', jobId)] : [],
    ...status ? [where('status', '==', status)] : [],
    orderBy(...order),
    limit(max || await getGlobal('partOrderJobConnectionsQueryLimit')),
    ...snap ? [startAt(snap)] : [],
  ]))
  .then(collect)

export const addPartOrderJobConnection = data => add('partOrderJobConnections', data)

export const updatePartOrderJobConnection = (id, updates) => update('partOrderJobConnections', id, updates, 'partOrderJobConnectionLogs')