import { useState } from 'react'
import { Box, TextField, Button } from '@mui/material'
import { get } from 'lodash'
import { addErrorProps, useAsyncFunc, getCurrentUserId, getUser } from '../functions'
import DateField from './DateField'

function ApprovedByOn({ createdByUserId, approvedByUserId, approvedAt, label, onApprove, style }) {
  const [approvedBy, setApprovedBy] = useState()
  const [errors, setErrors] = useState({})

  useAsyncFunc(async approvedByUserId => {
    if (approvedByUserId) return getUser(approvedByUserId)
    return () => null
  }, approvedByUserId, setApprovedBy, [approvedByUserId])

  return (
    <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', ...style }}>
      <Box style={{ width: '75%' }}>
        <TextField
          size="small"
          variant="outlined"
          label={label || 'Approved by'}
          fullWidth
          value={get(approvedBy, 'displayName', '')}
          style={{ background: '#fff' }}
          InputProps={{
            ...approvedByUserId ? {
              style: { fontFamily: '"Dancing Script", cursive', fontSize: '1.5rem' },
              endAdornment: (
                <Button
                  className="tiny"
                  size="small"
                  variant="outlined"
                  style={{ minWidth: 'auto', padding: '0 10px', fontSize: '12px', whiteSpace: 'nowrap' }}
                  disableElevation
                >
                  {get(approvedBy, 'title', '')}
                </Button>
              ),
            } : {
              endAdornment: (
                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={onApprove}
                  disableElevation
                  disabled={getCurrentUserId() === createdByUserId}
                >
                  Sign
                </Button>
              ),
            },
          }}
          {...addErrorProps(errors, 'approvedByUserId')}
          disabled={approvedByUserId || getCurrentUserId() === createdByUserId}
        />
      </Box>
      <Box style={{ width: '25%' }}>
        <DateField
          label="Date"
          value={approvedAt}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              fullWidth
              style={{ background: '#fff' }}
              {...addErrorProps(errors, 'approvedAt')}
            />
          )}
          disabled
        />
      </Box>
    </Box>
  )
}

export default ApprovedByOn