import { useState, useEffect } from 'react'
import { Box, Typography, TextField, TableRow, TableCell, Button, IconButton, Tooltip, Drawer } from '@mui/material'
import { Search, ArrowDropDown, ArrowDropUp, DeleteOutline } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import { useAsyncFunc, findFileRefs, positiveInt, positiveFloat } from '../../functions'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'
import SearchFiles from '../SearchFiles'
import UpdateFileRef from '../UpdateFileRef'
import PriceField from '../PriceField'
import MultiplierField from '../MultiplierField'
import Popover from '../Popover'
import SelectContact from '../Contacts/SelectContact'
import SelectPartOrder from '../Orders/SelectPartOrder'
import SelectPart from '../Parts/SelectPart'

function PartQuoteLine({ companyId, partId, readOnly, onUpdate, onMove, changesClearedAt, view, ...line }) {
  const [isRefOpen, setIsRefOpen] = useState(false)
  const [updates, setUpdates] = useState()
  const [fileRefs, setFileRefs] = useState([])
  const [part, setPart] = useState()

  const { id, number, description, quantity, quantityMade, unit, unitPrice, markupRate, vendorContactId, poNumberRef, isFlatFee } = line

  const fetch = () => findFileRefs({ refFromIds: [id], orderBy: ['updatedAt','desc'] }).then(setFileRefs)

  useAsyncFunc(findFileRefs, { refFromIds: [id], orderBy: ['updatedAt','desc'] }, setFileRefs, [id])

  useEffect(() => {
    if (updates) onUpdate(updates)
  }, [updates])

  useEffect(() => {
    if (changesClearedAt) setUpdates()
  }, [changesClearedAt])

  return (
    <>
      <Drawer
        anchor="right"
        open={isRefOpen}
        onClose={() => setIsRefOpen(false)}
        style={{ zIndex: 1400 }}
      >
        <Box style={{ width: '35vw', padding: '15px' }}>
          <SearchFiles
            companyId={companyId}
            refFroms={[
              { id, type: 'quoteLine' },
              { id: partId, type: 'part' },
            ]}
            refFromIds={[id, partId]}
            onUpdated={fetch}
            inline
          />
        </Box>
      </Drawer>
      <TableRow>
        <TableCell sx={{ padding: 0 }}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <IconButton size="small" onClick={() => onUpdate({ status: 'ARCHIVED' })}>
              <DeleteOutline fontSize="small" />
            </IconButton>
            <Typography variant="body2">
              {get(updates, 'number', number)}
            </Typography>
            {!readOnly && (
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Tooltip title="Move up" placement="right">
                  <IconButton
                    size="small"
                    onClick={() => onMove(id, -1)}
                  >
                    <ArrowDropUp fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Move down" placement="right">
                  <IconButton
                    size="small"
                    onClick={() => onMove(id, 1)}
                  >
                    <ArrowDropDown fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </TableCell>
        {view === 'VENDOR' && (
          <>
            <TableCell>
              {readOnly ? get(updates, 'vendorContactId', vendorContactId) : (
                <SelectContact
                  companyId={companyId}
                  contactId={get(updates, 'vendorContactId', vendorContactId)}
                  onChange={contact => (
                    (get(updates, 'vendorContactId', vendorContactId) && !contact)
                      || (!get(updates, 'vendorContactId', vendorContactId) && contact))
                        && setUpdates({ ...updates, vendorContactId: contact?.id || '' })}
                  style={{ minWidth: '300px', transform: 'translateX(-5px)' }}
                  findType="VEND"
                />
              )}
            </TableCell>
            <TableCell>
              {readOnly ? get(updates, 'poNumberRef', poNumberRef) : (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  placeholder="PO number"
                  value={get(updates, 'poNumberRef', poNumberRef)}
                  onChange={({ target: { value } }) => setUpdates({ ...updates, poNumberRef: value })}
                  style={{ maxWidth: '170px', transform: 'translateX(-5px)' }}
                  InputProps={{
                    endAdornment: (
                      <SelectPartOrder
                        companyId={companyId}
                        button={(
                          <Tooltip title="Search purchases">
                            <IconButton edge="end">
                              <Search fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        partId={partId}
                        query={get(updates, 'poNumberRef', poNumberRef)}
                        isPurchase
                        onChange={(partOrderId, { lineNumber, quantity, notes }, { numberRef, number }) => setUpdates({ ...updates, poNumberRef: numberRef })}
                      />
                    ),
                  }}
                />
              )}
            </TableCell>
          </>
        )}
        <TableCell>
          {readOnly ? get(updates, 'description', description) : (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              placeholder="Material, process, labor..."
              value={get(updates, 'description', description)}
              onChange={({ target: { value } }) => setUpdates({ ...updates, description: value })}
              style={{ minWidth: '500px', transform: 'translateX(-5px)' }}
              InputProps={{
                endAdornment: (
                  <Popover
                    button={(
                      <Tooltip title="Search materials or subparts">
                        <IconButton edge="end">
                          <Search fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    content={(
                      <Box style={{ minWidth: '700px', padding: '15px' }}>
                        <SelectPart
                          companyId={companyId}
                          assyPartId={partId}
                          onChange={part => {
                            setUpdates({ ...updates, description: part ? `${part.number} · ${part.name} · Rev.${part.revision || '-'}` : '' })
                            return setPart(part)
                          }}
                        />
                      </Box>
                    )}
                    forceClosingOnClick
                  />
                ),
              }}
            />
          )}
        </TableCell>
        {/* <TableCell>
          {!readOnly && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => setIsRefOpen(true)}
              style={{ marginBottom: '5px' }}
            >
              <Search fontSize="small" />
            </Button>
          )}
          {fileRefs.map(fileRef => {
            const { id, name, updatedAt } = fileRef
            return (
              <UpdateFileRef
                key={id}
                button={(
                  <Tooltip title={`Updated ${updatedAt.toLocaleDateString()}`}>
                    <Button
                      size="small"
                      variant="outlined"
                      style={{ marginLeft: '5px', marginBottom: '5px' }}
                    >
                      {name}
                    </Button>
                  </Tooltip>
                )}
                buttonStyle={{ display: 'inline-block' }}
                fileRef={fileRef}
                onUpdated={fetch}
              />
            )
          })}
        </TableCell> */}
        <TableCell>
          {readOnly ? get(updates, 'quantity', quantity) : (
            <TextField
              size="small"
              variant="outlined"
              value={get(updates, 'quantity', quantity)}
              onChange={({ target: { value } }) => setUpdates({ ...updates, quantity: get(updates, 'unit', unit) === 'piece' ? positiveInt(value) : positiveFloat(value) })}
              style={{ maxWidth: '120px', transform: 'translateX(-5px)' }}
            />
          )}
        </TableCell>
        <TableCell>
          {readOnly ? get(updates, 'unit', unit) : (
            <Dropdown
              button={(
                <TextField
                  size="small"
                  variant="outlined"
                  value={get(updates, 'unit', unit).toLowerCase()}
                  InputProps={{
                    endAdornment: <IconButton edge="end"><ArrowDropDown fontSize="small" /></IconButton>,
                  }}
                  style={{ maxWidth: '110px', transform: 'translateX(-5px)' }}
                />
              )}
              items={[
                { label: 'piece', value: 'PIECE' },
                { label: 'hour', value: 'HOUR' },
                { label: 'minute', value: 'MINUTE' },
              ]}
              onChange={({ value }) => setUpdates({ ...updates, unit: value })}
            />
          )}
        </TableCell>
        {view === 'PRICING' && (
          <>
            <TableCell>
              {readOnly ? get(updates, 'quantityMade', quantityMade) || 1 : (
                <TextField
                  size="small"
                  variant="outlined"
                  value={get(updates, 'quantityMade', quantityMade) || 1}
                  onChange={({ target: { value } }) => setUpdates({ ...updates, quantityMade: positiveInt(value) })}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title={`"It takes ${get(updates, 'quantity', quantity)} ${get(updates, 'unit', unit)} of ${get(updates, 'description', description)} ${get(updates, 'unit', unit) === 'PIECE' ? 'to make' : 'for'} ${get(updates, 'quantityMade', quantityMade) || 1} part"`}
                        placement="bottom"
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <Typography variant="body2" color="GrayText">part</Typography>
                          <Checkbox
                            checked={get(updates, 'isFlatFee', !!isFlatFee)}
                            onChange={({ target: { checked } }) => setUpdates({ ...updates, isFlatFee: checked })}
                            label="Flat"
                            style={{ marginRight: '-15px' }}
                          />
                        </Box>
                      </Tooltip>
                    ),
                    disabled: isFlatFee,
                  }}
                  style={{ maxWidth: '180px', transform: 'translateX(-5px)' }}
                />
              )}
            </TableCell>
            <TableCell>
              {readOnly ? get(updates, 'unitPrice', unitPrice) : (
                <TextField
                  size="small"
                  variant="outlined"
                  value={get(updates, 'unitPrice', unitPrice)}
                  onChange={({ target: { value } }) => setUpdates({ ...updates, unitPrice: positiveFloat(value) })}
                  style={{ maxWidth: '140px', transform: 'translateX(-5px)' }}
                  InputProps={{
                    inputComponent: PriceField,
                    endAdornment: <Typography variant="body2" color="GrayText">{`/${get(updates, 'unit', unit).toLowerCase()}`}</Typography>,
                  }}
                />
              )}
            </TableCell>
            {/* <TableCell align="right">
              {readOnly ? get(updates, 'markupRate', markupRate) : (
                <TextField
                  size="small"
                  variant="outlined"
                  value={get(updates, 'markupRate', markupRate)}
                  onChange={({ target: { value } }) => setUpdates({ ...updates, markupRate: positiveFloat(value) })}
                  style={{ maxWidth: '75px', transform: 'translateX(-5px)' }}
                  InputProps={{
                    inputComponent: MultiplierField,
                  }}
                />
              )}
            </TableCell> */}
            <TableCell align="right">
              <NumberFormat
                value={get(updates, 'quantity', quantity)*get(updates, 'unitPrice', unitPrice)*get(updates, 'markupRate', markupRate)}
                prefix="$"
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
                displayType="text"
              />
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  )
}

export default PartQuoteLine