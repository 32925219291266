import { useState } from 'react'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { Add, Upload as UploadIcon } from '@mui/icons-material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import Accordion from '../Accordion'
import FileRefs from '../FileRefs'
import { getPart, getLatestRevision } from './functions'
import EditPartDetails from './EditDetails'
import PartDetails from './PartDetails'
import PartInventory from './PartInventory'
import PartJobs from './PartJobs'
import PartOrders from './PartOrders'
import PartVersions from './PartVersions'

function Part({ partId }) {
  const [part, setPart] = useState()
  const [isUploading, setIsUploading] = useState(false)
  const [filesFetchedAt, setFilesFetchedAt] = useState(new Date())

  useAsyncFunc(async ({ partId }) => {
    if (partId) return getPart(partId)
  }, { partId }, setPart, [partId])

  return (
    <Box style={{ padding: '10px' }}>
      <Box style={{ display: 'flex' }}>
        <Box style={{ width: '50%' }}>
          <Accordion
            title="Part Details"
            content={(
              <EditPartDetails
                part={part}
                onUpdate={setPart}
              />
            )}
            defaultExpanded
          />
          <Accordion
            title={(
              <>
                Files
                <Button
                  size="small"
                  variant="outlined"
                  style={{ marginLeft: '10px' }}
                  startIcon={<UploadIcon />}
                  onClick={e => {
                    e.stopPropagation()
                    setIsUploading(true)
                  }}
                >
                  Upload
                </Button>
              </>
            )}
            content={get(part, 'id') && (
              <FileRefs
                key={filesFetchedAt}
                companyId={get(part, 'companyId')}
                refFroms={[
                  { id: partId, type: 'part' },
                ]}
              />
            )}
          />
        </Box>
        <Box style={{ width: '50%' }}>
          <Accordion
            title="Inventory"
            content={<PartInventory companyId={get(part, 'companyId')} partId={partId} />}
            defaultExpanded
          />
          <Accordion
            title="History"
            content={<PartVersions partId={partId} />}
            defaultExpanded
          />
          <Accordion
            title="Assemblies"
            content={<PartDetails partId={partId} />}
            defaultExpanded
          />
        </Box>
      </Box>
      <Accordion
        title={(
          <>
            Related Jobs
            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: '10px' }}
              startIcon={<Add />}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              Add Job
            </Button>
          </>
        )}
        content={(
          <PartJobs
            companyId={get(part, 'companyId')}
            partId={partId}
          />
        )}
      />
      <Accordion
        title={(
          <>
            Related Orders
            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: '10px' }}
              startIcon={<Add />}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              Add Order
            </Button>
          </>
        )}
        content={(
          <PartOrders
            partId={partId}
          />
        )}
      />
    </Box>
  )
}

export default Part