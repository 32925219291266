import { NavLink } from 'react-router-dom'
import { signInWithEmailLink, signOut as authSignOut } from 'firebase/auth'
import { Box, Typography, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Chip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { ImportContacts, Settings, DownloadForOffline, Remove, AllInbox, Inbox, Visibility, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { SnackbarProvider } from 'notistack'
import { get } from 'lodash'
import preval from 'preval.macro'
import { useAuth } from './components/Auth'
import EditProfile from './components/Auth/EditProfile'
import ManualDrawer from './components/Manual/ManualDrawer'

function Nav() {
  const { user } = useAuth()

  return (
    <Box className="nav" style={{ display: 'flex', alignItems: 'center' }}>
      <NavLink to="/parts">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          <IconButton size="large" edge="start">
            <Settings />
          </IconButton>
          Parts
        </Typography>
      </NavLink>
      <NavLink to="/">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Jobs
        </Typography>
      </NavLink>
      <NavLink to="/orders">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Orders
        </Typography>
      </NavLink>
      {/* <NavLink to="/shipments">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Shipments
        </Typography>
      </NavLink> */}
      <NavLink to="/invoices">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Invoices
        </Typography>
      </NavLink>
      <NavLink to="/quotes">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Quotes
        </Typography>
      </NavLink>
      <NavLink to="/contacts">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Contacts
        </Typography>
      </NavLink>
      <NavLink to="/journal">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Reports
        </Typography>
      </NavLink>
      <NavLink to="/manual">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Manual
        </Typography>
      </NavLink>
      <NavLink to="/devices">
        <Typography variant="h4" style={{ margin: '10px', fontWeight: 'bold' }}>
          Testing
        </Typography>
      </NavLink>
      <Box style={{ flexGrow: 1, padding: '10px', textAlign: 'right' }}>
        <ManualDrawer
          button={(
            <IconButton>
              <ImportContacts />
            </IconButton>
          )}
          buttonStyle={{ position: 'fixed', top: '60px', right: 0, background: '#fff', zIndex: 99999 }}
        />
        <Button
          size="small"
          startIcon={<DownloadForOffline />}
          disabled
        >
          {preval`module.exports = new Date().toLocaleString()`}
        </Button>
        <EditProfile
          button={(
            <Button
              size="small"
              variant="outlined"
              startIcon={<AccountCircle />}
            >
              {get(user, 'displayName', get(user, 'email', ''))}
            </Button>
          )}
          buttonStyle={{ display: 'inline-block', marginLeft: '5px' }}
        />
      </Box>
    </Box>
  )
}

export default Nav
