import { useState } from 'react'
import { Box, Menu, MenuItem, Divider, Tooltip } from '@mui/material'

function Dropdown({ button, buttonStyle, beforeItems, closeOnBeforeItemsClick, items, afterItems, closeOnAfterItemsClick, onChange, disableCloseOnSelect, disabled }) {
  const [anchorEl, setAnchorEl] = useState()

  const select = item => {
    if (!disableCloseOnSelect) setAnchorEl()
    const { value, onClick } = item
    if (value !== undefined && onChange) return onChange(item)
    if (onClick) return onClick(item)
  }

  return (
    <>
      <Box
        style={{ ...buttonStyle }}
        onClick={({ currentTarget }) => !disabled && setAnchorEl(currentTarget)}>
        {button}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl()}
      >
        {beforeItems && (
          <MenuItem {...closeOnBeforeItemsClick && { onClick: () => setAnchorEl() }}>
            {beforeItems}
          </MenuItem>
        )}
        {items.map(item => {
          const { label, value, tooltip, disabled, dividedBefore, dividedAfter } = item
          const menuItem = (
            <div key={value}>
              {dividedBefore && <Divider />}
              <MenuItem
                key={value}
                onClick={() => !disabled && select(item)}
              >
                {label}
              </MenuItem>
              {dividedAfter && <Divider />}
            </div>
          )
          return tooltip ? (
            <Tooltip title={tooltip} placement="right">
              {menuItem}
            </Tooltip>
          ) : menuItem
        })}
        {afterItems && (
          <MenuItem {...closeOnAfterItemsClick && { onClick: () => setAnchorEl() }}>
            {afterItems}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default Dropdown