import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import { useAsyncFunc } from '../../functions'
import { getInvoice } from './functions'

function InvoiceNumber({ invoiceId }) {
  const [invoice, setInvoice] = useState()

  useAsyncFunc(getInvoice, invoiceId, setInvoice, [invoiceId])

  return get(invoice, 'number', <Skeleton variant="rectangular" animation="wave" />)
}

export default InvoiceNumber