import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Box, IconButton, Button, TextField, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@mui/material'
import { Add, AccountTreeOutlined, List, SettingsOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Layer, Rectangle, Sankey, ResponsiveContainer } from 'recharts'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt } from '../../functions'
import Confirm from '../Confirm'
import Radio from '../Radio'
import { getPart, addPartDetail, updatePartDetail, findPartDetails } from './functions'
import SelectPart from './SelectPart'
import PartNumber from './PartNumber'
import PartName from './PartName'

const node = ({ x, y, width, height, index, payload, containerWidth }) => {
  const isOut = x + width + 6 > containerWidth

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={payload.type ? '#7e57c2' : '#1976d2'}
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="13"
        {...payload.onSelect && { onClick: payload.onSelect }}
      >
        <PartNumber partId={payload.partId} />
      </text>
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="11"
      >
        {`${payload.type === 'DETAIL' ? 'Needs ' : payload.type === 'ASSY' ? 'Takes ' : 'Makes '}${(payload.quantity || 1).toFixed(2)} unit${(payload.quantity || 1) > 1 ? 's' : ''}`}
      </text>
    </Layer>
  )
}

function PartDetails({ partId }) {
  const [isAdding, setIsAdding] = useState(false)
  const [part, setPart] = useState()
  const [partDetail, setPartDetail] = useState()
  const [assyParts, setAssyParts] = useState()
  const [detailParts, setDetailParts] = useState()
  const [quantity, setQuantity] = useState(1)
  const [targetPart, setTargetPart] = useState()
  const [targetType, setTargetType] = useState('DETAIL')
  const [viewMode, setViewMode] = useState('TREE')
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  useAsyncFunc(async args => {
    if (partId) {
      const part = await getPart(args)
      setDetailParts(await findPartDetails({ partId, orderBy: ['createdAt','asc'] }))
      setAssyParts(await findPartDetails({ detailPartId: partId, orderBy: ['createdAt','asc'] }))
      return part
    }
    return () => null
  }, partId, setPart, [partId])

  const fetch = async () => {
    setDetailParts(await findPartDetails({ partId, orderBy: ['createdAt','asc'] }))
    setAssyParts(await findPartDetails({ detailPartId: partId, orderBy: ['createdAt','asc'] }))
  }

  const addDetail = () => addPartDetail({
    ...targetType === 'DETAIL' ? {
      partId,
      detailPartId: targetPart.id,
    } : {
      partId: targetPart.id,
      detailPartId: partId,
    },
    quantity,
    status: 'ACTIVE',
  }).then(fetch).then(() => {
    enqueueSnackbar('Part detail added successfully.', { variant: 'success' })
    setIsAdding(false)
  })

  const updateDetail = (id, updates) => updatePartDetail(id, updates)
    .then(fetch).then(() => {
      enqueueSnackbar('Part detail updated successfully.', { variant: 'success' })
      setIsAdding(false)
      setPartDetail()
    })

  const selectDetail = partDetail => {
    setPartDetail(partDetail)
    setQuantity(partDetail.quantity)
    setTargetType(partDetail.detailPartId === partId ? 'ASSY' : 'DETAIL')
  }

  return (
    <Box>
      <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        {(isAdding || partDetail) ? (
          <>
            <Radio
              value={targetType}
              options={[
                { label: 'Detail', value: 'DETAIL' },
                { label: 'Assy', value: 'ASSY' },
              ]}
              onChange={({ target: { value } }) => setTargetType(value)}
              disabled={partDetail}
            />
            <Box style={{ flexGrow: 1 }}>
              <SelectPart
                companyId={get(part, 'companyId')}
                {...partDetail && { partId: get(partDetail, 'detailPartId'), AutocompleteProps: { disabled: true } }}
                onChange={setTargetPart}
              />
            </Box>
            <Box>
              <TextField
                size="small"
                variant="outlined"
                label="Quantity"
                value={quantity || get(partDetail, 'quantity')}
                onChange={({ target: { value } }) => setQuantity(positiveInt(value))}
                style={{ maxWidth: '100px' }}
                {...addErrorProps(errors, 'quantity')}
                required
              />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => partDetail ? updateDetail(partDetail.id, { quantity }) : addDetail()}
                disableElevation
                disabled={!targetPart || !quantity || quantity === get(partDetail, 'quantity')}
              >
                Save
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setIsAdding(false)
                  setPartDetail()
                }}
              >
                Discard
              </Button>
            </Box>
            {partDetail && (
              <Confirm
                button={(
                  <Tooltip title="Remove Part Detail">
                    <IconButton size="small">
                      <DeleteOutline color="error" />
                    </IconButton>
                  </Tooltip>
                )}
                buttonStyle={{ display: 'inline-block' }}
                title="Confirm removal?"
                content="Are you sure you want to remove this detail?"
                onConfirm={() => updateDetail(partDetail.id, { status: 'REMOVED', removedAt: new Date() })}
              />
            )}
          </>
        ) : (
          <>
            <IconButton size="small" onClick={() => setViewMode(viewMode === 'TREE' ? 'LIST' : 'TREE')}>
              {viewMode === 'TREE' && <AccountTreeOutlined />}
              {viewMode === 'LIST' && <List />}
            </IconButton>
            <Box style={{ width: '50%' }}>
              <Button
                fullWidth
                size="small"
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  setTargetType('ASSY')
                  setIsAdding(true)
                }}
              >
                Add Assy
              </Button>
            </Box>
            <Box style={{ width: '50%' }}>
              <Button
                fullWidth
                size="small"
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  setTargetType('DETAIL')
                  setIsAdding(true)
                }}
              >
                Add Detail
              </Button>
            </Box>
          </>
        )}
      </Box>
      {viewMode === 'LIST' && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Part Number</TableCell>
                <TableCell>Part Name</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Quantity Used</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailParts.length === 0 && <TableRow><TableCell colSpan={4}>No detail parts found.</TableCell></TableRow>}
              {detailParts.map(partDetail => {
                const { id, detailPartId, quantity, updatedAt } = partDetail
                return (
                  <TableRow key={id}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <Tooltip title="View part">
                        <IconButton size="small" onClick={() => navigate(`/parts/${detailPartId}`)} style={{ marginLeft: '-10px' }}>
                          <SettingsOutlined fontSize="small" color="disabled" />
                        </IconButton>
                      </Tooltip>
                      <PartNumber partId={detailPartId} />
                    </TableCell>
                    <TableCell>
                      <PartName partId={detailPartId} />
                    </TableCell>
                    <TableCell>
                      {quantity}
                      <Tooltip title="Edit Part Detail">
                        <IconButton size="small" edge="end" onClick={() => selectDetail(partDetail)} style={{ marginLeft: '5px' }}>
                          <EditOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {viewMode === 'TREE' && ((detailParts || []).length > 0 || (assyParts || []).length > 0) && (
        <ResponsiveContainer width="100%" height={300}>
          <Sankey
            margin={{
              top: 20,
              bottom: 20,
              right: 180,
            }}
            link={{ stroke: '#808080' }}
            nodePadding={25}
            node={node}
            data={{
              nodes: [
                ...detailParts.map(partDetail => {
                  const { detailPartId, quantity } = partDetail
                  return { partId: detailPartId, quantity, type: 'DETAIL', onSelect: () => selectDetail(partDetail) }
                }),
                { partId },
                ...(assyParts || []).map(({ partId, quantity }) => ({ partId, quantity, type: 'ASSY' })),
              ],
              links: [
                ...detailParts.map(({ quantity }, index) => ({
                  source: index,
                  target: detailParts.length,
                  value: quantity,
                })),
                ...(assyParts || []).map(({ quantity }, index) => ({
                  source: detailParts.length,
                  target: detailParts.length + 1 + index,
                  value: quantity,
                })),
              ],
            }}
          />
        </ResponsiveContainer>
      )}
    </Box>
  )
}

export default PartDetails