import { useState, createContext, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, Tabs, Tab, Card, CardContent, TextField, IconButton, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, AlertTitle, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Drawer } from '@mui/material'
import { Outbound, KeyboardArrowDown, Settings, Add, DownloadForOfflineOutlined, ListAlt, Inbox, Visibility, ArrowDropDown, Architecture, Article, Upload, Print, FilterList, FilterListOff, AccountCircle, LocalAtm, LocalShipping, InsertDriveFile, Sort, ContentCopy, DeleteOutline, Launch, Undo, AccessTime, ArrowBack, ArrowForward, Edit, Check, Clear } from '@mui/icons-material'
import { get } from 'lodash'
import { SnackbarProvider } from 'notistack'
import { useAsyncFunc, getLocal, setLocal, getDateDiffStr, jobRanges as ranges } from '../../functions'
import Dropdown from '../Dropdown'
import Paginate from '../Paginate'
import { getContact, findContacts } from './functions'
import CreateEditContact from './CreateEdit'
import ContactCard from './ContactCard'
import SelectContact from './SelectContact'
import ContactQuestionnaire from './ContactQuestionnaire'

function Contacts({ companyId }) {
  const [view, setView] = useState('ALL')
  const [selected, setSelected] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false)
  const [customers, setCustomers] = useState()
  const [vendors, setVendors] = useState()
  const [viewOptions, setViewOptions] = useState({ showDate: 'dueAt' })
  const [limit, setLimit] = useState(20)

  const [todayFrom, todayTo] = ranges.find(({ label }) => label === 'Today').value

  const [filterBy, setFilterBy] = useState(
    getLocal('ordersFilterBy', {
      label: 'Order Date',
      value: 'orderAt',
    }))
  const [filterByValue, setFilterByValue] = useState(
    getLocal('ordersFilterByValue', {
      orderAtFrom: todayFrom,
      orderAtTo: todayTo,
    }))

  const navigate = useNavigate()
  const { contactId } = useParams()

  useAsyncFunc(async args => {
    if (contactId) return getContact(args)
    return () => null
  }, contactId, setSelected, [contactId])

  const fetch = () => getContact(contactId).then(setSelected)

  useAsyncFunc(async args => {
    if (companyId) return findContacts({ ...args, type: 'CUST' })
    return () => null
  }, { companyId, orderBy: ['createdAt','desc'], limit }, setCustomers, [companyId, limit])

  useAsyncFunc(async args => {
    if (companyId) return findContacts({ ...args, type: 'VEND' })
    return () => null
  }, { companyId, orderBy: ['createdAt','desc'], limit }, setVendors, [companyId, limit])

  return (
    <>
      <Tabs value={view} onChange={(event, newValue) => setView(newValue)}>
        <Tab label="All" value="ALL" />
        <Tab label="Customers" value="CUSTOMERS" />
        <Tab label="Vendors" value="VENDORS" />
      </Tabs>
      {view === 'ALL' && (
        <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
          <Box style={{ width: '50%' }}>
            <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold' }}>
              <Outbound style={{ marginRight: '5px', verticalAlign: 'sub', color: '#4caf50', transform: 'rotateZ(90deg)' }} />
              Customers
            </Typography>
            <Box style={{ margin: '10px 0' }}>
              <CreateEditContact
                companyId={companyId}
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<Add />}
                  >
                    New
                  </Button>
                )}
                data={{ type: 'CUST' }}
              />
            </Box>
            {customers && customers.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                  No customers found.
                </TableCell>
              </TableRow>
            )}
            {(customers || []).map(contact => (
              <ContactCard
                key={contact.id}
                onSelect={() => {
                  setSelected(contact)
                  setIsEditing(true)
                }}
                {...contact}
              />
            ))}
          </Box>
          <Box style={{ width: '50%' }}>
            <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold' }}>
              <Outbound style={{ marginRight: '5px', verticalAlign: 'sub', color: '#03a9f4' }} />
              Vendors
            </Typography>
            <Box style={{ margin: '10px 0' }}>
              <CreateEditContact
                companyId={companyId}
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<Add />}
                  >
                    New
                  </Button>
                )}
                data={{ type: 'VEND' }}
              />
            </Box>
            {vendors && vendors.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                  No vendors found.
                </TableCell>
              </TableRow>
            )}
            {(vendors || []).map(contact => (
              <ContactCard
                key={contact.id}
                onSelect={() => {
                  setSelected(contact)
                  setIsEditing(true)
                }}
                {...contact}
              />
            ))}
          </Box>
        </Box>
      )}
      {view === 'VENDORS' && (
        <Box style={{ display: 'flex', gap: '10px', padding: '10px' }}>
          <Box style={{ width: '75%' }}>
            <SelectContact
              companyId={companyId}
              contactId=""
              onChange={contact => {}}
              style={{ marginBottom: '10px' }}
            />
          </Box>
          <Box style={{ width: '25%' }}>
            <ContactQuestionnaire
              companyId={companyId}
              forceOpen={isQuestionnaireOpen}
              setForceOpen={setIsQuestionnaireOpen}
            />
            <Button
              variant="outlined"
              startIcon={<Check />}
              disableElevation
              onClick={() => setIsQuestionnaireOpen(true)}
            >
              Vendor Questionnaire
            </Button>
          </Box>
        </Box>
      )}
      {selected && (
        <CreateEditContact
          companyId={companyId}
          forceOpen={isEditing}
          setForceOpen={setIsEditing}
          data={selected}
          onCreate={setSelected}
          onUpdate={setSelected}
        />
      )}
    </>
  )
}

export default Contacts
