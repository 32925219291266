import { useState, useEffect } from 'react'
import { Box, TextField, Typography, IconButton, FormHelperText, Button, TableRow, TableCell, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { Check, Clear, ArrowDropDown } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt, positiveFloat, jobStatuses, getDate } from '../../functions'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import PriceField from '../PriceField'
import SelectPart from '../Parts/SelectPart'
import { addJob, getNewJobNumber, validateJobNumber, validateLineNumber } from './functions'

const blankJob = { balance: 0, status: 'IN_PROGRESS', shipAt: new Date(), dueAt: new Date() }

function NewJobRow({ companyId, data, fields, dense, onCreate, forceOpen, setForceOpen }) {
  const [isCreating, setIsCreating] = useState(false)
  const [newJob, setNewJob] = useState(blankJob)
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  const validate = async () => {
    const errors = {}
    // if (!get(newJob, 'number')) errors.number = 'Required'
    // if (get(newJob, 'number') && !await validateJobNumber(newJob.number, companyId)) errors.number = 'Conflicted'
    if (!get(newJob, 'partId')) errors.partId = 'Required'
    if (!get(newJob, 'shipAt')) errors.shipAt = 'Required'
    if (!get(newJob, 'quantity')) errors.quantity = 'Required'
    if (!get(newJob, 'dueAt')) errors.dueAt = 'Required'
    if (!get(newJob, 'unitPrice') && fields.find(f => f.split(':')[0] === 'unitPrice')) errors.unitPrice = 'Required'
    if (!get(newJob, 'unit') && fields.find(f => f.split(':')[0] === 'unit')) errors.unit = 'Required'
    if (get(newJob, 'poNumber') && get(newJob, 'lineNumber') && !await validateLineNumber(newJob.poNumber, newJob.lineNumber, companyId)) errors.lineNumber = 'Conflicted'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return addJob({ ...newJob, companyId, number: await getNewJobNumber(companyId) })
      .then(async newJob => {
        enqueueSnackbar('Job created successfully.', { variant: 'success' })
        handleClose()
        setNewJob(blankJob)
        if (onCreate) onCreate(newJob)
      })
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  useEffect(() => {
    setNewJob({ ...blankJob, ...data })
  }, [data])

  const shipAt = (
    <DateField
      value={get(newJob, 'shipAt')}
      onChange={shipAt => setNewJob({ ...newJob, shipAt })}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          fullWidth
          style={{ transform: 'translateX(5px)' }}
          {...dense && { inputProps: { ...params.inputProps, style: { ...params.inputProps.style, padding: '5px 10px' } } }}
          {...addErrorProps(errors, 'shipAt')}
        />
      )}
      withDropdown
    />
  )

  const numberRef = (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      name="numberRef"
      value={get(newJob, 'numberRef', '')}
      placeholder="Optional"
      onChange={({ target: { value } }) => setNewJob({ ...newJob, numberRef: value })}
      style={{ maxWidth: '120px', transform: 'translateX(-5px)' }}
      {...addErrorProps(errors, 'numberRef')}
    />
  )

  const poNumber = (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      value={get(newJob, 'poNumber', '')}
      onChange={({ target: { value } }) => setNewJob({ ...newJob, poNumber: value })}
      style={{ maxWidth: '120px', transform: 'translateX(-5px)' }}
      {...addErrorProps(errors, 'poNumber')}
    />
  )

  const lineNumber = (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      value={get(newJob, 'lineNumber', '')}
      onChange={({ target: { value } }) => setNewJob({ ...newJob, lineNumber: positiveInt(value) })}
      style={{ maxWidth: '70px', transform: 'translateX(-5px)' }}
      {...dense && { inputProps: { style: { padding: '5px 10px' } } }}
      {...addErrorProps(errors, 'lineNumber')}
    />
  )

  const partId = (
    <SelectPart
      companyId={companyId}
      partId={get(newJob, 'partId')}
      onChange={part => setNewJob({ ...newJob, partId: get(part, 'id') })}
      style={{ transform: 'translateX(-5px)' }}
      inputProps={{
        label: null,
        ...addErrorProps(errors, 'partId')
      }}
      dense={dense}
    />
  )

  const quantity = (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      name="quantity"
      value={get(newJob, 'quantity', '')}
      onChange={({ target: { value } }) => setNewJob({ ...newJob, quantity: positiveInt(value) })}
      style={{ maxWidth: '120px', transform: 'translateX(-5px)' }}
      InputProps={{
        endAdornment: <Typography variant="subtitle2" color="GrayText">pcs</Typography>,
      }}
      {...dense && { inputProps: { style: { padding: '5px 10px' } } }}
      {...addErrorProps(errors, 'quantity')}
    />
  )

  const balance = (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      name="balance"
      value={get(newJob, 'balance', '')}
      onChange={({ target: { value } }) => setNewJob({ ...newJob, balance: positiveInt(value) })}
      style={{ maxWidth: '120px', transform: 'translateX(-5px)' }}
      {...addErrorProps(errors, 'balance')}
    />
  )

  const unit = (
    <Dropdown
      button={(
        <>
          <Button
            size="small"
            variant="outlined"
            {...addErrorProps(errors, 'unit').error && { color: 'error' }}
          >
            {get(newJob, 'unit', 'PIECE')}
          </Button>
          {addErrorProps(errors, 'unit').helperText && <FormHelperText error style={{ marginLeft: '10px' }}>{addErrorProps(errors, 'unit').helperText}</FormHelperText>}
        </>
      )}
      items={[
        { label: 'piece', value: 'PIECE' },
        { label: 'box', value: 'BOX' },
        { label: 'pallet', value: 'PALLET' },
      ]}
      onChange={({ value }) => setNewJob({ ...newJob, unit: value })}
    />
  )

  const unitPrice = (
    <TextField
      size="small"
      variant="outlined"
      name="unitPrice"
      placeholder="$"
      fullWidth
      value={get(newJob, 'unitPrice', '')}
      onChange={({ target: { value } }) => setNewJob({ ...newJob, unitPrice: positiveFloat(value) })}
      style={{ transform: 'translateX(5px)' }}
      InputProps={{
        inputComponent: PriceField,
      }}
      {...dense && { inputProps: { style: { padding: '5px 10px' } } }}
      {...addErrorProps(errors, 'unitPrice')}
    />
  )

  const dueAt = (
    <DateField
      value={get(newJob, 'dueAt')}
      onChange={dueAt => setNewJob({ ...newJob, dueAt })}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          fullWidth
          style={{ transform: 'translateX(5px)' }}
          {...dense && { inputProps: { ...params.inputProps, style: { ...params.inputProps.style, padding: '5px 10px' } } }}
          {...addErrorProps(errors, 'dueAt')}
        />
      )}
      withDropdown
    />
  )

  const status = (
    <Dropdown
      button={(
        <Button
          size="small"
          variant="outlined"
        >
          {get(newJob, 'status', 'NOT_STARTED').replace('_',' ')}
        </Button>
      )}
      items={jobStatuses}
      onChange={({ value }) => setNewJob({ ...newJob, status: value })}
    />
  )

  const confirm = (
    <Box style={{ whiteSpace: 'nowrap' }}>
      <Button
        size="small"
        variant="contained"
        startIcon={<Check />}
        onClick={submit}
        disableElevation
      >
        Save
      </Button>
      <Button
        size="small"
        variant="outlined"
        startIcon={<Clear />}
        onClick={handleClose}
        style={{ marginLeft: '5px' }}
        disableElevation
      >
        Discard
      </Button>
    </Box>
  )

  const templates = { shipAt, numberRef, poNumber, lineNumber, partId, quantity, balance, unit, unitPrice, dueAt, status, confirm }

  return isCreating && (
    <TableRow style={{ background: '#f2f2f2' }}>
      {fields.map(f => (
        <TableCell colSpan={f.includes(':') ? parseInt(f.split(':')[1]) : 1}>
          {templates[f.split(':')[0]]}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default NewJobRow