import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Box, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tabs, Tab, Tooltip } from '@mui/material'
import { Check, Clear, DoubleArrow, OpenInNew } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addErrorProps, positiveInt, positiveFloat, sequence } from '../../functions'
import Checkbox from '../Checkbox'
import DateField from '../DateField'
import Dropdown from '../Dropdown'
import PriceField from '../PriceField'
import { addOrder, getNewOrderNumber, validateOrderNumber, validateOrderNumberRef, findOrders, findPartOrders, addPartOrder, getLastPurchaseOrder, getLastSalesOrder } from './functions'
import { findContacts, getContact } from '../Contacts/functions'
import SelectContact from '../Contacts/SelectContact'
import PartName from '../Parts/PartName'

function CreateOrder({ companyId, button, buttonStyle, forceOpen, setForceOpen, data, jobs, onCreate, noBubble }) {
  const [isCreating, setIsCreating] = useState(false)
  const [order, setOrder] = useState()
  const [partOrders, setPartOrders] = useState([])
  const [selected, setSelected] = useState([])
  const [errors, setErrors] = useState({})

  const [isOpening, setIsOpening] = useState(true)
  const [orderType, setOrderType] = useState(data.isPurchasing ? 'PURCHASE' : 'SALES')

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsCreating(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isCreating !== forceOpen) setIsCreating(forceOpen)
  }, [forceOpen, isCreating])

  const toggleSelect = (item, checked) => {
    if (checked && !selected.find(i => i.id === item.id)) {
      return setSelected([...selected, item])
    }
    return setSelected(selected.filter(i => i.id !== item.id))
  }

  const updatePartOrders = (id, updates) => setPartOrders(partOrders.map(partOrder => {
    if (partOrder.id !== id) return partOrder
    return { ...partOrder, ...updates }
  }))

  const update = updates => {
    const { isNumberAuto, number } = updates
    setOrder({
      ...order,
      ...updates,
      ...!isNumberAuto && number && { isNumberAuto: false },
    })
  }

  const validate = async () => {
    const errors = {}
    if (!get(order, 'number')) errors.number = 'Required'
    if (get(order, 'number') && !await validateOrderNumber(order.number, companyId)) errors.number = 'Conflicted'
    if (get(order, 'numberRef') && !await validateOrderNumberRef(order.numberRef, companyId)) errors.numberRef = 'Conflicted'
    if (!get(order, 'orderAt')) errors.orderAt = 'Required'
    if (!get(order, 'shipFromContactId')) errors.shipFromContactId = 'Required'
    if (!get(order, 'billToContactId')) errors.billToContactId = 'Required'
    if (!get(order, 'shipToContactId')) errors.shipToContactId = 'Required'
    selected.map(({ id, quantity, unitPrice, dueAt }) => {
      if (!quantity) errors[`quantity-${id}`] = 'Required'
      if (!unitPrice) errors[`unitPrice-${id}`] = 'Required'
      if (!dueAt) errors[`dueAt-${id}`] = 'Required'
    })
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = async () => {
    if (!await validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return addOrder({ ...order, ...!order.numberRef && { numberRef: order.number } })
      .then(async newOrder => {
        enqueueSnackbar('Order created successfully.', { variant: 'success' })
        handleClose()
        await sequence(selected, async ({ lineNumber, partId, quantity, unit, unitPrice, dueAt }) =>
          addPartOrder({
            orderId: newOrder.id,
            lineNumber,
            partId,
            quantity,
            quantityBalance: quantity,
            unit,
            unitPrice,
            dueAt,
            status: 'OPEN',
          }))
        if (onCreate) onCreate(newOrder)
        if (isOpening) navigate(`/orders/${newOrder.id}`)
      })
  }

  useEffect(() => {
    setSelected(partOrders)
  }, [partOrders])

  useEffect(() => {
    if (data.id) findPartOrders({ orderId: data.id, orderBy: ['createdAt','asc'] }).then(setPartOrders)
    if (data.partOrders) setPartOrders(data.partOrders)
    if (jobs) setPartOrders(jobs.map(job => ({ ...job, unit: 'PIECE' })))
  }, [data, jobs])

  useEffect(() => {
    if (isCreating) setOrder(data)
  }, [isCreating, data])

  useAsyncFunc(async () => {
    if (order) {
      const { number, numberRef, isNumberAuto, shipFromContactId, shipToContactId, billToContactId } = order
      if (number) return order
      const [{ id: internalPurchasingContactId }] = await findContacts({ companyId, type: 'CUST', isInternal: true, orderBy: ['createdAt','desc'], limit: 1 })
      const [{ id: internalSalesContactId }] = await findContacts({ companyId, type: 'VEND', isInternal: true, orderBy: ['createdAt','desc'], limit: 1 })
      if (numberRef && !await validateOrderNumberRef(numberRef, companyId)) {
        setErrors({
          ...errors,
          numberRef: (
            <>
              Order already exists
              <Tooltip title="Open In New Window">
                <Link to={`/orders/${await findOrders({ companyId, numberRef, orderBy: ['createdAt','desc'], limit: 1 }).then(([{ id }]) => id)}`} target="_blank" rel="noopener noreferrer">
                  <IconButton size="small" edge="end">
                    <OpenInNew fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
            </>
          ),
        })
      } else {
        delete errors.numberRef
      }
      return {
        ...order,
        ...isNumberAuto && !number && { number: await getNewOrderNumber(companyId) },
        ...orderType === 'PURCHASE' && {
          ...!numberRef && { numberRef: await getLastPurchaseOrder(companyId).then(lastPurchaseOrder => parseInt(get(lastPurchaseOrder, 'numberRef', 0)) + 1) },
          shipToContactId: internalPurchasingContactId,
          billToContactId: internalPurchasingContactId,
          ...shipFromContactId && await getContact(shipFromContactId).then(({ isInternal }) => isInternal) && { shipFromContactId: '' },
        },
        ...orderType === 'SALES' && {
          shipFromContactId: internalSalesContactId,
          ...shipToContactId && await getContact(shipToContactId).then(({ isInternal }) => isInternal) && { shipToContactId: '' },
          ...billToContactId && await getContact(billToContactId).then(({ isInternal }) => isInternal) && { billToContactId: '' },
        },
      }
    }
    return () => null
  }, null, setOrder, [order, orderType, data, companyId])

  return (
    <>
      <Box style={{ ...buttonStyle }} onClick={e => {
        if (noBubble) e.stopPropagation()
        setIsCreating(true)
      }}>
        {button}
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isCreating}
        onClose={handleClose}
      >
        <DialogTitle>{`Create new ${orderType.toLowerCase()} order`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            <Box>
              <TextField
                size="small"
                variant="outlined"
                label="Order number"
                fullWidth
                style={{ marginBottom: '10px' }}
                value={get(order, 'number', '')}
                onChange={({ target: { value } }) => update({ number: value })}
                InputProps={{
                  endAdornment: (
                    <Checkbox
                      checked={get(order, 'isNumberAuto', false)}
                      onChange={async ({ target: { checked } }) => update({ isNumberAuto: checked, ...checked && { number: await getNewOrderNumber(companyId) } })}
                      style={{ marginRight: '-15px', whiteSpace: 'nowrap' }}
                      label="Auto-generate"
                    />
                  ),
                }}
                {...addErrorProps(errors, 'number')}
                disabled
              />
            </Box>
            <Tabs value={orderType} onChange={(event, newValue) => setOrderType(newValue)} variant="fullWidth">
              <Tab label="Purchase Order" value="PURCHASE" />
              <Tab label="Sales Order" value="SALES" />
            </Tabs>
            <TextField
              size="small"
              variant="outlined"
              name="numberRef"
              label="Order reference number"
              fullWidth
              style={{ marginTop: '10px', marginBottom: '10px' }}
              value={get(order, 'numberRef', '')}
              onChange={({ target: { value } }) => update({ numberRef: value })}
              {...addErrorProps(errors, 'numberRef')}
            />
            <DateField
              label="Date"
              value={get(order, 'orderAt')}
              onChange={orderAt => update({ orderAt })}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  style={{ marginBottom: '10px' }}
                  {...addErrorProps(errors, 'orderAt')}
                />
              )}
            />
            <Box style={{ display: 'flex', gap: '10px' }}>
              <Box style={{ flexGrow: 1 }}>
                <SelectContact
                  companyId={companyId}
                  contactId={get(order, 'shipFromContactId', '')}
                  label="Ship from name"
                  onChange={contact => contact && update({
                    shipFromContactId: get(contact, 'id', ''),
                    shipFromName: get(contact, 'name', ''),
                    shipFromAddress: get(contact, 'address', ''),
                    shipFromEmail: get(contact, 'email', ''),
                    shipFromPhone: get(contact, 'phone', ''),
                    shipFromFax: get(contact, 'fax', ''),
                  })}
                  style={{ marginBottom: '10px' }}
                  textFieldProps={{
                    ...addErrorProps(errors, 'shipFromContactId')
                  }}
                  findType="VEND"
                  disabled={orderType === 'SALES'}
                />
              </Box>
              <Box>
                <DoubleArrow style={{ marginTop: '7px' }} />
              </Box>
              <Box style={{ flexGrow: 1 }}>
                <SelectContact
                  companyId={companyId}
                  contactId={get(order, 'shipToContactId', '')}
                  label="Ship to name"
                  onChange={contact => contact && update({
                    shipToContactId: get(contact, 'id', ''),
                    shipToName: get(contact, 'name', ''),
                    shipToAddress: get(contact, 'address', ''),
                    shipToEmail: get(contact, 'email', ''),
                    shipToPhone: get(contact, 'phone', ''),
                    shipToFax: get(contact, 'fax', ''),
                  })}
                  style={{ marginBottom: '10px' }}
                  textFieldProps={{
                    ...addErrorProps(errors, 'shipToContactId')
                  }}
                  findType="CUST"
                  disabled={orderType === 'PURCHASE'}
                />
                <SelectContact
                  companyId={companyId}
                  contactId={get(order, 'billToContactId', '')}
                  label="Bill to name"
                  onChange={contact => contact && update({
                    billToContactId: get(contact, 'id', ''),
                    billToName: get(contact, 'name', ''),
                    billToAddress: get(contact, 'address', ''),
                    billToEmail: get(contact, 'email', ''),
                    billToPhone: get(contact, 'phone', ''),
                    billToFax: get(contact, 'fax', ''),
                  })}
                  style={{ marginBottom: '10px' }}
                  textFieldProps={{
                    ...addErrorProps(errors, 'billToContactId')
                  }}
                  findType="CUST"
                  disabled={orderType === 'PURCHASE'}
                />
              </Box>
            </Box>
            <List>
              {partOrders.map(item => {
                const { id, lineNumber, partId, quantity, unit, unitPrice, dueAt } = item
                const isSelected = !!selected.find(i => i.id === id)
                return (
                  <ListItem key={id} disablePadding>
                    <ListItemIcon>
                      <Checkbox
                        checked={isSelected}
                        onClick={() => toggleSelect(item, !isSelected)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={(
                        <>
                          {`Line ${lineNumber} · `}
                          <PartName partId={partId} />
                        </>
                      )}
                      secondary={(jobs || data.partOrders) ? (
                        <Box style={{ display: 'flex', gap: '5px', paddingTop: '10px' }}>
                          <TextField
                            size="small"
                            variant="outlined"
                            name="quantity"
                            label="Quantity"
                            value={quantity}
                            onChange={({ target: { value } }) => updatePartOrders(id, { quantity: positiveInt(value) })}
                            style={{ maxWidth: '180px' }}
                            InputProps={{
                              endAdornment: (
                                <Dropdown
                                  button={(
                                    <Button
                                      size="small"
                                      variant="outlined"
                                    >
                                      {unit}
                                    </Button>
                                  )}
                                  buttonStyle={{ transform: 'translateX(9px)' }}
                                  items={[
                                    { label: 'piece', value: 'PIECE' },
                                    { label: 'box', value: 'BOX' },
                                    { label: 'pallet', value: 'PALLET' },
                                  ]}
                                  onChange={({ value }) => updatePartOrders(id, { unit: value })}
                                />
                              ),
                            }}
                            {...addErrorProps(errors, `quantity-${id}`)}
                          />
                          <TextField
                            size="small"
                            variant="outlined"
                            name="unitPrice"
                            label="Unit price"
                            placeholder="$"
                            value={unitPrice || ''}
                            onChange={({ target: { value } }) => updatePartOrders(id, { unitPrice: positiveFloat(value) })}
                            InputProps={{
                              inputComponent: PriceField,
                            }}
                            {...addErrorProps(errors, `unitPrice-${id}`)}
                          />
                          <DateField
                            label="Due"
                            value={dueAt}
                            onChange={dueAt => updatePartOrders(id, { dueAt })}
                            renderInput={params => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                style={{ marginBottom: '10px' }}
                                {...addErrorProps(errors, `dueAt-${id}`)}
                              />
                            )}
                          />
                        </Box>
                      ) : (
                        <>
                          {`Quantity: ${quantity} · Unit price: `}
                          <NumberFormat
                            value={unitPrice}
                            prefix="$"
                            thousandSeparator
                            fixedDecimalScale
                            decimalScale={2}
                            displayType="text"
                          />
                          {` / ${unit}`}
                        </>
                      )}
                    />
                  </ListItem>
                )
              })}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box style={{ flexGrow: 1, paddingLeft: '12px' }}>
            <Checkbox
              checked={isOpening}
              onChange={({ target: { checked } }) => setIsOpening(checked)}
              label="Open order after creation"
            />
          </Box>
          <Button
            variant="contained"
            startIcon={<Check />}
            disableElevation
            onClick={submit}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            disableElevation
            onClick={() => setIsCreating(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateOrder