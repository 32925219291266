import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, IconButton, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, Tooltip } from '@mui/material'
import { Add, Remove, Inbox, PieChartOutline, Undo, OfflinePin, Contrast, Visibility, Upload as UploadIcon } from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print'
import { get, sortBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useAsyncFunc, addFileRef, getPartInventory, addUniqueFileRef } from '../../functions'
import Accordion from '../Accordion'
import Checkbox from '../Checkbox'
import FileRefs from '../FileRefs'
import Upload from '../Upload'
import { getPart } from '../Parts/functions'
import PartNumber from '../Parts/PartNumber'
import PartName from '../Parts/PartName'
import EditPartDetails from '../Parts/EditDetails'
import Actions from './Actions'
import EditJobDetails from './EditDetails'
import AllocateJobs from './Allocate'
import JobAllocations from './Allocations'
import JobLines from './JobLines'

function Job({ companyId, button, buttonStyle, inline, forceOpen, setForceOpen, onSelect, onUpdate, onRemove, ...job }) {
  const { id, number, partId, quantity, quantityAvailable, status } = job
  const [jobLines, setJobLines] = useState([])
  const [part, setPart] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isDetailed, setIsDetailed] = useState(false)
  const [isDroppable, setIsDroppable] = useState(false)
  const [isAllocating, setIsAllocating] = useState(false)
  const [dragoverId, setDragoverId] = useState()
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  useAsyncFunc(getPart, partId, setPart, [partId])

  const contentRef = useRef()
  const print = useReactToPrint({
    content: () => contentRef.current,
  })

  const dragStart = () => setIsDroppable(true)
  const dragEnter = (_e, { id }) => setDragoverId(id)
  const dragEnd = (_e, { name, fileId }) => addUniqueFileRef({ name, fileId, refFromId: dragoverId, refFromType: 'job', status: 'ACTIVE' })
    .then(() => {
      enqueueSnackbar('File tagged successfully.', { variant: 'success' })
      setDragoverId()
      setIsDroppable(false)
    })

  const content = (
    <>
      <Upload
        companyId={companyId}
        forceOpen={isUploading}
        setForceOpen={setIsUploading}
        onUploaded={async files => Promise.all(
          files.map(({ id: fileId, name }) => addFileRef({ companyId, name, fileId, refFromId: id, refFromType: 'job', status: 'ACTIVE' }))
        ).then(fileRefs => {
          enqueueSnackbar('Files uploaded successfully.', {
            variant: 'success',
          })
        })}
      />
      <Box style={{ flexGrow: 1 }}>
        <Box style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: 0, padding: '10px', background: '#efefef', borderRadius: '5px', zIndex: 3 }}>
          <Actions item={job} onPrint={print} onSelect={onSelect} onRemove={onRemove} />
        </Box>
        <Stack {...!inline && { style: { marginTop: '5px' } }}>
          <Alert
            severity="info"
            action={(
              <>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<Inbox />}
                  style={{ marginLeft: '5px' }}
                  disableElevation
                  disabled={status !== 'COMPLETED'}
                >
                  Move To Ship
                </Button>
              </>
            )}
          >
            {`Job is ${status.replace('_',' ')}.`}
          </Alert>
        </Stack>
        <Box ref={contentRef}>
          <Box style={{ display: 'flex' }}>
            <Box style={{ width: '60%' }}>
              <Accordion
                title={`J/N ${number}`}
                content={(
                  <EditJobDetails
                    job={{ companyId, ...job }}
                    onUpdate={onUpdate}
                  />
                )}
                defaultExpanded
              />
              <Accordion
                title="Job Traveler"
                afterTitle={(
                  <Box
                    style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                    onClick={e => e.stopPropagation()}
                  >
                    <Checkbox
                      checked={isDetailed}
                      onChange={({ target: { checked } }) => setIsDetailed(checked)}
                      label="Detailed"
                    />
                  </Box>
                )}
                content={(
                  <JobLines
                    companyId={companyId}
                    jobId={id}
                    partId={partId}
                    quantity={quantity}
                    isDetailed={isDetailed}
                    onUpdate={onUpdate}
                    onFetched={setJobLines}
                    isDroppable={isDroppable}
                    dragoverId={dragoverId}
                    dragEnter={dragEnter}
                  />
                )}
                defaultExpanded
              />
            </Box>
            <Box style={{ width: '40%' }}>
              <Box style={{ position: 'sticky', top: '60px' }}>
                <AllocateJobs
                  jobId={id}
                  forceOpen={isAllocating}
                  setForceOpen={setIsAllocating}
                  onAllocated={onUpdate}
                />
                <Accordion
                  title={(
                    <>
                      Allocations
                      <Button
                        size="small"
                        variant="contained"
                        style={{ marginLeft: '10px' }}
                        startIcon={<PieChartOutline />}
                        onClick={e => {
                          e.stopPropagation()
                          setIsAllocating(true)
                        }}
                        disableElevation
                        disabled={quantityAvailable <= 0}
                      >
                        Allocate
                      </Button>
                      <Checkbox
                        checked={true}
                        onChange={() => {}}
                        label="Show completed"
                        style={{ display: 'inline-block', marginLeft: '15px' }}
                      />
                    </>
                  )}
                  content={(
                    <JobAllocations
                      jobId={id}
                    />
                  )}
                />
                <Accordion
                  title={(
                    <>
                      {`P/N `}
                      <PartNumber partId={partId} />
                      {` · `}
                      <PartName partId={partId} />
                    </>
                  )}
                  content={(
                    <EditPartDetails
                      part={part}
                      onUpdate={setPart}
                    />
                  )}
                />
                <Accordion
                  title={(
                    <>
                      Part Files
                    </>
                  )}
                  content={(
                    <FileRefs
                      companyId={companyId}
                      refFroms={[
                        { id: partId, type: 'part' },
                      ]}
                      table
                      onDragStart={dragStart}
                      onDragEnd={dragEnd}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )

  return inline ? content : (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isOpen}
        onClose={handleClose}
      >
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Job