import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, TextField, IconButton, Button, Drawer, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Alert, Chip, Dialog, DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { Add, Visibility, DownloadForOfflineOutlined, ReceiptLongOutlined } from '@mui/icons-material'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { get } from 'lodash'
import { useAsyncFunc, addFileRef, getLocal, setLocal, jobRanges as ranges, jobStatuses as statuses } from '../../functions'
import Checkbox from '../Checkbox'
import Paginate from '../Paginate'
import Upload from '../Upload'
import { findJobs, getJob } from './functions'
import JobFilters from './Filters'
import Job from './Job'
import JobRow from './JobRow'
import NewJobRow from './NewJobRow'
import ImportJobs from './Import'
import OrderLookup from '../Orders/Lookup'
import Part from '../Parts/Part'

function Jobs({ companyId }) {
  const [jobs, setJobs] = useState()
  const [selected, setSelected] = useState()
  const [isUploading, setIsUploading] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [openPartId, setOpenPartId] = useState()
  const [limit, setLimit] = useState(20)
  const [isSyncing, setIsSyncing] = useState(false)

  const [todayFrom, todayTo] = ranges.find(({ label }) => label === 'Today').value

  const [filterBy, setFilterBy] = useState(
    getLocal('jobsFilterBy', {
      label: 'Ship Date',
      value: 'shipAt',
    }))
  const [filterByValue, setFilterByValue] = useState(
    getLocal('jobsFilterByValue', {
      shipAtFrom: todayFrom,
      shipAtTo: todayTo,
      dueAtFrom: todayFrom,
      dueAtTo: todayTo,
    }))
  const [statusIn, setStatusIn] = useState(getLocal('jobsFilterByStatus', statuses.filter(({ value }) => value !== 'CANCELLED').map(({ value }) => value)))

  const navigate = useNavigate()
  const { jobId } = useParams()

  const getFilterArgs = (field, value) => {
    const { shipAtFrom, shipAtTo, dueAtFrom, dueAtTo, numberBeginsWith, poNumberBeginsWith, partId } = value
    return {
      ...field === 'shipAt' && { shipAtFrom, shipAtTo },
      ...field === 'dueAt' && { dueAtFrom, dueAtTo },
      ...field === 'poNumber' && { poNumberBeginsWith, orderBy: ['updatedAt','desc'] },
      ...field === 'partId' && { partId, orderBy: ['updatedAt','desc'] },
    }
  }

  const fetch = () => findJobs({
    companyId,
    ...getFilterArgs(filterBy.value, filterByValue),
    statusIn,
    limit,
  }).then(results => {
    if (selected) setSelected(results.find(({ id }) => id === selected.id))
    return setJobs(results)
  })

  useAsyncFunc(async args => {
    setLocal('jobsFilterBy', filterBy)
    setLocal('jobsFilterByValue', filterByValue)
    setLocal('jobsFilterByStatus', statusIn)
    if (companyId) return findJobs(args)
    return () => []
  }, {
    companyId,
    ...getFilterArgs(filterBy.value, filterByValue),
    statusIn,
    limit,
    ...isSyncing && { onChange: fetch },
  }, setJobs, [companyId, filterBy, filterByValue, statusIn, limit, isSyncing])

  useAsyncFunc(async args => {
    if (jobId) return getJob(args)
    return () => null
  }, jobId, setSelected, [jobId])

  const tableRef = useBottomScrollListener(() => setLimit(limit + 10))

  return (
    <>
      {selected && (
        <Upload
          companyId={companyId}
          forceOpen={isUploading}
          setForceOpen={setIsUploading}
          onUploaded={async files => Promise.all(files.map(({ id: fileId, name }) => addFileRef({ companyId, name, fileId, refFromId: get(selected, 'id'), refFromType: 'job', status: 'ACTIVE' })))}
        />
      )}
      <Drawer
        anchor="right"
        open={!!openPartId}
        onClose={() => setOpenPartId()}
        style={{ zIndex: 1400 }}
      >
        <Box style={{ maxWidth: '75vw' }}>
          <Part partId={openPartId} />
        </Box>
      </Drawer>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <JobFilters
          companyId={companyId}
          beforeFilters={(
            <Box>
              <Button
                size="small"
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setIsAdding(true)}
                style={{ marginLeft: '5px' }}
              >
                New Job
              </Button>
            </Box>
          )}
          afterFilters={(
            <>
              <OrderLookup
                companyId={companyId}
                button={(
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ReceiptLongOutlined />}
                    style={{ marginLeft: '5px' }}
                  >
                    Order Lookup
                  </Button>
                )}
                buttonStyle={{ display: 'inline' }}
              />
              {/* <ImportJobs companyId={companyId} /> */}
              <Checkbox
                checked={isSyncing}
                onChange={({ target: { checked } }) => setIsSyncing(checked)}
                label="Autorefresh"
              />
            </>
          )}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          filterByValue={filterByValue}
          setFilterByValue={setFilterByValue}
          statusIn={statusIn}
          setStatusIn={setStatusIn}
        />
        <TableContainer ref={tableRef}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Ship</TableCell>
                <TableCell>Job number</TableCell>
                <TableCell>PO number</TableCell>
                <TableCell>Line #</TableCell>
                <TableCell>Part number</TableCell>
                <TableCell>Part name</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Due</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Last updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <NewJobRow
                companyId={companyId}
                fields={['shipAt', 'numberRef', 'poNumber', 'lineNumber', 'partId:2', 'quantity', 'balance', 'dueAt', 'status', 'confirm']}
                forceOpen={isAdding}
                setForceOpen={setIsAdding}
                onCreate={fetch}
              />
              {jobs && jobs.length === 0 && (
                <TableRow>
                  <TableCell colSpan={11}>
                    No matching results found.
                  </TableCell>
                </TableRow>
              )}
              {(jobs || []).map(job => (
                <JobRow
                  key={job.id}
                  isSelected={get(selected, 'id') === job.id}
                  selectIcon={<Visibility fontSize="small" />}
                  selectTooltip="View job"
                  onSelect={({ id }) => navigate(`/${id}`)}
                  onUpdate={fetch}
                  onPartOpen={setOpenPartId}
                  {...job}
                />
              ))}
              {jobs && (
                <TableRow>
                  <TableCell colSpan={11} style={{ textAlign: 'center' }}>
                    <Paginate
                      label="Load more"
                      size="small"
                      variant="outlined"
                      startIcon={<DownloadForOfflineOutlined />}
                      disableElevation
                      onClick={() => setLimit(limit + 10)}
                      query={() => findJobs({
                        companyId,
                        ...getFilterArgs(filterBy.value, filterByValue),
                        statusIn,
                        limit: 1,
                        startAfter: get(jobs[jobs.length - 1], '_snap'),
                      })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {selected && (
          <Job
            {...selected}
            companyId={companyId}
            onSelect={setSelected}
            onUpdate={fetch}
            onRemove={() => fetch().then(navigate('/'))}
            forceOpen={!!selected}
            setForceOpen={open => !open && navigate('/')}
          />
        )}
      </Box>
    </>
  )
}

export default Jobs