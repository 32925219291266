import { useState, useEffect } from 'react'
import { Box, TextField, Button, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material'
import { PersonOutline, ArrowDropDown } from '@mui/icons-material'
import { get, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { addErrorProps, updateFile } from '../functions'
import UserName from './User/UserName'
import Accordion from './Accordion'
import File from './File'
import Radio from './Radio'
import RefInventory from './RefInventory'
import Timeline from './Timeline'

function UpdateFile({ button, buttonStyle, inline, forceOpen, setForceOpen, file, onUpdated }) {
  const { id, name, path, type, refNumbers, url, status, createdByUserId, createdAt } = file
  const [isOpen, setIsOpen] = useState(false)
  const [updatedFile, setUpdatedFile] = useState({})
  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
    if (setForceOpen) setForceOpen(false)
  }

  useEffect(() => {
    if (forceOpen !== undefined && isOpen !== forceOpen) setIsOpen(forceOpen)
  }, [forceOpen, isOpen])

  const validate = () => {
    const errors = {}
    if (!get(updatedFile, 'name')) errors.name = 'Required'
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submit = () => {
    if (!validate()) return enqueueSnackbar('Invalid input.', { variant: 'warning' })
    return updateFile(file.id, {
      name: updatedFile.name,
      ...updatedFile.refNumbers && { refNumbers: updatedFile.refNumbers },
      status: updatedFile.status,
      ...updatedFile.status === 'RETIRED' && status !== updatedFile.status && { retiredAt: new Date() },
    })
      .then(updatedFile => {
        enqueueSnackbar('File updated successfully.', { variant: 'success' })
        setIsOpen(false)
        onUpdated(updatedFile)
      })
  }

  const content = (
    <Box style={{ display: 'flex' }}>
      <Box style={{ flexGrow: 1, minHeight: '60vh' }}>
        <File fileId={id} />
      </Box>
      <Box style={{ minWidth: '300px', paddingLeft: '20px', borderLeft: '1px solid #ccc' }}>
        <Box style={{ position: 'sticky', top: '5px' }}>
          <Accordion
            title="Details"
            content={(
              <>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Name"
                  fullWidth
                  value={updatedFile.name || updatedFile.path}
                  onChange={({ target: { value } }) => setUpdatedFile({ ...updatedFile, name: value })}
                  style={{ marginBottom: '10px' }}
                  {...addErrorProps(errors, 'name')}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label="Type"
                  fullWidth
                  value={type}
                  style={{ marginBottom: '10px' }}
                  disabled
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label="Reference numbers"
                  rows={3}
                  multiline
                  fullWidth
                  value={(refNumbers || []).join(',')}
                  onChange={({ target: { value } }) => setUpdatedFile({ ...updatedFile, refNumbers: value.split(',') })}
                  style={{ marginBottom: '10px' }}
                />
                <Radio
                  options={[
                    { label: 'Active', value: 'ACTIVE' },
                    { label: 'Retired', value: 'RETIRED' },
                  ]}
                  value={updatedFile.status || status}
                  onChange={({ target: { value } }) => setUpdatedFile({ ...updatedFile, status: value })}
                  style={{ marginBottom: '10px' }}
                />
                <Box>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={submit}
                    disabled={isEqual(file, updatedFile)}
                  >
                    Confirm
                  </Button>
                </Box>
              </>
            )}
            defaultExpanded
          />
          <Accordion
            title="Usage"
            content={<RefInventory fileId={id} />}
          />
          <Accordion
            title="History"
            content={(
              <Timeline
                items={[
                  { label: (
                    <>
                      <UserName userId={createdByUserId} prefix={<PersonOutline fontSize="small" style={{ marginRight: '5px', verticalAlign: 'sub' }} />} />
                      <Typography>{`Uploaded on ${createdAt.toLocaleDateString()}`}</Typography>
                    </>
                  )},
                ]}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  )

  useEffect(() => {
    setUpdatedFile(file)
  }, [file])

  return inline ? content : (
    <>
      {button && (
        <Box style={{ ...buttonStyle }} onClick={() => setIsOpen(true)}>
          {button}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpen}
        onClose={handleClose}
        style={{ zIndex: 1400 }}
      >
        <DialogTitle>Update file</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingTop: '5px' }}>
            {content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UpdateFile